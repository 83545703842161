import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetPasswordUserName, authCheck, login } from "../../services/authService";
import ReactTooltip from "react-tooltip";
import {
  EncryptOrDecrypt,
} from "../../services/commonService";
import Http from "../../Http";
import Moment from "moment";
import PasswordChecklist from "react-password-checklist";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { BsInfoCircle } from "react-icons/bs";
import { FaCheck, FaTimes } from "react-icons/fa";

const OdysseyLogin = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordUserIdMatchValidation, setPasswordUserIdMatchValidation] = useState(false);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const PasswordViewer = () => {
      setShowPassword(!showPassword);
    };

    const ConfirmPasswordViewer = () => {
      setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        if(password !== '' && password !== undefined){
            if(username.toLowerCase() === password.toLowerCase()){
                setPasswordUserIdMatchValidation(true);
            } else {
                setPasswordUserIdMatchValidation(false);
            }
        }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username) {
            toast.error(t('Please enter username!'));
            return false;
        }

        if (!password) {
            toast.error(t('Please enter Password!'));
            return false;
        }

        if (!confirmPass) {
            toast.error(t('Please enter Confirm Password!'));
            return false;
        }

        if (confirmPass !== password) {
            toast.error(t('Password and Confirm Password should match'));
            return false;
        }

        if (username.toLowerCase() === password.toLowerCase()) {
            toast.error(t('Password cannot be same as username'));
            return false;
        }
        const isContainFlood = /flood/i.test(password); 
        if(isContainFlood){
            return toast.error(t('The password must not include the word flood.'));
        }
        const isContainPassword = /password/i.test(password);
        if(isContainPassword){
            return toast.error(t('The password must not include the word Password.'));
        }

        // eslint-disable-next-line no-useless-escape
        const isValidPassword = /^(?!.*password)(?!.*flood)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!~#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]).{8,}$/.test(password);
        if(!isValidPassword){
            toast.error(t('Password Validation Failed.'));
            return false;
        }

        const params = {
            'username': username,
            'password': password,
            'confirmPass': confirmPass,
        }

        setLoading(true);
        dispatch(resetPasswordUserName(params)).then(async (res) => {
            // eslint-disable-next-line eqeqeq
            if (res.data.status == true) {
                dispatch(login(params)).then(async (response) => {
                    var EncryptUserData = '';
                    var accessToken = '';
                    if (response.success === true) {
                        if (Object.keys(response.data).length > 0 && response.data.status === 200) {
                            accessToken = response.data.access_token;
                            localStorage.setItem('access_token', response.data.access_token);
                            Http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
                            var tokenExpireAt = EncryptOrDecrypt(UTCDateToLocalDate(response.data.access_token_expires_at), 'E');
                            localStorage.setItem('access_token_expires_at', tokenExpireAt);
                            EncryptUserData = EncryptOrDecrypt(response.data.extraDetails, 'E');
                            localStorage.setItem('user', EncryptUserData);
                            localStorage.setItem('is_first_login', response.data.is_first_login);
                            await dispatch(authCheck());
                            navigate('/login');
                        }
                    }
                })
                toast.success(res.data.message);
            }else{
                setUsername("");
                setPassword("");
                setConfirmPass("");
                toast.error(res.data.message);
            }
           
            setLoading(false);
        })

    }

    const UTCDateToLocalDate = (date) => {
        date = date.replace('UTC', '').trim();
        return Moment.utc(date).local().format();
    }

    return (
        <>
        <Flex direction={"column"} width={"100%"} mt={6}>
          <Text fontSize="31px" mb={4}>
            {t("Please set a password")}!
          </Text>
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box>
              <FormControl mb={4}>
                <FormLabel>{t("Username")}</FormLabel>
                <Input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  isRequired
                  placeholder={t("Username")}
                  borderColor="var(--chakra-colors-neutral-500)"
                  _placeholder={{
                    color: "neutral.800",
                    fontSize: "sm",
                  }}
                  autoComplete="off"
                  backgroundColor="var(--chakra-colors-white)"
                  borderRadius={10}
                  width="100%"
                  height={"47px"}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>{t("Password")}</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    maxLength={25}
                    onChange={(e) => setPassword(e.target.value)}
                    isRequired
                    placeholder={t("Password")}
                    borderColor="var(--chakra-colors-neutral-500)"
                    _placeholder={{
                      color: "neutral.800",
                      fontSize: "sm",
                    }}
                    autoComplete="new-password"
                    backgroundColor="var(--chakra-colors-white)"
                    borderRadius={10}
                    width="100%"
                    height={"47px"}
                  />
                  <InputRightElement h={"100%"}>
                    <Flex
                      align={"center"}
                      pr={3}
                      cursor={"pointer"}
                      onClick={PasswordViewer}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Flex>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl mb={4}>
                <FormLabel display={"flex"}>
                  {t("Confirm Password")}
                  <BsInfoCircle
                    style={{
                      marginTop: "4px",
                      marginLeft: "4px",
                    }}
                    data-tip
                    data-for="password"
                    data-tooltip-delay-hide={5000}
                  />
                  <ReactTooltip id="password" effect="solid">
                    <PasswordChecklist
                      rules={[
                        "minLength",
                        "capital",
                        "lowercase",
                        "number",
                        "specialChar",
                        "match",
                      ]}
                      minLength={8}
                      maxLength={25}
                      // eslint-disable-next-line no-useless-escape
                      specialCharsRegex={/[@!~#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/}
                      value={password}
                      valueAgain={confirmPass}
                      messages={{
                        minLength: t(
                          "Must be 8-25 characters in length and cannot contain any spaces"
                        ),
                        capital: t(
                          "Must contain at least one uppercase alphabetic character: A-Z"
                        ),
                        lowercase: t(
                          "Must contain at least one lowercase alphabetic character: a-z"
                        ),
                        number: t("Must contain at least one digit"),
                        specialChar: t(
                          "Must contain at least one non-alphabetic 'special' character: i.e.: -#&$*!~@%+"
                        ),
                        match: t("Password and confirm password must match"),
                      }}
                    />
                    <Text display={"flex"} mb={0}>
                      {!(password !== "" && password !== undefined) ||
                      passwordUserIdMatchValidation ? (
                        <FaTimes size={22} color="red" />
                      ) : (
                        <FaCheck size={18} color="#4BCA81" />
                      )}
                      <Text
                        ml={
                          !(password !== "" && password !== undefined) ||
                          passwordUserIdMatchValidation
                            ? 0
                            : "6px"
                        }
                        mb={0}
                        as={"span"}
                        style={{
                          opacity:
                            passwordUserIdMatchValidation || password === ""
                              ? 0.5
                              : null,
                        }}
                      >
                        {t("Password cannot be same as username")}
                      </Text>
                    </Text>
                  </ReactTooltip>
                </FormLabel>
                <InputGroup>
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPass}
                    maxLength={25}
                    onChange={(e) => setConfirmPass(e.target.value)}
                    isRequired
                    placeholder={t("Confirm Password")}
                    borderColor="var(--chakra-colors-neutral-500)"
                    _placeholder={{
                      color: "neutral.800",
                      fontSize: "sm",
                    }}
                    autoComplete="new-password"
                    backgroundColor="var(--chakra-colors-white)"
                    borderRadius={10}
                    width="100%"
                    height={"47px"}
                  />
                  <InputRightElement h={"100%"}>
                    <Flex
                      align={"center"}
                      pr={3}
                      cursor={"pointer"}
                      onClick={ConfirmPasswordViewer}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </Flex>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Box>
                <Button
                  w={"100%"}
                  my={4}
                  borderRadius={"10px"}
                  fontWeight="var(--chakra-fontWeights-medium)"
                  fontSize={16}
                  type="submit"
                  border={"1px solid var(--chakra-colors-primary-500)"}
                  width="100%"
                  height={"47px"}
                  isDisabled={loading}
                >
                  {t("Set Password & Login")}
                </Button>
              </Box>
              <Box width={"100%"}>
                <Button
                  background="var(--chakra-colors-white)"
                  color="var(--chakra-colors-black)"
                  borderRadius="10px"
                  fontWeight="var(--chakra-fontWeights-medium)"
                  fontSize={16}
                  border="1px solid var(--chakra-colors-neutral-200)"
                  width="100%"
                  _hover={{
                    background: "var(--chakra-colors-primary-500)",
                    color: "var(--chakra-colors-white)",
                  }}
                  onClick={() => navigate("/login")}
                  height={"47px"}
                  isDisabled={loading}
                >
                  {t("Go to Login")}
                </Button>
              </Box>
            </Box>
          </form>
        </Flex>
        </>
    )
}

export default OdysseyLogin; 