import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
  GridItem,
  Text,
  VStack,
  Button,
  Image,
  IconButton,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { InputAdapter, TextMask } from "react-text-mask-hoc";
import { downloadW9CopyServer } from "../../../services/enrollService";
import { FaFileDownload } from "react-icons/fa";
import { IoArrowBackSharp, IoCloseSharp } from "react-icons/io5";
import { GrFormNextLink } from "react-icons/gr";
import { PhoneNumberMask } from "../../common/Helper";
import { useDropzone } from "react-dropzone";
import { isEmpty } from "lodash";

export default function AgencyDetails({
  nextStep,
  formDataArray,
  setFormDataArray,
  validateEmail,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onDrop = useCallback((event) => {
    const file = event[0];
    const allowedMimeTypes = ["application/pdf"];
    if (file && allowedMimeTypes.includes(file.type)) {
      setFormDataArray((prev) => ({
        ...prev,
        W9_Form: file,
      }));
    } else {
      deleteFile();
      toast.error(t("Please upload a PDF file"), {
        toastId: "uploadPdfFile",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const deleteFile = () => {
    setFormDataArray((prev) => ({
      ...prev,
      W9_Form: null,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataArray((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (formDataArray.agency_name.trim() === "") {
      toast.error("Agency Name is required");
      return false;
    }
    if (formDataArray.corp_status.trim() === "") {
      toast.error("Corporate Status is required");
      return false;
    }
    if (formDataArray.tax_type.trim() === "") {
      toast.error("Tax Type is required");
      return false;
    }
    if (formDataArray.tax_number.trim() === "") {
      toast.error("FEIN/SSN# is required");
      return false;
    }
    if (formDataArray.contact_s_ContactName.trim() === "") {
      toast.error("Contact name is required");
      return false;
    }
    if (formDataArray.phone_s_PhoneNumber.trim() === "") {
      toast.error("Phone Number is required");
      return false;
    }
    if (formDataArray.contact_s_Email.trim() === "") {
      toast.error("Email is required");
      return false;
    }
    if (!validateEmail(formDataArray.contact_s_Email)) {
      toast.error("Contact Email is invalid!");
      return false;
    }
    if (formDataArray.n_AgencyNPN.trim() === "") {
      toast.error("Agency NPN is required");
      return false;
    }
    if (!formDataArray.W9_Form) {
      toast.error("W9 Form is required");
      return false;
    }
    nextStep();
  };

  const handleFileDownload = () => {
    if (formDataArray?.W9_Form) {
      const url = URL.createObjectURL(formDataArray?.W9_Form);
      const a = document.createElement("a");
      a.href = url;
      a.download = formDataArray?.W9_Form.name;
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  const downloadW9Copy = () => {
    dispatch(downloadW9CopyServer()).then(async (res) => {
      if (res?.success) {
        window.open(res?.url);
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <>
      <Box>
        <VStack spacing={4} w={"100%"}>
          <Box
            borderRadius={"2xl"}
            border={"1px solid"}
            borderColor={{ lg: "whiteAlpha.100", base: "white" }}
            p={{ base: 3, sm: 4, md: 6, lg: 8 }}
            boxShadow={"0 0 6px rgba(0, 0, 0, 0.3)"}
            width={"65rem"}
            maxWidth={"70rem"}
            bgColor={"white"}
          >
            <Text fontWeight={"600"} fontSize={"3xl"}>
              {t("Agency Details")}
            </Text>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3,1fr)",
              }}
              gap={{ base: 3, md: 5, xl: 6 }}
            >
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="agency_name">
                    {t("Agency Name")}:
                  </FormLabel>
                  <Input
                    id="agency_name"
                    name="agency_name"
                    value={formDataArray.agency_name}
                    onChange={handleChange}
                    bgColor={"white"}
                    className="dashboard-group-input"
                    type="text"
                    autoComplete="off"
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="corp_status">
                    {t("Corporate Status")}:
                  </FormLabel>
                  <Select
                    id="corp_status"
                    name="corp_status"
                    bgColor={"white"}
                    className="dashboard-group-input"
                    value={formDataArray.corp_status}
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    <option value="YES">{t("Yes")}</option>
                    <option value="NO">{t("No")}</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel htmlFor="DBA_name">{t("DBA Name")}:</FormLabel>
                  <Input
                    id="DBA_name"
                    name="DBA_name"
                    value={formDataArray.DBA_name}
                    onChange={handleChange}
                    autoComplete="off"
                    bgColor={"white"}
                    className="dashboard-group-input"
                    type="text"
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="tax_type">{t("Tax Type")}:</FormLabel>
                  <Select
                    id="tax_type"
                    name="tax_type"
                    value={formDataArray.tax_type}
                    onChange={handleChange}
                    bgColor={"white"}
                    className="dashboard-group-input"
                  >
                    <option value=""></option>
                    <option value="FEIN">{t("FEIN")}</option>
                    <option value="SS">{t("SS")}</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>{t("FEIN/SSN#")}:</FormLabel>
                  <TextMask
                    mask={[
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    Component={InputAdapter}
                    id="tax_number"
                    name="tax_number"
                    bgColor={"white"}
                    className="form-control dashboard-group-input"
                    value={formDataArray.tax_number}
                    onChange={handleChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel htmlFor="agency_code">
                    {t("Agency Code")}:
                  </FormLabel>
                  <Input
                    id="agency_code"
                    name="agency_code"
                    autoComplete="off"
                    type="text"
                    bgColor={"white"}
                    className="dashboard-group-input"
                    value={formDataArray.agency_code}
                    onChange={handleChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="contact_s_ContactName">
                    {t("Contact Person")}:
                  </FormLabel>
                  <Input
                    id="contact_s_ContactName"
                    name="contact_s_ContactName"
                    autoComplete="off"
                    type="text"
                    bgColor={"white"}
                    className="dashboard-group-input"
                    value={formDataArray.contact_s_ContactName}
                    onChange={handleChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel htmlFor="web_s_WebsiteURL">
                    {t("Website")}:
                  </FormLabel>
                  <Input
                    id="web_s_WebsiteURL"
                    name="web_s_WebsiteURL"
                    autoComplete="off"
                    type="text"
                    bgColor={"white"}
                    className="dashboard-group-input"
                    value={formDataArray.web_s_WebsiteURL}
                    onChange={handleChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="phone_s_PhoneNumber">
                    {t("Phone Number")}:
                  </FormLabel>
                  <TextMask
                    mask={PhoneNumberMask}
                    Component={InputAdapter}
                    id="phone_s_PhoneNumber"
                    name="phone_s_PhoneNumber"
                    bgColor={"white"}
                    className="form-control dashboard-group-input"
                    autoComplete="none"
                    onChange={handleChange}
                    parentobject="mainNode"
                    value={formDataArray.phone_s_PhoneNumber}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel htmlFor="fax_s_FaxNumber">{t("Fax")}:</FormLabel>
                  <TextMask
                    mask={PhoneNumberMask}
                    Component={InputAdapter}
                    id="fax_s_FaxNumber"
                    name="fax_s_FaxNumber"
                    bgColor={"white"}
                    className="form-control dashboard-group-input"
                    autoComplete="none"
                    onChange={handleChange}
                    parentobject="mainNode"
                    value={formDataArray.fax_s_FaxNumber}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="contact_s_Email">{t("Email")}:</FormLabel>
                  <Input
                    id="contact_s_Email"
                    name="contact_s_Email"
                    autoComplete="off"
                    type="email"
                    bgColor={"white"}
                    className="dashboard-group-input"
                    value={formDataArray.contact_s_Email}
                    onChange={handleChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="n_AgencyNPN">
                    {t("Agency NPN")}:
                  </FormLabel>
                  <Input
                    id="n_AgencyNPN"
                    name="n_AgencyNPN"
                    autoComplete="off"
                    type="text"
                    bgColor={"white"}
                    className="dashboard-group-input"
                    value={formDataArray.n_AgencyNPN}
                    onChange={handleChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <Button
                  mt={2}
                  width={"100%"}
                  onClick={downloadW9Copy}
                  leftIcon={<FaFileDownload />}
                >
                  {t("Download W9 Form")}
                </Button>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="W9_Form">
                    {t("Upload W9 Form")}:
                  </FormLabel>
                  <Flex
                    width={"max-content"}
                    {...getRootProps({ className: "dropzone" })}
                  >
                    <Image
                      maxWidth={"300px"}
                      src="/assets/images/uploadImageFrame.svg"
                      alt="upload"
                      cursor={"pointer"}
                    />
                    <Input
                      id="inputE"
                      type="file"
                      name="W9_Form"
                      accept=".pdf"
                      multiple=""
                      autoComplete="off"
                      className="inp-file"
                      {...getInputProps()}
                    />
                  </Flex>
                  {!isEmpty(formDataArray?.W9_Form) ? (
                    <Box mt={2}>
                      <Button variant="link" onClick={handleFileDownload}>
                        {formDataArray.W9_Form.name}
                      </Button>
                      <IconButton
                        ml={2}
                        colorScheme="red"
                        onClick={deleteFile}
                        size={"xs"}
                        icon={<IoCloseSharp size={18} />}
                      />
                    </Box>
                  ) : (
                    <Text mb={0} mt={2} color="red.500" fontSize="xs">
                      {t("PDF File is Required")}
                    </Text>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
          </Box>
          <Container pt={4} pb={6} minH={"10vh"}>
            <Flex gap={6} alignItems={"center"} justifyContent={"center"}>
              <Box>
                <Button
                  variant={"outline"}
                  borderRadius={"xl"}
                  _hover={{ color: "white", bgColor: "primary.500" }}
                  isDisabled
                  leftIcon={<IoArrowBackSharp size={18} />}
                >
                  {t("Back")}
                </Button>
              </Box>
              <Box>
                <Button
                  type="submit"
                  color={"white"}
                  bgColor={"primary.500"}
                  borderRadius={"xl"}
                  onClick={handleSubmit}
                  rightIcon={<GrFormNextLink size={24} />}
                >
                  {t("Next")}
                </Button>
              </Box>
            </Flex>
          </Container>
        </VStack>
      </Box>
    </>
  );
}
