import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  PieChart,
  Pie,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./dashboard.scss";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { pieChartData, graphData, getDashboardNotification, getAgentTasksList, getFemaUpdates,  getClosedClaimData, getOpenClaimData, getApplicationStats,getUpcommingRenewalsStatistics,getCancelledPolicies, getPaidApplications, getPaidRenewals, getExpiredRenewals, getAgentTargetData , getNpnDetails, updateNpnDetails} from "../../services/dashboardService";
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import Tour from 'reactour';
import { useTranslation } from "react-i18next";
import { EncryptOrDecrypt , getTenant} from "../../services/commonService";
import ReactTooltip from "react-tooltip";
import { FaWalking } from "react-icons/fa";
import Moment from 'moment';
import springPromoTrackerLogo from '../../assets/spring-flood-promo/spring-flood-promo-logo.png';
import springPromotionDetailsFile from '../../assets/spring-flood-promo/promotion_details.pdf';
import { tenant } from "../common/Helper";
import {  Modal,
          ModalOverlay,
          ModalContent ,
          ModalHeader,
          ModalCloseButton,
          ModalBody,
          FormControl,
          FormLabel,
          Input,
          InputGroup,
          Icon,
          InputRightElement,
          ModalFooter,
          Box,
          Button
 } from "@chakra-ui/react";
 
import Handle from "rc-slider/lib/Handles/Handle";
import { starTenancySynonyms } from "../quote/common/tenantSynonyms";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [PieChartData, setPieChartData] = useState({});
  const [MonthlyGraphData, setMonthlyGraphData] = useState({});
  const [topLayoutData, setTopLayoutData] = useState([]);
  const [pendingTasksCount, setPendingTasksCount] = useState(0);
  const [femaUpdateMsg, setFemaUpdateMsg] = useState({ __html: "" });
  const [text, setText] = useState('');
  const [nameOption, setNameOption] = useState("self");
  const [applicationsData, setApplicationsData] = useState(0);
  const [upcomingData, setUpcomingData] = useState(0);
  const [expireData, setExpireData] = useState(0);
  const [cancelData, setCancelData] = useState(0);
  const [closedClaimData, setClosedClaimData] = useState(0);
  const [closedClaimCount, setClosedClaimCount] = useState(0);
  const [openClaimCount, setOpenClaimCount] = useState(0);
  const [paidApplicationData, setPaidApplicationData] = useState(0);
  const [paidRenewData, setPaidRenewData] = useState(0);
  const [isOpenGuide, setIsOpenGuide] = useState(false);
  const [isApplicationApiLoaded, setIsApplicationApiLoaded] = useState(false);
  const [isUpcomingRenewalApiLoaded, setIsUpcomingRenewalApiLoaded] = useState(false);
  const [isPaidRenewalApiLoaded, setIsPaidRenewalApiLoaded] = useState(false);
  const [isPaidApplicatiionApiLoaded, setIsPaidApplicatiionApiLoaded] = useState(false);
  const [isOpenClaimApiLoaded, setIsOpenClaimApiLoaded] = useState(false);
  const [isCloseClaimApiLoaded, setIsCloseClaimApiLoaded] = useState(false);
  const [isCancelPoliciesApiLoaded, setIsCancelPoliciesApiLoaded] = useState(false);
  const [isExpiredPoliciesApiLoaded, setIsExpiredPoliciesApiLoaded] = useState(false);
  const [agentTargetData, setagentTargetData] = useState(0);
  const [viewPromoDashboard, setViewPromoDashboard] = useState(false);
  const [showNpnModal, setShowNpnModal] = useState(false);
  const [showLaterBtn, setShowLaterBtn] = useState(false);
  const [agentNpnValue, setAgentNpnValue] = useState('');
  const [agencyNpnValue, setAgencyNpnValue] = useState('');
  const [agentPersonIdValue, setAgentPersonIdValue] = useState('');
  const [agencyPersonIdValue, setAgencyPersonIdValue] = useState('');
  const [updateStatus, setUpdateStatus] = useState(false);
  
  const tenant = getTenant();
  const location = useLocation();
  const { t } = useTranslation();
  const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
  var layoutData = [];

  useEffect( () => {
      setLoading(true);
      const storedCondNpn = localStorage.getItem('CondNpn')==="null" ? JSON.parse(localStorage.getItem('CondNpn')) : localStorage.getItem('CondNpn');

      if(storedCondNpn === null){
          dispatch(getNpnDetails()).then((res)=>{
            const data = res.data;
            setShowNpnModal(data.modelBox);
            setShowLaterBtn(data.askMeLaterButtonStatus);
            setAgencyNpnValue(data.agencyNPN);
            setAgentNpnValue(data.agentNPN);
            setAgentPersonIdValue(data.agentPersonId);
            setAgencyPersonIdValue(data.agencyPersonId);  
          }).catch(()=>{
            toast.error("something went wrong!")
          })
      }
      getDashboardNotificationData();
      PendingTasksCount();
      ApplicationAllData();
      UpComingData();
      PaidRenewData();
      PaidApplicationData();
      OpenedClaimData();
      ClosedClaimData();
      CancelData();
      ExpireData();
    }, []);

  useEffect(() => {
    getPieChartData();
    getGraphData();
    getDashboardNotificationData();
    if(tenant === 'farmers' || tenant === 'foremost'){
      getAgentTarget();
    }
  }, []);

  useEffect(() => {
	checkFemaUpdates();
  }, [localStorage.getItem('lang') || 'en']);

  useEffect (() => {
    if(checkLoaderCondition()){
      setLoading(false)
    }
  },[
      isApplicationApiLoaded,
      isUpcomingRenewalApiLoaded,
      isPaidRenewalApiLoaded,
      isPaidApplicatiionApiLoaded,
      isOpenClaimApiLoaded,
      isCloseClaimApiLoaded,
      isCancelPoliciesApiLoaded,
      isExpiredPoliciesApiLoaded,
  ]);


  const getPieChartData = async () => {
    await dispatch(pieChartData()).then((res) => {
      setPieChartData(res.data.data)
    }).catch(() => {
    });
  }

  const getGraphData = async () => {
    await dispatch(graphData()).then((res) => {
      setMonthlyGraphData(res.data.data)
    })
  }

  const PendingTasksCount = async () => {
    const params = {
      limit: 0,
      page: 1,
      pageLength: 10,
    }
    await dispatch(getAgentTasksList(params)).then((res)=>{
      setPendingTasksCount(res.data.data.total)
    })
  }

  const checkFemaUpdates = async () => {
    await dispatch(getFemaUpdates()).then((res) => {
      setText(res.data.data.msg);
      setFemaUpdateMsg({ __html: res.data.data.msg });
    })
  }

  const getDashboardNotificationData = async () => {
    const getData = await dispatch(getDashboardNotification('Agent - Dashboard')).then((res) => {
      var delayTime = 2000;
      res.data.map((e, i) => {
        let pop = JSON.parse(e.layout_type)
        if (pop.popout == "POPUP") {
          toast.success(e.s_message_body.replace(/<[^>]+>/g, ' '), { position: toast.POSITION.TOP_RIGHT, delay: delayTime });
        } else if (pop.top_layout == 'TOP LAYOUT') {
          setTopLayoutData(prevArray => ([...prevArray, e]))
        }
        delayTime = delayTime + 5000;
      })
    });
  }

  function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const pieChartDataObject = [
    {
      data: [
        { name: "Application", value: PieChartData.BOUND ? PieChartData.BOUND : 0, fill: "#64D6CD" },
        { name: "Quote", value: PieChartData.QUOTE ? PieChartData.QUOTE : 0, fill: "#D68928" },
        { name: "Issued", value: PieChartData.ISSUED ? PieChartData.ISSUED : 0, fill: "#33D14D" },
      ],
    },
  ];

  const pendingTaskTabColor = tenant == 'amfam' || tenant == 'ngm'  ? {backgroundColor: 'orange'} : {} ;

  // const lineChartData = [
  //   {
  //     name: "Jan",
  //     Bound: 20,
  //     Quote: 100,
  //     Renewals: 200,
  //     Transactions: 300,
  //   },
  //   {
  //     name: "Feb",
  //     Bound: 400,
  //     Quote: 500,
  //     Renewals: 600,
  //     Transactions: 700,
  //   },
  //   {
  //     name: "Mar",
  //     Bound: 800,
  //     Quote: 900,
  //     Renewals: 100,
  //     Transactions: 110,
  //   },
  //   {
  //     name: "Apr",
  //     Bound: 120,
  //     Quote: 500,
  //     Renewals: 200,
  //     Transactions: 340,
  //   },
  //   {
  //     name: "May",
  //     Bound: 20,
  //     Quote: 90,
  //     Renewals: 70,
  //     Transactions: 500,
  //   },
  //   {
  //     name: "Jun",
  //     Bound: 20,
  //     Quote: 550,
  //     Renewals: 600,
  //     Transactions: 500,
  //   },
  //   {
  //     name: "Jul",
  //     Bound: 20,
  //     Quote: 200,
  //     Renewals: 100,
  //     Transactions: 500,
  //   },
  // ];

  // const productsGenerator = (quantity) => {
  //   const items = [];
  //   for (let i = 0; i < quantity; i++) {
  //     items.push({
  //       policy_no: "HO32014012226",
  //       insured_name: "ROGE DAMEUS",
  //       product_type: "HO6 (Homeowners)",
  //       renew_date: "MM/DD/YYYY",
  //     });
  //   }
  //   return items;
  // };

  // const products = productsGenerator(5);

  // const columns = [
  //   {
  //     dataField: "policy_no",
  //     text: "Policy No",
  //   },
  //   {
  //     dataField: "insured_name",
  //     text: "Insured Name",
  //   },
  //   {
  //     dataField: "product_type",
  //     text: "Product Type",
  //   },
  //   {
  //     dataField: "renew_date",
  //     text: "Renew Date",
  //   },
  // ];

  const ApplicationAllData = async () => {
    await dispatch(getApplicationStats()).then((res) => {
      setApplicationsData(res);
      setIsApplicationApiLoaded(true)
    }).catch(() => {
      setIsApplicationApiLoaded(true)
    });
    
  }

  const UpComingData = async () => {

    await dispatch(getUpcommingRenewalsStatistics()).then((res) => {
      setUpcomingData(res);
      setIsUpcomingRenewalApiLoaded(true)
    }).catch(() => {
      setIsUpcomingRenewalApiLoaded(true)
    });
    
  }

  const ExpireData = async (type = nameOption, transType = "RENEWALSEXPIRATION") => {
    await dispatch(getExpiredRenewals()).then((res) => {
      setExpireData(res);
      setIsExpiredPoliciesApiLoaded(true)
    }).catch(() => {
      setIsExpiredPoliciesApiLoaded(true)
    });
    
  }

  const CancelData = async () => {
    await dispatch(getCancelledPolicies()).then((res) => {
      setCancelData(res);
      setIsCancelPoliciesApiLoaded(true);
    }).catch(() => {
      setIsCancelPoliciesApiLoaded(true)
    });
    
  }

  const ClosedClaimData = async (type = nameOption) => {
    await dispatch(getClosedClaimData(type)).then((res) => {
      setClosedClaimData(res.data[0].total_amount);
      setClosedClaimCount(res.data[0].claim_count);
      setIsCloseClaimApiLoaded(true);
    }).catch(() => {
      setIsCloseClaimApiLoaded(true)
    });
    
  }

  const OpenedClaimData = async (type = nameOption) => {
    await dispatch(getOpenClaimData(type)).then((res) => {
      setOpenClaimCount(res.data[0].claim_count);
      setIsOpenClaimApiLoaded(true);
    }).catch(() => {
      setIsOpenClaimApiLoaded(true)
    });
  }

  const PaidApplicationData = async () => {
    await dispatch(getPaidApplications()).then((res) => {
      setPaidApplicationData(res);
      setIsPaidApplicatiionApiLoaded(true)
    }).catch(() => {
      setIsPaidApplicatiionApiLoaded(true)
    });
  }

  const PaidRenewData = async () => {
    await dispatch(getPaidRenewals()).then((res) => {
      setPaidRenewData(res);
      setIsPaidRenewalApiLoaded(true)
    }).catch(() => {
      setIsPaidRenewalApiLoaded(true)
    });
  }
  const getAgentTarget = async () => {
    await dispatch(getAgentTargetData()).then((res) => {
      if(res.data?.baseData?.agency_upn !== null && res.data?.baseData?.agency_upn !== 0 ){
        setViewPromoDashboard(true);
      }
      setagentTargetData(res.data);
    })
  }

  const namelist = [
    { value: 'all', label: 'All Data' },
    { value: 'self', label: 'My Data' }
  ]

  const namelistdata = (e) => {
    // if(devKey !== "PROD"){
      setNameOption(e.target.value);
      ApplicationAllData(e.target.value);
      UpComingData(e.target.value);
      ExpireData(e.target.value);
      CancelData(e.target.value);
      ClosedClaimData(e.target.value);
      OpenedClaimData(e.target.value);
      PaidApplicationData(e.target.value);
      PaidRenewData(e.target.value);
    // }
  }

  function commafy(num) {
    num.toString().replace(/\B(?=(?:\d{3})+)$/g, ",");
  }

  const tourGuide = () => {
    setIsOpenGuide(true);

  }

  const closeTourguide = () => {
    setIsOpenGuide(false);
  }

  const steps = [
    {
      selector: '.zero-step',
      content: <div>
        {t("Welcome to your Flood Processing Portal: Odyssey! Let's take a moment to show you around the portal and its features")}
      </div>
    },
    {
      selector: '.first-step',
      content: <div>
        {t("Now that you are logged in, manage your profile and more by selecting the profile icon in the top right corner of the screen")}
      </div>
    },
    {
      selector: '.Second-step',
      content: <div>
        {t("Expand and collapse the menu on the left banner by selecting the arrow icon. Select any of the menu options to direct to the corresponding page within the portal. A blue bar will appear to the left of the icon and text of the page you are on, for example")}
      </div>
    },
    {
      selector: '.Third-step',
      content: <div>
        {t("Get notified when you have items needing your attention")}
      </div>
    },
    {
      selector: '.Forth-step',
      content: <div>
        {t("Select the Create Quote button from the top banner of any page to start an instant flood quote")}
      </div>,
      position: 'top'
    },
    {
      selector: '.Fifth-step',
      content: <div>
        {t("Want to learn more about the portal? Access flood system training and resources in the Agent Training & Resources page")}
      </div>,
      position: 'top'
    },
    {
      selector: '.Fifth-path-step',
      content: <div>
        {t("Select the Pending Tasks banner from the dashboard or select the Notification icon from the top banner of any page to see your action items")}
      </div>
    },
    {
      selector: '.Sixth-step',
      content: <div>
        {t("Quickly access and manage your flood book of business from the Dashboard. See a summary of your book of business. Select any blue or green button to drill down into business details and export a csv report")}
      </div>,
      position: 'bottom'
    },
  ]
  const checkLoaderCondition = () => {
    return isApplicationApiLoaded  &&
    isUpcomingRenewalApiLoaded  &&
    isPaidRenewalApiLoaded  &&
    isPaidApplicatiionApiLoaded  &&
    isOpenClaimApiLoaded  &&
    isCloseClaimApiLoaded  &&
    isCancelPoliciesApiLoaded  &&
    isExpiredPoliciesApiLoaded
  }

  const handleUpdateNpn = () => {
          setUpdateStatus(true);
          const params = {
            agencyNPN: agencyNpnValue,
            agentNPN: agentNpnValue,
            agentPersonId: agentPersonIdValue,
            agencyPersonId: agencyPersonIdValue,
          };
          dispatch(updateNpnDetails(params))
            .then((res) => {
              toast.success(res.data.message);
              localStorage.setItem('CondNpn', false);
              setShowNpnModal(false);
              setUpdateStatus(false);
            })
            .catch((err) => {
              setShowNpnModal(true);
              setUpdateStatus(false);
              if (err.status == 422) {
                Object.entries(err.data.data).forEach(([key, messages]) => {
                  messages.forEach((message) => {
                    toast.error(message);
                  });
                });
              } else {
                toast.error('Something went wrong!');
              }
            });
  };
  
  const laterFunc = () =>{
    setShowNpnModal(false);
    localStorage.setItem('CondNpn', false);
  }

  const targetCheck = agentTargetData?.achieved_target_count >= agentTargetData?.current_target;

  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      <div className="dashboard-page-container Forth-step">
        <Row>
          {
            topLayoutData.length > 0 ?
              topLayoutData.map((e, i) => {
                return (
                  <TopLayoutView mesgBody={e.s_message_body} />
                )
              })
              : ''
          }
        </Row>
        {/* <Row className="align-items-center">
          <Col xs="12">
            <div className="dashbaord-select-div">
            <p className="select-ym-text">Select By Year and Month</p>
            <div className="dashboard-page-selections">
              <Form.Select className="d-main-select" id="select-ym-option">
                <option>2021</option>
                <option>2020</option>
                <option>2019</option>
              </Form.Select>
              <Form.Select className="d-main-select" id="select-ym-option">
                <option>December</option>
                <option>November</option>
                <option>October</option>
              </Form.Select>
            </div>
          </div>
          </Col>
        </Row> */}
        {!['starinsurance', 'starvantage'].includes(tenant)  && (
        <div> 
          {Moment(Moment().format("YYYY-MM-DD")).isSameOrBefore("2024-11-30") && (
            <Alert variant="warning" style={{fontSize: "13px"}}>
              <p style={{ marginBottom: "10px" }}>
                On 10/4/2024 - FEMA issued bulletin W-24009, which extends the
                30-day grace period to 11/26/2024 for policyholders located in the
                following states: GA, NC, SC, TN, KY, VA.
              </p>
              <ul>
                <li>
                  This grace period extension applies to policies with expiration
                  dates on or between 8/28/2024 - 10/27/2024.
                </li>
                <li>
                  This grace period extension applies to policies with
                  underpayment notices dated on or between 8/28/2024 - 10/27/2024.
                </li>
              </ul>
              <p style={{ margin: 0 }}>
                Policies eligible for this grace period extension have a revised
                due date of 11/26/2024.
              </p>
              <p style={{ margin: 0 }}>
                Taurus Flood will operate in compliance with these directives and
                will continue to provide updates as the information becomes
                available.
              </p>
            </Alert>
          )}
        {Moment(Moment().format("YYYY-MM-DD")).isSameOrBefore("2024-12-10") && (
            <Alert variant="warning" style={{fontSize: "13px"}}>
              <p style={{ marginBottom: "10px" }}>
              On 10/11/2024 - FEMA issued bulletin W-24014, which extends the 30-day grace period to 12/10/2024 for policyholders located in the state of Florida.
              </p>
              <ul>
                <li>
                This grace period extension applies to policies with expiration dates on or between 8/28/2024 - 11/10/2024.
                </li>
                <li>
                This grace period extension applies to policies with underpayment notices dated on or between 8/28/2024 - 11/10/2024.
                </li>
              </ul>
              <p style={{ margin: 0 }}>
              Policies eligible for this grace period extension have a revised due date of 12/10/2024.
              </p>
              <p style={{ margin: 0 }}>
                Taurus Flood will operate in compliance with these directives and
                will continue to provide updates as the information becomes
                available.
              </p>
            </Alert>
          )}
         </div>
        )}
        {/* CARDS */}
        {(text != '') &&
          <>
            <div>
              <Alert variant="warning">
                <div dangerouslySetInnerHTML={femaUpdateMsg}></div>
              </Alert>
            </div>
            <br />
          </>
        }
        <Tour
          steps={steps}
          isOpen={isOpenGuide}
          onRequestClose={closeTourguide} />
          {(!starTenancySynonyms.includes(tenant)) ?
            <>
              <Row xs="2">
                {/* <Button>hii</Button> */}
                <Button className="zero-step" id="createQuoteHeaderButton" onClick={tourGuide} style={{ width: "10%", marginLeft: "90%", display: 'flex' }}>
                  <FaWalking size={20} className="sidebar-link-icon" style={{ marginRight: "4px" }} />
                  {t('Tour Guide')}
                </Button>
              </Row>
            </>
            : ""
          }
        <Row>
          <Col xs="12" className="billing-tab-row2">
            <div id="dashboard-table-section">
              <a href="/active-log" style={{ textDecoration: "none" }}>
                <div className="table-main-title-div Fifth-path-step" style={pendingTaskTabColor}>
                  <u style={{ color: "white" }}><span className="table-main-title-name"><b>Pending Tasks : {pendingTasksCount}</b></span></u>
                  <span className="table-main-title-name"><b>See tasks that require your attention</b></span>
                </div>
              </a>
            </div>
          </Col>
        </Row>
        <div className="Sixth-step">
          <Row>
            {/* {
              devKey !== "PROD" ?  */}
              <>
              <Col xs="2" style={{ marginTop: "7px" }}>
                <label htmlFor="">Select Data View</label>
              </Col>

              <Col xs="2" style={{marginLeft:"-5%"}}>
                {/* <Select options={namelist} defaultValue={nameOption} onChange={(e) => namelistdata(e)} /> */}
                <select style={{ width: "56%", padding: "8px" }} value={nameOption} onChange={(e) => namelistdata(e)}>
                  {namelist && namelist.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
              </Col>
                </>
            {/* : ""}  */}
          </Row>
          <br />
          <Row>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <div
                className="dashboard-stat-card c4"
                onClick={() => navigate("/applications",{state:applicationsData})}
              >
                <div className="dashboard-stat-svg1"></div>
                <div className="dashboard-stat-svg2"></div>
                <p style={{ fontSize: "25px" }}><b>{t('Applications')}</b></p>
                <p><h3 style={{ lineHeight: "8px" }}><b>${Math.round(applicationsData?.total_premium  ? applicationsData.total_premium : 0).toLocaleString("en-US")}</b></h3></p>
                <p style={{ fontSize: "22px", lineHeight: "12px" }}>{t('Count')}: <span><b>{applicationsData?.count ? applicationsData.count : 0}</b></span></p>
                <p style={{ lineHeight: "12px", fontSize: "11px", textAlign: "center" }}><u><b>{t('See Quotes')}</b></u>{t('from the last 30 days which are unpaid')} <br /></p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <div
                className="dashboard-stat-card c4"
                onClick={() => navigate("/upcoming-renewals",{state:upcomingData})}
              >
                <div className="dashboard-stat-svg1"></div>
                <div className="dashboard-stat-svg2"></div>
                <p style={{ fontSize: "25px", textAlign: "center" }}><b>{t('Upcoming Renewals')}</b></p>
                <p><h3 style={{ lineHeight: "8px" }}><b>${Math.round(upcomingData?.total_premium ? upcomingData.total_premium : 0).toLocaleString("en-US")}</b></h3></p>
                <p style={{ fontSize: "22px", lineHeight: "12px" }}>{t('Count')}: <span><b>{upcomingData?.count ? upcomingData.count : 0}</b></span></p>
                <p style={{ lineHeight: "12px", fontSize: "11px", textAlign: "center" }}><u><b>{t('See Renewals Due')}</b></u> {t('in the next 30 days which are unpaid')}<br /></p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <div
                className="dashboard-stat-card c4"
                onClick={() => navigate("/expire-renewals", {state:expireData})}
              >
                <div className="dashboard-stat-svg1"></div>
                <div className="dashboard-stat-svg2"></div>
                <p style={{ fontSize: "25px" }}><b>{t('Expired Renewals')}</b></p>
                <p><h3 style={{ lineHeight: "8px" }}><b>${Math.round(expireData?.total_premium ? expireData.total_premium : 0 ).toLocaleString("en-US")}</b></h3></p>
                <p style={{ fontSize: "22px", lineHeight: "12px" }}>{t('Count')}: <span><b>{expireData?.count ? expireData.count : 0}</b></span></p>
                <p style={{ lineHeight: "12px", fontSize: "11px", textAlign: "center" }}><u><b>{t('See Expired Renewals Due')}</b></u>{t('in the last 30 days grace period. Non-Payment will lose Coverage')} </p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <div
                className="dashboard-stat-card c4"
                onClick={() => navigate("/open-claim")}
              >
                <div className="dashboard-stat-svg1"></div>
                <div className="dashboard-stat-svg2"></div>
                <p style={{ fontSize: "25px" }}><b>{t('Open Claims')}</b></p>
                <p><h3 style={{ lineHeight: "8px" }}><b>{Math.round(openClaimCount).toLocaleString("en-US")}</b></h3></p>
                <p style={{ fontSize: "22px", lineHeight: "12px" }}><span></span></p>
                <p style={{ lineHeight: "12px", fontSize: "11px", textAlign: "center" }}><u><b>{t('See Claims')}</b></u><br /><br /><br /></p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <div
                className="dashboard-stat-card c3"
                onClick={() => navigate("/paid-application",{state:paidApplicationData})}
              >
                <div className="dashboard-stat-svg1"></div>
                <div className="dashboard-stat-svg2"></div>
                <p style={{ fontSize: "25px" }}><b>{t('Paid Applications')}</b></p>
                <p><h3 style={{ lineHeight: "8px" }}><b>${Math.round(paidApplicationData?.total_premium ? paidApplicationData.total_premium : 0 ).toLocaleString("en-US")}</b></h3></p>
                <p style={{ fontSize: "22px", lineHeight: "12px" }}>{t('Count')}: <span><b>{paidApplicationData?.count ? paidApplicationData.count : 0 }</b></span></p>
                <p style={{ lineHeight: "12px", fontSize: "11px", textAlign: "center" }}><u><b>{t('See Policies')}</b></u> {t('that have been paid in last 30 days')} <br /></p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <div
                className="dashboard-stat-card c3"
                onClick={() => navigate("/paid-renewal",{state:paidRenewData})}
              >
                <div className="dashboard-stat-svg1"></div>
                <div className="dashboard-stat-svg2"></div>
                <p style={{ fontSize: "25px", textAlign: "center" }}><b>{t('Paid Renewals')}</b></p>
                <p><h3 style={{ lineHeight: "8px" }}><b>${Math.round(paidRenewData?.total_premium ? paidRenewData.total_premium : 0).toLocaleString("en-US")}</b></h3></p>
                <p style={{ fontSize: "22px", lineHeight: "12px" }}>{t('Count')}: <span><b>{paidRenewData?.count ? paidRenewData.count : 0}</b></span></p>
                <p style={{ lineHeight: "12px", fontSize: "11px", textAlign: "center" }}><u><b>{t('See Renewal Policies')}</b></u>{t('that have been paid in last 30 days')}</p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <div
                className="dashboard-stat-card c3"
                onClick={() => navigate("/cancellation",{state:cancelData})}
              >
                <div className="dashboard-stat-svg1"></div>
                <div className="dashboard-stat-svg2"></div>
                <p style={{ fontSize: "25px", lineHeight:"28px" }}><b>{t('Cancellations')}</b></p>
                <p style={{ fontSize: "11px", lineHeight: "0px", marginTop:"-8px" }}>{t('Total Premium Returned')}</p>
                <p><h3 style={{ lineHeight: "12px" }}><b>${Math.round(cancelData?.total_premium ? cancelData.total_premium : 0 ).toLocaleString("en-US")}</b></h3></p>
                <p style={{ fontSize: "22px", lineHeight: "12px" }}>{t('Count')}: <span><b>{cancelData?.count ? cancelData.count : 0}</b></span></p>
                <p style={{ lineHeight: "12px", fontSize: "11px", textAlign: "center" }}><u><b>{t('See Policies')}</b></u>{t('that have been canceled in last 30 days')}</p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <div
                className="dashboard-stat-card c3"
                onClick={() => navigate("/closed-claim")}
              >
                <div className="dashboard-stat-svg1"></div>
                <div className="dashboard-stat-svg2"></div>
                <p style={{ fontSize: "25px" }}><b>{t('Closed Claims')}</b></p>
                <p><h3 style={{ lineHeight: "8px" }}><b>${Math.round(closedClaimData).toLocaleString("en-US")}</b></h3></p>
                <p style={{ fontSize: "22px", lineHeight: "12px" }}>{t('Count')}: <span><b>{closedClaimCount}</b></span></p>
                <p style={{ lineHeight: "12px", fontSize: "11px", textAlign: "center" }}><u><b>{t('See Claims')}</b></u></p>
              </div>
            </Col>
          </Row>
        </div>
        <br />
        {/* TABLE */}
        {/* <Row>
        <Col xs="12" style={{ padding: 0 }}>
          <div className="dashboard-table-div" id="dashboard-table-section">
            <Row>
              <Col xs="12">
                <div className="table-main-title-div">
                  <span className="table-main-title-name">
                    Upcoming Renewals In 60 Days
                  </span>
                  <div className="table-main-title-icons">
                    <div className="table-square"></div>
                    <FaBars
                      size={15}
                      color={"#fff"}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/new-claims")}
                    />
                  </div>
                </div>
              </Col>
              <Col xs="12">
                <BootstrapTable
                  wrapperClasses="dashboard-table-main"
                  bootstrap4
                  keyField="policy_no"
                  data={products}
                  columns={columns}
                  hover
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row> */}

      {/* Target progress */ }
      {
            ( ['farmers','foremost'].includes(tenant) && viewPromoDashboard ) ? 
              <Row>
                <Col>
                  <div className="dashboard-page-card">
                    <p className="dashboard-cards-table-title">
                    <Row style={{columnGap:"60px!important"}}>
                        <Col sm="1">
                          <img src={springPromoTrackerLogo} style={{height:"75px", width:"80px" }}></img>
                        </Col>
                        <Col sm="3" style={{fontSize:"19px"}}>
                          <Row>
                            {t('Spring Into Flood Promotion Results')}
                          </Row>
                          <Row style={{ textAlign:"center"}}>
                            <Col>
                              <Button style={{marginTop:"5px"}} variant="outline-info" size="sm" value="Submit"  href={springPromotionDetailsFile} target="_blank"> Learn More </Button>
                            </Col>
                          </Row>
                        </Col>
                        {
                          Moment().isBefore("2024-07-31") ?
                              <Col sm="4" style={{fontSize:"19px"}}>
                                  Promotion Period: May 1, 2024 - July 31, 2024
                              </Col>
                              : null
                        }
                        <Col sm="4" style={{fontSize:"19px"}} >
                          <Row>
                            Production: {` ${agentTargetData?.achieved_target_count >= 0 ? agentTargetData?.achieved_target_count : 0 } ${agentTargetData?.achieved_target_count > 1 ? ' Policies' : " Policy"} - ${agentTargetData?.achieved_target_percent >= 0 ? agentTargetData?.achieved_target_percent : 0 }% of Baseline`}
                          </Row>
                          <Row>
                            Baseline: {agentTargetData?.baseData?.baseline}
                          </Row>
                          <Row>
                            Territory: {agentTargetData?.baseData?.territory}
                          </Row>
                          <Row>
                            Tier: {agentTargetData?.baseData?.tier}
                          </Row>
                        </Col>
                      </Row>
                    </p>
                    <div className="dashboard-label">
                      <label htmlFor=""><b>Total New Business Production</b></label>
                    </div>
                    <ProgressBar
                      // animated
                      variant="success"
                      now={agentTargetData?.achieved_target_bar ? agentTargetData?.achieved_target_bar : 0 }
                      key={1}
                      data-tip
                      data-for="a_target"
                      label={` ${agentTargetData?.achieved_target_count} ${agentTargetData?.achieved_target_count > 1 ? ' Policies' : " Policy"} - ${agentTargetData?.achieved_target_percent}% of Baseline`}
                    />

                    <div className="dashboard-label">
                      <label  htmlFor=""><b>Baseline New Business Production</b></label>
                    </div>
                    <ProgressBar
                      // animated
                      variant="info"
                      now={agentTargetData?.baseline_bar ? agentTargetData?.baseline_bar : 0}
                      key={2}
                      data-tip
                      data-for="a_target"
                      label={` ${agentTargetData?.baseData?.baseline} ${agentTargetData?.baseData?.baseline > 1 ? ' Policies' : " Policy"} `}
                    />
                    <Row>
                      <Col>
                          <div className="msgProgressBar">
                            NFIP New Business Production is updated daily. Private product production is updated after month-end.
                          </div>
                      </Col>
                      <Col>
                          <div className="timestampProgressBar">
                            Last synced at: {agentTargetData?.timestamp}
                          </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            : null
        }
        {/* CHARTS */}
        <Row>
          <Col xs="12" sm="12" md="12" lg="4" xl="4">
            <div className="dashboard-page-chart-div">
              <p className="dp-chart-title">{t(tenant == 'amfam' || tenant == 'ngm'  ? 'Monthly Production' : 'Today’s Production')}</p>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart width="100%" height={250}>
                  {pieChartDataObject.map((s) => (
                    <Pie
                      dataKey="value"
                      nameKey="name"
                      data={s.data}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#fff"
                      label
                    />
                  ))}
                </PieChart>
              </ResponsiveContainer>
              <div className="dp-chart-footer">
                <div className="dp-chart-footer-title">
                  <div className="dp-chart-circle1"></div>
                  {t('Application')}
                </div>
                <div className="dp-chart-footer-title">
                  <div className="dp-chart-circle2"></div>
                  {t('Quote')}
                </div>
                <div className="dp-chart-footer-title">
                  <div className="dp-chart-circle3"></div>
                  {t('Issued')}
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" sm="12" md="12" lg="8" xl="8">
            <div className="dashboard-page-chart-div">
              <p className="dp-chart-title" id="dp-chart2-title">
                {t('Yearly Production')}
              </p>

              <ResponsiveContainer
                width="95%"
                height={300}
                style={{ marginBottom: "6px" }}
              >
                <LineChart
                  width="100%"
                  height={250}
                  data={MonthlyGraphData ? MonthlyGraphData : []}
                  margin={{
                    left: 0,
                    right: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />

                  <Tooltip />
                  <Legend />

                  <Line type="monotone" dataKey="Application" stroke="#64D6CD" />
                  <Line type="monotone" dataKey="Quote" stroke="#D68928" />
                  <Line type="monotone" dataKey="Issued" stroke="#33D14D" />
                  {/* <Line type="monotone" dataKey="Transactions" stroke="#2661AD" /> */}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
        <Modal
          isOpen={showNpnModal}
          onClose={() => setShowNpnModal(false)}
          closeOnOverlayClick={false}
          isCentered
          size={'lg'}
        >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
              {t("Please Update NPN Number")} :
              <Alert variant="warning" style={{fontSize: "13px"}}>
                <p className="note-text">
                {t("FEMA is requiring the Agent and Agency National Producer Number")} (“NPN”) {t("beginning January 1, 2025")}. 
                </p> 
                <p className="note-text">
                {t("Transactions submitted to FEMA may be rejected or delayed if valid NPNs are not provided")}.
                </p>
                <p className="note-text">
                {t("We currently do not have a valid NPN on file for you")}. {t("Please provide the following")} NPN(s): 
                </p>
                <ul>
                  <li>
                      {t("Agency NPN")} : {t("10 digits with leading zeros")}
                  </li>
                  <br/>
                  <li>
                    {t("Agent NPN")} : {t("10 digits with leading zeros")}
                  </li>
                </ul>
                <p style={{fontSize: "14px"}}>
                 {t("Here is a link where you can find your NPN Number")} :
                </p>
                <a
                  href="https://nipr.com/help/look-up-your-npn"
                  target="_blank"
                  rel=""
                  className="npn-link"
                >
                {t("Widget")}: {t("Look Up Your NPN | NIPR")}
                </a>
              </Alert>
          </ModalHeader>
          <ModalBody pb={2}>
            <FormControl>
              <FormLabel>{t("Agency NPN")} <span style={{color:"red"}}>*</span></FormLabel>
              <InputGroup>
              <Input
                placeholder='Agency NPN'
                value={agencyNpnValue}
                maxLength={10}
                onChange={(e) => setAgencyNpnValue(e.target.value)}
              />
              </InputGroup>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("Agent NPN")} <span style={{color:"red"}}>*</span></FormLabel>
              <Input
                placeholder='Agent NPN'
                value={agentNpnValue}
                maxLength={10}
                onChange={(e) => setAgentNpnValue(e.target.value)}
              />
            </FormControl>
            <br/>
            {/* <Box>
             
            </Box> */}
          </ModalBody>

          <ModalFooter style={{gap:"10px", alignItems:"center", justifyContent:'center'}} >
            <Button disabled={updateStatus} onClick={handleUpdateNpn} >
              {t("Update")}
            </Button>
            {
              showLaterBtn ?
              <Button onClick = {laterFunc}>
               {t("Ask Me Later")}
              </Button>
              : null
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
      </div>
    </LoadingOverlay>
  );
};

const TopLayoutView = (props) => {
  const [showLayoutView, setShowLayoutView] = useState(true)
  return (
    <>
      {
        showLayoutView ? (<Alert variant="success" onClose={() => setShowLayoutView(false)} dismissible>
          <div>{parse(props.mesgBody)}</div>
        </Alert>) : ''
      }
    </>
  )
}

export default Dashboard;
