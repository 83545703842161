import React, { useState, useEffect } from "react";
import { Table, Card, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
// import { quickSearch } from "../../../services/dashboardService"
import { quickSearchAdvance, getTransactionData } from "../../../services/dashboardService";
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { LoadingOverlay, Loader } from "react-overlay-loader";

import {
    verifyPolicy, setPolicyPropsData, getBaseData
  } from "../../../services/coreSystemService";
  import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { getPolicyStatusDD } from "../../../services/coreSystemService"

const AdvancedSearchPage = () => {
    const navigate = useNavigate();
    const [status, setStatus] = useState("SELECTALL");
    const [businessName, setBusinessMan] = useState("");
    const [propertyAddress, setPropertyAddress] = useState("");
    const [mailingAddress, setMailingAddress] = useState("");
    const [quotePolicyNo, setQuotePolicyNo] = useState("");
    const [insuredFirstName, setInsuredFirstName] = useState("");
    const [propertyCity, setPropertyCity] = useState("");
    const [mailingCity, setMailingCity] = useState("");
    const [lastName, setLastName] = useState("");
    const [insuredLastName, setInsuredLastName] = useState("");
    const [propertyState, setPropertyState] = useState("");
    const [mailingState, setMailingState] = useState("");
    const [firstName, setFirstName] = useState("");
    const [insuredPhoneNo, setInsuredPhoneNo] = useState("");
    const [propertyPinCode, setPropertyPinCode] = useState("");
    const [mailingPinCode, setMailingPinCode] = useState("");
    const [total, setTotal] = useState(0);
    const { t } = useTranslation();
    const [searchData, setSearchData] = useState([]);
    const dispatch = useDispatch();
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [link, setLink] = useState('#');
    const handleClose = () => setShowDownloadModal(false);
    const handleShow = () => setShowDownloadModal(true);
    const [policyStatusOptions, setPolicyStatusOptions] = useState([]);

    const [payload, setPayload] = useState("");
    const [filter, setFilter] = useState({
        page: 1,
        page_size: 10,
    });
    useEffect(() => {
        getPolicyStatusDropDown();
      }, []);

    useEffect(() => {
        if (searchData == "" && searchData == undefined && searchData == null){
            quickSearchData();
        }
    }, [filter.page, filter.page_size]);

    const policyNoColumnFormatter = (cell, row) => {
        if (row) {
          return (
            <a href="#" onClick={() =>  verify(row)}>
              {cell}
            </a>
            
          );
        } else {
          return row.policy_no;
        }
      };

      const verify = async (row) => {
        var params = {}
        setLoading(true);
        let policyStatus = false;
        if (row.policy_no.includes("TMP") || (row.policy_status == "QUOTE" || row.policy_status == "APPLICATION")){
            await dispatch(getTransactionData(row.policy_no)).then((res) => {
                if(isEmpty(res)){
                    toast.error("Policy Not Found");
                    setLoading(false);
                    return false;
                }else{
                    if (res.n_ProductId_FK === 10) {
                        navigate(`/flood/quote/${res.n_potransaction_PK}`);
                        setLoading(false);
                        window.location.reload();
                    }
                }
            })
        }else{
            await dispatch(verifyPolicy(row.policy_no)).then((res) => {
                policyStatus = res.status;
                if(!policyStatus){
                    toast.error(res.message)
                    setLoading(false);
                    return false;
                  }
                  else{
                    if (res.policyIdPK && res.status){
                        params = {
                            n_POPolicyMasterFK: res.policyIdPK,
                            n_POTermMasterFK: res.termMasterPK,
                            n_POTransactionFK: res.poTransPK,
                        };
                    }
                     else {
                            navigate(`/core/policy`);
                        }
                  }
               
            });
            if(policyStatus){
                await dispatch(setPolicyPropsData(params));
                await dispatch(
                    getBaseData(params.n_POTransactionFK)
                );
                navigate(`/core/policy/${row.policy_no}`);
                setLoading(false);
                window.location.reload();
            }
        }
    };

    //   const formatInsuredName = (cell,row) =>{
    //     return row.first_name+' '+row.last_name
    //   }
      const formatAddress = (cell,row) =>{
        return row.address_line1+', '+row.city+', '+row.county+', '+row.state+', '+row.zip_code
      }
      const formatMailingAddress = (cell, row) =>{
        return row.mailing_address_line1+', '+row.mailing_city+', '+row.mailing_county+', '+row.mailing_state+', '+row.mailing_zip_code
      }

    const columns = [
        // {
        //     dataField: "search_Match",
        //     text: t("search Match"),
        // },
        {
            dataField: "policy_no",
            text: t("Policy/Quote Number"),
            formatter: (value, row) => policyNoColumnFormatter(value, row),
            sort: true,
            style:{
                textAlign:"left"
            }
        },
        {
            dataField: "effective_date",
            text: t("Effective Date"),
            sort: true,
            style:{
                textAlign:"left"
            }
        },
        {
            dataField: "policy_status",
            text: t("Status"),
            sort: true,
            style:{
                textAlign:"left"
            }
        },
        {
            dataField: "full_name",
            text: t("Insured Name"),
            // formatter: (value,row) => formatInsuredName(value,row),
            sort: true,
            style:{
                textAlign:"left"
            }
        },
        {
            dataField: "address_line1",
            text: t("Property Address"),
            formatter: (value, row) => formatAddress(value, row),
            sort: true,
            style:{
                textAlign:"left"
            }
        },
        {
            dataField: "agency",
            text: t("Agency Name"),
            sort: true,
            style:{
                textAlign:"left"
            }
        },
        {
            dataField: "mailing_address_line1",
            text: t("Mailing Address"),
            formatter: (value,row) => formatMailingAddress(value,row),
            sort: true,
            style:{
                textAlign:"left"
            }
        },
        {
            dataField: "insured_contact_no",
            text: t("Insured Contact No"),
            sort: true,
            style:{
                textAlign:"left"
            }
        },
        {
            dataField: "2nd_insured_full_name",
            text: t("Second Insured Name"),
            sort: true,
            style:{
                textAlign:"left"
            }
        },
    ]

    const options = {
        page: filter.page,
        page_size: filter.page_size,
        sortIndicator: false,
        pageStartIndex: 1,
        totalSize: searchData.length,
        totalSize: total,
        showTotal: true,
    };

    // const policyStatusOptions = [
    //     {
    //         "value": "SELECTALL",
    //         "label": "Select All"
    //     },
    //     {
    //         "value": "QUOTE",
    //         "label": "Quote"
    //     },
    //     {
    //         "value": "APPLICATION",
    //         "label": "Application"
    //     },
    //     {
    //         "value": "ISSUED",
    //         "label": "Issued"
    //     },
    //     {
    //         "value": "ACTIVE",
    //         "label": "Active"
    //     },
    //     {
    //         "value": "CANCELED",
    //         "label": "Canceled"
    //     },
    //     {
    //         "value": "BILLEDRENEWAL",
    //         "label": "Billed renewal"
    //     },
    //     {
    //         "value": "EXPIRED",
    //         "label": "Expired"
    //     }
    // ]

    const quickSearchData = async () => {
        let Url = "";
        if (propertyCity != null && propertyCity != "" && propertyCity != undefined){
            Url = Url.concat("city=", propertyCity, "&")
        }
        if (status != "SELECTALL") {
            Url = Url.concat("policy_status=", status, "&")
        }
        if (firstName != null && firstName != "" && firstName != undefined) {
            Url = Url.concat("first_name=", firstName, "&")
        }
        if (lastName != null && lastName != "" && lastName != undefined) {
            Url = Url.concat("last_name=", lastName, "&")
        }
        if (quotePolicyNo != null && quotePolicyNo != "" && quotePolicyNo != undefined) {
            Url = Url.concat("policy_no=", quotePolicyNo, "&")
        }
        if (propertyState != null && propertyState != "" && propertyState != undefined) {
            Url = Url.concat("state=", propertyState, "&")
        }
        // if (mailingCity != null && mailingCity != "" && mailingCity != undefined) {
        //     Url = Url.concat("country=", mailingCity, "&")
        // }
        if (propertyAddress != null && propertyAddress != "" && propertyAddress != undefined) {
            Url = Url.concat("address_line1=", propertyAddress, "&")
        }
        if (businessName != null && businessName != "" && businessName != undefined) {
            Url = Url.concat("business_name=", businessName, "&")
        }
        if (propertyPinCode != null && propertyPinCode != "" && propertyPinCode != undefined) {
            Url = Url.concat("zip_code=", propertyPinCode, "&")
        }
        if (mailingAddress != null && mailingAddress != "" && mailingAddress != undefined) {
            Url = Url.concat("mailing_address_line1=", mailingAddress, "&")
        }
        if (mailingCity != null && mailingCity != "" && mailingCity != undefined) {
            Url = Url.concat("mailing_city=", mailingCity, "&")
        }
        if (mailingState != null && mailingState != "" && mailingState != undefined) {
            Url = Url.concat("mailing_state=", mailingState, "&")
        }
        
        if (mailingPinCode != null && mailingPinCode != "" && mailingPinCode != undefined) {
            Url = Url.concat("mailing_zip_code=", mailingPinCode, "&")
        }
        if (insuredPhoneNo != null && insuredPhoneNo != "" && insuredPhoneNo != undefined) {
            Url = Url.concat("insured_contact_no=", insuredPhoneNo, "&")
        }
        if (insuredFirstName != null && insuredFirstName != "" && insuredFirstName != undefined) {
            Url = Url.concat("2nd_insured_first_name=", insuredFirstName, "&")
        }
        if (insuredLastName != null && insuredLastName != "" && insuredLastName != undefined) {
            Url = Url.concat("2nd_insured_last_name=", insuredLastName, "&")
        }
       
        Url = Url.concat("paginate=", "true", "&")
        setPayload(Url);
        setLoading(true);
        await dispatch(quickSearchAdvance(Url)).then((res)=>{
            setSearchData(res.data.data.data);
            setTotal(res.data.data.total);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            let keys = Object.keys(err.data.errors);
            if(keys.includes("city")){
                toast.error("Please enter valid city!")
            }
            if(keys.includes("first_name")){
                toast.error("Please enter valid First Name!")
            }
            if(keys.includes("last_name")){
                toast.error("Please enter valid Last Name!")
            }
            if(keys.includes("policy_no")){
                toast.error("Please enter valid Policy No!")
            }
            if(keys.includes("state")){
                toast.error("Please enter valid State!")
            }
            if(keys.includes("county")){
                toast.error("Please enter valid County!")
            }
            if(keys.includes("address_line1")){
                toast.error("Please enter valid Address!")
            }
            if(keys.includes("zip_code")){
                toast.error("Please enter valid Zip Code!")
            }
            if(keys.includes("agency")){
                toast.error("Please enter valid Agency Name!")
            }
            if(keys.includes("mailing_address_line1")){
                toast.error("Please enter valid Mailing address!")
            }
            if(keys.includes("mailing_city")){
                toast.error("Please enter valid Mailing City!")
            }
            if(keys.includes("mailing_state")){
                toast.error("Please enter valid Mailing State!")
            }
            if(keys.includes("mailing_zip_code")){
                toast.error("Please enter valid Mailing Zip Code!")
            }
            if(keys.includes("insured_contact_no")){
                toast.error("Please enter valid Insured Contact Number!")
            }
            if(keys.includes("2nd_insured_first_name")){
                toast.error("Please enter valid 2nd Insuerd First Name!")
            }
            if(keys.includes("2nd_insured_last_name")){
                toast.error("Please enter valid 2nd Insured Last Name!")
            }
        }) ;
       
    }

    const emptyDataMessage = () => { return 'No Data to Display'; }

    const exportToCSV = async () => {
        var Url = payload + "is_csv=true&is_adv=true";
        setLoading(true);
        var csv = await dispatch(quickSearchAdvance(Url)).catch(() => {
            setLoading(false);
        });
        if (csv.status === 200) {
            setLink(csv.data.data.url)
            handleShow();
            setLoading(false);
        }
    }

    const getPolicyStatusDropDown = async () => {
        setLoading(true);
        await dispatch(getPolicyStatusDD()).then((res) => {
            setPolicyStatusOptions(res.policyStatus)
            setLoading(false)
        }).catch((c)=>{
            setLoading(false)
        })
    }
    return(
        <> 
            <LoadingOverlay>
                <Loader loading={loading} />
            <div className="search-policy-section">
                <Card>
                    <Row className="align-items-center">
                        <div>
                            {t('Refines Search instruction. Complete 1 or more of the search contain below')}
                        </div>
                        <Col xs="12">
                            <br />
                            <Row className="align-items-end">
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Status')}.
                                        </Form.Label>
                                        <Form.Select
                                            className="dashboard-group-input-flood"
                                            name="policy_status"
                                            id="policy_status"
                                            onChange={(e) => setStatus(e.target.value)}
                                            value={status}
                                        >
                                             <option key='SELECTALL' value='SELECTALL'>Select Status</option>
                                             {policyStatusOptions.map((option) => {
                                                    return <option value={option.value}>{option.label}</option>;
                                                })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Business Name')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="policyNo"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={businessName}
                                            onChange={(e) => setBusinessMan(e.target.value)}
                                            disabled={lastName.length > 0 || firstName.length > 0 ? true : false}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Property Address')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="address_line1"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={propertyAddress}
                                            onChange={(e) => setPropertyAddress(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Mailing Address')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="policyNo"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={mailingAddress}
                                            onChange={(e) => setMailingAddress(e.target.value)}
                                            
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br />
                            <Row className="align-items-end">
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Quote/Policy Number')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="policy_no"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={quotePolicyNo}
                                            onChange={(e) => setQuotePolicyNo(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('2nd Insured First Name')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="policyNo"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={insuredFirstName}
                                            onChange={(e) => setInsuredFirstName(e.target.value)}
                                            
                                            
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Property City')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="policyNo"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={propertyCity}
                                            onChange={(e) => setPropertyCity(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Mailing City')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="policyNo"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={mailingCity}
                                            onChange={(e) => setMailingCity(e.target.value)}
                                            
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br />
                            <Row className="align-items-end">
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Last Name')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="last_name"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            disabled = { businessName.length > 0 ? true : false}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('2nd Insured Last Name')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="policyNo"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={insuredLastName}
                                            onChange={(e) => setInsuredLastName(e.target.value)}
                                            
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Property State')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="state"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={propertyState}
                                            onChange={(e) => setPropertyState(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Mailing State')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="policyNo"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={mailingState}
                                            onChange={(e) => setMailingState(e.target.value)}
                                            
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br />
                            <Row className="align-items-end">
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('First Name')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="first_name"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            disabled = { businessName.length > 0 ? true : false}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Insured Phone Number')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="policyNo"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={insuredPhoneNo}
                                            onChange={(e) => setInsuredPhoneNo(e.target.value)}
                                            
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Property Pin Code')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="zip_code"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={propertyPinCode}
                                            onChange={(e) => setPropertyPinCode(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                                    <Form.Group
                                        className="policy-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Mailing Pin Code')}.
                                        </Form.Label>
                                        <Form.Control
                                            name="policyNo"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={mailingPinCode}
                                            onChange={(e) => setMailingPinCode(e.target.value)}
                                            
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col className="search-policy-btn-col" style={{ display: "flex", justifyContent: "end" }}>
                                    {searchData != "" && searchData != undefined && searchData != null ? <Button id="common-btn" variant="primary" onClick={exportToCSV}>Export CSV</Button> : ""}
                                    &nbsp;&nbsp;
                                    <Button id="common-btn" variant="primary" onClick={quickSearchData}>
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                            <br />
                            <Card>
                                <Col xs="12">
                                    <BootstrapTable 
                                        wrapperClasses="dashboard-table-main"
                                        bootstrap4
                                        keyField="policy_no"
                                        data={searchData}
                                        columns={columns}
                                        hover
                                        bordered={false}
                                        pagination={paginationFactory(options)}
                                        noDataIndication={emptyDataMessage}
                                    />
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </div>
            </LoadingOverlay>

            <Modal show={showDownloadModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Advanced Search List CSV File</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to download file ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Discard
                    </Button>
                    <Button variant="primary" href={link} target="_blank" onClick={handleClose}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AdvancedSearchPage;