import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { forgotPass } from "../../services/authService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username) {
      toast.error(t("Please enter username!"));
      return false;
    }

    const request = {
      username: username,
      sourceView: "AGENTPORTAL",
    };
    setLoading(true);
    dispatch(forgotPass(request))
      .then(async (response) => {
        if (response.status === true) {
          toast.success(response.message);
          Swal.fire("Success", response.message, "success");
          navigate("/login");
        } else {
          setUsername("");
          toast.error(response.data.errors);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error(t("Please Try again"));
      });
  };

  return (
    <>
      <Flex direction={"column"} width={"100%"} mt={6}>
        <Text fontSize="31px" mb={4}>
          {t("Forgot Password")}?
        </Text>
        <form onSubmit={handleSubmit}>
          <Box>
            <FormControl mb={4}>
              <FormLabel>{t("Username")}</FormLabel>
              <Input
                borderColor="var(--chakra-colors-neutral-500)"
                _placeholder={{
                  color: "neutral.800",
                  fontSize: "sm",
                }}
                backgroundColor="var(--chakra-colors-white)"
                borderRadius={10}
                width="100%"
                height={"47px"}
                type="text"
                placeholder={t("Username")}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                isRequired
              />
            </FormControl>
          </Box>
          <Box>
            <Button
              w={"100%"}
              my={4}
              borderRadius={"10px"}
              fontWeight="var(--chakra-fontWeights-medium)"
              fontSize={16}
              type="submit"
              border={"1px solid var(--chakra-colors-primary-500)"}
              width="100%"
              height={"47px"}
              isDisabled={loading}
            >
              {t("Submit")}
            </Button>
          </Box>
          <Box width={"100%"}>
            <Button
              background="var(--chakra-colors-white)"
              color="var(--chakra-colors-black)"
              borderRadius="10px"
              fontWeight="var(--chakra-fontWeights-medium)"
              fontSize={16}
              border="1px solid var(--chakra-colors-neutral-200)"
              width="100%"
              _hover={{
                background: "var(--chakra-colors-primary-500)",
                color: "var(--chakra-colors-white)",
              }}
              onClick={() => navigate("/login")}
              height={"47px"}
              isDisabled={loading}
            >
              {t("Go to Login")}
            </Button>
          </Box>
        </form>
      </Flex>
    </>
  );
};

export default ForgotPassword;
