import Http from "../../../../../Http";

const API_URL = `${process.env.REACT_APP_API_URL}/api/quote/getBaseData`;

export async function fetchStarComBaseData({ productPK }) {
  try {
    const response = await Http.get(`${API_URL}/${productPK}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching base data:", error);
    return false;
  }
}
