import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ResetPasswordScreenPage({
  loading,
  isPasswordExpired,
  resetPassword,
}) {
  const { t } = useTranslation();
  return (
    <Flex direction="column" mt={6} width={"100%"}>
      <form onSubmit={resetPassword}>
        <Box>
          <Text fontSize="31px" mb={4}>
            {t("Reset Password")}
          </Text>
          {isPasswordExpired ? (
            <Text>
              {t("Your password has expired")}.{" "}
              {t("Please reset your password")}.
            </Text>
          ) : (
            <Text fontSize="1rem">
              {t(
                "Hi, your current password does not comply with our new password requirements"
              )}
              . {t("Please reset your password")}.
            </Text>
          )}
        </Box>
        <Box my="2.2rem">
          <Divider borderColor={"gray.300"} />
        </Box>
        <Box>
          <Button
            type="submit"
            height={"47px"}
            borderRadius="10px"
            width={"100%"}
            fontWeight="var(--chakra-fontWeights-medium)"
            fontSize="16px"
            isDisabled={loading}
            border={"1px solid var(--chakra-colors-primary-500)"}
          >
            {t("Reset Password")}
          </Button>
        </Box>
      </form>
    </Flex>
  );
}
