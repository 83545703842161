import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ListRequiredDoc from "./ListRequiredDoc";
import Moment from 'moment';
import { toast } from 'react-toastify';
import { getRefundAmount } from "../../../services/floodService";
import "react-datepicker/dist/react-datepicker.css";
import "./cancel.scss";
import { AlertIcon, Box, Text,Alert, useDisclosure } from "@chakra-ui/react";
import {formateDate} from "../../common/FormatDate";
import {Currency} from "../../common/Currency";

const Refund = (props) => {
    const { loading, setLoading, showAlert, alertText, cancelJSON, poTransPK, policyPK, policyNumber, setCancelJSON, setDataInCancelJSON, transEffectiveTo, termMasterFK } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [transEffectiveFrom, setTransEffectiveFrom] = useState(null);
    const [refundAmount, setRefundAmount] = useState(0);
    const [isRefundAPICalled, setIsRefundAPICalled] = useState(false);
    useDisclosure({ defaultIsOpen: true })

    useEffect(() => {
        if (cancelJSON) {
            setTransEffectiveFrom(Moment(cancelJSON.cancelDate).format("MM-DD-YYYY") || null);
            setRefundAmount(cancelJSON.refundAmt);

            if (cancelJSON.refundAmt === 0) {
                getCancelRefund();
            }
        }
    }, [cancelJSON])

    // get refund amount
    const getCancelRefund = async () => {
        // avoid multiple calling if refund is 0
        if(isRefundAPICalled == true) { return false; }
        setIsRefundAPICalled(true);
        setLoading(true);
        var params = {
            "prevPoTrans": poTransPK,
            "cancelReasonCode": cancelJSON.cancelReason,
            "cancelEffFrom": cancelJSON.cancelDate,
            "cancelEffTo": transEffectiveTo
        };
        await dispatch(getRefundAmount(params)).then((res) => {
            if (res.status === "Y") {
                setDataInCancelJSON(res.refundAmt, "refundAmt");
                if (res.msg.length > 0) {
                    toast.info(t(res.msg))
                }
            } else {
                toast.error(t("Something Went Wrong"));
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            toast.error(t("Something Went Wrong"));
        });
    }

    return (
        <>
            {
                showAlert && (
                    <>
                        <Alert size={"sm"} status='warning' isOpen={true}>
                            <AlertIcon />
                            {alertText}
                        </Alert>
                    </>
                )
            }
            <Box>
                <Text fontSize={"lg"} sx={{fontWeight: 600}}>
                    {t('Cancellation Refund')}
                </Text>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Text>{t('Cancellation Effective date')}: <b>&nbsp;{formateDate(transEffectiveFrom)}</b></Text>
                    <Text>{t('Refund amount')}: <b>&nbsp;<Currency amount={refundAmount} /></b></Text>
                </Box>
                <Box>
                    <Text fontWeight={600}>{t('Required documents')}</Text>
                    <ListRequiredDoc
                        policyNumber={policyNumber}
                        policyPK={policyPK}
                        termMasterFK={termMasterFK}
                        cancelJSON={cancelJSON}
                        setCancelJSON={setCancelJSON}
                        loading={loading}
                        setLoading={setLoading}
                    />
                </Box>
            </Box>
        </>
    )
}

export default Refund;
