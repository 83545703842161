import React from "react";
import { Box, Flex, Text, VStack, Switch, Select } from "@chakra-ui/react";
import { ChakraSelect } from "../../commercial-quote/Components/CustomComponents";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";
import { spoilageCoverageType } from "../../commercial-quote/data/commercialDD";
import { CustomCurrencyInput } from "../../common/CustomCurrencyInput";

const FormField = ({ label, children }) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Text pt={0} mb={0}>
      {label}
    </Text>
    {children}
  </Flex>
);

export const AdditionalCoverages = ({ index }) => {
  const { t } = useTranslation();
  const { coverages, setCoverages, selectedCard, baseDD } = useQuote();

  const updateCoverageDetails = (index, name, value) => {
    setCoverages((prevCoverages) => {
      const updatedCoverages = [...prevCoverages];
      updatedCoverages[index] = {
        ...updatedCoverages[index],
        additionalCoverages: {
          ...updatedCoverages[index].additionalCoverages,
          [name]: value,
        },
      };
      return updatedCoverages;
    });
  };

  const updateBasicCoverages = (index, name, value) => {
    setCoverages((prevCoverages) => {
      const updatedCoverages = [...prevCoverages];
      updatedCoverages[index] = {
        ...updatedCoverages[index],
        basicCoverages: {
          ...updatedCoverages[index].basicCoverages,
          [name]: value,
        },
      };
      return updatedCoverages;
    });
  };

  return (
    <Box
      maxWidth="600px"
      height={selectedCard === "ConvenienceStore" ? 680 : 600}
      maxHeight={"700px"}
      margin="auto"
      padding="4"
      overflow={"auto"}
    >
      <VStack spacing="6" align="stretch">
        {/* <FormField label={t("All-Perils Deductible")}>
          <ChakraSelect
            w={"30%"}
            value={coverages[index]?.additionalCoverages?.allPerilsDed || ""}
            onChange={(e) => {
              updateCoverageDetails(index, "allPerilsDed", e.target.value);
            }}
          >
            <option value=""></option>
            {aopDed?.map((e, i) => (
              <option key={i} value={e?.s_DiscountCode}>
                {e?.s_ScreenName}
              </option>
            ))}
          </ChakraSelect>
        </FormField> */}

        {/* {Array.isArray(baseDD?.all_basic_Coverage) &&
          baseDD?.all_basic_Coverage?.map(
            (e, i) =>
              e?.s_LimitCodeType === "NUMBER" && (
                <FormField key={i} label={e?.s_ScreenName}>
                  <CustomCurrencyInput
                    onValueChange={(value) => {
                      updateCoverageDetails(index, e?.s_CoverageCode, value);
                    }}
                    value={
                      coverages[index]?.additionalCoverages?.[
                        e?.s_CoverageCode
                      ] || 0
                    }
                    currencyStyles={{
                      width: "30%",
                      borderColor: "var(--chakra-colors-gray-400)",
                    }}
                  />
                </FormField>
              )
          )} */}
        {Array.isArray(baseDD?.all_basic_Coverage) &&
          baseDD?.all_basic_Coverage.map(
            (element, i) =>
              element?.s_LimitCodeType === "DROPDOWN" &&
              ["Water Sublimit"].includes(element?.s_ScreenName) && (
                <FormField key={i} label={element?.s_ScreenName}>
                  <Select
                    w="30%"
                    className="inputSelectBoxes"
                    value={
                      (element?.s_ScreenName === "Water Sublimit" &&
                        coverages[index]?.additionalCoverages?.waterSublimit) ||
                      coverages[index]?.additionalCoverages?.[
                        element?.s_CoverageCode
                      ] ||
                      ""
                    }
                    name={
                      element?.s_ScreenName === "Water Sublimit"
                        ? "waterSublimit"
                        : element?.s_CoverageCode
                    }
                    onChange={(e) => {
                      updateCoverageDetails(
                        index,
                        e.target.name,
                        e.target.value
                      );
                    }}
                  >
                    <option value=""></option>
                    {element?.dropDown?.map((option, idx) => (
                      <option key={idx} value={option?.s_LimitCode}>
                        {option?.s_LimitScreenName}
                      </option>
                    ))}
                  </Select>
                </FormField>
              )
          )}

        {/* <FormField label={t("Water Sublimit")}>
          <SelectField
            value={coverages[index]?.additionalCoverages?.waterSublimit || ""}
            onChange={(e) => {
              updateCoverageDetails(index, "waterSublimit", e.target.value);
            }}
            options={waterSublimitFields}
          />
        </FormField> */}

        <FormField label={t("Exclude Sinkhole")}>
          <Switch
            isChecked={
              coverages[index]?.additionalCoverages?.excludSinkhole === "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                index,
                "excludSinkhole",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>

        <FormField label={t("Cosmetic Roof Damage")}>
          <Switch
            isChecked={
              coverages[index]?.additionalCoverages?.cosmeticRoofDamage ===
              "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                index,
                "cosmeticRoofDamage",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>

        <FormField label={t("ACV Roof Surface")}>
          <Switch
            isChecked={
              coverages[index]?.additionalCoverages?.acvRoofSurface === "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                index,
                "acvRoofSurface",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>
        <FormField label={t("Theft Exclusion")}>
          <Switch
            isChecked={
              coverages[index]?.basicCoverages?.theftExclusions === "YES"
            }
            onChange={(e) => {
              updateBasicCoverages(
                index,
                "theftExclusions",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
            defaultIsChecked
          />
        </FormField>

        <FormField label={t("Wind Exclusion")}>
          <Switch
            isChecked={
              coverages[index]?.basicCoverages?.windExclusions === "YES"
            }
            onChange={(e) => {
              updateBasicCoverages(
                index,
                "windExclusions",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
            defaultIsChecked
          />
        </FormField>
        <FormField label={t("BOP Coverage Extension")}>
          <Switch
            isChecked={
              coverages[index]?.additionalCoverages?.bopCoverageExtenstion ===
              "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                index,
                "bopCoverageExtenstion",

                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
            defaultIsChecked
          ></Switch>
        </FormField>

        {selectedCard === "ConvenienceStore" && (
          <>
            <FormField label={t("Canopy")}>
              <CustomCurrencyInput
                value={coverages[index]?.additionalCoverages?.canopy}
                onValueChange={(value) => {
                  updateCoverageDetails(index, "canopy", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
            <FormField label={t("Pumps")}>
              <CustomCurrencyInput
                value={coverages[index]?.additionalCoverages?.pumps}
                onValueChange={(value) => {
                  updateCoverageDetails(index, "pumps", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
            <FormField label={t("Sign")}>
              <CustomCurrencyInput
                value={coverages[index]?.additionalCoverages?.sign}
                onValueChange={(value) => {
                  updateCoverageDetails(index, "sign", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
          </>
        )}
        <FormField label={t("Spoilage Coverage")}>
          <Switch
            isChecked={
              coverages[index]?.additionalCoverages?.spoilageCoverage === "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                index,
                "spoilageCoverage",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>
        {coverages[index]?.additionalCoverages?.spoilageCoverage === "YES" && (
          <>
            {Array.isArray(baseDD?.all_basic_Coverage) &&
              baseDD?.all_basic_Coverage.map(
                (element, i) =>
                  element?.s_LimitCodeType === "DROPDOWN" &&
                  ["Spoilage"].includes(element?.s_ScreenName) && (
                    <FormField
                      key={i}
                      label={
                        element?.s_ScreenName === "Spoilage"
                          ? t("Spoilage Limit")
                          : element?.s_ScreenName
                      }
                    >
                      <Select
                        w="30%"
                        className="inputSelectBoxes"
                        value={
                          (element?.s_ScreenName === "Spoilage" &&
                            coverages[index]?.additionalCoverages
                              ?.spoilageLimit) ||
                          coverages[index]?.additionalCoverages?.[
                            element?.s_CoverageCode
                          ] ||
                          ""
                        }
                        name={
                          element?.s_ScreenName === "Spoilage"
                            ? "spoilageLimit"
                            : element?.s_CoverageCode
                        }
                        onChange={(e) => {
                          updateCoverageDetails(
                            index,
                            e.target.name,
                            e.target.value
                          );
                        }}
                      >
                        <option value=""></option>
                        {element?.dropDown?.map((option, idx) => (
                          <option key={idx} value={option?.s_LimitCode}>
                            {option?.s_LimitScreenName}
                          </option>
                        ))}
                      </Select>
                    </FormField>
                  )
              )}
            {/* <FormField label={t("Spoilage Limit")}>
              <ChakraSelect
                value={coverages[index]?.additionalCoverages?.spoilageLimit}
                onChange={(e) => {
                  updateCoverageDetails(index, "spoilageLimit", e.target.value);
                }}
                w="30%"
              >
                <option value=""></option>
                {spoilageLimit?.map((e, i) => (
                  <option value={e.value} key={i}>
                    {e.label}
                  </option>
                ))}
              </ChakraSelect>
            </FormField> */}
            <FormField label={t("Spoilage Coverage Type")}>
              <ChakraSelect
                value={
                  coverages[index]?.additionalCoverages?.spoilageCoverageType
                }
                onChange={(e) => {
                  updateCoverageDetails(
                    index,
                    "spoilageCoverageType",
                    e.target.value
                  );
                }}
                w="30%"
              >
                <option value=""></option>
                {spoilageCoverageType.map((e, i) => (
                  <option value={e} key={i}>
                    {e}
                  </option>
                ))}
              </ChakraSelect>
            </FormField>
          </>
        )}
        <FormField label={t("Carport Limit")}>
          <CustomCurrencyInput
            value={coverages[index]?.additionalCoverages?.carpolLimit}
            onValueChange={(value) => {
              updateCoverageDetails(index, "carpolLimit", value);
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>
        <FormField label={t("Swimming Pool Limit")}>
          <CustomCurrencyInput
            value={coverages[index]?.additionalCoverages?.swimmingPoolLimit}
            onValueChange={(value) => {
              updateCoverageDetails(index, "swimmingPoolLimit", value);
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>
        <FormField label={t("Outdoor Property Coverage")}>
          <CustomCurrencyInput
            value={coverages[index]?.additionalCoverages?.outdoorPropertyCVG}
            onValueChange={(value) => {
              updateCoverageDetails(index, "outdoorPropertyCVG", value);
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>
      </VStack>
    </Box>
  );
};
