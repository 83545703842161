import Http from "../Http";
import * as action from "../store/actions";
import { ToastContainer, toast } from 'react-toastify';
const coreServiceUrl = process.env.REACT_APP_CORE_API_URL;

export function getInForcePolicies() {
    return dispatch => (
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        Http.get('/api/dashboard/getInForcePolicies')
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      })
    );
  }

export function getTransactionCounts() {
    return dispatch => (
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        Http.get('/api/dashboard/getTransactionCount')
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      })
    );
  }

export function getUpcomingRenewalData() {
    return dispatch => (
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        Http.get('/api/dashboard/getUpcomingRenewalData')
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      })
    );
  }
export function getTotalClaims() {
    return dispatch => (
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        Http.get('/api/dashboard/getTotalClaims')
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      })
    );
  }
export function getTotalClaimReserve() {
    return dispatch => (
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        Http.get('/api/dashboard/getTotalClaimReserve')
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      })
    );
  }
export function getTotalClaimPayment() {
    return dispatch => (
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        Http.get('/api/dashboard/getTotalClaimPayment')
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      })
    );
  }
  export function policyList(params) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        var url = "/api/dashboard/policy-list?";
        Object.entries(params).forEach((v) => {
          if (v[1] !== "") {
            url = url + "&" + v[0] + "=" + v[1];
          }
        });
        Http.get(url)
          .then((res) => {
            return resolve(res);
          })
          .catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      });
  }
  export function RecentTransactionsPolicyList(params) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        var url = "/api/dashboard/recent-transactions-policies-list?";
        Object.entries(params).forEach((v) => {
          if (v[1] !== "") {
            url = url + "&" + v[0] + "=" + v[1];
          }
        });
        Http.get(url)
          .then((res) => {
            return resolve(res);
          })
          .catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      });
  }
  
  export function upcommingRenewalPolicyList(params) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        var url = "/api/dashboard/upcoming-renewal-policy-list?";
        Object.entries(params).forEach((v) => {
          if (v[1] !== "") {
            url = url + "&" + v[0] + "=" + v[1];
          }
        });
        Http.get(url)
          .then((res) => {
            return resolve(res);
          })
          .catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      });
  }
  
  export function recentPolicyClaimList(params) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        var url = "/api/dashboard/recent-claim-policies-list?";
        Object.entries(params).forEach((v) => {
          if (v[1] !== "") {
            url = url + "&" + v[0] + "=" + v[1];
          }
        });
        Http.get(url)
          .then((res) => {
            return resolve(res);
          })
          .catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      });
  }
  export function pieChartData() {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        var url = "/api/dashboard/pie-chart-data";
        Http.get(url)
          .then((res) => {
            return resolve(res);
          })
          .catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      });
  }
  
  export function graphData() {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        var url = "/api/dashboard/graph-data";
        Http.get(url)
          .then((res) => {
            return resolve(res);
          })
          .catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      });
  }
  export function getDashboardNotification(params){
    return dispatch => 
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      var url = `${coreServiceUrl}/api/v1/loadFilterDate?userType=`+params;
      Http.get(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
          dispatch(action.setIsLoading(false));
        });
    });
  }
  export function getStatistics(params){
    return dispatch => 
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      var url = `/api/dashboard/getStatistics?year=`+params;
      Http.get(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
          dispatch(action.setIsLoading(false));
        });
    });
  }

  export function getYearsForStatistics(){
    return dispatch => 
    new Promise((resolve, reject) => {
      dispatch(action.setIsLoading(true));
      var url = `/api/dashboard/getYearsForStatistics`;
      Http.get(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
          dispatch(action.setIsLoading(false));
        });
    });
  }
  export function getAgentTasks(limit = 0) {
    var url = '/api/dashboard/getAgentTasks/' + limit;
    return dispatch => (
      new Promise((resolve, reject) => {
        dispatch(action.setIsLoading(true));
        Http.get(url)
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
            dispatch(action.setIsLoading(false));
          });
      })
    );
  }

  export function getAgentTasksList(params) {
    var url = `/api/dashboard/getAgentTasksList?limit=${params.limit}&page=+${params.page}&pageLength=+${params.pageLength}`;

    return dispatch => (
      new Promise((resolve, reject) => {
        Http.get(url)
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
          });
      })
    );
  }
  
  export function getFemaUpdates() {
    var url = '/api/dashboard/getFemaUpdates';
    return dispatch => (
      new Promise((resolve, reject) => {
        Http.get(url)
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
          });
      })
    );
  }

  export function getApplicationData(type, transType) {
    var url = `/api/dashboard/new/getApplicationStatistics?type=${type}&transType=${transType}`;
    return dispatch => (
      new Promise((resolve, reject) => {
        Http.get(url)
          .then((res) => {
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return reject(err.response);
            }
          });
      })
    );
  }

export function getClosedClaimData(type) {
  var url = `api/dashboard/new/getClosedClaimsStats?type=${type}`
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

export function getOpenClaimData(type) {
  var url = `api/dashboard/new/getOpenClaimsCount?type=${type}`
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

export function getPolicyListData(type, transType, sizePerPage, page) {
  var url = `/api/dashboard/new/getPolicyList?type=${type}&transType=${transType}&sizePerPage=${sizePerPage}&page=${page}`;
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

export function getPaidPolicyData(type, transType) {
  var url = `api/dashboard/new/getPaidUnpaidApplicationCount?type=${type}&transType=${transType}`
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

export function getUnPaidListData(type, transType, sizePerPage, page) {
  var url = `/api/dashboard/new/getPaidUnpaidApplicationList?type=${type}&transType=${transType}&sizePerPage=${sizePerPage}&page=${page}`;
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

export function getClaimListData(type, transType, sizePerPage, page, sortOrder, sortField, policyNo) {
  var url = `/api/dashboard/new/getClaimsList?type=${type}&transType=${transType}&sizePerPage=${sizePerPage}&page=${page}&sortOrder=${sortOrder}&sortField=${sortField}&policyNo=${policyNo}`;
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

export function quickSearch(text){
  var url = `/api/dashboard/quickPolicySearch?text=${text}`;
  return new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          return reject(err?.response || "");
        });
    }); 
}

export function quickSearchAdvance(params) {
  var url = `/api/dashboard/quickPolicySearch?${params}`;

  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}

export const getTransactionData = (policyNumber) => {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/quote/getTransactionData/" + policyNumber).then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        return reject(err.response);
      });
    })
  );
}

export const getApplicationStats = () => {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/dashboard/new/getApplicationData").then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        return reject(err.response);
      });
    })
  );
}

export const getUpcommingRenewalsStatistics = () => {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/dashboard/new/getUpcommingRenewalsStatistics").then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        return reject(err.response);
      });
    })
  );
}
export const getCancelledPolicies = () => {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/dashboard/new/getCancelledPolicies").then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        return reject(err.response);
      });
    })
  );
}

export const getPaidApplications = () => {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/dashboard/new/getPaidApplications").then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        return reject(err.response);
      });
    })
  );
}
export const getPaidRenewals = () => {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/dashboard/new/getPaidRenewals").then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        return reject(err.response);
      });
    })
  );
}
export const getExpiredRenewals = () => {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/dashboard/new/getExpiredRenewals").then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        return reject(err.response);
      });
    })
  );
}

export const getAgentTargetData = () => {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/dashboard/new/getAgentTargetData").then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        return reject(err.response);
      });
    })
  );
}

export const getNpnDetails = () => {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get("/api/dashboard/getNpnDetails").then((res) => {
        return resolve(res.data);
      }).catch((err) => {
        return reject(err.response);
      });
    })
  )
}

export const updateNpnDetails = (params)=>{
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post("/api/dashboard/updateNpnDetails", params)
      .then(res => {
          return resolve(res);
      }).catch(err => {
        return reject(err.response);
      });
    })
  )
}