import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { tenant } from "../../common/Helper";

export default function LoginPage({
  username,
  setUsername,
  password,
  setPassword,
  handleSubmit,
  loading,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const textViewer = () => {
    setShowPassword(!showPassword);
  };

  const displayEnrollButtonForTenant = (tenant) => {
    const excludedTenants = [
      "amfam",
      "csaa",
      "farmers",
      "foremost",
      "alliedtrust",
      "autoclub",
      "ngm",
    ];
    return !excludedTenants.includes(tenant);
  };

  const odysseypage = () => {
    navigate("/odysseyLogin");
  };

  const enrolAgentPage = () => {
    navigate("/enroll-agent");
  };

  return (
    <>
      <Flex direction={"column"} width={"100%"} mt={6}>
        <Text fontSize="31px" mb={4}>
          {t("Sign in to Agent Portal")}
        </Text>
        <form onSubmit={handleSubmit}>
          <FormControl mb={4}>
            <FormLabel>{t("Username")}</FormLabel>
            <Input
              borderColor="var(--chakra-colors-neutral-500)"
              _placeholder={{
                color: "neutral.800",
                fontSize: "sm",
              }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              backgroundColor="var(--chakra-colors-white)"
              placeholder={t("Username")}
              borderRadius={10}
              width="100%"
              height={"47px"}
              isRequired
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>{t("Password")}</FormLabel>
            <InputGroup>
              <Input
                borderColor="var(--chakra-colors-neutral-500)"
                _placeholder={{
                  color: "neutral.800",
                  fontSize: "sm",
                }}
                backgroundColor="var(--chakra-colors-white)"
                type={showPassword ? "text" : "password"}
                placeholder={t("Enter Your Password")}
                height={"47px"}
                borderRadius={10}
                width="100%"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isRequired
              />
              <InputRightElement h={"100%"}>
                <Flex
                  align={"center"}
                  pr={3}
                  cursor={"pointer"}
                  onClick={textViewer}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </Flex>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Flex mb={6} justifyContent={"flex-end"}>
            <ChakraLink
              color={"blue"}
              as={ReactRouterLink}
              to="/forgotPassword"
            >
              {t("Forgot password")}?
            </ChakraLink>
          </Flex>
          <Box mb={4}>
            <Button
              borderRadius="10px"
              fontWeight="var(--chakra-fontWeights-medium)"
              fontSize={16}
              type="submit"
              border={"1px solid var(--chakra-colors-primary-500)"}
              width="100%"
              height={"47px"}
              isDisabled={loading}
            >
              {t("Sign In")}
            </Button>
          </Box>
          <Box mb={4}>
            <Button
              background="var(--chakra-colors-white)"
              color="var(--chakra-colors-black)"
              borderRadius="10px"
              fontWeight="var(--chakra-fontWeights-medium)"
              fontSize={16}
              border="1px solid var(--chakra-colors-neutral-200)"
              width="100%"
              _hover={{
                background: "var(--chakra-colors-primary-500)",
                color: "var(--chakra-colors-white)",
              }}
              onClick={odysseypage}
              height={"47px"}
              isDisabled={loading}
            >
              {t("Set a new password")}
            </Button>
          </Box>
          {displayEnrollButtonForTenant(tenant) ? (
            <Box mb={4}>
              <Button
                background="var(--chakra-colors-white)"
                color="var(--chakra-colors-black)"
                borderRadius="10px"
                fontWeight="var(--chakra-fontWeights-medium)"
                fontSize={16}
                border="1px solid var(--chakra-colors-neutral-200)"
                width="100%"
                _hover={{
                  background: "var(--chakra-colors-primary-500)",
                  color: "var(--chakra-colors-white)",
                }}
                onClick={enrolAgentPage}
                height={"47px"}
                isDisabled={loading}
              >
                {t("Enroll My Agency")}
              </Button>
            </Box>
          ) : null}
        </form>
      </Flex>
    </>
  );
}
