import { Box, Grid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  ChakraSelect,
  CustomFormControl,
} from "../../commercial-quote/Components/CustomComponents";
import { useQuote } from "../../CommCreateQuote";

export const Deductibles = ({ index }) => {
  const { t } = useTranslation();
  const { coverages, setCoverages, baseDD } = useQuote();

  const updateCoverageDetails = (index, name, value) => {
    setCoverages((prevCoverages) => {
      const updatedCoverages = [...prevCoverages];
      updatedCoverages[index] = {
        ...updatedCoverages[index],
        deductibles: {
          ...updatedCoverages[index].deductibles,
          [name]: value,
        },
      };
      return updatedCoverages;
    });
  };
  return (
    <>
      <Box>
        <Grid
          templateColumns={"repeat(auto-fit, minmax(200px, 1fr))"}
          gap={6}
          p={2}
        >
          <CustomFormControl label={t("All-Perils Deductible")}>
            <ChakraSelect
              value={coverages[index]?.deductibles?.allPerilsDed || ""}
              onChange={(value) => {
                updateCoverageDetails(
                  index,
                  "allPerilsDed",
                  value?.target?.value
                );
              }}
            >
              <option value=""></option>
              {baseDD?.ddDeductAllPeril?.map((data, index) => (
                <option key={index} value={data?.value}>
                  {data?.label}
                </option>
              ))}
            </ChakraSelect>
          </CustomFormControl>

          {coverages[index]?.basicCoverages?.theftExclusions === "NO" && (
            <CustomFormControl label={t("Theft Deductible")}>
              <ChakraSelect
                value={coverages[index]?.deductibles?.theftDed || ""}
                onChange={(e) => {
                  updateCoverageDetails(index, "theftDed", e.target.value);
                }}
              >
                <option value=""></option>
                {baseDD?.ddDeductTheft?.map((data, index) => (
                  <option key={index} value={data?.value}>
                    {data?.label}
                  </option>
                ))}
              </ChakraSelect>
            </CustomFormControl>
          )}
          {coverages[index]?.basicCoverages?.windExclusions === "NO" && (
            <>
              <CustomFormControl label={t("Hurricane Deductible")}>
                <ChakraSelect
                  value={coverages[index]?.deductibles?.hurricaneDed || ""}
                  onChange={(value) => {
                    updateCoverageDetails(
                      index,
                      "hurricaneDed",
                      value?.target?.value
                    );
                  }}
                >
                  <option value=""></option>
                  {baseDD?.ddDeductHurricane?.map((data, index) => (
                    <option key={index} value={data?.value}>
                      {data?.label}
                    </option>
                  ))}
                </ChakraSelect>
              </CustomFormControl>
              <CustomFormControl label={t("Wind/Hail Deductible")}>
                <ChakraSelect
                  value={coverages[index]?.deductibles?.windHailDed || ""}
                  onChange={(e) => {
                    updateCoverageDetails(index, "windHailDed", e.target.value);
                  }}
                >
                  <option value=""></option>
                  {baseDD?.ddDeductWind?.map((data, index) => (
                    <option key={index} value={data?.value}>
                      {data?.label}
                    </option>
                  ))}
                </ChakraSelect>
              </CustomFormControl>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};
