import React from "react";
import { Box, Flex, Text, VStack, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";

export default function ConfirmationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex
      h={"80vh"}
      justifyContent="center"
      alignItems="center"
      direction={"column"}
      maxHeight={"770px"}
    >
      <Box>
        <VStack spacing={4} w={"100%"}>
          <Box
            borderRadius={"2xl"}
            border={"1px solid"}
            borderColor={{ lg: "whiteAlpha.100", base: "white" }}
            p={{ base: 3, lg: 8, md: 5 }}
            boxShadow={"0 0 6px rgba(0, 0, 0, 0.3)"}
            w={"55vw"}
            maxWidth={900}
          >
            <Flex gap={3} direction={"column"} align={"center"}>
              <Text fontSize={"3xl"} fontWeight={600} align={"center"}>
                {t("Submission Confirmation")}
              </Text>
              <Text fontSize={"2xl"} fontWeight={600} align={"center"}>
                {t("Thank you for submitting your enrollment form")}
              </Text>
              <Text fontSize={"2xl"} fontWeight={600} align={"center"}>
                {t(
                  "Next: your application will be reviewed. You will be notified if further information is needed. Once approved, you will receive a welcome letter"
                )}.
              </Text>
              <Button
                w={{ base: "9rem", lg: "12rem" }}
                color={"white"}
                borderRadius={"xl"}
                onClick={() => {
                  navigate("/login");
                }}
                leftIcon={<IoCloseSharp />}
              >
                {t("Close")}
              </Button>
            </Flex>
          </Box>
        </VStack>
      </Box>
    </Flex>
  );
}
