import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHiscoxCreateQuote } from "../api/useHiscoxCreateQuote";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import getResidentialRequestBody from "./ApiRequestBodies/ResidentialRequestBody";
import getCommercialTenantedRequestBody from "./ApiRequestBodies/CommercialTenantedReqBody";
import getCommercialOwnedRequestBody from "./ApiRequestBodies/CommercialOwnedReqBody";
import { CreateQuoteContext, useMyContext } from "../CreateQuote";
import StepperDesign from "./StpeerDesign";
import StepConstructionDetails from "./Hiscox/StepConstructionDetails";
import StepPremiumDetails from "./Hiscox/StepPremiumDetails";
import StepFinancialDetails from "./Hiscox/StepFinancialDetails";
import StepApplicantDetails from "./Common/StepApplicantDetails";
import StepSearchAddress from "./Common/StepSearchAddress";
import { useTranslation } from "react-i18next";
import StepAdditionalInformation from "./Hiscox/StepAdditionalInformation";
import { useDispatch, useSelector } from "react-redux";
import { EncryptOrDecrypt } from "../../../../services/commonService";
import { getAgentAuthorizeState } from "../../../../services/floodService";
import { toast } from "react-toastify";
import { Box, Button, Card } from "@chakra-ui/react";
import { LuArrowLeft, LuArrowRight } from "react-icons/lu";
import ProductType from "./ProductType";

const Stepper = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [hicoxQuoteID, setHicoxQuoteID] = useState("N/A");
  const { productID } = useParams();
  const { loading, quoteData, createQuote } = useHiscoxCreateQuote();
  const [quoteObject, setQuoteObject] = useState({});
  const dispatch = useDispatch();
  const userData = EncryptOrDecrypt(localStorage.getItem("user"), "D");
  const [isLoading, setIsLoading] = useState(false);
  const [flagForAgentAuthorization, setFlagForAgentAuthorization] =
    useState(false);
  const { coreSystem } = useSelector((state) => state);
  const {
    constructionDetails,
    locationQuoteObject,
    financialDetails,
    choosedPreferredCard,
    applicantDetails,
    setIsNextClicked,
    setManualEntry,
    limitsValidationError,
    rcvValidationError,
    mailingAddress,
    isMailingAddressSame,
    mortgageeDetails,
    additionalInsured,
    additionalInsuredFlag,
    manualEntry,
  } = useMyContext(CreateQuoteContext);
  const navigation = useNavigate();
  useEffect(() => {
    if (quoteData) {
      setHicoxQuoteID(quoteData?.data?.hiscoxId);
    }
  }, [quoteData]);
  useEffect(() => {
    const { productType, commercialProductType } = constructionDetails;
    let requestBody = {};
    if (productType === "Residential") {
      requestBody = getResidentialRequestBody({
        constructionDetails,
        locationQuoteObject,
        financialDetails,
        applicantDetails,
        mailingAddress,
        isMailingAddressSame,
        mortgageeDetails,
        additionalInsured,
        manualEntry,
      });
    } else if (
      productType === "Commercial" &&
      commercialProductType === "Owned"
    ) {
      requestBody = getCommercialOwnedRequestBody({
        constructionDetails,
        locationQuoteObject,
        financialDetails,
        applicantDetails,
        mailingAddress,
        isMailingAddressSame,
        mortgageeDetails,
        additionalInsured,
        manualEntry,
      });
    } else if (
      productType === "Commercial" &&
      commercialProductType === "Tenanted"
    ) {
      requestBody = getCommercialTenantedRequestBody({
        constructionDetails,
        locationQuoteObject,
        financialDetails,
        applicantDetails,
        mailingAddress,
        isMailingAddressSame,
        mortgageeDetails,
        additionalInsured,
        manualEntry,
      });
    }
    setQuoteObject(requestBody);
  }, [
    locationQuoteObject,
    constructionDetails,
    financialDetails,
    applicantDetails,
    mailingAddress,
    isMailingAddressSame,
    mortgageeDetails,
    additionalInsured,
    manualEntry,
  ]);

  useEffect(() => {
    if (step === 6) {
      createQuote(quoteObject);
    } else {
      setIsNextClicked(false);
    }
  }, [step]);
  const checkAgentAutorisation = async () => {
    const userAccess = userData.accessRights
      ? userData.accessRights.levelName
      : "";
    let params = {
      userPersonInfoFK: userData.n_PersonInfoId_FK,
      product: coreSystem?.applicationData?.n_ProductId_FK || "26",
      state: locationQuoteObject?.stateCode,
    };
    if (userAccess !== "EMPLOYEE") {
      setIsLoading(true);
      await dispatch(getAgentAuthorizeState(params)).then((res) => {
        setIsLoading(false);
        if (res.userAuthorizedState === false) {
          toast.error(
            t("State Not Authorized To Agent!", {
              position: "top-center",
              toastId: "stateNotAuthorized",
            })
          );
          setFlagForAgentAuthorization((prev) => !prev);
        } else if (res.userAuthorizedState) {
          setIsLoading(false);
          setStep(step + 1);
        }
      }).catch(() => {
        setIsLoading(false);
      });
    }
  };
  const onNext = () => {
    setIsNextClicked(true);
    if (step === 0) {
      setStep(step + 1);
    } else if (step === 1) {
      checkAgentAutorisation();
    }
    if (step < 7 && !flagForAgentAuthorization) {
      setStep(step + 1);
    }
  };
  const handlePrevious = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const goPreviousStep = () => {
    setManualEntry(false);
    navigation(`/quote/${productID}/create`);
  };

  const continueToApplication = () => {
    if (choosedPreferredCard) {
      navigation(`/quote/${productID}/application/${hicoxQuoteID}`);
    }
  };

  const locationDetailsElements = [
    {
      name: "addressLine1",
      value: locationQuoteObject?.addressLine,
    },
    {
      name: "zipCode",
      value: locationQuoteObject?.postalCode,
    },
    {
      name: "stateCode",
      value: locationQuoteObject?.stateCode,
    },
    // {
    //   name: "county",
    //   value: locationQuoteObject?.countyName,
    // },
    // {
    //   name: "city",
    //   value: locationQuoteObject?.city,
    // },
  ];

  const manualAndLatLong = () => {
    if (locationQuoteObject?.latitude && !locationQuoteObject?.longitude) {
      return false;
    } else if (
      !locationQuoteObject?.latitude &&
      locationQuoteObject?.longitude
    ) {
      return false;
    }
    return true;
  };

  const allLocationDetailsFilled =
    (locationDetailsElements.every(
      (item) =>
        item.value !== "" && item.value !== null && item.value !== undefined
    ) &&
      !(
        isMailingAddressSame === false &&
        mailingAddress?.mailingAddressData === ""
      )) ||
    false;

  const applicantDetailsElements = [
    {
      name: "firstName",
      value: applicantDetails?.firstName,
    },
    {
      name: "lastName",
      value: applicantDetails?.lastName,
    },
    {
      name: "email",
      value: applicantDetails?.email,
    },
    {
      name: "phoneNumber",
      value: applicantDetails?.contactNo,
    },
    {
      name: "organisationName",
      value: applicantDetails?.organisationName,
    },
  ];

  const {
    occupancyType,
    constructionType,
    foundationType,
    buildingWaterType,
    yearBuilt,
    squareFootage,
    noOfStories,
    requestDate,
    effectiveDate,
  } = constructionDetails;

  const constructionDetailsFilled = [
    occupancyType,
    constructionType,
    foundationType,
    buildingWaterType,
    yearBuilt == 0 ? "" : yearBuilt,
    squareFootage == 0 ? "" : squareFootage,
    noOfStories == 0 ? "" : noOfStories,
    requestDate,
    effectiveDate,
  ].every((detail) => detail !== "");

  // const isValidEmail = /^\S+@\S+\.\S+$/.test(applicantDetails?.email);
  const regexForContact = /^\(\d{3}\)\d{3}-\d{4}$/;
  // const isValidContact = regexForContact.test(applicantDetails?.contactNo);

  const isIndividual = applicantDetails.propertyType === "individual";
  const isOrganisation = applicantDetails.propertyType === "organisation";

  const allDetailsFilled = applicantDetailsElements.every((item) => {
    if (isIndividual) {
      if (item.name === "email" || item.name === "phoneNumber") {
        return true;
      } else {
        return (
          item.value !== "" && item.value !== null && item.value !== undefined
        );
      }
    } else if (isOrganisation) {
      if (item.name === "organisationName") {
        return /[a-zA-Z]/.test(item.value);
      } else {
        return true;
      }
    }
  });

  const minDate = new Date().toISOString().split("T")[0];
  const isDateCorrect = constructionDetails?.effectiveDate
    ? constructionDetails?.effectiveDate >= minDate &&
      constructionDetails?.requestDate >= minDate
    : false;

  const nextButtonDisabled =
    (step === 0 && !constructionDetails?.productType) ||
    (step === 2 && !allDetailsFilled) ||
    (step === 1 && !allLocationDetailsFilled) ||
    (manualEntry && !manualAndLatLong()) ||
    (step === 3 && (!constructionDetailsFilled || !isDateCorrect)) ||
    (step === 5 && limitsValidationError) ||
    (step === 5 && additionalInsured.length === 0 && additionalInsuredFlag);

  const continueButtonDisabled = step === 6 && !choosedPreferredCard;

  const handleApplicantStep = () => {
    setIsNextClicked(true);
    if (allDetailsFilled) {
      onNext();
    }
  };
  const handleFinancialStep = () => {
    setIsNextClicked(true);
    if (!limitsValidationError && !rcvValidationError) {
      onNext();
    }
  };
  return (
    <>
      <Card
        backgroundClip={"white"}
        borderRadius={"3xl"}
        padding={"2rem"}
        boxShadow={"2xl"}
        mb={5}
        p={4}
      >
        <LoadingOverlay>
          <Loader loading={loading || isLoading} />
          <StepperDesign step={step + 1} />
          {step === 0 && <ProductType />}
          {step === 1 && (
            <StepSearchAddress
              allLocationDetailsFilled={allLocationDetailsFilled}
            />
          )}
          {step === 2 && (
            <StepApplicantDetails allDetailsFilled={allDetailsFilled} />
          )}
          {step === 3 && <StepConstructionDetails />}
          {step === 4 && <StepFinancialDetails />}
          {step === 5 && <StepAdditionalInformation />}
          {step === 6 && <StepPremiumDetails />}
          <Box borderTop={"1px solid"} borderColor={"gray.200"} mx={4} />
          <div
            style={{ paddingTop: "2.5rem", paddingBottom: "1rem" }}
            className="d-flex justify-content-center gap-4"
          >
            {step === 0 ? (
              <Button
                color={"black"}
                variant="ghost"
                size="lg"
                borderWidth="2px"
                borderColor="var(--chakra-colors-primary-500)"
                borderRadius="12px"
                padding="20px"
                onClick={goPreviousStep}
                leftIcon={<LuArrowLeft />}
              >
                {t("Back")}
              </Button>
            ) : (
              <Button
                color={"black"}
                onClick={handlePrevious}
                isDisabled={step === 0}
                leftIcon={<LuArrowLeft />}
                variant="ghost"
                size="lg"
                borderWidth="2px"
                borderColor="var(--chakra-colors-primary-500)"
                borderRadius="12px"
                padding="20px"
              >
                {t("Back")}
              </Button>
            )}
            {step === 2 && (
              <Button
                color={"black"}
                onClick={handleApplicantStep}
                rightIcon={<LuArrowRight />}
                variant="ghost"
                size="lg"
                borderWidth="2px"
                borderColor="var(--chakra-colors-primary-500)"
                borderRadius="12px"
                padding="20px"
              >
                {t("Next")}
              </Button>
            )}
            {step === 4 && (
              <Button
                color={"black"}
                variant="ghost"
                size="lg"
                borderWidth="2px"
                borderColor="var(--chakra-colors-primary-500)"
                borderRadius="12px"
                padding="20px"
                onClick={handleFinancialStep}
                rightIcon={<LuArrowRight />}
              >
                {t("Next")}
              </Button>
            )}
            {step === 6 && (
              <Button
                color={"black"}
                variant="ghost"
                size="lg"
                borderWidth="2px"
                borderColor="var(--chakra-colors-primary-500)"
                borderRadius="12px"
                padding="20px"
                isDisabled={continueButtonDisabled}
                onClick={continueToApplication}
                rightIcon={<LuArrowRight />}
              >
                {t("Continue")}
              </Button>
            )}
            {step !== 6 && step !== 2 && step !== 4 && (
              <Button
                color={"black"}
                variant="ghost"
                borderWidth="2px"
                borderColor="var(--chakra-colors-primary-500)"
                borderRadius="12px"
                padding="20px"
                isDisabled={nextButtonDisabled}
                onClick={onNext}
                size="lg"
                rightIcon={<LuArrowRight />}
              >
                {t("Next")}
              </Button>
            )}
          </div>
        </LoadingOverlay>
      </Card>
      <style>
        {`
         .chakra-switch {
          width: fit-content !important;
          margin-top: 1.5px;
        }

        .chakra-switch .chakra-switch__track::after {
          content: "NO" !important;
          color: var(--chakra-colors-white);
          display: block;
          position: absolute;
          top: 15px;
          left: 58%;
          font-size: 11px;
          font-weight: bold;
        }

        .chakra-switch[data-checked] .chakra-switch__track::after {
          content: "YES" !important;
          left: 7.7%;
          font-size: 10px;
          font-weight: normal;
        }

        .bread-crumbs {
          text-align: center;
        }
        
        .bread-crumbs ul {
          list-style: none;
          display: table;
          table-layout: fixed;
          width: 100%;
        }
        
        .bread-crumbs ul li {
          display: inline;
          margin-right: 5px;
        }
        
        .bread-crumbs ul li {
          display: table-cell;
          height: 45px;
          text-align: center;
          padding-left: 30px;
          padding-top: 10px;
          background-color: #f3f5fa;
          margin: 0 0 0 0;
          font-size: 16px;
          color: #8093a7;
          position: sticky;
        
          margin: 0 10px 0 0;
        }
        
        .bread-crumbs ul li:after {
          content: '';
          position: fixed;
          top: 0;
          right: -19px;
          width: 45px;
          height: 45px;
          transform: scale(0.707) rotate(45deg);
          margin-right: 30px !important;
          z-index: 1;
          box-shadow: 2px -2px 0 2px #fff,
            3px -3px 0 2px rgba(255, 255, 255, 0.1);
        
        }
        
        .bread-crumbs ul li:before {
          content: "";
          border-top: 23px solid transparent;
          border-bottom: 23px solid transparent;
          border-left: 23px solid #f3f5fa;
          position: absolute;
          top: 0;
          padding-right: 5px;
          left: -20px;
        }
        
        
        .bread-crumbs ul li.active:before {
          content: "";
          border-top: 23px solid transparent;
          border-bottom: 23px solid transparent;
          border-left: 23px solid var(--chakra-colors-primary-500) !important;
          position: absolute;
          
          left: 0;
          top: 0;
          /* margin-right: 10px; */
        }
        
        
        .bread-crumbs ul li:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          
          /* border-top-right-radius:23px ;
          border-bottom-right-radius: 23px; */
        }
        
        .bread-crumbs ul li:first-child:before {
          display: none;
        }
        
        .bread-crumbs ul li:last-child {
          padding-right: 20px;
          padding-left: 24px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          /* border: 1px solid red; */
        }
        
        .bread-crumbs ul li:last-child:after {
          display: none;
        }
        
        .bread-crumbs ul li:first-line {
          display: block;
          padding-top: 20px;
        }
        
        .arrow-steps .cui-steps__horizontal-step {
          font-size: 14px;
          text-align: center;
          color: var(--chakra-colors-neutral-500);
          cursor: default;
          margin: 0 3px;
          padding: 14px 10px 14px 30px;
          min-width: 16%;
          float: left;
          position: relative;
          background-color: var(--chakra-colors-neutral-100);
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          transition: background-color 0.2s ease;
        }
        .arrow-steps .cui-steps__horizontal-step:after,
        .arrow-steps .cui-steps__horizontal-step:before {
          content: " ";
          position: absolute;
          top: 0px;
          right: -16px;
          width: 0;
          height: 0;
          border-top: 26px solid transparent;
          border-bottom: 27px solid transparent;
          border-left: 17px solid var(--chakra-colors-neutral-100);
          z-index: 2;
          transition: border-color 0.2s ease;
        }
        
        .arrow-steps .cui-steps__horizontal-step:before {
          right: auto;
          left: 0;
          border-left: 17px solid var(--chakra-colors-white);
          z-index: 0;
        }
        
        .arrow-steps .cui-steps__horizontal-step:first-child:before {
          border: none;
        }
        
        .arrow-steps .cui-steps__horizontal-step:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        
        .arrow-steps .cui-steps__horizontal-step:last-child:after {
          border: none;
        }
        
        .arrow-steps .cui-steps__horizontal-step:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        
        .cui-steps__step-icon-container {
          display: none;
        }
        
        .cui-steps__horizontal-step-container .css-0 span {
          color: var(--chakra-colors-black);
          font-size: 16px;
          font-weight: bold;
        }
        
        .arrow-steps .cui-steps__horizontal-step[aria-disabled="false"] {
          color: var(--chakra-colors-white);
          background-color: var(--chakra-colors-primary-500);
        }
        
        .arrow-steps
          .cui-steps__horizontal-step[aria-disabled="false"]
          .cui-steps__horizontal-step-container
          .css-0
          span {
          color: var(--chakra-colors-white);
        }
        
        .arrow-steps .cui-steps__horizontal-step[aria-disabled="false"]:after {
          border-left: 17px solid var(--chakra-colors-primary-500);
        }
        
        .inputField {
          border: 1px solid var(--chakra-colors-neutral-100);
          padding-top: 25px;
          padding-bottom: 25px;
          border-radius: 12px;
        }
        
        .input-switch {
          display: none;
        }
        
        .label-switch {
          display: inline-block;
          position: relative;
        }
        
        .label-switch::before,
        .label-switch::after {
          content: "";
          display: inline-block;
          cursor: pointer;
          transition: all 0.5s;
        }
        
        .label-switch::before {
          width: 3em;
          height: 1em;
          border: 1px solid var(--chakra-colors-neutral-500);
          border-radius: 4em;
          background: var(--chakra-colors-neutral-500);
        }
        
        .label-switch::after {
          position: absolute;
          left: 0;
          top: -20%;
          width: 1.5em;
          height: 1.5em;
          border: 1px solid var(--chakra-colors-neutral-500);
          border-radius: 4em;
          background: var(--chakra-colors-white);
        }
        
        .input-switch:checked ~ .label-switch::before {
          background: var(--chakra-colors-primary-500);
          border-color: var(--chakra-colors-primary-500);
        }
        
        .input-switch:checked ~ .label-switch::after {
          left: unset;
          right: 0;
          background: var(--chakra-colors-primary-500);
          border-color: var(--chakra-colors-primary-500);
        }
        
        .clearfix:after {
          clear: both;
          content: "";
          display: block;
          height: 0;
        }
        
        .arrow-steps .cui-steps__horizontal-step {
          font-size: 14px;
          text-align: center;
          color: var(--chakra-colors-neutral-500);
          cursor: default;
          margin: 0 3px;
          padding: 14px 10px 14px 30px;
          min-width: 11.5%;
          float: left;
          position: relative;
          background-color: var(--chakra-colors-neutral-100);
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          transition: background-color 0.2s ease;
        }
        .arrow-steps .cui-steps__horizontal-step:after,
        .arrow-steps .cui-steps__horizontal-step:before {
          content: " ";
          position: absolute;
          top: 0px;
          right: -16px;
          width: 0;
          height: 0;
          border-top: 26px solid transparent;
          border-bottom: 27px solid transparent;
          border-left: 17px solid var(--chakra-colors-neutral-100);
          z-index: 2;
          transition: border-color 0.2s ease;
        }
        
        .arrow-steps .cui-steps__horizontal-step:before {
          right: auto;
          left: 0;
          border-left: 17px solid var(--chakra-colors-white);
          z-index: 0;
        }
        
        .arrow-steps .cui-steps__horizontal-step:first-child:before {
          border: none;
        }
        
        .arrow-steps .cui-steps__horizontal-step:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        
        .arrow-steps .cui-steps__horizontal-step:last-child:after {
          border: none;
        }
        
        .arrow-steps .cui-steps__horizontal-step:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        
        .cui-steps__step-icon-container {
          display: none;
        }
        
        .cui-steps__horizontal-step-container .css-0 span {
          color: var(--chakra-colors-black);
          font-size: 16px;
          font-weight: bold;
        }
        
        .arrow-steps .cui-steps__horizontal-step[aria-disabled="false"] {
          color: var(--chakra-colors-white);
          background-color: var(--chakra-colors-primary-500);
        }
        
        .arrow-steps
          .cui-steps__horizontal-step[aria-disabled="false"]
          .cui-steps__horizontal-step-container
          .css-0
          span {
          color: var(--chakra-colors-white);
        }
        
        .arrow-steps .cui-steps__horizontal-step[aria-disabled="false"]:after {
          border-left: 17px solid var(--chakra-colors-primary-500);
        }
        
        
        .inputPolicyField {
          border: 2px solid var(--chakra-colors-neutral-500);
          padding-top: 25px;
          padding-bottom: 25px;
          border-radius: 12px;
        }
        
        .coverageField {
          border: 1px solid var(--chakra-colors-neutral-500);
          padding-top: 20px;
          padding-bottom: 20px;
          border-radius: 9px;
          width: 25%;
          margin-top: 4px;
        }
        
        .jewelry-coverage-Field {
          border: 2px solid var(--chakra-colors-neutral-200);
          padding-top: 20px;
          padding-bottom: 20px;
          border-radius: 9px;
          width: 50%;
          margin-top: 4px;
        }
        
        .coverageSelectField {
          height: 42px;
          margin-top: 4px;
        }
        
        .coverageSelectLeftField {
          height: 42px;
          margin-left: -6px;
          margin-top: 4px;
        }
        
        .chakra-switch {
          width: fit-content !important;
          margin-top: 1.5px;
        }
        
        .chakra-switch .chakra-switch__track::after {
          content: "NO";
          color: var(--chakra-colors-white);
          display: block;
          position: absolute;
          top: 15px;
          left: 58%;
          font-size: 11px;
          font-weight: bold;
        }
        
        .chakra-switch[data-checked] .chakra-switch__track::after {
          content: "YES";
          left: 7.7%;
          font-size: 10px;
          font-weight: normal;
        }
        .coverageTitle {
          padding: 20px;
          background-color: var(--chakra-colors-primary-50);
          font-size: 24px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          font-weight: var(--chakra-fontWeights-bold);
          height: 4rem;
        }
        .inputBorder {
          border: 2px solid #9AA5B5;
          padding: 25px;
          border-radius: 12px;
          height:53.5px;   
          background-color: gray;
        }
        
        .selectBorder {
          border: 3px solid #9AA5B5;
          border-radius: 12px;
          height:54.7rem;   
          background-color: gray;
        }
      `}
      </style>
    </>
  );
};

export default Stepper;
