import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './page.scss';
import { Box, Flex, Text, VStack, Button } from "@chakra-ui/react";
import { FiLogIn } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

const Page200 = () => {
    const { t } = useTranslation();
    const { source } = useParams();
    const navigation = useNavigate();
    const [label, setLabel] = useState();

    useEffect(() => {
        switch (source) {
            case 'reset-username':
                setLabel('reset your username');
                break;
            case 'reset-password':
                setLabel('reset your password');
                break;
            default:
                break;
        }
    }, [source]);

    return (
      <>
        <Flex
          h={"80vh"}
          justifyContent="center"
          alignItems="center"
          direction={"column"}
          maxHeight={"770px"}
        >
          <Box>
            <VStack spacing={4} w={"100%"}>
              <Box
                borderRadius={"2xl"}
                border={"1px solid"}
                borderColor={{ lg: "whiteAlpha.100", base: "white" }}
                p={{ base: 3, lg: 8, md: 5 }}
                boxShadow={"0 0 6px rgba(0, 0, 0, 0.3)"}
                w={"55vw"}
                maxWidth={900}
              >
                <Flex gap={3} direction={"column"} align={"center"}>
                  <Text
                    fontSize={"3xl"}
                    fontWeight={600}
                    align={"center"}
                    color={"green"}
                  >
                    {t("Congratulations")}
                  </Text>
                  <Text
                    fontSize={"2xl"}
                    fontWeight={600}
                    align={"center"}
                    color={"green"}
                  >
                    {t("You have successfully")} {label}.
                  </Text>
                  <Button
                    w={{ base: "9rem", lg: "12rem" }}
                    color={"white"}
                    borderRadius={"xl"}
                    onClick={() => {
                      navigation("/login");
                    }}
                    leftIcon={<FiLogIn />}
                  >
                    {t("Go to Login")}
                  </Button>
                </Flex>
              </Box>
            </VStack>
          </Box>
        </Flex>
      </>
    )
}

export default Page200