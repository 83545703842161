import Http from "../../../../../Http";
import { commercialEndpoint } from "../../common/constants";

export async function getCommercialQuote(quoteNo) {
  const url = `/api/v2/quote/${commercialEndpoint}/${quoteNo}`;

  try {
    const { data } = await Http.get(url);
    return data;
  } catch (error) {
    console.error("Error getting commercial quote:", error);
    throw error;
  }
}
