import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LangContext } from "../../contexts/LangContext";
import "./simple-navbar.scss";
import "../layout/header/header.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import SecureImage from "../../pages/common/SecureImage";
import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const SimpleNavbar = () => {
  const { t } = useTranslation();
  const { selectedLng, handleDropdownChange } = useContext(LangContext);
  const navigation = useNavigate();
  const [logo, setLogo] = useState(
    encodeURIComponent(localStorage.getItem("company_logo"))
  );
  const getLogo = useSelector((state) => state.common.getLogo);

  useEffect(() => {
    setLogo(getLogo);
  }, [getLogo]);

  return (
    <>
      <Flex
        px={"40px"}
        py={"10px"}
        bgColor={"white"}
        justifyContent={"space-between"}
      >
        <Box minHeight={"52px"} cursor={"pointer"}>
          <SecureImage
            src={`${logo}`}
            maxHeight={"52px"}
            onClick={() => navigation("/")}
            alt=""
          />
        </Box>
        <Box>
          <Menu>
            <MenuButton
              bgColor={"transparent"}
              _hover={{
                bgColor: "transparent",
              }}
              _active={{
                bgColor: "transparent",
              }}
              border={"1px solid"}
              borderColor={"black"}
              color={"black"}
              as={Button}
              width={"150px"}
              rightIcon={<FaCaretDown size={12} />}
              leftIcon={
                <Image
                  src={`/assets/icons/${selectedLng}.png`}
                  h={"20px"}
                  w={"20px"}
                  alt=""
                />
              }
            >
              {selectedLng}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => handleDropdownChange("English", "en")}>
                {t("English")}
              </MenuItem>
              <MenuItem onClick={() => handleDropdownChange("Spanish", "spa")}>
                {t("Spanish")}
              </MenuItem>
            </MenuList>
          </Menu>
          {/* Notification */}
          <ToastContainer
            theme={"light"}
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Box>
      </Flex>
    </>
  );
};

export default SimpleNavbar;
