import React, { useState, useEffect,useCallback } from "react";
import { Row, Col, Button, Table, Form, Modal } from "react-bootstrap";
import { FaInbox, FaEye, FaPlus, FaFileUpload, FaTimes } from "react-icons/fa";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";
import "./reply.scss";
import { Link } from "react-router-dom";
import Moment from 'moment';
import { useDispatch, connect } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { getReplyData,getActivitiesTypeData, getDocumentsTypeData, verifyActivityType, getActivitySave, getActivityFileSave, getusersListData } from "../../../services/activityService.js";
import { useTranslation } from "react-i18next";
import { isEmpty } from 'lodash';
import { showDataTableTotalText, EncryptOrDecrypt } from "../../../services/commonService";
import { toast } from 'react-toastify';

const Reply = (props) => {
  let params = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [text, setText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [sourcePk,setsourcePk] = React.useState("0");
  const [activityTypesData, setactivityTypesData] = React.useState([]);
  const [docuTypesData, setDocuTypesData] = React.useState([]);
  const [activityTypes, setactivityTypes] = React.useState([]);
  const [ScheduledDate, setScheduledDate] = React.useState(new Date());
  const [uploadDate, setuploadDate] = React.useState(new Date());
  const [subModulePk, setSubModulePK] = React.useState("199");
  const [activityLogPk, setActivityLogPk] = React.useState("0");
  const [activityFlag, setactivityFlag] = React.useState("Activitylog");
  const [MessageToUser, setMessageToUser] = React.useState("");
  const [MessageToUserId, setMessageToUserId] = React.useState("");
  const [MessageToPersonInfoId, setMessageToPersonInfoId] = React.useState("");
  const [MessageSubject, setMessageSubject] = React.useState("");
  const [MessageSourceCode, setMessageSourceCode] = React.useState("");
  const [BaseMessageLinkIdFk, setBaseMessageLinkIdFk] = React.useState(0);
  const [ParentMessageIdFk, setParentMessageIdFk] = React.useState(0);
  const [userData, setUserData] = React.useState("");
  const [docType, setDocType] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [moduleName, setModuleName] = React.useState("Communication");
  const [subModuleName, setsubModuleName] = React.useState("DOCUMENT_FOLDER_DOC_UPLOAD");
  const [AgencyId_Pk, setAgencyId_Pk] = React.useState("0");
  const [searchKey, setSearchKey] = React.useState("");
  const [ActivityAuthorisation, setActivityAuthorisation] = React.useState(
    "Internal"
  );
  const [fileSelected, setFiles] = React.useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [tableData, setTableData] = useState([]);
  const [tableTotal, setTableTotal] = useState(0);
  const [filter, setFilter] = useState({
    AgencyId_Pk,
    ActivityAuthorisation,
    sourceType:moduleName,
    searchKey:searchKey,
    page:1,
    pageLength:10
  });
  const onDrop = useCallback(event => {
    setFiles(event[0])
  }, [])

  const { acceptedFiles, isDragActive, getRootProps, getInputProps} = useDropzone({ onDrop });
  var files = acceptedFiles.map((file, i) => (
     <li key={file.path}>
      {file.name} &nbsp;&nbsp;<FaTimes size={15} onClick={() => remove(i)} />
    </li>
  ));

  const remove = file => { 
    const newFiles = [...files];
    newFiles.splice(file, 1);
    acceptedFiles.splice(file, 1);
    setFiles(newFiles);
  };

  useEffect(() => {
    getActivitiesType();
    getDocumentsType();
		// if(typeof params.id != 'undefined') {
    //   //getDocumentsType();
		// }else {
    //   /* If id is undefined */
		// }  
	}, []);
  
  const getActivitiesType = async () => {
    setLoading(true);
		const activityRes = await dispatch(getActivitiesTypeData()).catch(() => {
      setLoading(false);
    });
    const activityTypesData = activityRes?.data.filter(type => type.s_AppCodeNameForDisplay === "Policy").map((type) => ({
          id: type.n_AppCodeId_PK,
          label: type.s_AppCodeNameForDisplay,
          codeLabel:
            type.s_AppCodeNameForDisplay === "Claim" ||
            type.s_AppCodeNameForDisplay === "Policy"
              ? `${type.s_AppCodeNameForDisplay} No`
              : `${type.s_AppCodeNameForDisplay} Code`,
      }));
      setactivityTypesData(activityTypesData);
      var tempvar = activityTypesData;
      //const userDatas = JSON.parse(localStorage.getItem("user"));
      const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
      setUserData(userDatas);
      let selectedActivityTypeId = '';
      activityTypesData?.map((type) => {
      if (type.label === 'Policy') {
          selectedActivityTypeId = type.id;
      }
    });
      
    setactivityTypes(props.activityType != undefined && props.activityType == 'POLICY' ? selectedActivityTypeId : []);
    setMessageSourceCode(props.sourceCode != undefined  ? props.sourceCode : "");
    if((typeof params.id != 'undefined' && params.id > 0) || (typeof props.messageId !='undefined' && props.messageId > 0)) {
      var messageId = typeof params.id != 'undefined' ? params.id : typeof props.messageId !='undefined' ? props.messageId : '';
      setLoading(true);
      getReplyForView(tempvar, messageId);
      setactivityFlag("editActivitylog");
    }
    else {
      setLoading(false);
    }
	};
  const getDocumentsType = async () => {
		await dispatch(getDocumentsTypeData(moduleName,subModuleName)).then((res) => { 
      setDocuTypesData(res.data);
		});
	};
  const getReplyForView = async (tempvar, messageId) => {
    setLoading(true);
    setsourcePk(messageId);
    let actions = await dispatch(getReplyData(messageId)).catch(() => {
      setLoading(false);
    });
    if (!actions) return;
    var fromUserData = actions.data.messagemappingdetails[0];
    var toUserData = actions.data.messagemappingdetails[1];
    var docsData = actions.data.getdocdetails;
    var mainData = actions.data.messagedetails;
    setMessageToUser(fromUserData.MessageUserName);
    setMessageToUserId(fromUserData.MessageUser_IdFk);
    setMessageToPersonInfoId(fromUserData.MessageUser_PInfo_FK);
    var subject = mainData.MessageSubject;
    setMessageSubject("Re:-".concat(subject.replace("Re:-", "")));
    //setactivityTypes(docsData.SourceType);
    setMessageSourceCode(mainData.MessageSourceCode);
    setBaseMessageLinkIdFk(mainData.BaseMessageLink_IdFk);
    setParentMessageIdFk(mainData.MessageInfo_PK);
    var str = mainData.MessageSourceType;
    var res = str.toLowerCase();
    const selectedActivity = activityTypesData.find(
      (element) => element.id == activityTypes
    );
    var selectedtype = res.split("_");
    var types = selectedtype[0];
    var activityTempVar = types.charAt(0).toUpperCase() + types.slice(1);
    var selectedid = tempvar.find(
      (element) => element.label == activityTempVar
    );
    setactivityTypes(selectedid.id);
    setLoading(false);
	};
  

  const handleSave = async (values) => {
    var date = null;
    date = Moment(ScheduledDate).format("MM-DD-YYYY");
    var activity = "0";
    if (activityTypes > 0) {
      const selectedActivity = activityTypesData.find(
        (element) => element.id == activityTypes
      );
      var activity = selectedActivity.label.toUpperCase();
    }
    const params = {
      MessageFromUser: userData.s_ScreenName,
      MessageFromUserId: userData.Admin_ID,
      MessageFromPersonInfoId: userData.n_PersonInfoId_FK,
      MessageToUser,
      MessageToUserId,
      MessageToPersonInfoId,
      MessageSubject,
      ActivityAuthorisation,
      ScheduledDate: date,
      ActivityType: activity,
      MessageSourceCode,
      ParentMessage_IdFk: ParentMessageIdFk,
      BaseMessageLink_IdFk: BaseMessageLinkIdFk,
      inputhp: editorState.getCurrentContent().getPlainText('\u0001')
    };
    let error = "N";
    let msg = "";
    const datas = {
      ActivityType: activity,
      MessageSourceCode,
    };
    if (params.MessageSourceCode != "" && params.ActivityType == "POLICY") {
      setLoading(true);
      let actions = await dispatch(verifyActivityType(datas)).catch(() => {
        setLoading(false);
      });
      if (actions.data.status === "Y" && actions.data.policyPk.n_PolicyNoId_PK != "") {
        setsourcePk(actions.data.policyPk.n_PolicyNoId_PK);
      }
      if (actions.data.status === "N") {
        error = "Y";
        msg = actions.data.msg;
      }
     }
     if (isEmpty(fileSelected)) {
      error = "Y";
      msg = "Please Select Attachement File";
     }
     if (params.MessageSourceCode == "") {
      error = "Y";
      msg = "Please Enter Activity Souce Code!";
     }
     if (params.ActivityType == "0") {
      error = "Y";
      msg = "Please Select Activity Type!";
     }
     if (params.MessageSubject == "") {
      error = "Y";
      msg = "Please Enter Subject !";
     }
     if (params.MessageToUser == "") {
      error = "Y";
      msg = "Please Enter Recipient Address in To Field!";
     }

     if (!isEmpty(fileSelected)) {
      if(fileName == ''){
        error = "Y";
        msg = "Please Enter fileName!";
      }
      if(docType == ''){
        error = "Y";
        msg = "Please Select DocType!";
      }
    }

    if (error == "Y") {
      setLoading(false);
      toast.error(t(msg));
    } else {
      saveActivity(params);
    }

  };
 
  const saveActivity = async (params) => {
    setLoading(true);
    let actions =  await dispatch(getActivitySave(params)).catch(() => {
      setLoading(false);
    });
    if(actions?.data.status == "Y") {
      setActivityLogPk(actions.data.Message_InsertedId);
      setsourcePk(actions.data.Message_InsertedId);
      sendRequestForFile(fileSelected, actions.data.Message_InsertedId)
    }
  };

  const sendRequestForFile = async (newFile,id) => {
    const formData = new FormData();
    let uploadDate = Moment(ScheduledDate).format("YYYY-MM-DD");
    var receivedDate = Moment(new Date()).format("YYYY-MM-DD");
    formData.append("file", newFile, newFile.name);
    formData.append("moduleSelected", 13);
    formData.append("doctypeSelected", docType);
    formData.append("description", newFile.name);
    formData.append("uploadDate", uploadDate);
    formData.append("sourceId", '');
    formData.append("moduleName", moduleName);
    formData.append("fileName", fileName);
    formData.append("authorization", ActivityAuthorisation);
    formData.append("caseType", '');
    formData.append("receivedDate", receivedDate);
    formData.append("sourcePk", id);
    formData.append("activityLogPk", id);
    formData.append("docType", '');
    formData.append("status", '');
    formData.append("subStatus", '');
    formData.append("demandAmt", '');
    formData.append("refNumber", '');
    formData.append("OppCounsel", '');
    formData.append("assignTo", '');
    formData.append("policyId", '');
    formData.append("subModule", subModuleName);
    formData.append("claimlossPaymentPk", '');
    formData.append("subModulePk", subModulePk);
    let SaveFileData =  await dispatch(getActivityFileSave(formData)).catch(() => {
      setLoading(false);
    });
    setLoading(false);
    if(props.sourceCode != undefined){
      props.viewPart('VIEW');
    }else {
      navigation('/active-log/');
    }
  };

  useEffect(() => {
    if(isOpen){
      getUserList();
    }
  }, [filter.page]);

  const openModel = async () => {
    setIsOpen(!isOpen);
    getUserList();
  }
  
  const getUserList = async () => {
    setLoading(true);
    await dispatch(getusersListData(filter)).then((res) => { 
       setTableData(res.data.data || []);
       setTableTotal(res.data.total || 0);
    }).catch(() => {
      setTableData([]);
      setTableTotal(0);
    }) ;
    setLoading(false);
  };

  const onKeyUpSearchHandler = (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter"){
      getUserList();
    }
    // let timer1 = setTimeout(() => getUserList(), 1500)
    // return () => {
    //   clearTimeout(timer1)
    // }
   
  };
  const handleChangeOfInputs = (e) => {
    const tempArray = filter; // creating copy of an array
    tempArray['searchKey'] = e.target.value
    setFilter(tempArray); // set copied array
  };

  const actionsButtons = (cell, row, enumObject, rowIndex) => {
    let linkBtn = null;
    linkBtn = (
      <React.Fragment>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onClickNameSelect(row, rowIndex);
          }}
        >
          {cell}
        </a>
      </React.Fragment>
    );
    return <React.Fragment>{linkBtn}</React.Fragment>;
  };
  
  const onClickNameSelect = (row, rowIndex) => {
    setMessageToUser(row.screenname);
    setMessageToUserId(row.adminid);
    setMessageToPersonInfoId(row.personinfofk);
    openModel();
  };
  
  const columns = [
    {
      dataField: "username",
      text: "USER",
      formatter: actionsButtons
    },
    {
      dataField: "screenname",
      text: "SCREEN NAME",
    },
    {
      dataField: "appcodename",
      text: "STATUS",
    },
    {
      dataField: "userlevelname",
      text: "LEVEL",
    },

  ];

  const handlePageChange = (page, sizePerPage) => {
    setFilter({...filter,page:page,pageLength:sizePerPage});
  };

  const sizePerPageListChange = (sizePerPage) => {
    setFilter({...filter,pageLength:sizePerPage});
  };

  const renderShowsTotal = (start, to, total) => {
    return showDataTableTotalText(start, to, total);
  };

  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    pageStartIndex: 1,
    paginationShowsTotal: renderShowsTotal,
    onPageChange: handlePageChange,
    totalSize:tableTotal
    //sizePerPageDropDown: renderSizePerPageDropDown
  };

  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      <div className="reply-page-section">
        <h4 className="activity-log-title">Communication</h4>
        <Row>
          <Col xs="12">
            <div className="card-header-main">
              <div className="card-header-main-title">
                <FaInbox size={21} color={"#fff"} />
                <span className="card-header-main-text">{ (sourcePk > 0 ) ? 'Reply' : 'Add New' }</span>
              </div>
              <div className="card-header-btns-div">
                <div className="card-header-action"  style={{display: "flex"}}>
                  <FaEye size={21} color={"#fff"} />
                  <span className="card-header-action-title" 
                   onClick={()=> 
                    {
                       props.sourceCode != undefined ?
                       props.viewPart('VIEW')
                    :
                       navigation('/active-log/')
                    }}
                  >
                    View Log</span>
                </div>
                <Button
                 style={{display: "flex"}}
                  id="common-outline-btn"
                  className="card-header-btn0"
                  variant="outline-primary"
                  onClick={()=> 
                    {
                       props.sourceCode != undefined ?
                       props.viewPart('NEW')
                    :
                       navigation('/active-log/new-activity')
                    }}
                >
                  <FaPlus size={15} /> Add New
                </Button>{" "}
              </div>
            </div>
            <div className="card-body-main">
              <Row>
                <Col xs="12" sm="12" md="12" lg="7" xl="7">
                  <Table borderless className="columns-table-main">
                    <tr>
                      <th>From</th>
                      <td>
                        <Form.Control
                          className="dashboard-group-input"
                          type="from"
                          name="from"
                          id="email-to"
                          autoComplete="from"
                          readOnly={true}
                          value={userData.s_ScreenName}
                          onChange={() => {}}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>To</th>
                      <td>
                        <Form.Control
                          className="dashboard-group-input"
                          type="email"
                          name="email"
                          id="email-to"
                          readOnly={true}
                          required
                          value={MessageToUser}
                        />
                      </td>
                      <td>
                        {(sourcePk != "" &&  sourcePk > 0)? null : (
                          <Button
                          id="common-btn" 
                            onClick={() => openModel()}
                          >
                            <i className="fa fa-Search"></i>&nbsp;Get Recipient
                          </Button>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Subject</th>
                      <td>
                        <Form.Control
                          className="dashboard-group-input"
                          type="text"
                          name="subject"
                          id="subject"
                          placeholder=""
                          onChange={(e) => setMessageSubject(e.target.value)}
                          value={MessageSubject}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Activity Type</th>
                      <td>
                        <Form.Select 
                        className="dashboard-group-input"
                        name="activityType"
                        id="activityType"
                        onChange={(e) => setactivityTypes(e.target.value)}
                        value={activityTypes}
                        disabled={props.activityType != undefined ? true : false}
                        >
                        <option value={0}>Select Activity Type</option>
                        {activityTypesData?.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.label}
                          </option>
                        ))}
                        </Form.Select>
                      </td>
                    </tr>
                    {activityTypesData?.find(
                    (type) => type.id === parseInt(activityTypes)
                    ) && (
                    <tr>
                      <th> {
                      activityTypesData?.find(
                        (type) => type.id === parseInt(activityTypes)
                      ).codeLabel
                      }
                    </th>
                      <td>
                        <Form.Control
                          className="dashboard-group-input"
                          type="text"
                          id="MessageSourceCode"
                          name="MessageSourceCode"
                          placeholder={
                            activityTypesData.find(
                              (type) => type.id === parseInt(activityTypes)
                            ).codeLabel
                          }
                          onChange={(e) => setMessageSourceCode(e.target.value)}
                          value={MessageSourceCode}
                          disabled={props.sourceCode != undefined ? true : false}
                        />
                      </td>
                    </tr>)}
                    <tr>
                      <th>Schedule Date</th>
                      <td className="schedule-date-row">
                        <DatePicker
                          id="dashboard-datepicker"
                          className="datepicker-full-width"
                          name="scheduleDate"
                          onChange={(date) => setScheduledDate(date)}
                          selected={ScheduledDate}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <th>Select Document Type</th>
                      <td>
                      <Form.Select className="dashboard-group-input">
                          <option>HO3 Diamond</option>
                          <option>HO6 (Homeowners)</option>
                          <option>MDP Direct</option>
                          <option>MDP Direct</option>
                        </Form.Select>
                      </td>
                    </tr> */}
                  </Table>
                </Col>

                {/* <Col xs="12">
                  <span>Select Document Type</span>
                  <div className="select-document-type-div">
                    <Row>
                      <Col xs="12" sm="12" md="12" lg="12" xl="3">
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>All Documents</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Inspection Report</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Police Report</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Letter of Acknowledgement</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Signed Application</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Four Point Inspection</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Wind Mitigation Inspection</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Photos </span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Proof of Prior Insurance</span>
                        </div>
                      </Col>
                      <Col xs="12" sm="12" md="12" lg="12" xl="3">
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>HUD Statement</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Loss History - lexis Nexis</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Loss History - A plus</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Burglar Alarm Certificate </span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Fire & Burglar Alarm Certificate</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Driver's License</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Endorsement Request</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Sinkhole Coverage Exclusion form signed </span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Animal Liability Exclusion form Signed</span>
                        </div>
                      </Col>
                      <Col xs="12" sm="12" md="12" lg="12" xl="3">
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>
                            Building Law & Ordination Building & Ordination{" "}
                          </span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Building Permit records</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Roof permit records</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>County Appraisal records</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Return Mail PH</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Return Mail MTG</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Email from Agent</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Email from PH </span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Complaint</span>
                        </div>
                      </Col>
                      <Col xs="12" sm="12" md="12" lg="12" xl="3">
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>POP </span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Reissue Refund Check Request</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Reissue Claim Check Request</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Reissue Commission Check Request</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Others</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Property Appraisal</span>
                        </div>
                        <div className="document-type-subdiv">
                          <Form.Check
                            type="checkbox"
                            label=""
                            className="table-checkbox"
                            id="custom-checker"
                          />
                          <span>Claim Supporting Document</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col> */}
                <Col xs="12" style={{ marginTop: "25px" }}>
                  <div className="reply-page-editor">
                    <Editor
                      editorState={editorState}
                      rows ={3}
                      onEditorStateChange={setEditorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </div>
                </Col>
                <Col xs="5" style={{ marginTop: "25px" }}>
                  <Table borderless className="columns-table-main">
                    <tr>
                      <th>&nbsp;&nbsp;&nbsp;&nbsp;Module</th>
                    </tr>
                    <tr>
                      <td>
                        <Form.Control
                          className="dashboard-group-input"
                          type="text"
                          placeholder="Communication"
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>&nbsp;&nbsp;&nbsp;&nbsp;Document Type</th>
                    </tr>
                    <tr>
                      <td>
                        <Form.Select 
                        className="dashboard-group-input"
                        name="docuemntType"
                        id="docuemntType"
                        onChange={(e) => setDocType(e.target.value)}
                        value={docType}
                        >
                        <option value={0}>Select Document Type</option>
                        {docuTypesData.map((doc) => (
                          <option key={doc.value} value={doc.value}>
                            {doc.label}
                          </option>
                        ))}
                        </Form.Select>
                      </td>
                    </tr>
                    <tr>
                      <th>&nbsp;&nbsp;&nbsp;&nbsp;Filename</th>
                    </tr>
                    <tr>
                        <td>
                          <Form.Control
                            className="dashboard-group-input"
                            placeholder="filename"
                            type="text"
                            onChange={(e) => setFileName(e.target.value)}
                            value={fileName}
                          />
                        </td>
                      </tr>
                    </Table>
                </Col>
                <Col xs="7" style={{ marginTop: "25px" }}>
                  <span><b>Attachment Detail</b></span>
                  <div
                    id="drop-document-type-div"
                    {...getRootProps({ className: "dropzone" })}
                  >
                    <input  type="file"
                      multiple=""
                      autocomplete="off"
                      className="inp-file"
                      // onChange={uploadFile}
                      {...getInputProps()} />
                    <FaFileUpload size={34} color={"#64D6CD"} />
                    <p>Drag and Drop File Here</p>
                    <Button id="common-btn" variant="primary">
                      <FaPlus size={15} />
                      Add File
                    </Button>{" "}
                  </div>

                  <aside className="reply-document-name">
                    <ul>{files}</ul>
                  </aside>
                </Col>
                <Col xs="12" style={{ marginTop: "25px" }}>
                      <Table>
                      <tr>
                        <th style ={{ textAlign:"right" }}><Button id="common-btn" variant="primary" onClick={() => handleSave()}>
                          Submit
                        </Button>{" "}</th>
                      </tr>
                      </Table>
                  </Col>
              
                
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
      className="custom-dashboard-modal"
      size="lg"
      show={isOpen}
      >
        <Modal.Header closeButton  onClick={() => {setIsOpen(false); }} className="custom-dashboard-modal-header">
          <Modal.Title>Search Users</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-dashboard-modal-body">
          <Row style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <Table borderless className="columns-table-main">
                      <tr>
                        <td style={{ textAlign : "right"}}>
                          <Form.Control
                            className="dashboard-group-input"
                            type="from"
                            name="search-model"
                            style={{ width : "300px", float : "right"}}
                            onChange={(e) => handleChangeOfInputs(e)}
                            onKeyUp={(e) => onKeyUpSearchHandler(e)}
                          />
                        </td>
                      </tr>
                </Table>
            </Col>

            <Col xs="12" style={{ padding: 0 }}>
              <LoadingOverlay>
                <Loader loading={loading} />
                <BootstrapTable
                  wrapperClasses="dashboard-table-main"
                  bootstrap4
                  remote
                  keyField="screenname"
                  data={tableData}
                  columns={columns}
                  hover
                  pagination={paginationFactory(options)}
                />
              </LoadingOverlay>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </LoadingOverlay>
  );
};

export default Reply;
