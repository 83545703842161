import React, { Fragment, useEffect, useState } from "react";
import { Button, Row, Col, Accordion, Alert,Badge } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getBaseData, getQuoteData, getEditEmptyQuoteData, getEmptyQuoteData, rateorSaveApplication, resetFloodStore, createApplication, getGeneratedDocs, validateCeLicense, getPrivateProductsExcess, getProducerInfo,getExcessPremium,getPrivateProductConfigurations } from "../../../../services/floodService";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Applicant from "./Applicant";
import BuildingFoundation from "./BuildingFoundation";
import Community from "./Community";
import Construction from "./Construction";
import Coverage from "./Coverage";
import MachineryEquipment from "./MachineryEquipment";
import Mortgagee from "./Mortgagee/FloodAddMortages";
import Occupancy from "./Occupancy";
import Policy from "./Policy";
import PriorPolicyInfo from "./PriorPolicyInfo";
import AdditionalInsured from "./Mortgagee/FloodAddInsured";
import NewlyMapDiscount from "./NewlyMapDiscount";
import ElevationCertificate from "./ElevationCertificate";
import FloodVents from "./FloodVents";
import CreateApplication from "./Modal/CreateApplication";
import Validation from "./Validation";
import ValidationPrivateProducts from "./ValidationPrivateProducts";
import ErrorModal from "./Modal/Error";
import "react-overlay-loader/styles.css";
import PivotLog from "./Modal/PivotLog";
import PremiumDetails from "./Modal/PremiumDetails";
import { FaSave, FaCheckDouble, FaPrint, FaHistory, FaListAlt, FaExclamationTriangle, FaCheckCircle } from "react-icons/fa";
import ListDocument from "./Modal/ListDocument";
import Swal from 'sweetalert2';
import Moment from 'moment';
import { getPayGovToken } from "../../../../services/paymentService";
import PayNow from "../../../policy/Common/PayNow";
import { checkPivotOutage,EncryptOrDecrypt } from "../../../../services/commonService"; 
import {selectExcessRequestParams} from "../../../../store/selectors/private-flood/excessProduct";
import {selectExcessProducts,selectPrivateProducts} from "../../../../store/selectors/private-flood";
import {getProfileInfo} from "../../../../services/profileService";
import ExcessProductModal from "./../../../quote/flood/instantQuote/flood-detail/private-flood-plan/ExcessProductQuoteScreenModal";
import {setExcessProductData} from '../../../../store/actions';
import PrivateProductModal from "./../../../quote/flood/instantQuote/flood-detail/private-flood-plan/PrivateProductModal/PrivateProductModal";
import * as action from '../../../../store/actions';

const AccordionView = () => {
  let { poTransPk } = useParams();
  const isPivotOutage = checkPivotOutage();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, apiLoading, floodSystemDD, isAgencyAgentNameUpdated = false } = useSelector(state => state.flood);
  const [isFirmUpdated, setIsFirmUpdated] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isBind, setIsBind] = useState(false);
  const [isCondoAssociation, setIsCondoAssociation] = useState('');
  const [showMortgagee, setShowMortgagee] = useState(false);
  const [showAddInsureds, setShowAddInsureds] = useState(false);
  const [isMaillingAddrDiffer, setIsMaillingAddrDiffer] = useState(false);
  const [waitingPeriod, setWaitingPeriod] = useState('');
  const [initFirmDate, setInitFirmDate] = useState(null);
  const [baseFlood, setBaseFlood] = useState(null);
  const [isPreFirm, setIsPreFirm] = useState(false);
  const [substantialImprovement, setSubstantialImprovement] = useState('NO');
  const [substantialImprovmentDate, setSubstantialImprovmentDate] = useState(null);
  const [DOC, setDOC] = useState(null);
  const [isMobileHome, setIsMobileHome] = useState(false);
  const [occupancyType, setOccupancyType] = useState('');
  const [NOU, setNOU] = useState(0);
  const [isInsuredResides, setIsInsuredResides] = useState(false);
  const [buildingType, setBuildingType] = useState('');
  const [foundationType, setFoundationType] = useState('');
  const [isCurrMapDateGreater, setIsCurrMapDateGreater] = useState(false);
  const [isNewlyMapped, setIsNewlyMapped] = useState(false);
  const [currentMapDate, setCurrentMapDate] = useState(null);
  const [isEC, setIsEC] = useState(false);
  const [floodZone, setFloodZone] = useState('');
  const [preFirmSubsidyEligible, setPreFirmSubsidyEligible] = useState(false);
  const [isTheInsured, setIsTheInsured] = useState('');
  const [poTransPK, setPoTransPK] = useState(null);
  const [showSubmitAppModal, setShowSubmitAppModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [index, setIndex] = useState(['0']);
  const [btLabel, setBtLabel] = useState('Expand All');
  const [isExpand, setIsExpand] = useState(false);
  const [pivotLog, showPivotLog] = useState(null);
  const [showPremium, setShowPremium] = useState(false);
  const [show, setShow] = useState(false);
  const [bindAlert, setBindAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showRequiredDocModal, setShowRequiredDocModal] = useState(false);
  const [showPaymentBtn, setShowPaymentBtn] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [wantAddLender, setWantAddLender] = useState(false);
  const [requireDocumentCount, setRequireDocumentCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [determinationStatus, setDeterminationStatus] = useState("");
  const [showRolloverAlert, setShowRolloverAlert] = useState(false);
  const [displayExcessproduct, setDisplayExcessproduct] = useState(false);
  const excessRequestParams = useSelector(state => selectExcessRequestParams(state) ?? null);
  const [adminEmail, setAdminEmail] = useState('');
  const [tenantInterest, setTenantInterest] = useState(null)
  const excessPrivateProduct = useSelector(state => selectExcessProducts(state))
  const [openModalForExcessProducts, setOpenModalForExcessProducts] = useState(false);
  const [openModalForPrivateProducts, setOpenModalForPrivateProducts] = useState(false);
  const url_success = `https://`+ window.location.host + `/flood/thank-you/`;
  const url_cancel = `https://`+ window.location.host + `/flood/pay-now/`;
  const privateProduct = useSelector(state => selectPrivateProducts(state))
  const [viewPrivateProductQuotesButton, setViewPrivateProductQuotesButton] = useState(false);
  const [errorHeadingText, setErrorHeadingText] = useState('');
  const [determinationWarningMsg, setDeterminationWarningMsg] = useState('');
  
  // Button Layout
  const btnLayout = { background: '#fff', border: '1.5px solid #64d6cd', borderRadius: '4px', boxShadow: 'none!important', color: '#424242', display: 'inline-flex' }

  useEffect(()=>{
    async function fetchAndSetAdminEmail() {
      await dispatch(getProfileInfo()).then((res) => {
        setAdminEmail(res?.data?.data?.Email);
      })
    }
    fetchAndSetAdminEmail();
  },[])
  
  useEffect(()=>{
    if(
      excessPrivateProduct?.privateproduct?.length > 0
      ) {
        setDisplayExcessproduct(true);
    }
  },[excessPrivateProduct])

  useEffect(()=>{
    if(
      privateProduct?.privateproduct?.length > 0
      ) {
        setViewPrivateProductQuotesButton(true);
    }
  },[privateProduct])

  useEffect(() => {
    if (isExpand) {
      setIndex(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']);
      setBtLabel('Close All');
    } else {
      setIndex(['0']);
      setBtLabel(t('Expand All'));
    }
  }, [isExpand]);

  useEffect(() => {
    // Get Quote Data
    if (poTransPk) {
      getDropDown();
      getQuoteDataFLD(poTransPk, 'INIT');
    }
    // Auto-Save - On Select Coverage From Coverage Plan Options
    if (!poTransPk) {
      handleSubmit('Save', "Y");
    }
    // get emptyquote object
    if ([undefined, "", null].includes(poTransPk) && Object.keys(emptyQuoteData).length === 0) {
      getEmptyQuoteObject();
      getDropDown();
    }
    getPrivateProductConfigurationsFunction();
    getPrivateProductsAonedgeExcess(); // to get excess private product
  }, []);

  // isbinder
  useEffect(() => {
    // Get Quote Data
    if (Object.keys(emptyQuoteData).length > 0) {
      var temp = false;
      if (checkDateisValid(emptyQuoteData.policyInfo.d_BinderDate)) {
        temp = true;
      }
      setShowPaymentBtn(emptyQuoteData.showPaymentBtn);
      setPoTransPK(emptyQuoteData.transactionInfo.n_potransaction_PK || null);
      setIsBind(temp);

      // Only For Issued Application
      if (emptyQuoteData && emptyQuoteData.transactionInfo && emptyQuoteData.transactionInfo.s_TransactionCycleCode === 'ISSUED') {
        setShow(true);
        setAlertMsg(t('Application Issued.'));
      }

      setRequireDocumentCount(emptyQuoteData.requireDocumentCount);
      
      temp = false;
      if(emptyQuoteData.propertyInfo && emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod == 'ROLLOVER_RENEWAL' && emptyQuoteData.transactionInfo.s_TransactionCycleCode !== 'ISSUED') {
        temp = true;
      }
      setShowRolloverAlert(temp);

      // 
      setDeterminationWarningMsg(emptyQuoteData?.determinationArray?.message || '');
    }
  }, [emptyQuoteData]);

   // Binder Alert
  //  useEffect(() => {
  //   if (isBind && emptyQuoteData && emptyQuoteData.transactionInfo && emptyQuoteData.transactionInfo.s_TransactionCycleCode !== 'ISSUED') {
  //     setBindAlert(isBind);
  //     setAlertMsg(t('Application Submitted, Underwriter will review & contact you shortly.'));
  //   }
  // }, [isBind]);

  // Check Firm type
  useEffect(() => {
    checkFirmType();
  }, [DOC, substantialImprovement, substantialImprovmentDate]);

  // check firm type
  useEffect(() => {
    checkFirmType();
  }, [isFirmUpdated])

  // ODS-2328-Save & Rate is not available if the producer name is changed.
  useEffect(() => {
    setIsChanged(isAgencyAgentNameUpdated);
  }, [isAgencyAgentNameUpdated])

  // get dropdown
  const getDropDown = async () => {
    await dispatch(getBaseData(10));
  }

  // get emptyquote object
  const getEmptyQuoteObject = async () => {
    // reset store
    try {
      dispatch(resetFloodStore());
      await dispatch(getEmptyQuoteData(10));
    } finally {
      setIsLoaded(true);
    }
  }

  // get quote data based on poTransPk
  const getQuoteDataFLD = async (poTransPk, from = null) => {
    if (from === 'INIT') {
      // reset store
      dispatch(resetFloodStore());
    }
    await dispatch(getQuoteData(poTransPk)).catch(()=> {
      setIsLoaded(true);
      navigation("/quote");
    });
    setIsLoaded(true);
  }

  // For All Input/Select Handler
  const handleChange = (e, fullNameChange = '') => {
    isLoaded && setIsChanged(true);
    var quoteDataVar = emptyQuoteData;
    // Change if Applicant Type Change
    if (fullNameChange.length > 0) {
      quoteDataVar['personInfo']['s_FirstName'] = '';
      quoteDataVar['personInfo']['s_MiddleName'] = '';
      quoteDataVar['personInfo']['s_LastOrganizationName'] = '';
      quoteDataVar['personInfo']['s_FullLegalName'] = '';
    } else {
      const { name, value, attributes } = e.target;
      const parentKey = attributes.parentobject.value;
      quoteDataVar[parentKey][name] = value;

      // update full name
      if (['s_FirstName', 's_MiddleName', 's_LastOrganizationName'].includes(name)) {
        var fullName = '';
        if (name === 's_FirstName') {
          fullName = value + ' ' + quoteDataVar[parentKey]['s_LastOrganizationName'];
        }
        if (name === 's_LastOrganizationName') {
          fullName = quoteDataVar[parentKey]['s_FirstName'] + ' ' + value;
        }
        if (name === 's_MiddleName') {
          fullName = quoteDataVar[parentKey]['s_FirstName'] + ' ' + value + ' ' + quoteDataVar[parentKey]['s_LastOrganizationName'];
        }
        quoteDataVar[parentKey]['s_FullLegalName'] = fullName.toUpperCase();
      }
      if(['s_AddressLine2'].includes(name)){
        quoteDataVar['personAddrMail'][name]= value
      }
      // Update Date Of Construction If Year Built Changed
      if (['s_YearBuilt'].includes(name)) {
        quoteDataVar[parentKey]['d_DateOfConstruction'] = value ? new Date(value + '-01-01') : '';
        setIsFirmUpdated(false);
      }
      if (['s_BillTo'].includes(name)) {
        quoteDataVar['propertyInfo']['s_BillTo'] = value;
      }
    }

    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // For All App Switch Handler
  const handleSwitchChange = (e) => {
    isLoaded && setIsChanged(true);
    const { name, attributes, checked } = e.target;
    var parentObjectName = attributes.parentobject.value;
    var quoteDataVar = emptyQuoteData;
    if (checked) {
      quoteDataVar[parentObjectName][name] = attributes.checkedvalue.value;
    } else {
      quoteDataVar[parentObjectName][name] = attributes.unCheckedValue.value;
      if (name === 's_IsAddInsureds') {
        quoteDataVar['additionaInsuredData'] = [];
      }
      if (name === 's_IsPolicyMortgage') {
        quoteDataVar['mortgageeData'] = [];
      }
      // Updated Dependent Question Value
      if (name === 's_IsPriorNFIPLapsed' && ['', 'NO'].includes(attributes.unCheckedValue.value)) {
        quoteDataVar[parentObjectName]['s_IsCommunitySuspension'] = '';
      }
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // handle State Change 
  const handleStateChange = (view, value, field = '') => {
    if (view === 'APPLICANT') {
      var temp = '';
      if (field === 'MORTGAGE') {
        setShowMortgagee(value);
      }
      if (field === 'condoAssociation') {
        temp = value === 'YES' ? true : false;
        setIsCondoAssociation(temp);
      }
      if (field === 'ADDINSUREDS') {
        setShowAddInsureds(value);
      }
      if (field === 'isMaillingAddrDiffer') {
        temp = value === 'YES' ? true : false;
        setIsMaillingAddrDiffer(temp);
      }
      if (field === 'isTheInsured') {
        setIsTheInsured(value);
      }
    }
    if (view === 'POLICY') {
      if (field === 'waitingPeriod') {
        setWaitingPeriod(value);
      }
    }
    if (view === 'COMMUNITY') {
      if (field === 'EC') {
        setBaseFlood(value);
      }
      if (field === 'InitFirmDate') {
        setInitFirmDate(value);
      }
      if (field === 'currentMapDate') {
        setCurrentMapDate(value);
      }
      if (field === 'FloodZone') {
        setFloodZone(value);
      }
      if (field === 'isEC') {
        setIsEC(value);
      }
      if (field === 'determinationStatus') {
        setDeterminationStatus(value);
      }
    }
    if (view === 'DETERMINATION') {
      if (field === 'isNewlyMapped') {
        setIsNewlyMapped(value);
      }
      if (field === 'isCurrMapDateGreater') {
        setIsCurrMapDateGreater(value);
      }
    }
    if (view === 'CONSTRUCTION') {
      if (field === 's_SubstantialImprovement') {
        setSubstantialImprovement(value);
      } else if (field === 'd_SubstantialImprovmentDate') {
        setSubstantialImprovmentDate(value);
      } else if (field === 'MOBILE') {
        setIsMobileHome(value);
      } else if (field === 'DOC') {
        setDOC(value);
      }
    }
    if (view === 'OCCUPANCY') {
      if (field === 'OccupancyType') {
        setOccupancyType(value);
      }
      if (field === 'NOU') {
        setNOU(value);
      }
      if (field === 'isInsuredResides') {
        setIsInsuredResides(value);
      }
      if (field === 'buildingType') {
        setBuildingType(value);
      }
    }
    if (view === 'BUILDING') {
      if (field === 'FT') {
        setFoundationType(value);
      }
    }
    if (view === 'PREFIRM') {
      if (field === 'preFirmSubsidyEligible') {
        setPreFirmSubsidyEligible(value);
      }
    }
  }

  // For All Date Handler
  const handleDateChange = (date, name, parent) => {
    isLoaded && setIsChanged(true);
    var quoteDataVar = emptyQuoteData;
    
    // if not empty then format as per db require
    if (date) {
      date = Moment(date).format('YYYY-MM-DD');
    }

    if (parent) {
      quoteDataVar[parent][name] = date;
    } else {
      quoteDataVar[name] = date;
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // check date if valid or not 
  const checkDateisValid = (date) => {
    return !isNaN(Date.parse(date));
  }

  //Check If Firm is Pre-Firm Or not
  const checkFirmType = () => {
    if (Object.keys(emptyQuoteData).length > 0) {
      const emptyQuote = { ...emptyQuoteData };
      var temp = emptyQuote.propertyInfo.s_FloodConstructionType;
      if ((new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction) <= new Date('1974-12-31')) || (new Date(emptyQuoteData.propertyInfo.d_DateOfConstruction) < new Date(emptyQuoteData.propertyInfo.d_InitialFirmDate))) {
        temp = 'FLDPREFIRM';
      } else {
        temp = 'FLDPOSTFIRM'
      }
      if (substantialImprovement === 'YES' && ![null, '', '0000-00-00'].includes(substantialImprovmentDate)) {
        temp = 'FLDPOSTFIRM';
      }
      // default historical question to no if it is post firm, and if it is pre firm ask the question
      if (temp === 'FLDPOSTFIRM') {
        emptyQuote.propertyInfo.s_IsBldgNRHP = 'NO';
      }

      emptyQuote.propertyInfo.s_FloodConstructionType = temp;
      dispatch(getEditEmptyQuoteData(emptyQuote));
      setIsPreFirm(temp)
    }
  }

  // Change Date Formate to Y-m-d
  const changeDateFormate = (request) => {
    for (const [key, val] of Object.entries(request)) {
      if (['personInfo', 'propertyInfo', 'policyInfo', 'transactionInfo'].includes(key)) {
        for (const [innerKey, innerVal] of Object.entries(val)) {
          if ((innerKey.indexOf('d_') === 0 && !['d_CreatedDate', 'd_UpdatedDate'].includes(innerKey)) || ['s_PriorPolicyExpDt'].includes(innerKey)) {
            if (checkDateisValid(innerVal)) {
              request[key][innerKey] = Moment(innerVal).format('YYYY-MM-DD')
            }
          }
        }
      }

      if (key === 'effectiveDate') {
        if (checkDateisValid(val)) {
          request[key] = Moment(val).format('YYYY-MM-DD')
        }
      }
    }
    return request;
  }

  // open accordion
  const toggleAccordion = (index) => {
    setIndex(index);
  }

  // validation
  const validateFields = (formData = emptyQuoteData, firstTime = "N") => {
    // Change Date Formate
    var request = changeDateFormate(formData);
    const { toastArray, indexArray, status } = Validation(request, checkDateisValid, firstTime);
    if (toastArray.length > 0) {
      toggleAccordion(indexArray);
      setErrorList(toastArray);
      setShowErrorModal(!showErrorModal);
      setIsChanged(true);
    }
    setValidated(!status);
    return { status, request };
  }

  // Validation Private Products
  const validateFieldsPrivateProduct = (formData = emptyQuoteData, firstTime = "N") => {
    var request = changeDateFormate(formData);
    const { toastArray, indexArray, status } = ValidationPrivateProducts(request, checkDateisValid, firstTime);
    if (toastArray.length > 0) {
      toggleAccordion(indexArray);
      setErrorList(toastArray);
      setShowErrorModal(!showErrorModal);
      setIsChanged(true);
    }
    setValidated(!status);
    return { status, request };
  }


  const handleSubmit = async (type, firstTime = "N") => {
    // validate CE License Of agent before save & rate as well as submit
    let isValidCeLicense = true;
    var ceValidationParams = {};
    var subAgentPersonPk =
      emptyQuoteData.policyInfo.n_SubAgentPersoninfo_FK ?? 0;
    var agencyPersonPk = emptyQuoteData.policyInfo.n_AgencyPeson_FK ?? 0;
    ceValidationParams.subAgentPersonPk = subAgentPersonPk;
    ceValidationParams.agencyPersonPk = agencyPersonPk;

    try {
      await dispatch(validateCeLicense(ceValidationParams)).then((res) => {
        if (res.status == false) {
          Swal.fire({
            icon: "warning",
            title: t("Please Wait"),
            html: t("celicenseErrorMsg"),
            confirmButtonColor: "#66e3d8",
          });
          //  toast.error(t('CE License Expired - You are not eligible for write new business.'));
          isValidCeLicense = false;
          return false;
        }
      });
      if (isValidCeLicense == true) {
        // validate first
        setErrorHeadingText("");
        const { status, request } = validateFields(emptyQuoteData, firstTime);
        setValidated(!status);
        if (status === true) {
          request.whichButton = type;
          request.firstTime = firstTime;
          await dispatch(rateorSaveApplication(request, type))
            .then((res) => {
              if (res.status === "Y") {
                if (type === "Save") {
                  setIsChanged(false);
                }
                // Obly For Save & Rate
                type !== "Submit" && toast.success(res.msg);
                setAlertMsg(res.msg);
                setShow(true);
                if (poTransPK) {
                  // To Fetch Quote Data
                  getQuoteDataFLD(poTransPK);
                } else {
                  getQuoteDataFLD(res.transactionPK);
                  setPoTransPK(res.transactionPK);
                  window.history.replaceState(
                    null,
                    null,
                    "/flood/quote/" + res.transactionPK
                  );
                }
                // For Create Application
                if (type === "Submit") {
                  setShowSubmitAppModal(true);
                }
              } else {
                if (res.hasOwnProperty("validation")) {
                  setErrorList(res.validation);
                  setShowErrorModal(!showErrorModal);
                  if (poTransPK) {
                    // To Fetch Quote Data
                    getQuoteDataFLD(poTransPK);
                  } else {
                    getQuoteDataFLD(res.transactionPK);
                    setPoTransPK(res.transactionPK);
                    window.history.replaceState(
                      null,
                      null,
                      "/flood/quote/" + res.transactionPK
                    );
                  }
                }
                toast.error(res.msg);
                setIsChanged(true);
              }
            })
            .catch(() => {
              toast.error(t("Something Went Wrong!"));
              setIsChanged(true);
            });
        } else {
          dispatch(action.setIsLoading(false));
        }
      }
    }
    finally {
      dispatch(action.setIsLoading(false));
    }
  }

  const submitApplication = async () => {
    await dispatch(createApplication(poTransPK)).then((res) => {
      if (res.success && res.data.success) {
        if (res.data.data) {
          let coreResponse = res.data.data;
          if (coreResponse.Status == 'Y') {
            const emptyQuote = { ...emptyQuoteData };
            emptyQuote.policyInfo.Policy_No = res.data.data.Policy_No;
            emptyQuote.corePoTransPK = res.data.data.PotransactionPK;
            toast.success(t(coreResponse.Msg));
            dispatch(getEditEmptyQuoteData(emptyQuote));
            navigation('/flood/pay-now/' + res.data.data.PotransactionPK);
          } else {
            if (coreResponse.msg && typeof coreResponse.msg === "string") {
              toast.error(t(coreResponse.msg));
              setShowSubmitAppModal(false);
            }
            if (Array.isArray(coreResponse.ErrorArray) && coreResponse.ErrorArray.length > 0) {
              setErrorList(coreResponse.ErrorArray);
              setShowErrorModal(!showErrorModal);
            }
          }
        }
      } else {
        if (res.data.success === false) {
          if (res.data.data && typeof res.data.data === "string") {
            setShowSubmitAppModal(false);
            if (res?.data?.isDocRequired) {
              Swal.fire({
                title: 'Error',
                text: res.data.data,
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'View Required Document',
                cancelButtonText: 'Cancel',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                  setShowRequiredDocModal(true);
                }
              })
            } else {
              toast.error(res?.data?.data);
            }
          }
        }
      }
    }).catch(() => {
      toast.error(t('Something Went Wrong!'));
    })
  }

  /**
   * To generate document
   * @param {String} source (RATESHEET, PRINTQUOTE, PRINTAPP)
   */
  const generateDoc = async (source) => {
    await dispatch(getGeneratedDocs(source, poTransPK)).then((res) => {
      if (res.status === 'Y') {
        window.open(res.url);
      } else {
        var msg = res.msg ? res.msg : 'Something Went Wrong!'
        toast.error(t(msg));
      }
    }).catch(() => {
      toast.error(t('Something Went Wrong!'));
    })
  }

  /**
  * @param {String} paymenttype
  */
  const navigatePayment = (type) => {
    // for local and UAT
    // if (process.env.REACT_APP_NODE_ENV !== 'PROD') {
    //   navigation('/flood/payment/' + type + '/' + emptyQuoteData.corePoTransPK);
    //   return false;
    // }

    if(type == 'ECHECK' || 'ACH'){
      const url_success = `https://`+ window.location.host + `/flood/thank-you/` + emptyQuoteData.corePoTransPK;
      const url_cancel = `https://`+ window.location.host + `/flood/pay-now/` + emptyQuoteData.corePoTransPK;
      const paymentType = type == 'ECHECK' ? 'PLASTIC_CARD':'ACH';
      const params = {
        'transaction_amount': emptyQuoteData.premium,
        'url_success': url_success,
        'url_cancel': url_cancel,
        'payment_type': paymentType,
        'policyNo': emptyQuoteData.policyInfo.Policy_No,
        'source': "AgentSide_Quote"
      };
      dispatch(getPayGovToken(params)).then((res) => {
        if(res.data.token != '')
        {
          window.location = res.data.payment_page_url;
        }
      });
    } else {
      navigation('/flood/payment/' + type + '/' + emptyQuoteData.corePoTransPK);
    }
  }

  // Validate Waiting Period Before Create Application
  const validateWaitingPeriod = () => {
    if (emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod === 'LOANNOWAIT' && emptyQuoteData.propertyInfo.s_IsPolicyMortgage === 'NO') {
      Swal.fire({
        title: 'Warning',
        text: 'A lender must be listed prior to issuance, or the policy will be updated to 30 day wait, would you like to add a lender now?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Add Lender',
        cancelButtonText: 'Continue to Submit Application',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          setWantAddLender(true);
          toggleAccordion('1')
        } else {
          // Continue to Submit Application
          handleSubmit('Submit');
        }
      })
    } else {
      // Continue to Submit Application
      handleSubmit('Submit');
    }
  }

  // Update Require Doucment Count on Upload document
  const countRequiredDocFunction = () => setRequireDocumentCount(requireDocumentCount - 1);

  // only save application
  const handleSave = async () => {
    setLoading(true);
    try {
      await dispatch(validateCeLicense()).then(async (res) => {
        if (res.status) {
          var request = changeDateFormate(emptyQuoteData);
          await dispatch(rateorSaveApplication(request)).then((res) => {
            if (res.status === 'Y') {
              toast.success(res.msg);
              // To Fetch Quote Data
              getQuoteDataFLD(res.transactionPK);
            } else {
              if (res.hasOwnProperty('validation')) {
                setErrorList(res.validation);
                setShowErrorModal(!showErrorModal);
              }
              toast.error(res.msg);
            }
          });
        } else {
          toast.error(t('CE License Expired - You are not eligible for write new business.'));
        }
      });
    }
    finally {
      setIsChanged(false);
      setLoading(false);
    }
  }

  const getPrivateProductConfigurationsFunction = async () => {
    await dispatch(getPrivateProductConfigurations());
  }
  const getPrivateProductsAonedgeExcess = async () => {
    await dispatch(getPrivateProductsExcess());
  }
  const handlePvtProductAonEdgeExcessSave = async () => {
    const newAonEdgeExcess = [];
      const newState = [];
      newAonEdgeExcess['error'] = '';
      newAonEdgeExcess['quoteNumber'] = '';
      newAonEdgeExcess['premium'] = [];
      newAonEdgeExcess['quoteLink'] = '';
      newAonEdgeExcess['loading'] = true;
      newState['excessAonEdgeproduct'] = newAonEdgeExcess;

      dispatch(setExcessProductData(newState))
      setErrorHeadingText('Please fix the following errors in order to view Aon Edge Excess quote.');
      const { status, request } = validateFieldsPrivateProduct(emptyQuoteData, 'N');
      if(status){
        setOpenModalForExcessProducts(!openModalForExcessProducts);
      }
  }
  
  const getExcessFloodQuote = async (coverage,selectedProduct,policyNumber,effectiveDate) => {
    try {
      const agencyInfo = await getProducerInfo(emptyQuoteData.policyInfo.n_AgencyPeson_FK,emptyQuoteData.policyInfo.n_SubAgentPersoninfo_FK);
      const agencyAddress = {
        city: agencyInfo.address?.s_CityName ?? '',
        county: agencyInfo.address?.s_CountyName ?? '',
        line1: agencyInfo.address?.s_AddressLine1 ?? '',
        line2: agencyInfo.address?.s_AddressLine2 ?? '',
        state: agencyInfo.address?.s_StateCode ?? '',
        street: agencyInfo.address?.s_StreetName ?? '',
        zip: agencyInfo.address?.s_PostalCode ?? '',
        googlePlaceId: '',
        latitude: agencyInfo.address?.s_Latitude ?? '',
        longitude: agencyInfo.address?.s_Longitude ?? '',
      }
      
      const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
      excessRequestParams['agency'] = {...agencyInfo, address : agencyAddress}; //need to change agnecyNo
      excessRequestParams['building'] = {...excessRequestParams['building'], tenantInterest}
      excessRequestParams['agent'] = {...excessRequestParams['agent'], email: adminEmail}
      const coverages = excessRequestParams['coverages'];

      excessRequestParams['primaryPolicy'] = {...excessRequestParams['primaryPolicy'], 
      PolicyNumber: policyNumber ?? null,
        InsuranceType: selectedProduct.value == 'nfip' ? 'NFIP' : 'Private',
        Carrier: selectedProduct.value ?? null,
        FloodZone: excessRequestParams['building']['floodZone'],
        ContentCoverage: excessRequestParams['building']['n_ContentCvg'],
        EffectiveDate: Moment(effectiveDate).format('YYYY-MM-DD') ?? null,
        ExcessCoverageBuilding : parseInt(coverage),
        ExcessCoverageContent:0
      }

      const customBldgDed = excessRequestParams['customBldgDed'] ? parseFloat(excessRequestParams['customBldgDed'].replace('FLDDED', '')) : 0;
      const customContentDed = excessRequestParams['customContentDed'] ? parseFloat(excessRequestParams['customContentDed'].replace('FLDDED', '')) : 0;

      const apiRequestParams = {...excessRequestParams, coverages, deductible: customBldgDed > 0 ? customBldgDed : customContentDed}
      dispatch(getExcessPremium(apiRequestParams))
    } catch (e) {
      throw  e;
    }
  }

  const closeModal = () => {
    setOpenModalForExcessProducts(false);
  };

  const closePvtProductModal = () => {
    setOpenModalForPrivateProducts(false);
  };

  const handleGetPremiumClick = (coverage,selectedOption,policyNumber,effectiveDate) => {
    getExcessFloodQuote(coverage,selectedOption,policyNumber,effectiveDate);
  };
  
  const handlePvtProductModal = async () => {
    setErrorHeadingText('Please fix the following errors in order to view private product quotes.');
    const { status, request } = validateFieldsPrivateProduct(emptyQuoteData, 'N');
    if(status){
      setOpenModalForPrivateProducts(!openModalForPrivateProducts);
    }
  }   
  return (
    <LoadingOverlay >
      <Loader loading={apiLoading || loading} />
      {/* Success Alert */}
      <Alert show={show} variant={'success'} style={{ marginTop: '5px' }} onClose={() => setShow(false)} dismissible>
        <FaCheckCircle />&nbsp;&nbsp;{alertMsg}
      </Alert>
      {/* Rollover Alert */}
      {
        showRolloverAlert && (
          <Alert show={showRolloverAlert} variant={'warning'} style={{ marginTop: '5px' }}>
            <FaExclamationTriangle />&nbsp;&nbsp;{t('Rollover at Renewal')}
          </Alert>
        )
      }
      {
        ['external', 'internal'].includes(determinationStatus) && (
          <Alert show={['external', 'internal'].includes(determinationStatus)} variant={'warning'} style={{ marginTop: '5px' }}>
            <FaExclamationTriangle />&nbsp;&nbsp;{determinationWarningMsg}
          </Alert>
        )
      }
      {/* Warning Alert */}
      <Alert show={bindAlert} variant={'warning'} style={{ marginTop: '5px' }}>
        <FaExclamationTriangle />&nbsp;&nbsp;{alertMsg}
      </Alert>
      {
        Object.keys(emptyQuoteData).length > 0 && (
          <Fragment>
          <Row>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style={{ padding: 5 }}
            >
              {
                (![null, ''].includes(poTransPK) && !isBind) && (
                  <Button
                    style={btnLayout}
                    variant="primary"
                    id="search-quote-btn"
                    onClick={() => {validateWaitingPeriod(); setWantAddLender(false);}}
					disabled={isPivotOutage=='false' ? false : true}
                  >
                    <FaSave />&nbsp;&nbsp;
                    {t('Continue to Create Application')}
                  </Button>
                )
              }
              &nbsp;
              {
                (![null, ''].includes(poTransPK)) && (emptyQuoteData.premium >= 0) && (
                  <Button style={btnLayout} variant="primary" id="btn_dec_cvg_waviar_doc" onClick={() => generateDoc('PRINT_DECLINE_COVERAGE')}>
                  <FaPrint />&nbsp;&nbsp;
                  {t('Decline Coverage')}
                </Button>
                )
              }
              <Button className="pull-right" style={{ ...btnLayout, marginLeft: 5 }} variant="primary" id="close-all-btn" onClick={() => setIsExpand(!isExpand)}>
                {t(btLabel)}
              </Button>
              {
                errorList.length > 0 && (
                  <Button
                    className="pull-right"
                    style={{ ...btnLayout, marginLeft: 5 }}
                    variant="primary"
                    id="search-quote-btn"
                    onClick={() => validateFields()}
                  >
                    <FaHistory />&nbsp;&nbsp;
                    {t('Error Log')}
                  </Button>
                )
              }{" "}
              {
                !isBind && isPivotOutage=='false' && (
                  <>
                    <Button
                          className="pull-right"
                          style={{ ...btnLayout, marginLeft: 5 }}
                          variant="primary"
                          id="search-quote-btn"
                          onClick={() => handleSave()}
                          hidden={['external', 'internal'].includes(determinationStatus) ? false : true}
                        >
                          <FaSave />&nbsp;&nbsp;
                          {t('Save')}{" "}{t('Quote')}
                    </Button>{" "}
                    {
                      isChanged && (
                        <Button
                          className="pull-right"
                          style={{ ...btnLayout, marginLeft: 5 }}
                          variant="primary"
                          id="search-quote-btn"
                          onClick={() => handleSubmit('Rate')}
                        >
                          <FaCheckDouble />&nbsp;&nbsp;
                          {t('Save & Rate')}
                        </Button>
                      )
                    }{" "}
                  </>
                )
              }
              <Button
                className="pull-right"
                style={{ ...btnLayout, marginLeft: 5 }}
                variant="primary"
                id="search-quote-btn"
                onClick={() => setShowPremium(true)}
              >
                <FaListAlt />&nbsp;&nbsp;
                {t('Premium Details')}
              </Button>
              {
                ![null, ''].includes(poTransPK) && (
                  <React.Fragment>
                    <Button className="pull-right" style={{ ...btnLayout, marginLeft: 5 }} variant="primary" id="search-quote-btn" onClick={() => generateDoc('PRINTQUOTE')}>
                      <FaPrint />&nbsp;&nbsp;
                      {t('Print Quote')}
                    </Button>
                    {
                      isBind && (
                        <React.Fragment>
                          <Button className="pull-right" style={{ ...btnLayout, marginLeft: 5 }} variant="primary" id="search-quote-btn" onClick={() => generateDoc('PRINTAPP')}>
                            <FaPrint />&nbsp;&nbsp;
                            {t('Print App')}
                          </Button>
                          {
                            showPaymentBtn && (
                              <PayNow
                                showPaymentButton={showPaymentBtn}
                                amount={emptyQuoteData.premium || 0}
                                successUrl={url_success + emptyQuoteData.corePoTransPK + '/' + poTransPK}
                                cancelUrl={url_cancel + emptyQuoteData.corePoTransPK}
                                policyNumber={emptyQuoteData.policyInfo.Policy_No || ""}
                                poTransPK={emptyQuoteData.corePoTransPK}
                                loading={loading}
                                setLoading={setLoading}
                                afterPayment={null}
                                source={"AgentSide_Quote"}
                                transTypeScreenName={emptyQuoteData.transactionInfo.s_PRTranTypeCode || ""}
                                showRolloverAlert={showRolloverAlert}
                              />
                            )
                          }
                        </React.Fragment>
                      )
                    }
                    <Button className="pull-right" style={{ ...btnLayout, marginLeft: 5 }} variant="primary" id="search-quote-btn" onClick={() => showPivotLog(<PivotLog showPivotLog={showPivotLog} poTransPK={poTransPK} from="QUOTE_VIEW" selecteduuid={(emptyQuoteData?.propertyInfo?.uuid || '')} />)}>
                      <FaHistory />&nbsp;&nbsp;
                      {t('Pivot Api Logs')}
                    </Button>
                    <Button className="pull-right" style={{ ...btnLayout, marginLeft: 5 }} id="search-quote-btn" onClick={() => setShowRequiredDocModal(!showRequiredDocModal)}>
                      {
                        requireDocumentCount > 0 ? <Badge bg='danger' className='Blink'>{requireDocumentCount}</Badge> : <FaPrint />
                      }&nbsp;&nbsp;
                      {t('Required Document')}
                    </Button>
                    {" "}
                  </React.Fragment>
                )
              }
            </Col >
            </Row>
            <Row>
            {
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style={{ padding: 5 }}
            >
              {displayExcessproduct && emptyQuoteData.propertyCoverageInfo.n_BuildingCvg == 250000 ?
              <Button
                className="pull-left"
                style={{ ...btnLayout, marginLeft: 5 }}
                variant="primary"
                id="search-quote-btn"
                onClick={() => handlePvtProductAonEdgeExcessSave()}
              >
                <FaListAlt />&nbsp;&nbsp;
                {t('Aon Edge Excess Available')}
              </Button> : null
              }
              {
                viewPrivateProductQuotesButton && 
              <Button
                className="pull-left"
                style={{ ...btnLayout, marginLeft: 5 }}
                variant="primary"
                id="search-quote-btn"
                onClick={() => handlePvtProductModal()}
              >
                <FaListAlt />&nbsp;&nbsp;
                {t('View Private Product Quotes')}
              </Button>
              }
            </Col> 
            }
            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ padding: 0 }} >
              <Accordion activeKey={index} className="custom-quote-accordion">
                {/* Applicant & Property */}
                <Accordion.Item eventKey="0" onClick={() => toggleAccordion('0')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('APPLICANT AND PROPERTY')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <Applicant
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      validated={validated}
                      isBind={isBind}
                      handleChange={handleChange}
                      handleSwitchChange={handleSwitchChange}
                      handleStateChange={handleStateChange}
                      isDataChanged={setIsChanged}
                      wantAddLender={wantAddLender}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* MORTGAGEE(S) */}
                <Accordion.Item eventKey="1" hidden={!showMortgagee} onClick={() => toggleAccordion('1')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('MORTGAGEE(S)')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <Mortgagee
                      showMortgagee={showMortgagee}
                      wantAddLender={wantAddLender}
                      isDataChanged={setIsChanged}
                      isBind={isBind}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* ADDITIONAL INSURED(S) */}
                <Accordion.Item eventKey="10" hidden={!showAddInsureds} onClick={() => toggleAccordion('10')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('ADDITIONAL INSURED(S)')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <AdditionalInsured
                      showAddInsureds={showAddInsureds}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* Policy */}
                <Accordion.Item eventKey="2" onClick={() => toggleAccordion('2')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('POLICY')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <Policy
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      validated={validated}
                      isBind={isBind}
                      isMortgage={showMortgagee}
                      currentMapDate={currentMapDate}
                      handleChange={handleChange}
                      handleDateChange={handleDateChange}
                      handleStateChange={handleStateChange}
                      checkDateisValid={checkDateisValid}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* community */}
                <Accordion.Item eventKey="3" onClick={() => toggleAccordion('3')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('COMMUNITY')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <Community
                      poTransPK={poTransPK}
                      generateDoc={generateDoc}
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      validated={validated}
                      isBind={isBind}
                      handleChange={handleChange}
                      handleDateChange={handleDateChange}
                      handleStateChange={handleStateChange}
                      checkDateisValid={checkDateisValid}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* NEWLY MAPPED DISCOUNT ELIGIBILITY */}
                <Accordion.Item eventKey="11" hidden={!isNewlyMapped} onClick={() => toggleAccordion('11')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('NEWLY MAPPED DISCOUNT ELIGIBILITY')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <NewlyMapDiscount
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      isCurrMapDateGreater={isCurrMapDateGreater}
                      isPropNewlyMapped={isNewlyMapped}
                      validated={validated}
                      isBind={isBind}
                      handleChange={handleChange}
                      handleDateChange={handleDateChange}
                      checkDateisValid={checkDateisValid}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* construction */}
                <Accordion.Item eventKey="4" onClick={() => toggleAccordion('4')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('CONSTRUCTION')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <Construction
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      validated={validated}
                      isBind={isBind}
                      handleChange={handleChange}
                      handleDateChange={handleDateChange}
                      handleStateChange={handleStateChange}
                      checkDateisValid={checkDateisValid}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* occupancy */}
                <Accordion.Item eventKey="5" onClick={() => toggleAccordion('5')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('OCCUPANCY')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <Occupancy
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      validated={validated}
                      isMobileHome={isMobileHome}
                      isCondoAssociation={isCondoAssociation}
                      isAddressSame={isMaillingAddrDiffer}
                      isBind={isBind}
                      handleSwitchChange={handleSwitchChange}
                      handleChange={handleChange}
                      handleStateChange={handleStateChange}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* BUILDING AND FOUNDATION */}
                <Accordion.Item eventKey="6" onClick={() => toggleAccordion('6')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('BUILDING AND FOUNDATION')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <BuildingFoundation
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      validated={validated}
                      isMobileHome={isMobileHome}
                      occupancyType={occupancyType}
                      isBind={isBind}
                      handleChange={handleChange}
                      handleStateChange={handleStateChange}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* Machinery And Equipment */}
                <Accordion.Item eventKey="7" onClick={() => toggleAccordion('7')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('MACHINERY AND EQUIPMENT')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <MachineryEquipment
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      validated={validated}
                      foundationType={foundationType}
                      isBind={isBind}
                      handleChange={handleChange}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* Elevation Certificate */}
                <Accordion.Item eventKey="12" hidden={isEC} onClick={() => toggleAccordion('12')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('ELEVATION CERTIFICATE (MAY LOWER YOUR PREMIUM)')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <ElevationCertificate
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      validated={validated}
                      floodZone={floodZone}
                      isEC={isEC}
                      baseFloodEle={baseFlood}
                      occupancyType={occupancyType}
                      isBind={isBind}
                      handleChange={handleChange}
                      handleDateChange={handleDateChange}
                      checkDateisValid={checkDateisValid}
                      isDataChanged={setIsChanged}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* Flood Vent */}
                <Accordion.Item eventKey="13" hidden={!['ELEWATEDONCRAWLSPACE20', 'ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20'].includes(foundationType)} onClick={() => toggleAccordion('13')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('FLOOD VENTS')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <FloodVents
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      validated={validated}
                      foundationType={foundationType}
                      isBind={isBind}
                      handleChange={handleChange}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* Prior Policy Information */}
                <Accordion.Item eventKey="8" hidden={!((isPreFirm === 'FLDPREFIRM' && isInsuredResides) || (waitingPeriod !== 'ROLLOVER_RENEWAL'))} onClick={() => toggleAccordion('8')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('PRIOR POLICY INFORMATION')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <PriorPolicyInfo
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      initFirmDate={initFirmDate}
                      firmType={isPreFirm}
                      isInsuredResides={isInsuredResides}
                      waitingPeriod={waitingPeriod}
                      DOC={DOC}
                      validated={validated}
                      isBind={isBind}
                      checkFirmType={checkFirmType}
                      handleChange={handleChange}
                      handleStateChange={handleStateChange}
                      checkDateisValid={checkDateisValid}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {/* COVERAGRE */}
                <Accordion.Item eventKey="9" onClick={() => toggleAccordion('9')}>
                  <Accordion.Header className="search-quote-card-header">
                    <span>{t('COVERAGE')}</span>
                  </Accordion.Header>
                  <Accordion.Body className="search-quote-card-body">
                    <Coverage
                      emptyQuoteData={emptyQuoteData}
                      floodSystemDD={floodSystemDD}
                      preFirmSubsidyEligible={preFirmSubsidyEligible}
                      occupancyType={occupancyType}
                      isTheInsured={isTheInsured}
                      buildingType={buildingType}
                      NOU={NOU}
                      handleChange={handleChange}
                      isBind={isBind}
                      isDataChanged={setIsChanged}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              {/* Create Application Modal */}
              {
                showSubmitAppModal && (
                  <CreateApplication
                    showSubmitAppModal={showSubmitAppModal}
                    waitingPeriod={waitingPeriod}
                    submitApplication={submitApplication}
                    setShowSubmitAppModal={setShowSubmitAppModal}
                  />
                )
              }
              {/* Error Modal */}
              {
                showErrorModal === true && (
                  <ErrorModal show={showErrorModal} error={errorList} toggle={setShowErrorModal} errorHeadingText={errorHeadingText} />
                )
              }
              {/* Required Documents Modal */}
              {
                showRequiredDocModal === true && (
                  <ListDocument show={showRequiredDocModal} toggle={setShowRequiredDocModal} poTransPK={poTransPK} updateReqDocCount={countRequiredDocFunction}/>
                )
              }
              {/* Pivot Api Resp Log */}
              {pivotLog}
              {/* Premium Details Modal */}
              <PremiumDetails showPremium={showPremium} setShowPremium={setShowPremium} poTransPK={poTransPK} />
            </Col>
          </Row>
          </Fragment>
        )
      }
      {
        openModalForExcessProducts  ?
        <ExcessProductModal
          data={emptyQuoteData}
          isModalOpen={openModalForExcessProducts}
          closeModal={closeModal}
          onGetPremiumClick={handleGetPremiumClick}
        /> : null
      }
      {
        openModalForPrivateProducts  ?
        <PrivateProductModal
        emptyQuoteData={emptyQuoteData}
          isModalOpen={openModalForPrivateProducts}
          closePvtProductModal={closePvtProductModal}
          onGetPremiumClick={handleGetPremiumClick}
        /> : null
      }
    </LoadingOverlay>
  );
};

export default AccordionView;
