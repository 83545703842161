import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  ListItem,
  Switch,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

export const Discounts = () => {
  const { t } = useTranslation();
  const { discounts, setDiscounts, baseDD } = useQuote();

  const getAlarmOptions = (alarmType) => {
    const alarmData =
      alarmType === "fire" ? baseDD?.COMFIREALARM : baseDD?.COMBURGLARALARM;
    return (
      alarmData?.map((option) => ({
        name: option.s_AppCodeNameForDisplay,
        code: option.s_AppCodeName,
      })) || []
    );
  };

  const fireAlarmOptions = getAlarmOptions("fire");

  const burglarAlarmOptions = getAlarmOptions("burglar");

  return (
    <Box px={1}>
      {/* Fire Alarm System Section */}
      <Accordion
        allowToggle
        index={[discounts?.fireDiscountSys ? 0 : 1]}
        borderColor={"transparent"}
        width={"100%"}
        mt={2}
      >
        <AccordionItem>
          {({ isExpanded }) => (
            <Box
              border={
                isExpanded ? "1px solid var(--chakra-colors-neutral-50)" : ""
              }
              borderRadius={"xl"}
            >
              <h2>
                <AccordionButton
                  pr={0}
                  borderTopRadius={"xl"}
                  bg={isExpanded ? "var(--chakra-colors-primary-50)" : ""}
                  borderBottom={
                    !isExpanded
                      ? "1px solid var(--chakra-colors-neutral-100)"
                      : ""
                  }
                  borderBottomRadius={!isExpanded ? "xl" : "0"}
                  pt={"2"}
                >
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontSize={"18px"} pt={3}>
                      {t(
                        "Does the Building's premises has a Fire Alarm System"
                      )}
                      ?
                    </Text>
                  </Box>

                  <Switch
                    isChecked={discounts?.fireDiscountSys}
                    onChange={(e) => {
                      setDiscounts({
                        ...discounts,
                        fireDiscountSys: e.target.checked,
                      });
                    }}
                    size="lg"
                    mr={"1rem"}
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                {isExpanded && (
                  <UnorderedList>
                    {fireAlarmOptions.map(({ name, code }) => (
                      <ListItem key={code}>
                        <Flex justifyContent="space-between">
                          <Text
                            fontSize={"18px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={3}
                          >
                            {t(name)}
                          </Text>

                          <Switch
                            isChecked={discounts?.fireAlarmSystem === code}
                            onChange={(e) => {
                              setDiscounts({
                                ...discounts,
                                fireAlarmSystem: e.target.checked ? code : "",
                              });
                            }}
                            size="lg"
                          />
                        </Flex>
                        <Box
                          mb={4}
                          borderTop={"1px solid"}
                          borderColor={"primary.50"}
                        />
                      </ListItem>
                    ))}
                  </UnorderedList>
                )}
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>
      </Accordion>

      {/* Burglar Alarm System Section */}
      <Accordion
        borderColor={"transparent"}
        width={"100%"}
        mt={2}
        allowToggle
        index={[discounts?.burglarAlarmSys ? 0 : 1]}
      >
        <AccordionItem>
          {({ isExpanded }) => (
            <Box
              border={
                isExpanded ? "1px solid var(--chakra-colors-neutral-50)" : ""
              }
              borderRadius={"xl"}
            >
              <h2>
                <AccordionButton
                  pr={0}
                  borderTopRadius={"xl"}
                  bg={isExpanded ? "var(--chakra-colors-primary-50)" : ""}
                  borderBottom={
                    !isExpanded
                      ? "1px solid var(--chakra-colors-neutral-100)"
                      : ""
                  }
                  borderBottomRadius={!isExpanded ? "xl" : "0"}
                  pt={"2"}
                >
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontSize={"18px"} pt={3}>
                      {t(
                        "Does the Building's premises has a Burglar Alarm System"
                      )}
                      ?
                    </Text>
                  </Box>

                  <Switch
                    isChecked={discounts?.burglarAlarmSys}
                    onChange={(e) => {
                      setDiscounts({
                        ...discounts,
                        burglarAlarmSys: e.target.checked,
                      });
                    }}
                    size="lg"
                    mr={"1rem"}
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                {isExpanded && (
                  <UnorderedList>
                    {burglarAlarmOptions.map(({ name, code }) => (
                      <ListItem key={code}>
                        <Flex justifyContent="space-between">
                          <Text
                            fontSize={"18px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={3}
                          >
                            {t(name)}
                          </Text>

                          <Switch
                            isChecked={discounts?.burglarAlarmSystem === code}
                            onChange={(e) => {
                              setDiscounts({
                                ...discounts,
                                burglarAlarmSystem: e.target.checked
                                  ? code
                                  : "",
                              });
                            }}
                            size="lg"
                          />
                        </Flex>
                        <Box
                          mb={4}
                          borderTop={"1px solid"}
                          borderColor={"primary.50"}
                        />
                      </ListItem>
                    ))}
                  </UnorderedList>
                )}
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
