import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
// import { Row, Col, Form, Tooltip } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { BiReset } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
// import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import paginationFactory from "react-bootstrap-table2-paginator";
import "./search-policy.scss";
import { getPolicyListData, setPolicyPropsData, productList, verifyPolicy, getBaseData, resetCoreStore, actionTypeDD, getPolicyStatusDD//getPolicyListCsv
} from "../../../services/coreSystemService";
import { useDispatch, connect } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// import Modal from 'react-bootstrap/Modal';
import { checkPivotOutage, getTenant } from "../../../services/commonService";
import { Box, Flex, Text, Grid, FormControl, FormLabel, Input, Select, Alert, AlertIcon, Link, Button, Tooltip } from "@chakra-ui/react";
import AdvancedGridTable from "../../../components/AdvancedGridTable";
import Tour from 'reactour';
import PolicyNumberCell from "./PolicyNumberCell";
import "./policySearch.css";
import { FaDownload } from 'react-icons/fa';
import { FaFileLines } from "react-icons/fa6";
import { pickBy } from "lodash";

const SearchPolicy = (props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("translations", "policySearch");
  const gridRef = useRef(null);
  const tourRef = useRef(null);
  const isPivotOutage = checkPivotOutage();
  const [isOpenGuide, setIsOpenGuide] = useState(false);
  const [gridKey, setGridKey] = useState(`grid-key-${Math.random()}`);
  const [loading, setLoading] = useState(false);
  const tenant = getTenant();

  // const [TableData, setTableData] = useState([]);
  // const [total, setTotal] = useState(0);
  // const [isChanged, setIsChanged] = useState(false);
  // const actionsArray = [ //temp hard-coded
  //   { s_AppCodeName: "VIEWENDORSEPOLICY", s_AppCodeNameForDisplay: "View/Endorse Policy" },
  //   { s_AppCodeName: "DOWNLOADDOCUMENTS", s_AppCodeNameForDisplay: "Download Documents" },
  // ];
  // const policyTypeArray = [ //temp hard-coded
  //   { n_ProductId_PK: 10, s_ProductName: "Flood Program" },
  //   { n_ProductId_PK: 26, s_ProductName: "Hiscox Flood Plus" },
  // ];

  // const [filter, setFilter] = useState({
  //   policyNo: "",
  //   product: "",
  //   firstName: "",
  //   lastName: "",
  //   page: 1,
  //   pageLength: 10,
  // });
  //eslint-disable-next-line
  const [actionDD, setActionDD] = useState([]);
  const [policyStatusOptions, setPolicyStatusOptions] = useState([]);
  const [policyRemarkOptions, setPolicyRemarkOptions] = useState([]);
  // const [showDownloadModal, setShowDownloadModal] = useState(false);
  // const [link, setLink] = useState('#');

  useEffect(() => {
    // PolicyListDataDefault();
    const getProducts = async () => {
      setLoading(true);
      try {
        await dispatch(productList());
      }
      finally {
        setLoading(false);
      }
    }

    if (props.allProducts && Object.keys(props.allProducts).length === 0) {
      getProducts();
    }
    dispatch(actionTypeDD());
    //eslint-disable-next-line
  }, []);

  useEffect(() => { //maybe used
    if (Object.keys(props.actionTypeDD).length !== 0) {
      setActionDD(props?.actionTypeDD?.ACTIONTYPE);
    }
  }, [props?.actionTypeDD?.ACTIONTYPE]);

  // get policy statuc code dropdown
  useEffect(() => {
    getPolicyStatusDropDown();
  }, []);

  const getPolicyStatusDropDown = async () => {
    setLoading(true);
    await dispatch(getPolicyStatusDD()).then((res) => {
      setPolicyStatusOptions(res.policyStatus)
      setPolicyRemarkOptions(res.policyRemark)
    })
    setLoading(false)
  }

  // useEffect(() => {
  //   if (isChanged) {
  //     PolicyListData();
  //   }
  // }, [filter.page, filter.pageLength]);

  const filterOptions = {
    policyNo: "",
    firstName: "",
    lastName: "",
    product: "",
    status:"",
  }
  const [filterParams, setFilterParams] = useState(filterOptions);

  const onPolicyNumberClick = (params) => {
    const rowData = params.data;
    navigation(`/core/policy/${rowData.s_POAppNo}`);
  };

  const WordBreakRenderer = (params) => {
    return (
      <div style={{ wordBreak: "break-word" }}>
        {params.value}
      </div>
    )
  };

  const ActionCellRenderer = (params) => {
    const rowData = params.data;
    if (props?.actionTypeDD?.ACTIONTYPE) { //actionDD maybe used
      var actions = props.actionTypeDD.ACTIONTYPE;// [...actionDD];
    // if (actionsArray) { //temp
      // let actions = actionsArray; 
      // Hide View/Endorse Option for 1.2
      if (rowData.s_RiskRating20 !== 'YES' || isPivotOutage === 'true') {
        let index = actions.findIndex((v) => { return v.s_AppCodeName === 'VIEWENDORSEPOLICY' });
        index !== -1 && actions.splice(index, 1);
      }
    }
    return (
      <Flex p={'4px'}>
        {
            actions && Object.keys(actions).length > 0 && actions.map((v, i) => {
              return (
                <Tooltip hasArrow label={v.s_AppCodeNameForDisplay} placement='top' key={`${rowData.s_POAppNo}_${i}`}>
                  <Button
                    p={'4px'}
                    mr={'4px'}
                    size={'sm'}
                    key={i}
                    onClick={() => handleButtonClick(v.s_AppCodeName, rowData)}
                  >
                    {v.s_AppCodeName === 'VIEWENDORSEPOLICY' ? <FaFileLines /> : (v.s_AppCodeName === 'DOWNLOADDOCUMENTS' ? <FaDownload /> : "")}
                  </Button>
                </Tooltip>
              )
            })
          }
        {/* <Select
          size={'sm'}
          name="action"
          className="dashboard-group-input"
          id="select-ym-option"
          onChange={(e) => handleChangeSelect(e, rowData)}
        >
          <option value=''>Select Action</option>
          {
            actions && Object.keys(actions).length > 0 && actions.map((v, i) => {
              return <option key={i} value={v.s_AppCodeName}>{v.s_AppCodeNameForDisplay}</option>
            })
          }
        </Select> */}
      </Flex>
    );
  };

  const handleSearch = (filterParams) => {
    const {policyNo, firstName, lastName, product, status } = filterParams;

    if (
      !policyNo && !firstName && !lastName && !product && !status
    ) {
      toast.warning('Please enter at least one search parameter!', {
        toastId: 'policySearch',
      });
      return;
    }

    const datasource = createServerSideDatasource(filterParams);
    gridRef?.current?.api?.setServerSideDatasource(datasource);
  };

  const handleReset = () => {
    setFilterParams(filterOptions);
    gridRef?.current?.api?.resetColumnState();
    gridRef.current.api.setFilterModel(null);
    setGridKey(`grid-key-${Math.random()}`);
    gridRef.current.api.sizeColumnsToFit();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterParams(prevParams => {
      const updatedParams = { ...prevParams };
      updatedParams[name] = value;
      return updatedParams;
    });
  };

  // const handleChangeSelect = (e, rowData) => {
  //   let actionType = e.target.value;
  //   if (actionType == 'DOWNLOADDOCUMENTS') {
  //     ActionUrlBuilder(rowData, actionType);
  //   } else {
  //     ActionUrlBuilder(rowData, actionType);
  //   }
  // };

  const handleButtonClick = (actionType, rowData) => {
    if (actionType === 'DOWNLOADDOCUMENTS' || actionType === 'VIEWENDORSEPOLICY') {
      ActionUrlBuilder(rowData, actionType);
    } else {
      ActionUrlBuilder(rowData, actionType);
    }
  };

  const ActionUrlBuilder = async (rowData, actionType = '') => {
    await dispatch(verifyPolicy(rowData.s_POAppNo)).then((res) => {
      if (res.status) {
        // To Reset Core System Reducer Store
        dispatch(resetCoreStore());
        const params = {
          n_POPolicyMasterFK: res.policyIdPK,
          n_POTermMasterFK: res.termMasterPK,
          n_POTransactionFK: res.poTransPK,
        };
        dispatch(setPolicyPropsData(params));
        // To Base Data (Dropdown)
        dispatch(
          getBaseData(res.productFK)
        );
        if (actionType === 'DOWNLOADDOCUMENTS') {
          navigation(`/core/policy/${actionType}/${rowData.s_POAppNo}`);
        } else if (actionType === 'VIEWENDORSEPOLICY') {
          navigation(`/core/policy/${actionType}/${rowData.s_POAppNo}`);
        } else {
          navigation(`/core/policy/${rowData.s_POAppNo}`);
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  const loadData = async (params = { pageLength: 50, page: 1 }) => {
    try {
      const res = await dispatch(getPolicyListData(params)).then((res)=>{
        return res;
      });
      return res?.data;
    } catch (e) {
      const errorMessages = Object.values(e?.errors).flat();
      errorMessages.forEach(errorMessage => {
        toast.error(errorMessage);
      });
      toast.warning("No data found");
      return {
        rows: 0,
        total: 0,
      };
    }
  };

  const createServerSideDatasource = (filterParams) => {
    return {
      getRows: async (params) => {
        const pageLength = params.request.endRow - params.request.startRow;
        const page = params.request.endRow / pageLength;

        let apiParams = {
          pageLength,
          page,
        };

        if (filterParams?.policyNo) {
          apiParams = {
            ...apiParams,
            policyNo: filterParams.policyNo,
          }
        }
        if (filterParams?.firstName) {
          apiParams = {
            ...apiParams,
            firstName: filterParams.firstName,
          }
        }
        if (filterParams?.lastName) {
          apiParams = {
            ...apiParams,
            lastName: filterParams.lastName,
          }
        }
        if (filterParams?.product) {
          apiParams = {
            ...apiParams,
            product: filterParams.product,
          }
        }
        if (filterParams?.status) {
          apiParams = {
            ...apiParams,
            status: filterParams.status,
          }
        }

        if (params.request?.filterModel?.s_POAppNo?.filter) {
          apiParams = {
            ...apiParams,
            policyNo: params.request.filterModel.s_POAppNo.filter,
          };
        }
        if (params.request?.filterModel?.InsuredName?.filter) {
          apiParams = {
            ...apiParams,
            firstName: params.request.filterModel.InsuredName.filter,
          };
        }
        if (params.request?.filterModel?.s_ProductName?.values) {
          let paramValue = "";
          if(Object.keys(props.allProducts).length > 0) {
            props?.allProducts.forEach((v, i) => {
              if(v.s_ProductName === params.request?.filterModel?.s_ProductName?.values[0]) {
                paramValue = v.n_ProductId_PK;
              }
            })
          }
          // policyTypeArray.forEach((v,i)=>{ //temp 
          //   if(v.s_ProductName === params.request?.filterModel?.s_ProductName?.values[0]) {
          //     paramValue = v.n_ProductId_PK;
          //   }
          // })
          apiParams = {
            ...apiParams,
            product: paramValue,
          };
        }
        const policies = await loadData(apiParams);

        setTimeout(function () {
          if (policies?.total) {
            policies.data.map((item) => {
              // For policy status
              const selectedOption = pickBy(policyStatusOptions, (status) => {
                return status.value == item.status;
              });
              if(Object.keys(selectedOption)) {
                item.status = Object.values(selectedOption)[0].label;
              }

              // For Policy status remark
              const selectedRemarkOption = pickBy(policyRemarkOptions, (remark) => {
                return remark.value == item.remark;
              });
              if(Object.keys(selectedRemarkOption)) {
                item.remark = Object.values(selectedRemarkOption)[0].label;
              }
              return item;
            });

            // supply rows for requested block to grid
            params.success({
              rowData: policies.data,
              rowCount: policies.total,
            });
            params.api.hideOverlay();
          } else {
            params.api.showNoRowsOverlay();
            params.success({
              rowData: [],
              rowCount: 0,
            });
            // params.fail();
          }
        }, 500);
      },
    };
  };

  const onGridReady = useCallback((params) => {
    // setActionDD(props.actionTypeDD.ACTIONTYPE); //didn't work
    // const datasource = createServerSideDatasource(); //temp
    // register the datasource with the grid
    // params.api.setServerSideDatasource(datasource);
    params.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true,
    filter: true,
    floatingFilter: false, //temp
    suppressHeaderMenuButton: true,
    sizeColumnsToFit: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    suppressMultiSort: true,
  }), []);

  // const sideBar = useMemo(() => {
  //   return {
  //     toolPanels: [
  //       {
  //         id: "filters",
  //         labelDefault: "Filters",
  //         labelKey: "filters",
  //         iconKey: "filter",
  //         toolPanel: "agFiltersToolPanel",
  //         /*minWidth: 180,
  //         maxWidth: 400,
  //         width: 250,*/
  //       },
  //     ],
  //     position: "left",
  //     //defaultToolPanel: "filters",
  //     hiddenByDefault: false
  //   };
  // }, []);

  const [columnDefs] = useState([
    {
      headerName: t("Policy Number"),
      field: "s_POAppNo",
      sortable: false, //temp
      filter: "agTextColumnFilter",
      minWidth: 170,
      cellRenderer: (params) => (
        <PolicyNumberCell
          value={params.value}
          onPolicyNumberClick={() => onPolicyNumberClick(params)}
        />
      ),
      cellStyle: {
        color: "var(--chakra-colors-linkColor)",
        textDecoration: "underline",
      },
      floatingFilterComponentParams: {},
      suppressFloatingFilterButton: true,
    },
    {
      headerName: t("Policyholder/Member Names", {ns: "policySearch"}),
      filter: "agTextColumnFilter",
      field: "InsuredName",
      sortable: false, //temp
      floatingFilterComponentParams: {},
      suppressFloatingFilterButton: true,
      minWidth: 220,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params)=> WordBreakRenderer(params),
    },
    {
      headerName: t("Policy Type"),
      field: "s_ProductName",
      sortable: false,
      minWidth: 150,
      filter: 'agSetColumnFilter',
      filterParams: {
        values:
          Object.keys(props.allProducts).length > 0 ? props.allProducts.map((v, i) => {
            return v.s_ProductName
          }) : []
      },
      floatingFilterComponentParams: {},
      suppressFloatingFilterButton: true,
    },
    {
      headerName: t("Status"),
      field: "status",
      sortable: false,
      filter: 'agSetColumnFilter',
      filterParams: { //map with backend
        values: ["ACTIVE", "APPLICATION", "ISSUED", "CANCELED"]
      },
      floatingFilterComponentParams: {},
      suppressFloatingFilterButton: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: t("Agent"),
      field: "AgentName",
      minWidth: 220,
      filter: false,
      sortable: false,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params)=> WordBreakRenderer(params),
    },
    {
      headerName: t("Agency"),
      field: "AgencyName",
      minWidth: 220,
      filter: false,
      sortable: false,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params)=> WordBreakRenderer(params),
    },
    {
      headerName: t("Remark"),
      field: "remark",
      filter: false,
      sortable: false,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: t("Action"),
      field: "",
      filter: false,
      sortable: false,
      cellRenderer: ActionCellRenderer,
    },
  ]);

  //fetch default policy Data Only Firt Time 
  // const PolicyListDataDefault = async () => {
  //     setLoading(true);
  //     await dispatch(getPolicyListData(filter)).then((res) => {
  //       setTableData(res.data.data);
  //       setTotal(res.data.total);
  //     });
  //     setLoading(false);
  //     setIsChanged(false);
  // };

  // const policyListCsv = async () => {
  //   setLoading(true);
  //   await dispatch(getPolicyListCsv(filter)).then((res)=>{
  //     if(res.status === 200){
  //       setLink(res.data.url)
  //       handleShow();
  //     }
  //   });
  //   setLoading(false);
  // }

  //fetch policy Data based on filter 
  // const PolicyListData = async () => {
  //   if (
  //     filter.policyNo.length > 0 ||
  //     filter.firstName.length > 0 ||
  //     filter.lastName.length > 0
  //   ) {
  //     setLoading(true);
  //     await dispatch(getPolicyListData(filter)).then((res) => {
  //       if (res && res.data) {
  //         let resData = res.data;
  //         if (resData?.status == false) {
  //           if (resData.errors && typeof resData.errors === 'object' && resData.errors !== null) {
  //             let errorText = Object.values(resData.errors).join(", ");
  //             toast.error(t(errorText));
  //           } else {
  //             toast.error(t("Something Went Wrong"));
  //           }
  //         }
  //         if (resData.hasOwnProperty('total') && resData.hasOwnProperty('data')) {
  //           setTableData(resData?.data || []);
  //           setTotal(resData?.total || 0);

  //           if (resData?.total == 0) {
  //             toast.warning(t("No data to display"));
  //           }
  //         }
  //       } else {
  //         toast.error(t("Something Went Wrong"));
  //       }
  //     }).catch((error) => {
  //       toast.error(t("Something Went Wrong"));
  //     });
  //     setLoading(false);
  //     setIsChanged(false);
  //   } else {
  //     toast.warning(t("Please enter some words for search!"));
  //   }
  // };

  // const onTableChange = (type, { page, sizePerPage }) => {
  //   setFilter({ ...filter, page: page, pageLength: sizePerPage });
  //   setIsChanged(true);
  // }

  // const handlePageChange = (page, sizePerPage) => {
  //   setFilter({ ...filter, page: page, pageLength: sizePerPage });
  // };

  // const sizePerPageListChange = (page, sizePerPage) => {
  //   setFilter({ ...filter, pageLength: sizePerPage });
  // };

  // const renderShowsTotal = (from, to, size) => (
  //   <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
  // );

  // const options = {
  //   page: filter.page,
  //   sizePerPage: filter.pageLength,
  //   sortIndicator: false,
  //   pageStartIndex: 1,
  //   totalSize: total,
  //   showTotal: true,
  //   paginationTotalRenderer: renderShowsTotal,
  //   onPageChange: handlePageChange,
  //   onSizePerPageChange: sizePerPageListChange,
  // };


  // clickable URL builder for POLICY Details
  // const urlBuilder = async (row , acttionType='') => {
  //   await dispatch(verifyPolicy(row.s_POAppNo)).then((res) => {
  //     if (res.status) {
  //       // To Reset Core System Reducer Store
  //       dispatch(resetCoreStore());
  //       const params = {
  //         n_POPolicyMasterFK: res.policyIdPK,
  //         n_POTermMasterFK: res.termMasterPK,
  //         n_POTransactionFK: res.poTransPK,
  //       };
  //       dispatch(setPolicyPropsData(params));
  //       // To Base Data (Dropdown)
  //       dispatch(
  //         getBaseData(res.productFK)
  //       );
  //       if(acttionType == 'DOWNLOADDOCUMENTS'){
  //         navigation(`/core/policy/${acttionType}/${row.s_POAppNo}`);
  //       } else if(acttionType == 'VIEWENDORSEPOLICY') {
  //         navigation(`/core/policy/${acttionType}/${row.s_POAppNo}`);
  //       } else {
  //         navigation(`/core/policy/${row.s_POAppNo}`);

  //       }
  //     } else {
  //       toast.error(res.message);
  //     }
  //   });
  // };

  //table column formating
  // const columnFormatter = (cell, row) => {
  //   if (row && row.s_POAppNo) {
  //     return (
  //       <a href="#" onClick={() => urlBuilder(row)}>
  //         {cell}
  //       </a>
  //     );
  //   } else {
  //     return row.s_POAppNo;
  //   }
  // };

  //table column formating Action
  // const columnFormatterAction = (cell, row) => {
  //   if (actionDD) {
  //     var actions = [...actionDD];

  //     // Hide View/Endorse Option for 1.2
  //     if (row.s_RiskRating20 !== 'YES' || isPivotOutage == 'true') {
  //       var index = actions.findIndex((v) => { return v.s_AppCodeName === 'VIEWENDORSEPOLICY' });
  //       index !== -1 && actions.splice(index, 1);
  //     }
  //   }

  //   return (
  //     <Form.Select
  //       name="action"
  //       className="dashboard-group-input"
  //       id="select-ym-option"
  //       onChange={(e) => onHandleChangeSelect(e,row,cell)}
  //     >
  //       <option value=''>Select Action</option>
  //       {
  //         actions && Object.keys(actions).length > 0 && actions.map((v, i) => {
  //           return <option key={i} value={v.s_AppCodeName}>{v.s_AppCodeNameForDisplay}</option>
  //         })
  //       }
  //     </Form.Select>
  //   );
  // };

  // const columnFormatterRemark = (cell, row) => {
  //   if (row && row.remark) {
  //     var remark =  '';
  //     remarkArray && Object.keys(remarkArray).length > 0 && remarkArray.map((v, i) => {        
  //       if(row.remark == v.value){
  //         remark = v.label;
  //       }
  //     })      
  //     return remark;
  //   } else {
  //     return row.label;
  //   }
  // };

  // const columnFormatterStatus = (cell, row) => {
  //   if (row && row.status) {
  //     var status =  '';
  //     statusArray && Object.keys(statusArray).length > 0 && statusArray.map((v, i) => {
  //       if(row.status == v.value){
  //         status = v.label;
  //       }
  //     })

  //     return status;
  //   } else {
  //     return row.label;
  //   }
  // };

  // const columns = [
  //   {
  //     dataField: "d_DateSubmitted",
  //     text: t("Submit Date"),
  //   },
  //   {
  //     dataField: "s_ProductName",
  //     text: t("Policy Type"),
  //   },
  //   {
  //     dataField: "s_POAppNo",
  //     text: t("Policy NO"),
  //     formatter: columnFormatter,
  //     style: {
  //       textDecoration: "underline",
  //       color: "blue"
  //     }
  //   },
  //   {
  //     dataField: "InsuredName",
  //     text: t("Policyholder/Member Name"),
  //   },
  //   {
  //     dataField: "status",
  //     text: t("Status"),
  //     formatter: columnFormatterStatus,
  //   },
  //   {
  //     dataField: "remark",
  //     text: t("Remark"),
  //     formatter: columnFormatterRemark,
  //   },
  //   {
  //     dataField: "AgentName",
  //     text: t("Agent"),
  //   },
  //   {
  //     dataField: "AgencyName",
  //     text: t("Agency"),
  //   },
  //   {
  //     dataField: "",
  //     text: t("Action"),
  //     formatter: columnFormatterAction,
  //   },
  // ];

  // const onHandleChange = (e) => {
  //   const { name, value } = e.target;
  //   const tempArray = filter; // creating copy of an array
  //   tempArray[name] = value;
  //   setFilter(tempArray); // set copied array
  // };

  // const onHandleChangeSelect = (e,row,cell) => {
  //  var acttionType = e.target.value;
  //   if(acttionType ==  'DOWNLOADDOCUMENTS'){
  //     urlBuilder(row,acttionType);
  //   }else{
  //     urlBuilder(row,acttionType);
  //   }
  // };
  // const handleClose = () => setShowDownloadModal(false);
  // const handleShow = () => setShowDownloadModal(true);

  const tourGuide = () => {
    setIsOpenGuide(true);
  }

  const closeTourguide = () => {
    setIsOpenGuide(false);
  }

  const steps = [
    {
      selector: '.tour-guide-enter-search',
      content: <div>{t("Click enter key after entering information to search", {ns: "policySearch"})}.</div>
    },
    {
      selector: '.tour-reset',
      content: <div>{t("Click to reset your search", {ns: "policySearch"})}.</div>
    },
    {
      selector: ".ag-header-cell-resize",
      content: <div>{t("From here, columns can be resized", {ns: "policySearch"})}.</div>,
    },
    {
      selector: ".ag-row-level-0 .ag-column-first",
      content: <div>{t("To see more options, right-click on a column", {ns: "policySearch"})}.</div>,
    },
  ];

  const handleEnterPolicySearch =(e)=> {
    if (e.key === "Enter") {
      e.preventDefault();
      // PolicyListData();
      handleSearch(filterParams);
    }
  }

  return (
    <>
      <Tour
        accentColor={"var(--chakra-colors-primary-500)"}
        ref={tourRef}
        startAt={tourRef?.current?.state?.current===steps?.length-1 ? 0 : tourRef?.current?.state?.current}
        steps={steps}
        isOpen={isOpenGuide}
        onRequestClose={closeTourguide} 
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        lastStepNextButton={<Button>Close</Button>}
      />
      <LoadingOverlay>
        <Loader loading={loading} />
        <Box mb={8}>
          <Flex mb={4} pt={0} align={'center'}>
            <Text mb={0} fontWeight={600} fontSize="2xl" lineHeight="32px" mr={4}>
              {t("Policy Search")}
            </Text>
            {/*<Button
              leftIcon={<FaWalking size={20} className="sidebar-link-icon" />}
              className="zero-step"
              id="createQuoteHeaderButton"
              onClick={tourGuide}
              style={{ display: 'flex' }}
            >
              {t("What's New")}
            </Button>*/}
          </Flex>
          <Box pt={0} mb={4}>
            <Alert status='info'>
              <AlertIcon />
              {t("Check out quick tips and tools to simplify your experience", {ns: "policySearch"})}.&nbsp;
              <Link href="#" onClick={tourGuide} style={{ textDecoration: "underline", color: "blue" }}>
                {t("Guide Me", {ns: "policySearch"})}!
              </Link>
            </Alert>
          </Box>
          <Box p={5} bgColor={"white"} boxShadow={'lg'} borderRadius={"2xl"} borderColor={"white"} mb={4}>
            <Grid alignItems={'end'} templateColumns='repeat(5, 1fr)' gap={6}>
              <FormControl>
                <FormLabel mb={2} >{t("Policy Number")}</FormLabel>
                <Input
                  name="policyNo"
                  type="text"
                  className="dashboard-group-input tour-guide-enter-search"
                  autoComplete="off"
                  value={filterParams.policyNo}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onKeyDown ={handleEnterPolicySearch}
                  autoFocus= {true}
                />
              </FormControl>
              <FormControl>
                <FormLabel mb={2} >{t("Last Name")}</FormLabel>
                <Input
                  name="lastName"
                  type="text"
                  className="dashboard-group-input"
                  autoComplete="off"
                  value={filterParams.lastName}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onKeyDown ={handleEnterPolicySearch}
                />
              </FormControl>
              <FormControl>
                <FormLabel mb={2} >{t("First Name")}</FormLabel>
                <Input
                  name="firstName"
                  type="text"
                  className="dashboard-group-input"
                  autoComplete="off"
                  value={filterParams.firstName}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onKeyDown ={handleEnterPolicySearch}
                />
              </FormControl>
              <FormControl>
                <FormLabel mb={2} >{t("Product", {ns: "policySearch"})}</FormLabel>
                <Select
                  name="product"
                  className="dashboard-group-input"
                  value={filterParams.product}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  {(props.allProducts?.length > 1 ) ? 
                    <option key={0} value=""></option>
                  :null}
                  {
                    Object.keys(props.allProducts).length > 0 && props.allProducts.map((v, i) => {
                      return <option key={i} value={v.n_ProductId_PK}>{v.s_ProductName}</option>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel mb={2} >{t("Status")}</FormLabel>
                <Select
                  name="status"
                  className="dashboard-group-input"
                  value={filterParams?.status}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  {policyStatusOptions?.length > 1 ? (
                    <option key={0} value="">{}</option>
                  ) : null}
                  {policyStatusOptions.map((option) => {
                    return <option key={option.label} value={option.value}>{option.label}</option>;
                  })}
                </Select>
              </FormControl>
              <Box display={"flex"}>
                <Button
                  leftIcon={<FaSearch />}
                  onClick={() => handleSearch(filterParams)}
                >
                  {t("Search")}
                </Button>
                &nbsp;&nbsp;
                <Button
                  className="tour-reset"
                  leftIcon={<BiReset />}
                  onClick={() => handleReset()}
                >
                  {t("Reset", {ns: "policySearch"})}
                </Button>
              </Box>
            </Grid>
          </Box>
          <Box bgColor={"white"} p={5} boxShadow={'lg'} borderRadius={"2xl"} borderColor={"white"}>
            <Box
              className="ag-theme-alpine"
              h={"70vh"}
              minHeight={'400px'}
              maxHeight={'600px'}
              id="myGrid"
              // borderRadius={"2xl"}
              // border={"1px solid"}
              // borderColor={"gray.200"}
              overflow={"hidden"}
              w={'100%'}
            >
              <AdvancedGridTable
                gridRef={gridRef}
                key={gridKey}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // sideBar={sideBar}
                // floatingFilter={false}
                rowBuffer={0}
                rowModelType={"serverSide"}
                cacheBlockSize={25}
                maxBlocksInCache={20}
                onGridReady={(params) => onGridReady(params)}
                enableCellTextSelection={true}
              />
            </Box>
          </Box>
        </Box>
        {/* <div className="search-policy-section"> */}
        {/* <Row className="align-items-center">
          <Col xs="12">
            <div className="search-policy-filters">
              <h2>{t('Search Policy')}</h2>
              <Row className="align-items-end">
                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                  <Form.Group
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t('Policy No')}.
                    </Form.Label>
                    <Form.Control
                      name="policyNo"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      onChange={(e) => onHandleChange(e)}
                      onKeyDown ={handleEnterPolicySearch}
                      autoFocus= {true}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                  <Form.Group
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t('Last Name')}
                    </Form.Label>
                    <Form.Control
                      name="lastName"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      onChange={(e) => onHandleChange(e)}
                      onKeyDown ={handleEnterPolicySearch}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                  <Form.Group
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t('First Name')}
                    </Form.Label>
                    <Form.Control
                      name="firstName"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      onChange={(e) => onHandleChange(e)}
                      onKeyDown ={handleEnterPolicySearch}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="4" lg="3" xl="3">
                  <Form.Group
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t('Select Product')}
                    </Form.Label>
                    <Form.Select
                      name="product"
                      className="dashboard-group-input"
                      id="select-ym-option"
                      onChange={(e) => onHandleChange(e)}
                    >
                     {(props.allProducts?.length > 1 ) ? 
                      <option key={0} value=''>Select Product</option>
                      :null}
                      {
                        Object.keys(props.allProducts).length > 0 && props.allProducts.map((v, i) => {
                          return <option key={i} value={v.n_ProductId_PK}>{v.s_ProductName}</option>
                        })
                      }
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="search-policy-btn-col">
                  <Button
                    id="common-btn"
                    variant="primary"
                    onClick={() => PolicyListData()}
                  >
                    <FaSearch size={15} style={{ marginRight: "10px" }} />
                    {t('Search Policy')}
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Col>
        </Row> */}

        {/* <Row id="dashboard-table-section">
          <Col xs="12" style={{ marginTop: "50px" }}>
            <div className="table-main-title-div">
              <span className="table-main-title-name">{t('Search Result')}</span>
              <div className="table-main-title-div"> */}
        {/*<Button
                  disabled={TableData===[]}
                  onClick={()=>policyListCsv()}
                  >Export To CSV
                </Button>*/}
        {/* </div> */}
        {/* <div className="table-main-title-icons">
                <div className="table-square"></div>
                <FaBars size={15} color={"#fff"} />
              </div> */}
        {/* </div>
          </Col>
          <Col xs="12">
            <BootstrapTable
              wrapperClasses="dashboard-table-main"
              noDataIndication={t("No data to display")}
              bootstrap4
              remote
              keyField="s_POAppNo"
              data={TableData}
              columns={columns}
              hover
              pagination={paginationFactory(options)}
              onTableChange={onTableChange}
            />
          </Col>
        </Row>
      </div> */}
      </LoadingOverlay>

      {/* <Modal show={showDownloadModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Policy CSV File</Modal.Title>
      </Modal.Header>
      <Modal.Body>Do you want to download file ?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Discard
        </Button>
        <Button variant="primary" href={link}  target="_blank" onClick={handleClose}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal> */}

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    actionTypeDD: state.coreSystem.actionTypeDD,
    allProducts: state.coreSystem.allProducts,
  };
}

export default connect(mapStateToProps)(SearchPolicy);
