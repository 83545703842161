import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Input,
  GridItem,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Select,
  IconButton,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { enrollUser } from "../../../services/enrollService";
import { FaTrash } from "react-icons/fa";
import { InputAdapter, TextMask } from "react-text-mask-hoc";
import { GrFormNextLink } from "react-icons/gr";
import { IoArrowBackSharp } from "react-icons/io5";
import DateInput from "../../quote/create-star-quote/utils/DateInput";
import { formateDate } from "../../common/FormatDate";
import { IoMdAdd } from "react-icons/io";
import { PhoneNumberMask } from "../../common/Helper";
import { sortMutliDimensionArray } from "../../common/ArrayFunctions";

export default function AddAgencyUsers({
  prevStep,
  states,
  userData,
  userDataArray,
  setUserDataArray,
  formDataArray,
  setFormDataArray,
  setLoading,
  setIsConfirmation,
  setLicManagerArray,
  validateEmail,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasError = false;

    if (userDataArray.length > 0) {
      new Promise(() => {
        for (var row = 0; row < userDataArray.length; row++) {
          if (!userDataArray[row].first_name) {
            toast.error(
              "First Name at row " + (parseInt(row) + 1) + " is required"
            );
            hasError = true;
            break;
          }
          if (userDataArray[row].last_name.trim() === "") {
            toast.error(
              "Last Name at row " + (parseInt(row) + 1) + " is required"
            );
            hasError = true;
            break;
          }
          if (userDataArray[row].email === "") {
            toast.error("Email at row " + (parseInt(row) + 1) + " is required");
            hasError = true;
            break;
          }
          if (!validateEmail(userDataArray[row].email)) {
            toast.error("Email at row " + (parseInt(row) + 1) + " is invalid!");
            hasError = true;
            break;
          }
          if (!userDataArray[row].phone) {
            toast.error(
              "Phone Number at row " + (parseInt(row) + 1) + " is required"
            );
            hasError = true;
            break;
          }
          if (!userDataArray[row].npn) {
            toast.error(
              "Agent NPN at row " + (parseInt(row) + 1) + " is required"
            );
            hasError = true;
            break;
          }
          if (!userDataArray[row].resident_state_id) {
            toast.error(
              "Resident State at row " + (parseInt(row) + 1) + " is required"
            );
            hasError = true;
            break;
          }
          const userDetail = userDataArray[row].user_detail;
          if (userDetail) {
            for (var i = 0; i < userDetail.length; i++) {
              if (userDetail[i].n_LicenseNo.trim() === "") {
                toast.error("User License Number is required");
                hasError = true;
                break;
              }
              if (!userDetail[i].n_StateId_FK) {
                toast.error("User License State is required");
                hasError = true;
                break;
              }
            }
          }
        }
      });
    }

    if (userDataArray.length > 0) {
      new Promise(() => {
        var checkArr = userDataArray.map(function (item) {
          return item.email;
        });
        var isDuplicate = checkArr.some(function (item, idx) {
          return checkArr.indexOf(item) !== idx;
        });
        if (isDuplicate) {
          toast.error("Please enter unique email for every user!");
          hasError = true;
        }
      });
    }

    if (hasError) {
      setIsDisabled(false);
      setLoading(false);
      return false;
    } else {
      setLoading(true);
      setIsDisabled(true);
      const data = new FormData();
      data.append("formDataArray", JSON.stringify(formDataArray));
      data.append("W9_Form", formDataArray.W9_Form);
      dispatch(enrollUser(data))
        .then(async (response) => {
          if (response?.status) {
            setLicManagerArray([]);
            setUserDataArray([userData]);
            setFormDataArray({
              agency_name: "",
              agency_code: "",
              n_AgencyNPN: "",
              corp_status: "",
              DBA_name: "",
              tax_type: "",
              tax_number: "",
              mail_s_AddressLine1: "",
              mail_s_PostalCode: "",
              mail_n_Zipcodes_PK: "",
              mail_n_CityId_FK: "",
              mail_s_CityName: "",
              mail_n_StateId_FK: "",
              mail_s_StateName: "",
              mail_n_CountyId_FK: "",
              mail_s_CountyName: "",
              mail_n_CountryId_FK: "",
              loc_s_AddressLine1: "",
              loc_s_PostalCode: "",
              loc_n_Zipcodes_PK: "",
              loc_n_CityId_FK: "",
              loc_s_CityName: "",
              loc_n_StateId_FK: "",
              loc_s_StateName: "",
              loc_n_CountyId_FK: "",
              loc_s_CountyName: "",
              loc_n_CountryId_FK: "",
              web_s_WebsiteURL: "",
              phone_s_PhoneNumber: "",
              fax_s_FaxNumber: "",
              contact_s_Email: "",
              contact_s_ContactName: "",
              W9_Form: "",
            });
            setLoading(false);
            setIsConfirmation(true);
          } else {
            if (response?.errors?.W9_Form) {
              toast.error("W9 form should be in PDF format and less than 5MB");
            }
            setIsConfirmation(false);
            setLoading(false);
            setIsDisabled(false);
          }
        })
        .catch(() => {
          toast.error("An error occurred, Please try again later");
          setIsConfirmation(false);
          setLoading(false);
          setIsDisabled(false);
        });
    }
  };

  const addUserRow = () => {
    const oldUserDataArray = [...userDataArray];
    oldUserDataArray.push(userData);
    setUserDataArray(oldUserDataArray);
  };

  const addProductsLicenseRow = (user, i) => {
    const item = {
      s_LicenseStatus: "",
      n_LicenseNo: "",
      n_StateId_FK: "",
      n_AgentNPN: "",
      d_ExpireDate: "",
    };
    user?.user_detail.push(item);
    const oldUserDataArray = [...userDataArray];
    oldUserDataArray[i] = user;
    setUserDataArray(oldUserDataArray);
  };

  const handleRemoveUserRow = async (i) => {
    const oldUserDataArray = [...userDataArray];
    if (oldUserDataArray.length > 1) {
      oldUserDataArray.splice(i, 1);
      setUserDataArray(oldUserDataArray);
    } else {
      toast.error("At least one row is required!");
      return false;
    }
  };

  const removeProductsLicenseRow = (userInIndex, index, user) => {
    if (user?.user_detail.length > 1) {
      user?.user_detail.splice(userInIndex, 1);
      const oldUserDataArray = [...userDataArray];
      oldUserDataArray[index] = user;
      setUserDataArray(oldUserDataArray);
    } else {
      toast.error("At least one row is required!");
      return false;
    }
  };

  const handleChangeUserProdLic = (
    userInIndex,
    index,
    user,
    dataType,
    value
  ) => {
    const newUserDataArray = user?.user_detail.map((item, i) => {
      if (i == userInIndex) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    const oldUserDataArray = [...userDataArray];
    oldUserDataArray[index].user_detail = newUserDataArray;
    setUserDataArray(oldUserDataArray);
  };

  const handleChangeUser = (index, dataType, value) => {
    const newUserDataArray = userDataArray.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setUserDataArray(newUserDataArray);
  };

  return (
    <>
      <Box>
        <VStack spacing={4} w={"100%"}>
          <Box
            borderRadius={"2xl"}
            border={"1px solid"}
            borderColor={{ lg: "whiteAlpha.100", base: "white" }}
            p={{ base: 3, sm: 4, md: 6, lg: 8 }}
            boxShadow={"0 0 6px rgba(0, 0, 0, 0.3)"}
            width={"65rem"}
            maxWidth={"70rem"}
            bgColor={"white"}
          >
            <HStack justifyContent={"space-between"} alignItems={"center"}>
              <Text fontWeight={"600"} fontSize={"3xl"}>
                {t("Add Agency Users")}
              </Text>
              <Box>
                <Button
                  size={"md"}
                  onClick={() => addUserRow()}
                  leftIcon={<IoMdAdd />}
                >
                  {t("Add User")}
                </Button>
              </Box>
            </HStack>
            {userDataArray.map((user, index) => {
              return (
                <Box
                  borderRadius={"2xl"}
                  mt={3}
                  p={4}
                  border={"1px solid"}
                  borderColor={"gray.300"}
                >
                  <Flex width={"100%"} justifyContent={"flex-end"} mb={4}>
                    <IconButton
                      size={"sm"}
                      colorScheme="red"
                      icon={<FaTrash size={16} />}
                      onClick={(e) => handleRemoveUserRow(index)}
                    />
                  </Flex>
                  <Grid
                    templateColumns={{
                      base: "repeat(1, 1fr)",
                      md: "repeat(2, 1fr)",
                      lg: "repeat(3,1fr)",
                    }}
                    gap={{ base: 3, md: 5, xl: 6 }}
                  >
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel htmlFor="first_name">
                          {t("First Name")}:
                        </FormLabel>
                        <Input
                          id="first_name"
                          name="first_name"
                          autoComplete="off"
                          bgColor={"white"}
                          className="dashboard-group-input"
                          type="text"
                          value={user.first_name}
                          onChange={(e) =>
                            handleChangeUser(
                              index,
                              "first_name",
                              e.target.value
                            )
                          }
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel htmlFor="last_name">
                          {t("Last Name")}:
                        </FormLabel>
                        <Input
                          id="last_name"
                          name="last_name"
                          autoComplete="off"
                          bgColor={"white"}
                          className="dashboard-group-input"
                          type="text"
                          value={user.last_name}
                          onChange={(e) =>
                            handleChangeUser(index, "last_name", e.target.value)
                          }
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel htmlFor="email">{t("Email")}:</FormLabel>
                        <Input
                          id="email"
                          name="email"
                          autoComplete="off"
                          bgColor={"white"}
                          className="dashboard-group-input"
                          type="email"
                          value={user.email}
                          onChange={(e) =>
                            handleChangeUser(index, "email", e.target.value)
                          }
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel htmlFor="phone">
                          {t("Phone Number")}:
                        </FormLabel>
                        <TextMask
                          mask={PhoneNumberMask}
                          Component={InputAdapter}
                          id="phone"
                          name="phone"
                          className="form-control dashboard-group-input"
                          parentobject="mainNode"
                          bgColor={"white"}
                          autoComplete="none"
                          value={user.phone}
                          onChange={(e) =>
                            handleChangeUser(index, "phone", e.target.value)
                          }
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel htmlFor="agent_code">
                          {t("Agent Code")}:
                        </FormLabel>
                        <Input
                          id="agent_code"
                          name="agent_code"
                          autoComplete="off"
                          bgColor={"white"}
                          className="dashboard-group-input"
                          type="text"
                          value={user.agent_code}
                          onChange={(e) =>
                            handleChangeUser(
                              index,
                              "agent_code",
                              e.target.value
                            )
                          }
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel htmlFor="npn">{t("Agent NPN")}:</FormLabel>
                        <Input
                          id="npn"
                          name="npn"
                          autoComplete="off"
                          bgColor={"white"}
                          className="dashboard-group-input"
                          type="text"
                          value={user.npn}
                          onChange={(e) =>
                            handleChangeUser(index, "npn", e.target.value)
                          }
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel htmlFor="resident_state_id">
                          {t("Resident State")}:
                        </FormLabel>
                        <Select
                          id="resident_state_id"
                          name="resident_state_id"
                          bgColor={"white"}
                          className="dashboard-group-input"
                          value={user.resident_state_id}
                          onChange={(e) =>
                            handleChangeUser(
                              index,
                              "resident_state_id",
                              e.target.value
                            )
                          }
                        >
                          <option value=""></option>
                          {sortMutliDimensionArray("s_StateName", states).map(
                            (state) => (
                              <option value={state.n_StateId_PK}>
                                {state.s_StateName}
                              </option>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <Flex mt={4} flexDir={"column"}>
                    <Box
                      border={"1px solid"}
                      borderColor={"gray.300"}
                      p={4}
                      borderRadius={"2xl"}
                    >
                      <HStack mb={2} justifyContent={"space-between"}>
                        <Text fontSize={20}>
                          {t("User Products/License Information")}
                        </Text>
                        <Button
                          size={"md"}
                          onClick={() => addProductsLicenseRow(user, index)}
                          leftIcon={<IoMdAdd />}
                        >
                          {t("Add Row")}
                        </Button>
                      </HStack>
                      <Box>
                        <TableContainer
                          borderRadius={"2xl"}
                          border={"1px solid"}
                          borderColor={"gray.200"}
                          overflowY={"auto"}
                          maxHeight={"20rem"}
                        >
                          <Table variant="unstyled">
                            <Thead
                              bg={"var(--chakra-colors-agGridHeaderBg)"}
                              position={"sticky"}
                              top={0}
                              zIndex={"2"}
                            >
                              <Tr>
                                <Th>{t("License Status")}</Th>
                                <Th>
                                  {t("License Number")}{" "}
                                  <Text display={"inline"} color="red">
                                    *
                                  </Text>
                                </Th>
                                <Th>
                                  {t("State")}{" "}
                                  <Text display={"inline"} color="red">
                                    *
                                  </Text>
                                </Th>
                                <Th>{t("Expiration Date")}</Th>
                                <Th></Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {user?.user_detail.map((userIn, userInIndex) => {
                                return (
                                  <Tr
                                    key={userInIndex}
                                    _hover={{
                                      bg: `rgba(0, 0, 0, 0.075)`,
                                    }}
                                  >
                                    <Td
                                      p={"8px"}
                                      width={"20%"}
                                      border={"1px solid"}
                                      borderColor={
                                        "var(--chakra-colors-primary-50)"
                                      }
                                    >
                                      <Select
                                        size="sm"
                                        borderRadius={"lg"}
                                        bgColor={"white"}
                                        id="s_LicenseStatus"
                                        name="s_LicenseStatus"
                                        value={userIn.s_LicenseStatus}
                                        onChange={(e) =>
                                          handleChangeUserProdLic(
                                            userInIndex,
                                            index,
                                            user,
                                            "s_LicenseStatus",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value=""></option>
                                        <option value="111">
                                          {t("Active")}
                                        </option>
                                        <option value="112">
                                          {t("Closed")}
                                        </option>
                                      </Select>
                                    </Td>
                                    <Td
                                      p={"8px"}
                                      width={"25%"}
                                      border={"1px solid"}
                                      borderColor={
                                        "var(--chakra-colors-primary-50)"
                                      }
                                    >
                                      <Input
                                        size="sm"
                                        borderRadius={"lg"}
                                        bgColor={"white"}
                                        type="text"
                                        id="n_LicenseNo"
                                        name="n_LicenseNo"
                                        autoComplete="off"
                                        value={userIn.n_LicenseNo}
                                        onChange={(e) =>
                                          handleChangeUserProdLic(
                                            userInIndex,
                                            index,
                                            user,
                                            "n_LicenseNo",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Td>
                                    <Td
                                      p={"8px"}
                                      width={"15%"}
                                      border={"1px solid"}
                                      borderColor={
                                        "var(--chakra-colors-primary-50)"
                                      }
                                    >
                                      <Select
                                        size="sm"
                                        borderRadius={"lg"}
                                        bgColor={"white"}
                                        id="n_StateId_FK"
                                        name="n_StateId_FK"
                                        value={userIn.n_StateId_FK}
                                        onChange={(e) =>
                                          handleChangeUserProdLic(
                                            userInIndex,
                                            index,
                                            user,
                                            "n_StateId_FK",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value=""></option>
                                        {sortMutliDimensionArray(
                                          "s_StateName",
                                          states
                                        ).map((state) => (
                                          <option value={state.n_StateId_PK}>
                                            {state.s_StateName}
                                          </option>
                                        ))}
                                      </Select>
                                    </Td>
                                    <Td
                                      p={"8px"}
                                      width={"25%"}
                                      border={"1px solid"}
                                      borderColor={
                                        "var(--chakra-colors-primary-50)"
                                      }
                                    >
                                      <DateInput
                                        id="d_ExpireDate"
                                        name="d_ExpireDate"
                                        placeholder="MM/DD/YYYY"
                                        value={formateDate(userIn.d_ExpireDate)}
                                        styleProps={{
                                          bgColor: "white",
                                          size: "sm",
                                          borderRadius: "lg",
                                        }}
                                        onChange={(value) =>
                                          handleChangeUserProdLic(
                                            userInIndex,
                                            index,
                                            user,
                                            "d_ExpireDate",
                                            formateDate(value, "YYYY-MM-DD")
                                          )
                                        }
                                      />
                                    </Td>
                                    <Td
                                      p={"8px"}
                                      width={"15%"}
                                      border={"1px solid"}
                                      borderColor={
                                        "var(--chakra-colors-primary-50)"
                                      }
                                    >
                                      <Flex justifyContent={"center"}>
                                        <Button
                                          size={"sm"}
                                          colorScheme="red"
                                          leftIcon={<FaTrash size={16} />}
                                          onClick={(e) =>
                                            removeProductsLicenseRow(
                                              userInIndex,
                                              index,
                                              user
                                            )
                                          }
                                        >
                                          Delete
                                        </Button>
                                      </Flex>
                                    </Td>
                                  </Tr>
                                );
                              })}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              );
            })}
          </Box>
          <Container pt={4} pb={6} minH={"10vh"}>
            <Flex gap={6} alignItems={"center"} justifyContent={"center"}>
              <Box>
                <Button
                  w={{ base: "15vh", lg: "18vh" }}
                  variant={"outline"}
                  borderRadius={"xl"}
                  onClick={prevStep}
                  _hover={{ color: "white", bgColor: "primary.500" }}
                  leftIcon={<IoArrowBackSharp size={18} />}
                >
                  {t("Back")}
                </Button>
              </Box>
              <Box>
                <Button
                  type="submit"
                  w={{ base: "15vh", lg: "18vh" }}
                  color={"white"}
                  bgColor={"primary.500"}
                  borderRadius={"xl"}
                  onClick={handleSubmit}
                  isDisabled={isDisabled}
                  rightIcon={<GrFormNextLink size={24} />}
                >
                  {t("Submit")}
                </Button>
              </Box>
            </Flex>
          </Container>
        </VStack>
      </Box>
      <style>
        {`
          .react-datepicker__day--keyboard-selected,
          .react-datepicker__month-text--keyboard-selected,
          .react-datepicker__quarter-text--keyboard-selected,
          .react-datepicker__year-text--keyboard-selected {
            background-color: transparent !important;
          }
        `}
      </style>
    </>
  );
}
