import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function EmailUpdatePage({
  loading,
  newEmail,
  setNewEmail,
  addEmailOnCognito,
}) {
  const { t } = useTranslation();
  return (
    <Flex direction="column" mt={6} width={"100%"}>
      <Text fontSize="31px" mb={4}>
        {t("Add Email")}
      </Text>
      <form onSubmit={addEmailOnCognito}>
        <FormControl mb={4}>
          <FormLabel>{t("Email")}</FormLabel>
          <Input
            isRequired
            backgroundColor="var(--chakra-colors-white)"
            borderColor="var(--chakra-colors-neutral-500)"
            _placeholder={{
              color: "neutral.800",
              fontSize: "sm",
            }}
            type="text"
            borderRadius={10}
            width="100%"
            height={"47px"}
            placeholder={t("Enter Email Address")}
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </FormControl>
        <Box>
          <Button
            type="submit"
            height={"47px"}
            mt={4}
            borderRadius="10px"
            width={"100%"}
            fontWeight="var(--chakra-fontWeights-medium)"
            border={"1px solid var(--chakra-colors-primary-500)"}
            fontSize="16px"
            isDisabled={loading}
          >
            {t("Save")}
          </Button>
        </Box>
      </form>
    </Flex>
  );
}
