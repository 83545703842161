import React, { useEffect, useState } from "react";
import {
  Text,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Box,
} from "@chakra-ui/react";
import { BsDashCircle } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const ICON_MAP = {
  NA: "/starImages/miniIcons/none.svg",
  CERTIFIED: "/starImages/miniIcons/certified.svg",
  NONE: "/starImages/miniIcons/none.svg",
};

const DEFAULT_ICON = "/starImages/miniIcons/none.svg";

const ElevatorSafetyCard = ({ name, icon, isSelected, onClick }) => (
  <Button
    width="full"
    justifyContent={"flex-start"}
    borderRadius={"xl"}
    variant={isSelected ? "outline" : "outline"}
    colorScheme={isSelected ? "primary" : "gray"}
    py={6}
    leftIcon={
      name === "NA" ? (
        <Box pr={1}>
          <BsDashCircle size={25} />
        </Box>
      ) : (
        <Image src={icon || DEFAULT_ICON} width="2em" height="1.5em" />
      )
    }
    onClick={onClick}
  >
    {name}
  </Button>
);

export const ElevatorSafetySelection = ({ index }) => {
  const { buildingDetails, setBuildingDetails, baseDD } = useQuote();
  const { t } = useTranslation();
  const [elevatorSafetyOptions, setElevatorSafetyOptions] = useState([]);

  useEffect(() => {
    const safetyOptionsWithIcons = baseDD?.COMELEVATORSAFETY?.map((option) => ({
      name: option.s_AppCodeNameForDisplay,
      icon: ICON_MAP[option.s_AppCodeName] || DEFAULT_ICON,
      value: option.s_AppCodeName,
    }));

    setElevatorSafetyOptions(safetyOptionsWithIcons);
  }, [baseDD]);

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  const handleSelection = (name) => {
    updateBuildingDetails(index, "elevatorSafety", name);
  };

  return (
    <>
      <Flex gap={2} my={0}>
        <Text fontWeight="500">{t("Elevator Safety")}:</Text>{" "}
        {buildingDetails[index]?.elevatorSafety && (
          <Text fontWeight="500" color={"neutral.300"}>
            ({" "}
            {baseDD?.COMELEVATORSAFETY?.find(
              (option) =>
                option.s_AppCodeName === buildingDetails[index]?.elevatorSafety
            )?.s_AppCodeNameForDisplay ||
              buildingDetails[index]?.elevatorSafety}{" "}
            )
          </Text>
        )}
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={4} align="stretch">
        {Array.isArray(elevatorSafetyOptions) &&
          elevatorSafetyOptions?.map(({ name, icon, value }) => (
            <GridItem key={name}>
              <ElevatorSafetyCard
                name={name}
                icon={icon}
                isSelected={buildingDetails[index]?.elevatorSafety === value}
                onClick={() => handleSelection(value)}
              />
            </GridItem>
          ))}
      </Grid>
    </>
  );
};
