import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { refreshToken } from "../../services/authService";
import { toast } from 'react-toastify';
import Moment from "moment";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { EncryptOrDecrypt } from "../../services/commonService";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { GrFormNextLink } from "react-icons/gr";
import { IoCloseSharp } from "react-icons/io5";

const RefreshToken = (props) => {
    const { showModel, setShowModel } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const getRefreshToken = async () => {
        setLoading(true);
        var userDetails = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
        await dispatch(refreshToken(userDetails.Admin_ID)).then((response) => {
            setLoading(false);
            var res = response.data;
            if (res.status === 'Y') {
                localStorage.setItem('access_token', res.access_token);
                var tokenExpireAt = EncryptOrDecrypt(UTCDateToLocalDate(res.access_token_expires_at), 'E');
                localStorage.setItem('access_token_expires_at', tokenExpireAt);
                setShowModel(false);
            } else {
                var msg = res.msg || 'An error occurred, please try again later'
                toast.error(t(msg));
            }
        }).catch(() => {
            setLoading(false);
            toast.error(t('An error occurred, please try again later'));
        });
    }

    const UTCDateToLocalDate = (date) => {
        date = date.replace('UTC', '').trim();
        return Moment.utc(date).local().format();
    }

    return (
      <>
        <Modal size={"xl"} isOpen={showModel}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>
              {t("Login")}
            </ModalHeader>
            <ModalBody>
              <LoadingOverlay>
                <Loader loading={loading} />
                <Text fontWeight={600} fontSize={"xl"} mb={0}>
                {t("Your Session will expire soon. Please click 'Next' to continue")}.
                </Text>
              </LoadingOverlay>
            </ModalBody>
            <ModalFooter gap={4}>
              <Box>
                <Button
                  onClick={() => getRefreshToken()}
                  size={"md"}
                  isDisabled={loading}
                  leftIcon={<GrFormNextLink size={20} />}
                >
                  {t("Next")}
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    setShowModel(false);
                    setLoading(false);
                  }}
                  size={"md"}
                  variant={"outline"}
                  isDisabled={loading}
                  leftIcon={<IoCloseSharp size={20} />}
                >
                  {t("Close")}
                </Button>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
};

export default RefreshToken;