
import React, { useEffect, Fragment, useState } from "react";
import {
    Row,
    Col,
    Form,
    Modal,
    Button
} from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { toast } from "react-toastify";
import { getZipDetails } from "../../../../../services/floodService";
import ZipDetailsView from '../Modal/ZipDetailsView';
import { customStyles } from "../../../../../components/reactSelectCustomStyle";
import GooglePlacesAutocomplete, { geocodeByAddress } from "react-google-places-autocomplete";
import { isEmpty } from "lodash";

const FloodNewMortgageeAdd = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { show, setShow, selectInterest, tableNewData, tableData } = props;
    const { emptyQuoteData } = useSelector(state => state.flood);

    const [loading, setLoading] = useState(false);
    const key = process.env.REACT_APP_GOOGLE_API_KEY
    const mortgageeJson = {
        "n_PolicyAddInterest_PK": null,
        "n_POTransaction_FK": null,
        "n_PersonInfoId_PK": null,
        "s_PersonType": null,
        "s_MortgageeType": null,
        "s_LoanNo": null,
        "s_AddionalIntType": null,
        "s_RelationType": null,
        "s_HouseholdType": null,
        "s_isDeleted": "N",
        "n_CorePersonPK": null,
        "n_CorePersonAddressPK": null,
        "n_CreatedUser": null,
        "d_CreatedDate": null,
        "n_UpdatedUser": null,
        "d_UpdatedDate": null,
        "person": {
          "n_PersonInfoId_PK": null,
          "s_PersonUniqueId": null,
          "s_EntityType": "ORGANISATION",
          "s_FirstName": null,
          "s_MiddleName": null,
          "s_LastOrganizationName": null,
          "s_FullLegalName": null,
          "d_BirthDate": null,
          "s_Occupation": null,
          "s_IsMailingDiff": null,
          "s_IsTempMailing": null,
          "n_CreatedUser": null,
          "d_CreatedDate": null,
          "n_UpdatedUser": null,
          "d_UpdatedDate": null
        },
        "address": {
          "n_PersonAddressesId_PK": null,
          "n_PersonInfoId_PK": null,
          "s_AddressTypeCode": "MAILING",
          "s_IsDefaultAddress": "Y",
          "s_AddressLine1": null,
          "s_AddressLine2": null,
          "n_CountyId_FK": null,
          "n_CityId_FK": null,
          "n_StateId_FK": null,
          "n_CountryId_FK": null,
          "s_PostalCode": null,
          "s_HouseNo": null,
          "s_StreetName": null,
          "s_PostalCodeSuffix": null,
          "s_Latitude": null,
          "s_Longitude": null,
          "s_CountryName": null,
          "s_StateName": null,
          "s_CountyName": null,
          "s_CityName": null,
          "s_ParcelId": null,
          "n_CreatedUser": null,
          "d_CreatedDate": null,
          "n_UpdatedUser": null,
          "d_UpdatedDate": null
        }
      }
    const [mortgageeData, setMortgageeData] = useState(mortgageeJson);
    const [loanNo, setLoanNo] = useState('');
    const [mortData, setMortData] = useState(false);
    const [search, setSearchProperty] = useState(null);
    const [googlePlace, setGooglePlace] = useState('');
    const [streetName, setStreetName] = useState('');
    const [isZipSet, setIsZipSet] = useState(false);
    const [multipleCity, setMultipleCity] = useState(null);
    const [showZipModal, setShowZipModal] = useState(false);
    const [showMultipleCity, setShowMultipleCity] = useState(false);
    const [enableSave, setEnableSave] = useState(false);
    const [isValidate, setIsValidate] = useState(true);
    const getGeoCode = (description) => {
      geocodeByAddress(description)
        .then(addr => {
          addr && addr[0].address_components.forEach(e => {
            if (e.types.includes('postal_code')) {
              getZipData(e.short_name);
            }
          });
        })
    }

    // get zip details 
  const getZipData = (zip) => {
    if (zip !== '') {
      dispatch(getZipDetails('10', zip, 'APPLICANT')).then((res) => {
        if (res.success === true) {
          var data = res.data;
          if (data.status === 'Y') {
            if (data.count === 1) {
              setZipDetailsData(res.data.zipData[0]);
            } else {
              showMultipleCityModal(data.zipData);
            }
          } else {
            toast.error(res.errorMsg);
            mortgageeData.address.s_CityName = '';
            mortgageeData.address.s_StateName = '';
          }
        }
      });
    } else {
      toast.error('Zip Code should not be empty!');
    }
  }

  // open Zip Modal
  const showMultipleCityModal = (zipData) => {
    setMultipleCity(<ZipDetailsView zipData={zipData} setZipDetailsData={setZipDetailsData} />)
    setShowZipModal(true);
    setShowMultipleCity(true);
  }

  // get selected zip data
  const setZipDetailsData = (zipData) => {
    const values = mortgageeData;
    values.address.s_PostalCode = zipData.s_ZipCode;
    // values.address.n_Zipcodes_PK = zipData.n_Zipcodes_PK;
    values.address.s_StateName = zipData.s_StateCode;
    values.address.n_CityId_FK = zipData.n_CityId_PK;
    values.address.n_CountyId_FK = zipData.n_CountyId_FK;
    values.address.n_StateId_FK = zipData.n_StateId_PK;
    values.address.n_CountryId_FK = zipData.n_CountryId_FK;
    values.address.s_CityName = zipData.s_CityName;
    setMortgageeData(values);
    setIsZipSet(!isZipSet);
    setShowZipModal(false);
    setShowMultipleCity(false)
  }

    useEffect(() => {
      if (!show) {
        setLoanNo('')
        setMortgageeData(mortgageeJson);
        setSearchProperty(null)
        setStreetName(null)
        setGooglePlace(null)
      }
    }, [show]);

    const handleGooglAutoComplete = (val, status) => {
      if (val) {
        if (status.action === "input-change") {
          setStreetName(val);
        } else if (["set-value", "menu-close", "input-blur", "menu-close"].includes(status.action)) {
          setStreetName(streetName.toUpperCase());
        }
      }
    }

    useEffect(() => {
      if (search != null) {
        setStreetName(search.value.structured_formatting.main_text);
        getGeoCode(search.value.description);
        setGooglePlace(search);
      }
    }, [search]);

    const inputChange = (event) => {
      let tempData = mortgageeData;

      const { name, value, attributes } = event.target;
      const parentKey = attributes.parentobject.value;
      if (parentKey.length > 0) {
        tempData[parentKey][name] = value;
      } else {
        tempData[name] = value;
      }
      tempData.s_PersonType = selectInterest?.value;
      tempData.s_MortgageeType = selectInterest?.label;
      setMortData(true);
      setMortgageeData(tempData);
    };

    const handleSaveInterest = () => {
      if (selectInterest?.value === 'DISASTER') {
        var fullName = mortgageeData.person.s_FirstName || '';
        if (fullName && fullName !== '') {
          if (mortgageeData.person.s_LastOrganizationName && mortgageeData.person.s_LastOrganizationName !== '') {
            fullName += ' ';
            fullName += mortgageeData.person.s_MiddleName || '';
          }
          fullName += ' ';
          fullName += mortgageeData.person.s_LastOrganizationName || '';
        }
      
        mortgageeData.person.s_FullLegalName = fullName;
        mortgageeData.s_PersonType = selectInterest.value;
        mortgageeData.s_MortgageeType = selectInterest.label;
      }
      
      var temp = [...tableData];
      temp.push(mortgageeData);
      emptyQuoteData.mortgageeData = temp
      tableNewData(emptyQuoteData.mortgageeData);
      setShow(false);
    };

    const validation = () => {    
      if (([null, '', undefined].includes(mortgageeData.person.s_FullLegalName) || isEmpty(mortgageeData.person.s_FullLegalName)) && mortgageeData.s_PersonType !== "DISASTER") {
        toast.error("Enter Mortgagee Name");
        setIsValidate(false);
        setEnableSave(false);
      }
      else if (mortgageeData.s_PersonType == "DISASTER" && ([null, '', undefined].includes(mortgageeData.person.s_FirstName) || isEmpty(mortgageeData.person.s_FirstName))) {
        toast.error("Enter First Name");
          setIsValidate(false);
          setEnableSave(false);
      }
      else if (mortgageeData.s_PersonType == "DISASTER" && ([null, '', undefined].includes(mortgageeData.person.s_LastOrganizationName) || isEmpty(mortgageeData.person.s_LastOrganizationName))) {
        toast.error("Enter Last Name");
        setIsValidate(false);
        setEnableSave(false);
      }
      else if ([null, '', undefined].includes(mortgageeData.address.s_AddressLine1) || isEmpty(mortgageeData.address.s_AddressLine1)) {
        toast.error("Enter Mailing Address 1");
        setIsValidate(false);
        setEnableSave(false);
      }
      else if ([null, '', undefined].includes(mortgageeData.address.s_CityName) || [null, '', undefined].includes(mortgageeData.address.s_StateName) || [null, '', undefined].includes(mortgageeData.address.s_PostalCode)) {
        toast.error("Invalid Zip");
        setIsValidate(false);
        setEnableSave(false);
      }
      else if ([null, '', undefined].includes(mortgageeData.s_LoanNo) || isEmpty(mortgageeData.s_LoanNo)) {
        toast.error("Enter Loan Number");
        setIsValidate(false);
        setEnableSave(false);
      }
      else {
        setIsValidate(true);
        setEnableSave(true);
        handleSaveInterest();
      }
    };

    useEffect(() => {
      if (mortData) {
        setMortgageeData(mortgageeJson);
      }
    }, [mortData]);
    
    useEffect(() => {
      if (!isEmpty(selectInterest.value) && !mortData) {
        mortgageeData.s_PersonType = selectInterest.value;
        mortgageeData.s_MortgageeType = selectInterest.label;
      }
    }, [mortgageeData])

    return (
      <>
        <Modal size="lg" show={show} className="add-mortage-modal">
          <Modal.Header id="add-mortage-modal-header">
            <Modal.Title className="text-white">{t("Search Interest")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-mortage-modal-body">
            <LoadingOverlay>
              {
                !isEmpty(selectInterest) && selectInterest.value === "DISASTER" ? (
                <Fragment>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="add-mortage-form-group"
                      >
                        <Form.Label className="myform-label">{t('First Name')}
                        <span className="label-asterisk" color="#FF1E1E"> *</span>
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="text"
                          placeholder=""
                          name="s_FirstName"
                          parentobject="person"
                          value={mortgageeData.person.s_FirstName ?? null}
                          onChange={(e) => {
                            inputChange(e);
                            setMortData(true);
                          }}
                          onClick={(e) => {
                            inputChange(e);
                            setMortData(true);
                          }}
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="add-mortage-form-group"
                      >
                        <Form.Label className="myform-label">
                          {t('Middle Name')}
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="text"
                          placeholder=""
                          name="s_MiddleName"
                          parentobject="person"
                          value={mortgageeData.person.s_MiddleName}
                          onChange={(e) => {
                            inputChange(e);
                            setMortData(true);
                          }}
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="add-mortage-form-group"
                      >
                        <Form.Label className="myform-label">{t('Last Name')}
                        <span className="label-asterisk" color="#FF1E1E"> *</span>
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="text"
                          placeholder=""
                          name="s_LastOrganizationName"
                          parentobject="person"
                          value={mortgageeData.person.s_LastOrganizationName}
                          onChange={(e) => {
                            inputChange(e);
                            setMortData(true);
                          }}
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Fragment>
              ) : (<Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="add-mortage-form-group"
                  >
                    <Form.Label className="myform-label">
                      {t("Mortgagee(s) Name")}
                      <span className="label-asterisk" color="#FF1E1E"> *</span>
                    </Form.Label>
                    <Form.Control
                      className="myform-input"
                      type="text"
                      placeholder=""
                      name="s_FullLegalName"
                      parentobject="person"
                      value={mortgageeData.person.s_FullLegalName}
                      onChange={(e) => {
                        inputChange(e);
                        setMortData(true);
                      }}
                      maxLength={50}
                    />
                  </Form.Group>
                </Col>
              )}
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Mailing Address")} 1{" "}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_AddressLine1"
                    parentobject="address"
                    value={mortgageeData.address.s_AddressLine1}
                    onChange={(e) => {inputChange(e); setMortData(true);}}
                    maxLength={150}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Mailing Address")} 2
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_AddressLine2"
                    parentobject="address"
                    value={mortgageeData.address.s_AddressLine2}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    maxLength={50}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Zip Code")}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_PostalCode"
                    parentobject="address"
                    value={mortgageeData.address.s_PostalCode}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    onBlur={(e) => getZipData(e.currentTarget.value)}
                    maxLength={5}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("City")}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_CityName"
                    parentobject="address"
                    value={mortgageeData.address.s_CityName}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("State")}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_StateName"
                    parentobject="address"
                    value={mortgageeData.address.s_StateName}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Loan No")}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_LoanNo"
                    parentobject=""
                    value={mortgageeData.s_LoanNo}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    maxLength={20}
                  />
                </Form.Group>
              </Col>
              
            </LoadingOverlay>
          </Modal.Body>

          <Modal.Footer className="add-mortage-modal-footer">
            <Button
              variant="outline-dark"
              onClick={() => setShow(false)}
              className="mybtn-light"
              style={{
                marginRight: "1rem",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
              disabled={loading}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                validation(); setEnableSave(true)
              }}
              id="primary-colored-btn"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              {t("Submit")}
            </Button>
          </Modal.Footer>
        </Modal>
        {showMultipleCity && (
          <Modal size="md" show={show} className="zip-details-modal">
            <Modal.Body className="zip-details-modal-body">
              {multipleCity}
            </Modal.Body>
          </Modal>
        )}
      </>
    );
};

export default FloodNewMortgageeAdd;