import { Box, Grid, GridItem, Select } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { starCommercialContext } from "../CommercialContext";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { useTranslation } from "react-i18next";

import { useAutoZipSelection } from "../../../create-star-quote/utils/useAutoZipDetection";
import LocationSelectionModal from "../../../common/LocationSelectionModal";
import states from "../../../common/States";
import {
  CustomFormControl,
  CustomInput,
  CustomSelect,
  LabeledSwitch,
} from "./CustomComponents";

const ApplicantTab = ({ quoteData }) => {
  const { commercialData, setCommercialData, quoteNumber } = useContext(
    starCommercialContext
  );
  const { t } = useTranslation();
  const [zipTriggered, setZipTriggered] = useState(false);

  const [zipData, setZipData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    commercialData?.applicantDetail?.zip,
    zipTriggered || !quoteNumber
  );
  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
    if (showModal) {
      setOpenModal(true);
    }
  }, [selectedLocation, showModal]);

  useEffect(() => {
    if (zipData && Object.keys(zipData).length > 0) {
      setCommercialData({
        ...commercialData,
        applicantDetail: {
          ...commercialData.applicantDetail,
          city: zipData?.s_CityName || "",
          state: zipData?.s_StateCode || "",
          cityId: zipData.city_id ?? 0,
          stateId: zipData.state_id ?? 0,
          countryId: zipData.n_CountryId_FK ?? 1,
          countyId: zipData.county_id ?? 0,
          county: zipData.s_CountyName ?? "",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipData]);

  const handleChange = (field, value) => {
    setCommercialData((prev) => ({
      ...prev,
      applicantDetail: {
        ...prev.applicantDetail,
        [field]: value,
      },
    }));
  };

  return (
    <Box opacity={isLoading ? 0.5 : 1}>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />
      <Grid
        templateColumns={"repeat(auto-fit, minmax(250px, 1fr))"}
        gap={5}
        p={2}
      >
        <GridItem colSpan={2}>
          <CustomFormControl label={t("Insured Name")}>
            <CustomInput
              value={commercialData?.applicantDetail?.lastName}
              onChange={(e) => handleChange("lastName", e.target.value)}
            />
          </CustomFormControl>
        </GridItem>
        <GridItem>
          <CustomFormControl label={t("Entity Type")}>
            <CustomSelect
              value={commercialData?.applicantDetail?.entityType}
              onChange={(e) => handleChange("entityType", e.target.value)}
              options={quoteData?.COMENTITYTYPE}
              custom={true}
            />
          </CustomFormControl>
        </GridItem>
        <GridItem>
          <CustomFormControl label={t("Email Address")}>
            <CustomInput
              value={commercialData?.applicantDetail?.email}
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </CustomFormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <CustomFormControl label={t("Mailing Address")}>
            <CustomInput
              value={commercialData?.applicantDetail?.address}
              onChange={(e) => handleChange("address", e.target.value)}
            />
          </CustomFormControl>
        </GridItem>
        <GridItem>
          <CustomFormControl label={t("Phone Number")}>
            <MaskedFormControl
              className="inputSelectBoxes"
              name="phone-number"
              type="text"
              placeholder="&nbsp;"
              size="md"
              focusBorderColor="secondary.500"
              mask="(111) 111-1111"
              guide={false}
              defaultValue={"+1 "}
              value={commercialData?.applicantDetail?.phone?.replace(
                /^\+\d+\s?/,
                ""
              )}
              onChange={(e) => handleChange("phone", e.target.value)}
            />
          </CustomFormControl>
        </GridItem>
        <GridItem>
          <CustomFormControl label={t("Zip Code")}>
            <CustomInput
              value={commercialData?.applicantDetail?.zip}
              onChange={(e) => {
                if (e.target.value.length <= 5) {
                  handleChange("zip", e.target.value);
                  setZipTriggered(true);
                }
              }}
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              onWheel={(e) => {
                e.target.blur();
              }}
            />
          </CustomFormControl>
        </GridItem>
        <GridItem>
          <CustomFormControl label={t("County")}>
            <CustomInput
              value={commercialData?.applicantDetail?.county}
              isDisabled
            />
          </CustomFormControl>
        </GridItem>
        <GridItem>
          <CustomFormControl label={t("State")}>
            <Select
              className="inputSelectBoxes"
              _disabled={{ bg: "neutral.50" }}
              _hover={{ bg: "gray.50" }}
              value={commercialData?.applicantDetail?.state}
              isDisabled
            >
              <option value=""></option>
              {states.map((state, i) => (
                <option key={i} value={state?.abbr}>
                  {state?.name || ""} ({state?.abbr || ""})
                </option>
              ))}
            </Select>
          </CustomFormControl>
        </GridItem>
        <GridItem>
          <CustomFormControl label={t("City")}>
            <CustomInput
              value={commercialData?.applicantDetail?.city}
              isDisabled
            />
          </CustomFormControl>
        </GridItem>
        <GridItem>
          <CustomFormControl label={t("Business Establishment")}>
            <CustomSelect
              value={commercialData?.applicantDetail?.buissinessEstablishment}
              onChange={(e) =>
                handleChange("buissinessEstablishment", e.target.value)
              }
              options={quoteData?.COMBUISNESSESTABLISHMENT}
              custom={true}
            />
          </CustomFormControl>
        </GridItem>

        <GridItem>
          <CustomFormControl label={t("Pay Plan")}>
            <Select
              _disabled={{ bg: "neutral.50" }}
              _hover={{ bg: "gray.50" }}
              className="inputSelectBoxes"
              value={commercialData?.payPlan}
              onChange={(e) => {
                setCommercialData((prev) => ({
                  ...prev,
                  payPlan: e.target.value,
                }));
              }}
            >
              <option></option>
              {quoteData?.PayPlanDD?.map((data, index) => (
                <option key={index} value={data?.value}>
                  {data?.label}
                </option>
              ))}
            </Select>
          </CustomFormControl>
        </GridItem>
        <GridItem>
          <CustomFormControl label={t("Blanket")} hasQuestionMark={false}>
            <LabeledSwitch
              leftLabel={t("No")}
              rightLabel={t("Yes")}
              isChecked={commercialData?.applicantDetail?.blanket === "YES"}
              onChange={(e) =>
                handleChange("blanket", e.target.checked ? "YES" : "NO")
              }
            />
          </CustomFormControl>
        </GridItem>
      </Grid>
      <style>
        {`
        .inputSelectBoxes {
          border: 2px solid var(--chakra-colors-neutral-200);
          border-radius: 12px;
          padding-right: 10px;
          padding-left: 15px;
          width: 100%;
          height: 45px;
        }
        `}
      </style>
    </Box>
  );
};

export default ApplicantTab;
