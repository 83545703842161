import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useContext } from "react";
import { FaConnectdevelop, FaSave } from "react-icons/fa";
import { TbLayoutNavbarExpand } from "react-icons/tb";
import { starCommercialContext } from "../CommercialContext";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RateHistoryModal } from "../../../search-quote/components/QuoteTabComponents/RateHistoryGrid";
import { LuFileSpreadsheet } from "react-icons/lu";

export const QuoteButtons = () => {
  const { t } = useTranslation();
  const [openRateHistoryModal, setOpenRateHistoryModal] = useState(false);

  const {
    accordiansArray,
    setAccordianArray,
    handleSubmitQuote,
    defaultAccordion,
    bindComQuote,
    isBind,
    commercialData,
  } = useContext(starCommercialContext);
  const showAll = () => {
    setAccordianArray(!accordiansArray);
    if (accordiansArray.length === 4) {
      setAccordianArray([]);
    } else {
      setAccordianArray(defaultAccordion);
    }
  };
  return (
    <>
      <Flex my={1} gap={3} justifyContent={"flex-end"}>
        <Button
          fontWeight={400}
          borderRadius={"xl"}
          leftIcon={<LuFileSpreadsheet />}
          onClick={() => {
            setOpenRateHistoryModal(true);
          }}
        >
          {t("Quote History")}
        </Button>
        <Button
          fontWeight={400}
          borderRadius={"xl"}
          leftIcon={<TbLayoutNavbarExpand />}
          onClick={showAll}
        >
          {accordiansArray && accordiansArray.length > 0
            ? t("Collapse All")
            : t("Expand All")}
        </Button>
        <Button
          fontWeight={400}
          borderRadius={"xl"}
          leftIcon={<FaConnectdevelop />}
          onClick={bindComQuote}
          isDisabled={isBind}
        >
          {t("Submit for UW Review")}
        </Button>
        <Button
          fontWeight={400}
          borderRadius={"xl"}
          leftIcon={<FaSave />}
          isDisabled={isBind}
          onClick={() => {
            handleSubmitQuote();
          }}
        >
          {t("Save & Update")}
        </Button>
      </Flex>

      <Modal
        isCentered
        size={"6xl"}
        isOpen={openRateHistoryModal}
        onClose={() => setOpenRateHistoryModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={"1px solid"} borderColor={"gray.300"}>
            {t("Quote History")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex my={4} mb={6} w={"100%"}>
              <RateHistoryModal poTransPk={commercialData?.poTransactionPk} />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
