import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  AiOutlineExclamationCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function DebarmentModal({
  showModal,
  setShowModal,
  handleApproved,
  handleDebard,
  showDebardModal,
  setShowDebardModal,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {/* Suspension or Debarment Attestation Modal */}
      <Modal
        size={"4xl"}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>
            <Text mb={0} fontWeight={600}>
              {t("Suspension or Debarment Attestation")}
            </Text>
          </ModalHeader>
          <ModalBody>
            <Box mb={4}>
              <Text>
                {t(
                  "Agents are required to notify the insurer should they be suspended or debarred from doing business with the federal government"
                )}
                .
              </Text>
              <Text>
                {t("Click")}{" "}
                <Link
                  color={"blue"}
                  textDecoration={"underline"}
                  isExternal={true}
                  href="https://www.gsa.gov/policy-regulations/policy/acquisition-policy/office-of-acquisition-policy/gsa-acq-policy-integrity-workforce/suspension-debarment-and-agency-protests/suspension-debarment-faq"
                >
                  {t("here")}
                </Link>{" "}
                {t("for information about Suspension or Debarment")}.
              </Text>
              <Flex mt={2} width={"100%"} justifyContent={"space-between"}>
                <Button
                  size={"md"}
                  width={"21rem"}
                  height={"auto"}
                  style={{ whiteSpace: "normal" }}
                  textAlign={"center"}
                  p={2}
                  onClick={(e) => {
                    handleDebard(e);
                  }}
                  leftIcon={
                    <AiOutlineExclamationCircle
                      size={26}
                      style={{
                        cursor: "pointer",
                        color: "red",
                      }}
                    />
                  }
                >
                  {t(
                    "I am currently debarred or suspended from doing business with the Federal Government"
                  )}
                  .
                </Button>
                <Button
                  size={"md"}
                  width={"21rem"}
                  height={"auto"}
                  style={{ whiteSpace: "normal" }}
                  textAlign={"center"}
                  p={2}
                  onClick={(e) => {
                    handleApproved(e);
                  }}
                  leftIcon={
                    <AiOutlineCheckCircle
                      size={26}
                      style={{
                        cursor: "pointer",
                        color: "green",
                      }}
                    />
                  }
                >
                  {t("I am in good standing")}.{" "}
                  {t(
                    "I am not suspended or debarred from doing business with the Federal Government"
                  )}
                  .
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Access Denied Modal */}
      <Modal
        size={"2xl"}
        isOpen={showDebardModal}
        onClose={() => {
          setShowDebardModal(false);
          navigate("/login");
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>
            {t("Access Denied")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Text>
                {t(
                  "Agents who are suspended or debarred from doing business with the federal government cannot participate in the National Flood Insurance Program"
                )}
                .
              </Text>
              <Text>
                {t("Click")}{" "}
                <Link
                  color={"blue"}
                  textDecoration={"underline"}
                  isExternal={true}
                  href="https://www.gsa.gov/policy-regulations/policy/acquisition-policy/office-of-acquisition-policy/gsa-acq-policy-integrity-workforce/suspension-debarment-and-agency-protests/suspension-debarment-faq"
                >
                  {t("here")}
                </Link>{" "}
                {t("for information about Suspension or Debarment")}.
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
