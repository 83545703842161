// import React, { Fragment } from "react";
import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { policyList } from "../../../services/dashboardService";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDispatch } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";

const PaymentDue = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [policyListData, setPolicyListData] = useState({});
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 1,
    pageLength: 10,
  });
  useEffect(() => {
   
      getPolicyData();
    
  }, [filter.page, filter.pageLength]);
  const getPolicyData = async () => {
    setLoading(true);
    await dispatch(policyList(filter)).then(
      (res) => {
        setPolicyListData(res.data.data);
        setTotal(res.data.total)
      }
    ).catch(() => {
      // Nothing specific needed on catch
    });
    setLoading(false);
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    setFilter({ ...filter, page: page, pageLength: sizePerPage });
  }

  const columns = [
    {
      dataField: "PolicyNo",
      text: "Policy No",
      formatter: (value, row) => (
        <Link className="management-reports-link" to={"/core/policy/"+row.PolicyNo}>
          {value}
        </Link>
      ),
    },

    {
      dataField: "InsuredName",
      text: "Policyholder/Member Name",
    },
    {
      dataField: "ProductName",
      text: "Product Name",
    },
    {
      dataField: "BookingDate",
      text: "Booking Date",
    },
    {
      dataField: "WrittenPremium",
      text: "Premium",
      formatter: (WrittenPremium)=>{return '$('+ numberWithCommas(WrittenPremium) +')'}
    },
  ];

  function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 }
  const renderShowsTotal = (from, to, size) => (
    <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
  );

  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    showTotal: true,
    totalSize: total,
    pageStartIndex: 1,
    // alwaysShowAllBtns:true,
    paginationTotalRenderer: renderShowsTotal,
  };
  
  return (
    <Fragment>
      <h4 className="dashboard-cards-table-title">Dashboard</h4>
      <div className="dashboard-tab-header-fill">
        <span>Payment due</span>
      </div>
      <div className="managment-tab-body" style={{ paddingBottom: "2rem" }}>
        <div
          className="management-reports-table"
          id="management-reports-table-outer-div"
        >
          <LoadingOverlay>
           <Loader loading={loading} />
          <BootstrapTable
            wrapperClasses="dashboard-table-main"
            bootstrap4
            keyField="PolicyNo"
            data={policyListData.length > 0 ? policyListData : []}
            columns={columns}
            hover
            remote
            bordered={false}
            pagination={paginationFactory(options)}
            onTableChange={onTableChange}
          />
          </LoadingOverlay>
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentDue;
