import React from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const ZipDetailsView = ({ zipData, setZipDetailsData, target }) => {
  const { t } = useTranslation();
  var tableBody = zipData.map((data, i) => {
    return (
      <Tr key={i}>
        <Td
          p={"12px"}
          width={"45%"}
          style={{
            textWrap: "wrap",
            wordBreak: "break-word",
          }}
          border={"1px solid"}
          borderColor={"var(--chakra-colors-primary-50)"}
        >
          <Button
            variant={"link"}
            textDecoration={"underline"}
            onClick={(e) => {
              e.preventDefault();
              setZipDetailsData(data, target);
            }}
          >
            {data.s_CityName}
          </Button>
        </Td>
        <Td
          p={"12px"}
          width={"45%"}
          style={{
            textWrap: "wrap",
            wordBreak: "break-word",
          }}
          border={"1px solid"}
          borderColor={"var(--chakra-colors-primary-50)"}
        >
          {data.s_CountyName}
        </Td>
      </Tr>
    );
  });

  return (
    <>
      <Box bg={"transparent"} maxHeight={"20rem"} overflowY={"auto"}>
        <TableContainer
          borderRadius={"2xl"}
          border={"1px solid"}
          borderColor={"var(--chakra-colors-primary-50)"}
        >
          <Table
            variant="unstyled"
            border={"1px solid"}
            borderColor={"gray.200"}
            borderRadius={"2xl"}
            overflow={"hidden"}
          >
            <Thead bg={"var(--chakra-colors-agGridHeaderBg)"}>
              <Tr>
                <Th>{t("City")}</Th>
                <Th>{t("County")}</Th>
              </Tr>
            </Thead>
            <Tbody>{tableBody}</Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ZipDetailsView;
