import React, { useState, useEffect } from "react";
import { Row,Col,Form,Button,Table, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import delIcon from "../../../assets/delete-icon.svg";
import { deleteInformation } from "../../../services/coreSystemService";
import { useDispatch } from 'react-redux';
const AdditionalInsured = (props) => {
  const dispatch = useDispatch();
  const { applicationData, additionalInterestInfo, handleChange, loading, setLoading, showReview } = props;
    const { t } = useTranslation();
    const [additionalInsuredData, setAdditionalInsuredData] = useState([]);
    const [personAddr, setPersonAddr] = useState({});

    useEffect(() => {
      if (additionalInterestInfo && Object.keys(additionalInterestInfo).length > 0) {  
         setAdditionalInsuredData(additionalInterestInfo.additionalInsured || []);
      }
    }, [additionalInterestInfo])

    useEffect(() => {
      if (applicationData && Object.keys(applicationData).length > 0) {  
         setPersonAddr(applicationData.mailingAddress)
      }
    }, [applicationData])


    const handleAdditionalInsuredAdd = () => {
        const additionalInsured = [...additionalInsuredData];
      var addInJson = {
        "n_POAppsOtherParties_Pk": null,
        "n_POAPPSMASTER_FK": null,
        "s_OtherPartyCategory": null,
        "n_PORISKMASTER_FK": null,
        "n_CreatedTransaction_Fk": null,
        "s_ISAdditionalInsured": null,
        "s_PartyInterestCode": 'ADDITIONALINSURED',
        "n_OtherPartyClient_Fk": null,
        "n_OtherPartyAddress_Fk": null,
        "person": {
          "n_PersonInfoId_PK": null,
          "s_PersonUniqueId": null,
          "s_EntityType": null,
          "s_FirstName": null,
          "s_MiddleName": null,
          "s_LastOrganizationName": "",
          "s_FullLegalName": null
        },
        "address": [{
          "n_PersonAddressesId_PK": null,
          "n_PersonId_FK": null,
          "s_AddressTypeCode": "MAILING",
          "s_HouseNo": personAddr['s_HouseNo'],
          "s_StreetName": personAddr['s_AddressLine1'],
          "s_AddressLine1": personAddr['s_AddressLine1'],
          "n_CountyId_FK": personAddr['n_CountyId_FK'],
          "n_CityId_FK": personAddr['n_CityId_FK'],
          "n_StateId_FK": personAddr['n_StateId_FK'],
          "n_CountryId_FK": personAddr['n_CountryId_FK'],
          "s_PostalCode": personAddr['s_PostalCode'],
          "s_PostalCodeSuffix": personAddr['s_PostalCodeSuffix'],
          "s_Latitude": personAddr['s_Latitude'],
          "s_Longitude": personAddr['s_Longitude'],
          "s_CountryName": personAddr['s_CountryName'],
          "s_CityName": personAddr['s_CityName'],
          "s_StateName": personAddr['s_StateName'],
          "d_EffectiveDateFrom": personAddr['d_EffectiveDateFrom'],
          "d_EffectiveDateTo": personAddr['d_EffectiveDateTo'],
          "s_IsDefaultAddress": "Y",
          "n_Zipcodes_FK": personAddr['n_Zipcodes_FK']          
        }]
      }
        if(additionalInsured.length < 3){
            additionalInsured.push(addInJson)
            setAdditionalInsuredData(additionalInsured);
        } else {
            toast.error('3 Additional Insured Added.');
        }
      }

      const handleAdditionalInsuredDelete = async (index, row) => {
        const additionalInsured = [...additionalInsuredData];
        
        if(additionalInsured[index].n_POAppsOtherParties_Pk != null){
          const params = {
            selectedPK: additionalInsured[index].n_POAppsOtherParties_Pk,
            source :  "ADDIINTEREST",
            isUpdateBillTo : 'N'
          }
          setLoading(true);
          var res = await dispatch(deleteInformation(params)).catch(() => {
            setLoading(false);
          });
          if (res.success && res.data.status === 'Y') {
            additionalInsured.splice(index, 1);
            // update isAdditionalInsuredDeleted in endorsement json 
            handleChange("isAdditionalInsuredDeleted", '', 'YES', "InsuredInformation");
            toast.success('Entry Deleted.');
          }
          setLoading(false);
        } else {
          additionalInsured.splice(index, 1);
        }
          setAdditionalInsuredData(additionalInsured);
      }

      const handleAdditionalInsuredChange = (index, name, value) => {
        const additionalInsured = [...additionalInsuredData];
        additionalInsured[index]['person'][name] = value.toUpperCase();
        additionalInsured[index]['person']['s_FullLegalName'] = additionalInsured[index]['person']['s_FirstName'] + ' ' + additionalInsured[index]['person']['s_LastOrganizationName'];
        setAdditionalInsuredData(additionalInsured);
        handleChange("AdditionalInsured", additionalInterestInfo, additionalInsured, "InsuredInformation");
      }

    return (
      <>
        <section className="add-mortage-section">
        {/* Form */}
        <div className="justify-content-center flood-add-mortage-form-section">
          <Card>
            <Card.Header id="card-header-style">
              <p >{t('Additional Insured')}</p>
            </Card.Header>
            <Card.Body>
              <Col xs="12" className="add-mortage-form-main">
                <div className="add-mortage-form">
                  <div className="add-mortage-add-btn">
                    <div></div>
                    <Button
                      variant="primary"
                      id="add-input-field"
                      onClick={() => handleAdditionalInsuredAdd()}
                      disabled={showReview}
                    >
                      {" "}
                      <span> + </span> {t('Add')}
                    </Button>
                  </div>
                  <div className="add-mortage-table-div">
                    <Table className="add-mortage-table" borderless responsive>
                      <thead>
                        <tr>
                          <th>{t('First name')}</th>
                          <th>{t('Last Name')}</th>
                          <th>{t('Action')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {additionalInsuredData.map((field, index) => (
                          <tr key={index}>
                            <td>
                              <Form.Control
                                className="add-insured-input"
                                type="text"
                                placeholder={t("Name")}
                                name="s_FirstName"
                                value={field.person ? field.person.s_FirstName : ''}
                                onChange={(e) => handleAdditionalInsuredChange(index, e.currentTarget.name, e.currentTarget.value)}
                                readOnly={showReview}
                              />
                            </td>
                            <td>
                              <Form.Control
                                className="add-insured-input"
                                type="text"
                                placeholder={t("Last Name")}
                                name="s_LastOrganizationName"
                                value={field.person ? field.person.s_LastOrganizationName : ''}
                                onChange={(e) => handleAdditionalInsuredChange(index, e.currentTarget.name, e.currentTarget.value)}
                                readOnly={showReview}
                              />
                            </td>
                            <td className="add-mortage-action-row">
                              <div className="add-mortage-action" hidden={showReview}>
                                <img
                                  src={delIcon}
                                  onClick={() => handleAdditionalInsuredDelete(index, field)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Card.Body>
          </Card>
        </div>
      </section>
      </>
    )
}

export default AdditionalInsured;
  