import { Grid, Switch } from "@chakra-ui/react";
import {
  ChakraSelect,
  CustomFormControl,
  CustomInput,
} from "../../commercial-quote/Components/CustomComponents";
import { useQuote } from "../../CommCreateQuote";
import { useTranslation } from "react-i18next";

export const UnderwritingForm = ({ index }) => {
  const { t } = useTranslation();
  const { buildingDetails, setBuildingDetails, baseDD } = useQuote();

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  return (
    <>
      <Grid templateColumns={"repeat(4, 1fr)"} gap={6}>
        <CustomFormControl label={t("Year Built")}>
          <CustomInput
            defaultValue={buildingDetails[index]?.yearBuilt || ""}
            onBlur={(e) => {
              if (e.target.value.length <= 4) {
                // setPropertyDetails({
                //   ...propertyDetails,
                //   yearBuilt: e.target.value,
                // });
                updateBuildingDetails(index, "yearBuilt", e.target.value);
              }
            }}
          />
        </CustomFormControl>
        {/* <CustomFormControl label={t("Age of Building")}>
          <ChakraSelect
            isDisabled={true}
            // value={propertyDetails?.ageOfBuilding || ""}
            value={buildingDetails[index]?.ageOfBuilding || ""}
            onChange={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   ageOfBuilding: e.target.value,
              // })
              updateBuildingDetails(index, "ageOfBuilding", e.target.value)
            }
          >
            <option value=""></option>
            {[...Array(61).keys()].map((i) => (
              <option key={i} value={i}>
                {i === 60 ? "60+" : i}
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl> */}
        <CustomFormControl label={t("Number of Stories")}>
          <ChakraSelect
            // value={propertyDetails?.noOfStories || ""}
            value={buildingDetails[index]?.noOfStories || ""}
            onChange={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   noOfStories: e.target.value,
              // })
              updateBuildingDetails(index, "noOfStories", e.target.value)
            }
          >
            <option value=""></option>
            <option value={"1-10"}>1-10</option>
            <option value={"11-30"}>11-30</option>
            <option value={"31 and over"}>31 & Over</option>
          </ChakraSelect>
        </CustomFormControl>
        <CustomFormControl label={t("Number of Units")}>
          <CustomInput
            // value={propertyDetails?.noOfUnits || ""}
            defaultValue={buildingDetails[index]?.noOfUnits || ""}
            onBlur={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   noOfUnits: e.target.value,
              // })
              updateBuildingDetails(index, "noOfUnits", e.target.value)
            }
          />
        </CustomFormControl>
        <CustomFormControl label={t("Square Footage")}>
          <CustomInput
            // value={propertyDetails?.squareFootage}
            defaultValue={buildingDetails[index]?.squareFootage || ""}
            onBlur={(e) => {
              // setPropertyDetails({
              //   ...propertyDetails,
              //   squareFootage: e.target.value,
              // });
              updateBuildingDetails(index, "squareFootage", e.target.value);
            }}
          />
        </CustomFormControl>
        <CustomFormControl label={t("Plumbing Updates")}>
          <ChakraSelect
            // value={propertyDetails?.plumbingUpdates || ""}
            value={buildingDetails[index]?.plumbingUpdates || ""}
            onChange={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   plumbingUpdates: e.target.value,
              // })
              updateBuildingDetails(index, "plumbingUpdates", e.target.value)
            }
          >
            <option value=""></option>
            {baseDD?.COMPLUMBINGUPDATE?.map((e, i) => (
              <option key={i} value={e?.s_AppCodeName}>
                {e?.s_AppCodeNameForDisplay}
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl>
        <CustomFormControl label={t("Roofing Updates")}>
          <ChakraSelect
            // value={propertyDetails?.roofingUpdates || ""}
            value={buildingDetails[index]?.roofingUpdates || ""}
            onChange={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   roofingUpdates: e.target.value,
              // })
              updateBuildingDetails(index, "roofingUpdates", e.target.value)
            }
          >
            <option value=""></option>
            {baseDD?.COMROOFUPDATE?.map((e, i) => (
              <option key={i} value={e?.s_AppCodeName}>
                {e?.s_AppCodeNameForDisplay}
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl>
        <CustomFormControl label={t("Wiring Updates")}>
          <ChakraSelect
            // value={propertyDetails?.wiringUpdates || ""}
            value={buildingDetails[index]?.wiringUpdates || ""}
            onChange={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   wiringUpdates: e.target.value,
              // })
              updateBuildingDetails(index, "wiringUpdates", e.target.value)
            }
          >
            <option value=""></option>
            {baseDD?.COMWIREUPDATES?.map((e, i) => (
              <option key={i} value={e?.s_AppCodeName}>
                {e?.s_AppCodeNameForDisplay}
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl>
        <CustomFormControl label={t("BBA Rating")}>
          <ChakraSelect
            // value={propertyDetails?.bbaRating || ""}
            value={buildingDetails[index]?.bbaRating || ""}
            onChange={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   bbaRating: e.target.value,
              // })
              updateBuildingDetails(index, "bbaRating", e.target.value)
            }
          >
            <option value=""></option>
            {baseDD?.COMBBBRATING?.map((e, i) => (
              <option key={i} value={e?.s_AppCodeName}>
                {e?.s_AppCodeNameForDisplay}
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl>
        {/* <CustomFormControl label={t("3-Years Loss Count")}>
          <ChakraSelect
            // value={propertyDetails?.threeYearLossCount || ""}
            defaultValue={buildingDetails[index]?.threeYearLossCount || ""}
            onBlur={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   threeYearLossCount: e.target.value,
              // })
              updateBuildingDetails(index, "threeYearLossCount", e.target.value)
            }
          >
            <option value=""></option>
            {lossCounts?.map((e, i) => (
              <option key={i} value={e.value}>
                {e.label}
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl> */}
        <CustomFormControl label={t("PPC")}>
          <ChakraSelect
            // value={propertyDetails?.ppc || ""}
            value={buildingDetails[index]?.ppc || ""}
            onChange={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   ppc: e.target.value,
              // })
              updateBuildingDetails(index, "ppc", e.target.value)
            }
          >
            <option value=""></option>
            {baseDD?.PROTECTIONCLS?.map((e, i) => (
              <option key={i} value={e?.s_AppCodeName}>
                {e?.s_AppCodeNameForDisplay}
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl>

        <CustomFormControl label={t("Roof Deck Attachment")}>
          <ChakraSelect
            // value={propertyDetails?.roofDeckAttachment || ""}
            value={buildingDetails[index]?.roofDeckAttachment || ""}
            onChange={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   roofDeckAttachment: e.target.value,
              // })
              updateBuildingDetails(index, "roofDeckAttachment", e.target.value)
            }
          >
            <option value=""></option>
            {baseDD?.COMMHOROOFDECKATT?.map((e, i) => (
              <option key={i} value={e?.s_AppCodeName}>
                {e?.s_AppCodeNameForDisplay}
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl>
        <CustomFormControl label={t("Roof Wall Anchorage")}>
          <ChakraSelect
            // value={propertyDetails?.roofWallAnchorage || ""}
            value={buildingDetails[index]?.roofWallAnchorage || ""}
            onChange={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   roofWallAnchorage: e.target.value,
              // })
              updateBuildingDetails(index, "roofWallAnchorage", e.target.value)
            }
          >
            <option value=""></option>
            {baseDD?.HOROOFWALLCONN?.map((e, i) => (
              <option key={i} value={e?.s_AppCodeName}>
                {e?.s_AppCodeNameForDisplay}
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl>
        <CustomFormControl label={t("Roof Geomentry")}>
          <ChakraSelect
            // value={propertyDetails?.roofGeometry || ""}
            value={buildingDetails[index]?.roofGeometry || ""}
            onChange={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   roofGeometry: e.target.value,
              // })
              updateBuildingDetails(index, "roofGeometry", e.target.value)
            }
          >
            <option value=""></option>
            {baseDD?.COMMPLROOFSHAPE?.map((e, i) => (
              <option key={i} value={e?.s_AppCodeName}>
                {e?.s_AppCodeNameForDisplay}
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl>
        <CustomFormControl label={t("Roof Covering")}>
          <ChakraSelect
            // value={propertyDetails?.roofCovering || ""}
            value={buildingDetails[index]?.roofCovering || ""}
            onChange={(e) =>
              // setPropertyDetails({
              //   ...propertyDetails,
              //   roofCovering: e.target.value,
              // })
              updateBuildingDetails(index, "roofCovering", e.target.value)
            }
          >
            <option value=""></option>
            {baseDD?.COMMROOFTYPE?.map((e, i) => (
              <option key={i} value={e?.s_AppCodeName}>
                {e?.s_AppCodeNameForDisplay}
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl>

        <CustomFormControl
          label={t("Do you have Pets/Animals")}
          hasQuestionMark
        >
          <Switch
            size={"lg"}
            isChecked={buildingDetails[index]?.hasPets}
            onChange={(e) =>
              updateBuildingDetails(index, "hasPets", e.target.checked)
            }
          />
        </CustomFormControl>
        {buildingDetails[index]?.hasPets && (
          <CustomFormControl label={t("Pets/Animals")}>
            <ChakraSelect
              // value={propertyDetails?.petAnimals || ""}
              value={buildingDetails[index]?.petAnimals || ""}
              onChange={(e) =>
                // setPropertyDetails({
                //   ...propertyDetails,
                //   petAnimals: e.target.value,
                // })
                updateBuildingDetails(index, "petAnimals", e.target.value)
              }
            >
              <option value=""></option>
              {baseDD?.COMPETANIMALS?.map((e, i) =>
                e?.s_AppCodeName === "NONE" ? (
                  <></>
                ) : (
                  <option key={i} value={e?.s_AppCodeName}>
                    {e?.s_AppCodeNameForDisplay}
                  </option>
                )
              )}
            </ChakraSelect>
          </CustomFormControl>
        )}
        <CustomFormControl label={t("Roof Warranty")} hasQuestionMark>
          <Switch
            // isChecked={propertyDetails?.roofWarrenty === "YES"}
            isChecked={buildingDetails[index]?.roofWarrenty === "YES"}
            onChange={(e) => {
              // setPropertyDetails({
              //   ...propertyDetails,
              //   roofWarrenty: e.target.checked ? "YES" : "NO",
              // });
              updateBuildingDetails(
                index,
                "roofWarrenty",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size={"lg"}
          />
        </CustomFormControl>

        <CustomFormControl label={t("Converted Structure")} hasQuestionMark>
          <Switch
            // isChecked={propertyDetails?.convertedStructure === "YES"}
            isChecked={buildingDetails[index]?.convertedStructure === "YES"}
            onChange={(e) => {
              // setPropertyDetails({
              //   ...propertyDetails,
              //   convertedStructure: e.target.checked ? "YES" : "NO",
              // });
              updateBuildingDetails(
                index,
                "convertedStructure",
                e.target.checked ? "YES" : "NO"
              );
            }}
            colorScheme={"primary"}
            size={"lg"}
          />
        </CustomFormControl>
        <CustomFormControl label={t("Fire Sprinkler")} hasQuestionMark>
          <Switch
            isChecked={buildingDetails[index]?.fireSprinklers === "YES"}
            onChange={(e) => {
              updateBuildingDetails(
                index,
                "fireSprinklers",
                e.target.checked ? "YES" : "NO"
              );
            }}
            colorScheme={"primary"}
            size={"lg"}
          />
        </CustomFormControl>
      </Grid>
    </>
  );
};
