import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Modal, Badge } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { customStyles } from "../../../../components/reactSelectCustomStyle";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getEditEmptyQuoteData, getZipDetails } from '../../../../services/floodService';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import MaskedFormControl from 'react-bootstrap-maskedinput'
import GoogleMaps from '../instantQuote/flood-detail/google-map/GoogleMap';
import ZipDetailsView from './Modal/ZipDetailsView';
import MailingAddress from './Mailing/MailingAddress';
import TempMailingAddress from './Mailing/TempMailingAddress';
import '../quote/quote.scss';
import { FaInfoCircle } from 'react-icons/fa';
import ReactTooltip from "react-tooltip";
import { isEmpty } from 'lodash';

const Applicant = (props) => {
    const {emptyQuoteData, floodSystemDD, handleChange, handleSwitchChange, handleStateChange, validated, isDataChanged, isBind, wantAddLender } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [billTo, setBillTo] = useState('');
    const [applicantType, setApplicantType] = useState('INDIVIDUAL');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nameOfDec, setNameOfDec] = useState('');
    const [streetName, setStreetName] = useState('');
    const [googlePlace, setGooglePlace] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [zipCodeSuffix, setZipCodeSuffix] = useState('');
    const [isGPUpdate, setIsGPUpdate] = useState(false);
    const [countryName, setCountyName] = useState('');
    const [stateName, setStateName] = useState('');
    const [cityName, setCityName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isMaillingAddrDiffer, setIsMaillingAddrDiffer] = useState(false);
    const [isAddInsureds, setIsAddInsureds] = useState(false);
    const [isPolicyMortgage, setIsPolicyMortgage] = useState(false);
    const [isTempMailing, setIsTempMailing] = useState(false);
    const [phoneType, setPhoneType] = useState('');
    const [isInsuredTenant, setIsInsuredTenant] = useState('');
    const [isRentalProp, setIsRentalProp] = useState('');
    const [show, setShow] = useState(false);
    const [multipleCity, setMultipleCity] = useState(null);
    const [isInsuredNonProfitEnt, setIsInsuredNonProfitEnt] = useState('');
    const [isSmallBusinessEmp, setIsSmallBusinessEmp] = useState('');
    const [condoAssociation, setCondoAssociation] = useState('');
    const [lastOrgNameLabel, setLastOrgNameLabel] = useState('');
    const [isATChanged, setIsATChanged] = useState(false);
    const [isBillToChange, setIsBillToChange] = useState(false);
    const [isDataSet, setIsDataSet] = useState(false);
    const [memberNo, setMemberNo] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [uuid, setUuid] = useState('');

    const emptyMailAddress = {
        n_PersonAddressesId_PK: null,
        n_PersonInfoId_PK: null,
        s_AddressTypeCode: null,
        s_IsDefaultAddress: null,
        s_AddressLine1: null,
        n_CountyId_FK: null,
        n_CityId_FK: null,
        n_StateId_FK: null,
        n_CountryId_FK: null,
        s_PostalCode: null,
        s_HouseNo: null,
        s_StreetName: null,
        s_PostalCodeSuffix: null,
        s_Latitude: null,
        s_Longitude: null,
        s_CountryName: null,
        s_StateName: null,
        s_CountyName: null,
        s_CityName: null,
        s_ParcelId: null,
        s_countyFips: null,
        s_stateFips: null,
        n_CreatedUser: null,
        d_CreatedDate: null,
        n_UpdatedUser: null,
        d_UpdatedDate: null,
        s_AddressLine2:null
    }

    /**
     * Set State
     */
    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            // propertyInfo
            setUuid(emptyQuoteData.propertyInfo.uuid ||'');
            setBillTo(emptyQuoteData.policyInfo.s_BillTo || null);
            setIsAddInsureds(emptyQuoteData.propertyInfo.s_IsAddInsureds || null);
            setIsPolicyMortgage(emptyQuoteData.propertyInfo.s_IsPolicyMortgage || null);
            setIsInsuredTenant(emptyQuoteData.propertyInfo.s_isInsuredTenant || null);
            setIsRentalProp(emptyQuoteData.propertyInfo.s_RentalProperty || null);
            setIsInsuredNonProfitEnt(emptyQuoteData.propertyInfo.s_IsInsuredNonProfitEnt || null);
            setIsSmallBusinessEmp(emptyQuoteData.propertyInfo.s_IsSmallBusinessEmp || null);
            setCondoAssociation(emptyQuoteData.propertyInfo.s_CondoOwnership || null);

            // personAddr
            setStreetName(emptyQuoteData.personAddr.s_StreetName || null);
            setGooglePlace({ label: emptyQuoteData.personAddr.s_StreetName || '', value: emptyQuoteData.personAddr.s_StreetName || '' } || null);
            setAddressLine2(emptyQuoteData.personAddr.s_AddressLine2 || null);
            setZipCode(emptyQuoteData.personAddr.s_PostalCode || null);
            setCountyName(emptyQuoteData.personAddr.s_CountyName || null);
            setStateName(emptyQuoteData.personAddr.s_StateName || null);
            setCityName(emptyQuoteData.personAddr.s_CityName || null);
            setZipCodeSuffix(emptyQuoteData.personAddr.s_PostalCodeSuffix || '' || null);

            // personInfo
            if (![null, ''].includes(emptyQuoteData.personInfo.s_EntityType)) {
                setApplicantType(emptyQuoteData.personInfo.s_EntityType || null);
            }
            setFirstName(emptyQuoteData.personInfo.s_FirstName || null);
            setMiddleName(emptyQuoteData.personInfo.s_MiddleName || null);
            setLastName(emptyQuoteData.personInfo.s_LastOrganizationName || null);
            setNameOfDec(emptyQuoteData.personInfo.s_FullLegalName || null);
            setIsTempMailing(emptyQuoteData.propertyInfo.s_IsTempMailing || null);
            setIsMaillingAddrDiffer(emptyQuoteData.personInfo.s_IsMailingDiff || null);
            if (emptyQuoteData.personInfo.metadata) {
                setMemberNo(emptyQuoteData.personInfo.metadata.member_id || null);
            }

            // contact
            setEmail(emptyQuoteData.personEmailContact.s_CommValue || null);
            setPhone(emptyQuoteData.personPhoneContact.s_CommValue || null);
            setPhoneType(emptyQuoteData.personPhoneContact.s_CommType || null);
            setIsDataSet(true);
        }
    }, [emptyQuoteData])

    /**
     * Set Full Name
     */
    const setNameOnDec = () => {
        var fullName = firstName;
        if (![null, ''].includes(middleName)) {
            fullName = fullName + ' ' + middleName;
        }
        fullName = fullName + ' ' + lastName;
        if (fullName.indexOf(null) === -1) {
            setNameOfDec(fullName.toUpperCase())
        }
    }

    // trigger on Update Applicant Type to set view
    useEffect(() => {
        if (![null, ''].includes(applicantType)) {
            switch (applicantType) {
                case 'INDIVIDUAL':
                    setLastOrgNameLabel('Last Name');
                    break;
                case 'BUSINESS':
                    setLastOrgNameLabel('Business Name');
                    break;
                case 'ASSOCIATION':
                    setLastOrgNameLabel('Association Name');
                    break;
            }
            if (isATChanged) {
                setFirstName('');
                setLastName('');
                setMiddleName('');
                setNameOfDec('');
                setIsATChanged(false);
                handleChange({}, 's_FullLegalName');
            }
        }
    }, [applicantType])

    // trigger on update zipCode
    useEffect(() => {
        if (zipCode && isGPUpdate) {
            getDataFromZip(zipCode);
            setIsGPUpdate(false);
        }
    }, [zipCode])

    useEffect(() => {
        handleStateChange('APPLICANT', isMaillingAddrDiffer, 'isMaillingAddrDiffer');
    }, [isMaillingAddrDiffer])

    // trigger to set selected address street name
    const handleGooglAutoComplete = (val, status) => {
        if (val) {
            if (status.action == "input-change") {
                setStreetName(val);
            } else if (["set-value", "menu-close", "input-blur", "menu-close"].includes(status.action)) {
                setStreetName(streetName.toUpperCase());
            }
        }
    }

    // get geocode from address
    const getGeoCode = (description) => {
        geocodeByAddress(description)
            .then(addr => {
                var propertyAddress = {};
                addr && addr[0].address_components.forEach(e => {
                    if (e.types.includes('postal_code')) {
                        setZipCode(e.short_name);
                        propertyAddress = {
                            ...propertyAddress,
                            's_PostalCode': e.short_name
                        };
                    } else if (e.types.includes('street_number')) {
                        propertyAddress = {
                            ...propertyAddress,
                            's_HouseNo': e.short_name
                        };
                    } else if (e.types.includes('locality', 'political')) {
                        setCityName(e.short_name);
                        propertyAddress = {
                            ...propertyAddress,
                            's_CityName': e.short_name
                        };
                    } else if (e.types.includes('country', 'political')) {
                        propertyAddress = {
                            ...propertyAddress,
                            's_CountryName': e.short_name
                        };
                    } else if (e.types.includes('administrative_area_level_2', 'political')) {
                        setCountyName(e.short_name);
                        propertyAddress = {
                            ...propertyAddress,
                            's_CountyName': e.short_name
                        };

                    } else if (e.types.includes('administrative_area_level_1', 'political')) {
                        setStateName(e.short_name);
                        propertyAddress = {
                            ...propertyAddress,
                            's_StateName': e.short_name
                        };
                    } else if (e.types.includes('postal_code_suffix')) {
                        setZipCodeSuffix(e.short_name);
                        propertyAddress = {
                            ...propertyAddress,
                            's_PostalCodeSuffix': e.short_name
                        };
                    }else if(e.types.includes('subpremise')){
                        setAddressLine2('#' +e.short_name);
                        propertyAddress={
                            ...propertyAddress,'apt_number':'#' +e.short_name}                      
                    }

                    if (Object.keys(propertyAddress).length > 0) {
                        handlePropertyAddressChange('', propertyAddress);
                    }
                });
                if (addr && addr[0]) {
                    getLatLng(addr[0]).then(({ lat, lng }) => {
                        var propertyAddress = {
                            's_Latitude': lat,
                            's_Longitude': lng
                        };
                        handlePropertyAddressChange('', propertyAddress);
                    });
                }
            });
    }

    /**
     * Handle Google Place Autocomplete
     * @param {element values} e 
     */
    const handleGooglePlace = (e) => {
        setAddressLine2("");  //to clear apt number before reassigning
        setGooglePlace(e); // setting element value to google place autocomplete
        setStreetName(e.value.structured_formatting.main_text); // setting only address line 1 as streen name
        setIsGPUpdate(true); // to trigger dependent function
        getGeoCode(e.value.description); // to get geo-details based on address
        isDataChanged(true); // to visible "Rate" Button
    }

    // get zip details from address
    const getDataFromZip = (zipCodeVal) => {
        if (streetName === '') {
            toast.error('Please Enter Street Name!');
            setZipCode('')
            return false;
        }
        if (zipCodeVal.length != 5) {
            toast.error('Invalid zip code!');
            return false;
        }
        if (zipCodeVal !== '') {
            handlePropertyAddressChange('s_StreetName', streetName);
            dispatch(getZipDetails('10', zipCodeVal, 'APPLICANT'))
                .then((res) => {
                    if (res.success === true) {
                        var data = res.data;
                        if (data.status === 'Y') {
                            if (data.count === 1) {
                                setZipDetailsData(data.zipData[0]);
                            } else {
                                showMultipleCityModal(data.zipData);
                            }
                        } else {
                            toast.error(data.errorMsg);
                        }
                    }
                });
        } else {
            toast.error('Zip Code should not be empty!');
        }
    }

    // to update value in reducer
    const handlePropertyAddressChange = (key, value) => {
        var quoteDataVar = emptyQuoteData;
        if (key.length === 0) {
            for (const [key, val] of Object.entries(value)) {
                quoteDataVar['personAddr'][key] = val;
            }
        } else {
            if (key === 's_StreetName') {
                quoteDataVar['personAddr']['s_AddressLine1'] = value;
            }
            quoteDataVar['personAddr'][key] = value;
        }
        dispatch(getEditEmptyQuoteData(quoteDataVar));
    }

    // open Zip Modal
    const showMultipleCityModal = (zipData) => {
        setMultipleCity(<ZipDetailsView zipData={zipData} setZipDetailsData={setZipDetailsData} />)
        setShow(true);
    }

    // get selected zip data
    const setZipDetailsData = (zipData) => {
        var addressPK = {
            'n_CityId_FK': zipData.n_CityId_PK,
            's_ZipCounty': zipData.s_CountyName.toUpperCase(),
            'n_CountyId_FK': zipData.n_CountyId_FK,
            's_StateCode': zipData.s_StateCode.toUpperCase(),
            'n_StateId_PK': zipData.n_StateId_PK,
            's_CityName': zipData.s_CityName.toUpperCase(),
            'n_CityId_PK': zipData.n_CityId_PK,
            'n_CountryId_FK': zipData.n_CountryId_FK,
            'n_Zipcodes_PK': zipData.n_Zipcodes_PK,
            's_CountyName': zipData.s_CountyName.toUpperCase(),
        };
        setCountyName(zipData.s_CountyName.toUpperCase());
        setStateName(zipData.s_StateCode.toUpperCase());
        setCityName(zipData.s_CityName.toUpperCase());
        handlePropertyAddressChange('', addressPK);
        setMultipleCity(null);
        setShow(false);
    }

    // handle switch Change
    const handleSwitch = (e, state) => {
        var temp = 'NO';
        if (state === 'isMaillingAddrDiffer') {
            temp = 'YES';
        }
        const { attributes, checked } = e.target;
        if (checked) {
            temp = attributes.checkedvalue.value;
        }
        switch (state) {
            case 'isMaillingAddrDiffer':
                setIsMaillingAddrDiffer(temp);
                updateMailingAddress(temp);
                break;
            case 'isAddInsureds':
                setIsAddInsureds(temp);
                break;
            case 'isPolicyMortgage':
                setIsPolicyMortgage(temp);
                break;
            case 'isTempMailing':
                setIsTempMailing(temp);
                updateTempMailingAddress(temp);
                break;
            default:
                break;
        }
        handleSwitchChange(e);
    }

    // handle bill to 
    useEffect(() => {
        if (isBillToChange === true || isDataSet === true) {
            var quoteDataVar = emptyQuoteData;
            var temp = false;
            if (['FIRSTMORTGAGE', 'SECONDMORTGAGE', 'LOSSPAYEE'].includes(billTo)) {
                temp = true;
            }
            if (Object.keys(emptyQuoteData).length > 0) {
                if (emptyQuoteData.mortgageeData && emptyQuoteData.mortgageeData.length > 0) {
                    temp = true;
                }
            }
            setIsPolicyMortgage(temp === true ? 'YES' : 'NO');
            quoteDataVar['propertyInfo']['s_IsPolicyMortgage'] = (temp === true ? 'YES' : 'NO');
            handleStateChange('APPLICANT', temp, 'MORTGAGE');
            dispatch(getEditEmptyQuoteData(quoteDataVar));
            setIsBillToChange(false);
            setIsDataSet(false);
        }
    }, [isBillToChange, isDataSet])

    // Copy Property address into mailing address 
    const updateMailingAddress = (maillingAddrDiffer) => {
        var quoteDataVar = emptyQuoteData;
        if (maillingAddrDiffer === 'NO') {
            if (quoteDataVar['personAddrMail'] && [null, ''].includes(quoteDataVar['personAddrMail']['n_PersonAddressesId_PK'])) {
                quoteDataVar['personAddrMail'] = { ...quoteDataVar['personAddr'] };
                quoteDataVar['personAddrMail']['n_PersonAddressesId_PK'] = null;
                quoteDataVar['personAddrMail']['s_AddressTypeCode'] = "MAILING";
            }
        }
        if (maillingAddrDiffer === 'YES') {
            quoteDataVar['personAddrMail'] = emptyMailAddress;
        }
        dispatch(getEditEmptyQuoteData(quoteDataVar));
    }

    // Copy Property address into Temp mailing address 
    const updateTempMailingAddress = (isTempMailing) => {
        var quoteDataVar = emptyQuoteData;
        if (isTempMailing === 'YES') {
            quoteDataVar['tempMailingAddr'] = { ...quoteDataVar['personAddr'] };
            quoteDataVar['tempMailingAddr']['n_PersonAddressesId_PK'] = null;
            quoteDataVar['tempMailingAddr']['s_AddressTypeCode'] = "TempMailing"
        } else {
            quoteDataVar['tempMailingAddr'] = emptyMailAddress;
        }
        dispatch(getEditEmptyQuoteData(quoteDataVar));
    }

    useEffect(() => {
        handleStateChange('APPLICANT', condoAssociation, 'condoAssociation');
        handleStateChange('APPLICANT', isInsuredTenant, 'isTheInsured')
    }, [condoAssociation, isInsuredTenant]);

    // trigger on Update to toggler Mortgagee Tab based on App Switch value
    useEffect(() => {
        var temp = false;
        if (isPolicyMortgage === 'YES') {
            temp = true;
        }
        handleStateChange('APPLICANT', temp, 'MORTGAGE');
        temp = false;
        if (isAddInsureds === 'YES') {
            temp = true;
        }
        handleStateChange('APPLICANT', temp, 'ADDINSUREDS');
    }, [isPolicyMortgage, isAddInsureds]);

    // Inserting style only for address field
    useEffect(() => {
        document.head.insertAdjacentHTML("beforeend", `<style>input[id^='react-select-'] { opacity: 2 !important; width: 250px !important; }</style>`)
    }, []);

    // trigger on wantAddLender to Add Policy Mortagee
    useEffect(() => {
        if (wantAddLender) {
            setIsPolicyMortgage(wantAddLender ? 'YES' : 'NO');
            let quoteDataVar = emptyQuoteData;
            quoteDataVar['propertyInfo']['s_IsPolicyMortgage'] = (wantAddLender ? 'YES' : 'NO');
            dispatch(getEditEmptyQuoteData(quoteDataVar));
        }
    }, [wantAddLender])

    // set membor no in emptyquote
    const handleMemborNoChange = (value) => {
        var emptyQuote = { ...emptyQuoteData };
        emptyQuote.personInfo.metadata = {
            member_id: value
        }
        dispatch(getEditEmptyQuoteData(emptyQuote));
        setMemberNo(value);
    }
    const checkIsHomestead = () =>{
        let isHomestead = 'No';
        let metadata = emptyQuoteData?.propertyInfo?.metadata || []
        if (!isEmpty(metadata)) {
            let estedData = metadata;
            if (typeof estedData === 'string') {
                estedData = JSON.parse(estedData);
            }
            var currentYear = new Date().getFullYear();
            var previousYear = (currentYear - 1);
            estedData = estedData.estedTaxsesData
            if(estedData?.length > 0){
                isHomestead = estedData.some(element => ((element.year >= previousYear) && element.exemptions.includes('HOMESTEAD'))) ? "Yes":"No"
            }
        }
        return isHomestead;
    }
    return (
        <>
            <Form validated={validated}>
                <Row className="align-items-end">
                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('On Renewal Bill To')}
                            </Form.Label>
                            <Form.Select
                                className="table-show-entries-select"
                                name="s_BillTo"
                                parentobject="policyInfo"
                                title={t('Bill to')}
                                value={billTo}
                                onChange={(e) => {
                                    handleChange(e);
                                    setBillTo(e.currentTarget.value);
                                    setIsBillToChange(true);
                                }}
                                required
                                autoComplete="off"
                                disabled={isBind}
                            >
                                <option value={''}>{t('Select')}</option>
                                {
                                    Object.keys(floodSystemDD).length > 0 && floodSystemDD.BILLTOFLOOD.map((v, i) => {
                                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('Applicant Type')}:
                            </Form.Label>
                            <Form.Select
                                className="table-show-entries-select"
                                name="s_EntityType"
                                parentobject="personInfo"
                                title={t('Applicant Type')}
                                value={applicantType}
                                onChange={(e) => {
                                    handleChange(e);
                                    setApplicantType(e.currentTarget.value);
                                    setIsATChanged(true);
                                }}
                                required
                                autoComplete="off"
                                disabled={isBind}
                            >
                                <option value={''}>{t('Select')}</option>
                                {
                                    Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODAPPLICANTTYPE.map((v, i) => {
                                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    {
                        applicantType === 'INDIVIDUAL' && (
                            <>
                                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                    <Form.Group
                                        className="search-quote-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('First name')}
                                        </Form.Label>
                                        <Form.Control
                                            className="dashboard-group-input-flood"
                                            type="text"
                                            placeholder={t("First name")}
                                            name="s_FirstName"
                                            title={t('First name')}
                                            parentobject="personInfo"
                                            value={firstName}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFirstName(e.currentTarget.value.toUpperCase());
                                            }}
                                            required
                                            autoComplete="off"
                                            disabled={isBind}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                    <Form.Group
                                        className="search-quote-form-group"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label className="dashboard-group-label">
                                            {t('Middle Name')}
                                        </Form.Label>
                                        <Form.Control
                                            className="dashboard-group-input-flood"
                                            type="text"
                                            placeholder={t("Middle Name")}
                                            name="s_MiddleName"
                                            title={t('Middle Name')}
                                            parentobject="personInfo"
                                            value={middleName}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setMiddleName(e.currentTarget.value.toUpperCase());
                                            }}
                                            autoComplete="off"
                                            disabled={isBind}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                        )
                    }
                    <Col xs="12" sm="12" md="6" lg={applicantType === 'INDIVIDUAL' ? '2' : '3'} xl={applicantType === 'INDIVIDUAL' ? '2' : '3'}>
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t(lastOrgNameLabel)}
                            </Form.Label>
                            <Form.Control
                                className="dashboard-group-input-flood"
                                type="text"
                                placeholder={t(lastOrgNameLabel)}
                                name="s_LastOrganizationName"
                                title={t(lastOrgNameLabel)}
                                parentobject="personInfo"
                                value={lastName}
                                onChange={(e) => {
                                    handleChange(e);
                                    setLastName(e.currentTarget.value.toUpperCase());
                                }}
                                onBlur={() => setNameOnDec()}
                                required
                                autoComplete="off"
                                disabled={isBind}
                            />
                        </Form.Group>
                    </Col>
                    <br />
                    <Col xs="12" sm="12" md="6" lg={applicantType === 'INDIVIDUAL' ? '2' : '3'} xl={applicantType === 'INDIVIDUAL' ? '2' : '3'} style={{width:"30%"}}>
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('Name(s) on Title')}
                            </Form.Label>
                            <Form.Control
                                className="dashboard-group-input-flood"
                                type="text"
                                placeholder={t("Last Name")}
                                title={t('Name(s) on Title')}
                                value={nameOfDec}
                                readOnly
                            />
                        </Form.Group>
                    </Col>
                   { localStorage.getItem('member_id') === "Y" ? 
                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('Enter Member')}#
                            </Form.Label>
                            <Form.Control
                                className="dashboard-group-input-flood"
                                type="text"
                                placeholder={t("Enter Member")}
                                title={t('Enter Member')}
                                value={memberNo}
                                onChange={(e) => {
                                    handleMemborNoChange(e.currentTarget.value);
                                }}
                                autoComplete="off"
                                disabled={isBind}
                            />
                        </Form.Group>
                    </Col>
                     : null }
                    <Col xs="12" sm="12" md="6" lg="3" xl="3">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('Property Address')}
                            </Form.Label>
                            {
                                isBind ? (
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        type="text"
                                        placeholder={t("Property Address")}
                                        title={t('Property Address')}
                                        value={streetName}
                                        disabled={isBind}
                                    />
                                ) : (
                                    <GooglePlacesAutocomplete
                                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                        selectProps={{
                                            styles: customStyles,
                                            inputValue: streetName,
                                            value: googlePlace,
                                            onInputChange: handleGooglAutoComplete,
                                            onChange: handleGooglePlace,
                                            name: "googlePlacesStreetName",
                                            id: "googlePlacesStreetName",
                                            placeholder: "Enter a location",
                                            isDisabled: isBind
                                        }}
                                    />
                                )
                            }

                        </Form.Group>
                    </Col>
                    
                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('AddressLine 2')}
                            </Form.Label>
                            <Form.Control
                                type="text"                                
                                name="s_AddressLine2"                                
                                parentobject="personAddr"
                                value={addressLine2}
                               
                                onChange={(e) => {
                                    handleChange(e);
                                    setAddressLine2(e.currentTarget.value);
                                }}                               
                                autoComplete="off"
                                disabled={isBind}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="1" xl="1">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('Zip')}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t("Zip")}
                                name="s_PostalCode"
                                title={t('Zip')}
                                parentobject="personAddr"
                                value={zipCode}
                                maxLength='5'
                                onChange={(e) => {
                                    handleChange(e);
                                    setZipCode(e.currentTarget.value);
                                }}
                                onBlur={(e) => getDataFromZip(e.currentTarget.value)}
                                required
                                autoComplete="off"
                                disabled={isBind}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="1" xl="1">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('Zip Suffix')}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t("Zip Suffix")}
                                name="s_PostalCodeSuffix"
                                title={t('Zip Suffix')}
                                parentobject="personAddr"
                                value={zipCodeSuffix}
                                onChange={(e) => {
                                    handleChange(e);
                                    setZipCodeSuffix(e.currentTarget.value);
                                }}
                                autoComplete="off"
                                disabled={isBind}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t("County")}
                            </Form.Label>
                            <Form.Control
                                className="dashboard-group-input-flood"
                                type="text"
                                placeholder={t("County")}
                                name="s_CountyName"
                                title={t('County')}
                                parentobject="personAddr"
                                value={countryName}
                                onChange={(e) => {
                                    handleChange(e);
                                    setCountyName(e.currentTarget.value);
                                }}
                                readOnly={isBind}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="1" xl="1">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('State')}
                            </Form.Label>
                            <Form.Control
                                className="dashboard-group-input-flood"
                                type="text"
                                placeholder={t("State")}
                                name="s_StateName"
                                title={t('State')}
                                parentobject="personAddr"
                                value={stateName}
                                readOnly
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t("City")}
                            </Form.Label>
                            <Form.Control
                                className="dashboard-group-input-flood"
                                type="text"
                                placeholder={t("City")}
                                name="s_CityName"
                                title={t('City')}
                                parentobject="personAddr"
                                value={cityName}
                                readOnly
                            />
                        </Form.Group>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('Email')}
                            </Form.Label>
                            <Form.Control
                                className="dashboard-group-input-flood"
                                type="email"
                                placeholder={t("Enter Email")}
                                name="s_CommValue"
                                title={t('Email')}
                                parentobject="personEmailContact"
                                value={email}
                                onChange={(e) => {
                                    handleChange(e);
                                    setEmail(e.currentTarget.value);
                                }}
                                required
                                autoComplete='off'
                                disabled={isBind}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('Phone Type')}
                            </Form.Label>
                            <Form.Select
                                className="table-show-entries-select"
                                name="s_CommType"
                                parentobject="personPhoneContact"
                                title={t('Phone Type')}
                                value={phoneType}
                                onChange={(e) => {
                                    handleChange(e);
                                    setPhoneType(e.currentTarget.value);
                                }}
                                required
                                disabled={isBind}
                            >
                                <option value={''}>{t('Select')}</option>
                                {
                                    Object.keys(floodSystemDD).length > 0 && floodSystemDD.PHONETYPE.map((v, i) => {
                                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="search-quote-form-group">
                                {t('Phone Number')}
                            </Form.Label>
                            <MaskedFormControl
                                type='text'
                                name='s_CommValue'
                                mask="(111)111-1111"
                                placeholder={t('Phone Number')}
                                title={t('Phone Number')}
                                parentobject="personPhoneContact"
                                value={phone}
                                onChange={(e) => {
                                    handleChange(e);
                                    setPhone(e.currentTarget.value);
                                }}
                                className="dashboard-group-input-flood"
                                required
                                disabled={isBind}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('Is the Policyholder/Member the owner or tenant')}?
                            </Form.Label>
                            <Form.Select
                                className="table-show-entries-select"
                                name="s_isInsuredTenant"
                                parentobject="propertyInfo"
                                title={t('Is the Insured the owner or tenant')}
                                value={isInsuredTenant}
                                onChange={(e) => {
                                    handleChange(e);
                                    setIsInsuredTenant(e.currentTarget.value);
                                }}
                                required
                                disabled={isBind}
                            >
                                <option value={''}>{t('Select')}</option>
                                {
                                    Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODINSUREDTYPE.map((v, i) => {
                                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('Is the policy a rental property')}?
                            </Form.Label>
                            <Form.Select
                                className="table-show-entries-select"
                                name="s_RentalProperty"
                                parentobject="propertyInfo"
                                title={t('Is the policy a rental property')}
                                value={isRentalProp}
                                onChange={(e) => {
                                    handleChange(e);
                                    setIsRentalProp(e.currentTarget.value);
                                }}
                                required
                                disabled={isBind}
                            >
                                <option value={''}>{t('Select')}</option>
                                {
                                    Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label" >
                                {t('Is the Policyholder/Member a non-profit entity')}?
                            </Form.Label>
                            <Form.Select
                                className="table-show-entries-select"
                                name="s_IsInsuredNonProfitEnt"
                                parentobject="propertyInfo"
                                title={t('Is the policy a rental property')}
                                value={isInsuredNonProfitEnt}
                                onChange={(e) => {
                                    handleChange(e);
                                    setIsInsuredNonProfitEnt(e.currentTarget.value);
                                }}
                                required
                                disabled={isBind}
                            >
                                <option value={''}>{t('Select')}</option>
                                {
                                    Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            {/* isSmallBusinessEmp */}
                            <Form.Label className="dashboard-group-label">
                                {t('Is the Policyholder/Member a small business with less than 100 employees')}?
                            </Form.Label>
                            <Form.Select
                                className="table-show-entries-select"
                                name="s_IsSmallBusinessEmp"
                                parentobject="propertyInfo"
                                title={t('Is the insured a small business with less than 100 employees')}
                                value={isSmallBusinessEmp}
                                onChange={(e) => {
                                    handleChange(e);
                                    setIsSmallBusinessEmp(e.currentTarget.value);
                                }}
                                required
                                disabled={isBind}
                            >
                                <option value={''}>{t('Select')}</option>
                                {
                                    Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                    })
                                }

                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                {t('Is the policyholder/Member a condominium association')}?
                            </Form.Label>
                            <Form.Select
                                className="table-show-entries-select"
                                name="s_CondoOwnership"
                                parentobject="propertyInfo"
                                title={t('Is the policyholder a condominium association')}
                                value={condoAssociation}
                                onChange={(e) => {
                                    handleChange(e);
                                    setCondoAssociation(e.currentTarget.value);
                                }}
                                required
                                disabled={isBind}
                            >
                                <option value={''}>{t('Select')}</option>
                                {
                                    Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                    })
                                }

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Row>
                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t('Mailing Address same as the home address')}?
                                </Form.Label>
                                <div
                                    style={{ display: "flex", justifyContent: "flex-start" }}
                                    className="custom-radio-check0"
                                >
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        className="flood-construction-row-switch"
                                        label=""
                                        parentobject="personInfo"
                                        name="s_IsMailingDiff"
                                        checked={isMaillingAddrDiffer === 'NO' ? true : false}
                                        onChange={(e) => { handleSwitch(e, 'isMaillingAddrDiffer') }}
                                        checkedvalue='NO'
                                        uncheckedvalue='YES'
                                        disabled={isBind}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t('Assign Temporary mailing address')}?
                                </Form.Label>
                                <div
                                    style={{ display: "flex", justifyContent: "flex-start" }}
                                    className="custom-radio-check0"
                                >
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        className="flood-construction-row-switch"
                                        label=""
                                        parentobject="personInfo"
                                        name="s_IsTempMailing"
                                        checked={isTempMailing === 'YES' ? true : false}
                                        onChange={(e) => { handleSwitch(e, 'isTempMailing') }}
                                        checkedvalue='YES'
                                        uncheckedvalue='NO'
                                        disabled={isBind}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t('Does the policy have a Mortgage')}?
                                </Form.Label>
                                <div
                                    style={{ display: "flex", justifyContent: "flex-start" }}
                                    className="custom-radio-check0"
                                >
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        className="flood-construction-row-switch"
                                        label=""
                                        parentobject="propertyInfo"
                                        name="s_IsPolicyMortgage"
                                        checked={isPolicyMortgage === 'YES' ? true : false}
                                        onChange={(e) => { handleSwitch(e, 'isPolicyMortgage') }}
                                        checkedvalue='YES'
                                        uncheckedvalue='NO'
                                        disabled={isBind}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t('List Additional Policyholders/Members')}?
                                </Form.Label>
                                <div
                                    style={{ display: "flex", justifyContent: "flex-start" }}
                                    className="custom-radio-check0"
                                >
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        className="flood-construction-row-switch"
                                        label=""
                                        parentobject="propertyInfo"
                                        name="s_IsAddInsureds"
                                        checked={isAddInsureds === 'YES' ? true : false}
                                        onChange={(e) => { handleSwitch(e, 'isAddInsureds') }}
                                        checkedvalue='YES'
                                        uncheckedvalue='NO'
                                        disabled={isBind}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    {
                        isMaillingAddrDiffer === 'YES' && (
                            <>
                                {/* Mailing Address */}
                                <MailingAddress emptyQuoteData={emptyQuoteData} handleChange={handleChange} isDataChanged={isDataChanged} isBind={isBind} />
                            </>
                        )
                    }
                    {
                        isTempMailing === 'YES' && (
                            <>
                                {/* Temp Mailing Address */}
                                <TempMailingAddress emptyQuoteData={emptyQuoteData} handleChange={handleChange} isDataChanged={isDataChanged} isBind={isBind} />
                            </>
                        )
                    }
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                            <GoogleMaps from='QUOTE' />
                        </Col>
                        <Col  xs="12" sm="12" md="12" lg="6" xl="6">
                            <Form.Label
                                className="dashboard-group-label"

                            >
                                <FaInfoCircle
                                    data-tip
                                    data-for="homesteadInfo"
                                    className="info-icon"
                                    style={{ marginLeft: '8px', cursor: 'pointer', color: '#3572b0', position: 'relative', fontSize: "20px", paddingBottom: "2px" }}
                                />
                                <ReactTooltip id="homesteadInfo" place="top" effect="solid" type='info' multiline={true} >
                                    <center>
                                        {t('Homestead value has been captured from community property tax Information.')}<br />
                                        {t('This assists to identify primary residence.')}<br />
                                        {t('Please adjust primary residence as needed.')}
                                    </center>
                                </ReactTooltip>
                                &nbsp;&nbsp;
                                {t('Is Insured Homestead?')}{" "}: {checkIsHomestead()}
                            </Form.Label>
                        <br />
                            {uuid ?
                                <Form.Label className="dashboard-group-label">
                                    {t('Selected Quote UUID')}{" "}:<br /> <Badge bg="warning" text='dark'>{uuid}</Badge>
                                </Form.Label>
                                : null}
                        </Col>
                    </Row>
                </Row>
            </Form>

            {/* Zip Details Modal */}
            {
                show && (
                    <Modal size="md" show={show} className="zip-details-modal">
                        <Modal.Body className="zip-details-modal-body">
                            {multipleCity}
                        </Modal.Body>
                    </Modal>
                )
            }
        </>
    )
}

export default Applicant