import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row ,Badge} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from "react-i18next";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { pivotRespLog } from "../../../../../services/floodService";
import ViewJson from "./ViewJson";
import { pivotRespLogFromCore } from "../../../../../services/coreSystemService";
import { toast } from "react-toastify";


const PivotLog = (props) => {
  const {selecteduuid} = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let { poTransPk } = useParams();
  const { from } = props; // quote/policy
  const [apiLoading, setApiLoading] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const [respLog, setRespLog] = useState([]);
  const [total, setTotal] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [viewJson, setViewJson] = useState(null);
  const [filter, setFilter] = useState({
    poTransPK: poTransPk || props.poTransPK,
    page: 1,
    pageLength: 10,
  });

  useEffect(() => {
    getRespLog();
  }, []);
  const getRespLog = async () => {
    const param = {
      page: filter.page,
      pageLength: filter.pageLength,
      poTransPK: poTransPk || props.poTransPK,
      ...(props?.uuid && { uuid: props.uuid }),
    };
    setApiLoading(true);
    try {
      if (from === "QUOTE_VIEW") {
        await dispatch(pivotRespLog(param)).then((res) => {
          setRespLog(res.data);
          setTotal(res.total);
        });
      }
      if (from === "POLICY_VIEW") {
        var code = "QUOTE_NEWBUSINESS";

        if (props.tranTypeCode === "NEWBUSINESS") {
          code = "QUOTE_NEWBUSINESS|CREATE_POLICY_2|CREATE_POLICY";
        } else if (props.tranTypeCode === "ENDORSE") {
          code = "QUOTE_ENDORSEMENT|POLICY_ENDORSEMENT_2|POLICY_ENDORSEMENT";
        } else if (props.tranTypeCode === "CANCEL") {
          code = "CANCEL_POLICY_2|CANCEL_POLICY";
        } else if (props.tranTypeCode === "RENEW") {
          code = "";
        }
        {
          props?.productType === "HISCOX"
            ? (param["code"] = "")
            : (param["code"] = code);
        }

        await dispatch(pivotRespLogFromCore(param))
          .then((res) => {
            if (res.success) {
              setRespLog(res.data.Data);
              setTotal(res.data.Count);
            } else {
              toast.error(res.data || t("Something Went Wrong"));
            }
          })
          .catch(() => {
            toast.error(t("Something Went Wrong"));
          });
      }
    }
    finally {
      setApiLoading(false);
      setIsChanged(false);
    }
  };

  useEffect(() => {
    if (isChanged) {
      getRespLog();
    }
  }, [filter.page, filter.pageLength]);

  const onTableChange = (type, { page, sizePerPage }) => {
    setFilter({ ...filter, page: page, pageLength: sizePerPage });
    setIsChanged(true);
  };

  const renderShowsTotal = (from, to, size) => (
    <span style={{ paddingLeft: 10 }}>
      Showing {from} to {to} of {size} Results
    </span>
  );

  // to check whether json string is parsable or not
  const isJsonParsable = (string) => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  };

  // common view option
  const jsonViewOption = (request, response) => {
    return (
      <React.Fragment>
        <a href={'#'} onClick={() => showFullJson(request)} >
          Request JSON
        </a>
        &nbsp;&nbsp;&nbsp;
        <a href={'#'} onClick={() => showFullJson(response)} >
          Response JSON
        </a>
      </React.Fragment>
    )
  }

  // list of error
  const listOfErrors = (request, response, objKey) => {
    const respObj = JSON.parse(response);

    return (
      <React.Fragment>
        <span style={{ color: 'red' }}>
          {respObj['message']}
        </span>
        {
          respObj[objKey] &&
          <ul>
            {
              respObj[objKey].map((val, key) => {
                let text = val.field + ' - ' + val.message;
                if (objKey === 'ruleFailures') text = val.exception
                return (
                  <li key={key}>{text}</li>
                )
              })
            }
          </ul>
        }
        {
          jsonViewOption(request, response)
        }
      </React.Fragment>
    );
  }

  // To Format Response (Error / Success)
  const respFormat = (cell, row) => {
    // Only For Error Response Log
    if (row.status === "ERROR") {
      if (row.errorResponse) {
        // Check if errorResponse is a valid JSON
        if (isJsonParsable(row.errorResponse)) {
          const errorResponseObj = JSON.parse(row.errorResponse);
          if (errorResponseObj.length > 0) {
            return (
              <React.Fragment>
                <span>
                  {errorResponseObj[0].message}
                </span>
                {
                  jsonViewOption(row.request, row.errorResponse)
                }
              </React.Fragment>
            )
          } else {
            var log = null;
            // Input Validation Message
            if (errorResponseObj.fieldErrors && errorResponseObj.fieldErrors.length > 0) {
              log = listOfErrors(row.request, row.errorResponse, 'fieldErrors')
            }

            // Rule Validation Message
            if (errorResponseObj.ruleFailures && errorResponseObj.ruleFailures.length > 0) {
              log = listOfErrors(row.request, row.errorResponse, 'ruleFailures')
            }
            return log;
          }
        } else {
          // Display the error response as is
          return <span>{row.errorResponse}</span>;
        }
      }
      else if (props?.productType === "HISCOX") {
        const errorResponseObj = JSON.parse(row.response);
        if (errorResponseObj?.messages) {
          // Display error message
          return (
            <>
              <span style={{ color: "red" }}>{errorResponseObj.messages?.validation.join(",") || errorResponseObj?.messages?.underwriterDecisions.join(",") || errorResponseObj?.messages?.errors.join(",")}</span>
              <br />
              {
                jsonViewOption(row.request, row.response)
              }
            </>
          );
        }
      }
    }

    // Handle Success Response
    if (row.status === "SUCCESS") {
      if (row.response && Object.keys(JSON.parse(row.response)).length > 0) {
        const jsonResponse = JSON.parse(row.response);
        const uuid = jsonResponse?.policyQuoteDetails?.uuid;
        let highlightStyle ='';
        if (selecteduuid === uuid) {
          highlightStyle ="warning";
          
        }
        return (
          <React.Fragment>
            <span style={{ color: "green" }}>
              {props?.productType === "HISCOX"
                ? row.display_name
                : JSON.parse(row.response).message}
            </span>
            <br />
            {uuid && (
              <Badge bg ={ highlightStyle=== "warning" ? "warning" : "transparent"} text={"dark"} style={{fontSize:"medium"}}>
                UUID: {uuid}
              </Badge>
            )}
             <br />
             <br />
            {
              jsonViewOption(row.request, row.response)
            }
          </React.Fragment>
        );
      }
    }
  };

  // To Show Full Json
  const showFullJson = (json) => {
    setViewJson(<ViewJson json={json} setViewJson={setViewJson} />);
  };

  const columns = [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "errorResponse",
      text: t("Response"),
      formatter: respFormat,
    },
    {
      dataField: "status",
      text: t("Status"),
    },
    {
      dataField: "d_CreatedDate",
      text: t("Date"),
    },
  ];

  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    pageStartIndex: 1,
    totalSize: total,
    showTotal: true,
    paginationTotalRenderer: renderShowsTotal,
  };

  const tableView = () => {
    return (
      <Row style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          style={{ overflowX: "scroll" }}
        >
          <BootstrapTable
            wrapperClasses="dashboard-table-main"
            bootstrap4
            remote
            keyField="s_POAppNo"
            data={respLog}
            columns={columns}
            hover
            pagination={paginationFactory(options)}
            onTableChange={onTableChange}
            noDataIndication={t("No data to display")}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      {apiLoading && <Loader loading={apiLoading} />}
      {from === "POLICY_VIEW" && tableView()}
      {from === "QUOTE_VIEW" && (
        <Modal className="custom-dashboard-modal" size="lg" show={isShow}>
          <Modal.Header
            closeButton
            className="custom-dashboard-modal-header"
            onClick={() => {
              setIsShow(false);
              props.showPivotLog(null);
            }}
          >
            <Modal.Title>
              {props.productType == "HISCOX"
                ? "API Logs"
                : "Pivot Api Response Logs"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-dashboard-modal-body">
            <LoadingOverlay>
              <Loader loading={apiLoading} />
              {tableView()}
            </LoadingOverlay>
          </Modal.Body>
        </Modal>
      )}
      {viewJson}
    </>
  );
};

export default PivotLog;