import React, { useEffect, useState } from "react";
import { Text, Button, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const ICON_MAP = {
  NONE: "/starImages/miniIcons/none.svg",
  VIDEOSURVEILLANCE: "/starImages/miniIcons/videoSurveillance.svg",
  SECURITYGUARD: "/starImages/miniIcons/securityGuard.svg",
  VIDEOANDSECURITYGUARD: "/starImages/miniIcons/videoAndSecurityGuard.svg",
};

const DEFAULT_ICON = "/starImages/miniIcons/none.svg";

const SecurityServiceCard = ({ name, icon, isSelected, onClick }) => (
  <Button
    width="full"
    justifyContent={"flex-start"}
    borderRadius={"xl"}
    variant={isSelected ? "outline" : "outline"}
    colorScheme={isSelected ? "primary" : "gray"}
    py={6}
    leftIcon={<Image src={icon || DEFAULT_ICON} width="2em" height="1.5em" />}
    onClick={onClick}
  >
    {name}
  </Button>
);

export const SecurityServiceSelection = ({ index }) => {
  const { buildingDetails, setBuildingDetails, baseDD } = useQuote();
  const { t } = useTranslation();
  const [securityServices, setSecurityServices] = useState([]);

  useEffect(() => {
    const servicesWithIcons = baseDD?.COMSECURITYSERVICE?.map((service) => ({
      name: service.s_AppCodeNameForDisplay,
      icon: ICON_MAP[service.s_AppCodeName] || DEFAULT_ICON,
      value: service.s_AppCodeName,
    }));

    setSecurityServices(servicesWithIcons);
  }, [baseDD]);

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  const handleSelection = (name) => {
    updateBuildingDetails(index, "securityService", name);
  };

  return (
    <>
      <Flex gap={2} my={0}>
        <Text fontWeight="500">{t("Security Service")}:</Text>{" "}
        {buildingDetails[index]?.securityService && (
          <Text fontWeight="500" color={"neutral.300"}>
            ({" "}
            {baseDD?.COMSECURITYSERVICE?.find(
              (service) =>
                service.s_AppCodeName ===
                buildingDetails[index]?.securityService
            )?.s_AppCodeNameForDisplay ||
              buildingDetails[index]?.securityService}{" "}
            )
          </Text>
        )}
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={4} align="stretch">
        {securityServices?.map(({ name, icon, value }) => (
          <GridItem key={name}>
            <SecurityServiceCard
              name={name}
              icon={icon}
              isSelected={buildingDetails[index]?.securityService === value}
              onClick={() => handleSelection(value)}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
