import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Underwriting } from "../Underwriting/Underwriting";
import { BuissnessEstablisments } from "../Policy/BuissnessEstablisments";
import TitleHeader from "../TitleHeader";
import { TermsAdnConditons } from "./TermsAndConditions";
import Mortgagee from "./Mortgagee";

export const Confirmations = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Underwriting />
      <Box mt={5}>
        <TitleHeader title={t("Policy Details")}>
          <BuissnessEstablisments />
        </TitleHeader>
        <Mortgagee />
        <Box mt={4}>
          <TermsAdnConditons />
        </Box>
      </Box>
    </Box>
  );
};
