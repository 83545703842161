import React, { useEffect, useState } from "react";
import { Text, Button, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const ICON_MAP = {
  "24HOURS": "/starImages/miniIcons/24Hours.svg",
  ALLOTHER: "/starImages/miniIcons/allOtherClosingHours.svg",
};

const DEFAULT_ICON = "/starImages/miniIcons/allOtherClosingHours.svg";

const ClosingHourCard = ({ name, icon, isSelected, onClick }) => (
  <Button
    width="full"
    justifyContent={"flex-start"}
    borderRadius={"xl"}
    variant={isSelected ? "outline" : "outline"}
    colorScheme={isSelected ? "primary" : "gray"}
    py={6}
    leftIcon={<Image src={icon || DEFAULT_ICON} width="2em" height="1.5em" />}
    onClick={onClick}
  >
    {name}
  </Button>
);

export const LatestClosingHoursSelection = ({ index }) => {
  const { buildingDetails, setBuildingDetails, baseDD } = useQuote();
  const { t } = useTranslation();
  const [closingHoursOptions, setClosingHoursOptions] = useState([]);

  useEffect(() => {
    const closingHoursWithIcons = baseDD?.COMCLOSINGHOURS?.map((option) => ({
      name: option.s_AppCodeNameForDisplay,
      icon: ICON_MAP[option.s_AppCodeName] || DEFAULT_ICON,
      value: option.s_AppCodeName,
    }));

    setClosingHoursOptions(closingHoursWithIcons);
  }, [baseDD]);

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  const handleSelection = (name) => {
    updateBuildingDetails(index, "closingHour", name);
  };

  return (
    <>
      <Flex flexDir={"column"} w={"100%"}>
        <Flex gap={2} my={0}>
          <Text fontWeight="500">{t("Latest Closing Hours")}:</Text>{" "}
          {buildingDetails[index]?.closingHour && (
            <Text fontWeight="500" color={"neutral.300"}>
              ({" "}
              {baseDD?.COMCLOSINGHOURS?.find(
                (option) =>
                  option.s_AppCodeName === buildingDetails[index]?.closingHour
              )?.s_AppCodeNameForDisplay ||
                buildingDetails[index]?.closingHour}{" "}
              )
            </Text>
          )}
        </Flex>
        <Grid templateColumns="repeat(4, 1fr)" gap={4} align="stretch">
          {closingHoursOptions?.map(({ name, icon, value }) => (
            <GridItem key={name}>
              <ClosingHourCard
                name={name}
                icon={icon}
                isSelected={buildingDetails[index]?.closingHour === value}
                onClick={() => handleSelection(value)}
              />
            </GridItem>
          ))}
        </Grid>
      </Flex>
    </>
  );
};
