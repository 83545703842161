import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isEmpty } from 'lodash';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import paginationFactory from "react-bootstrap-table2-paginator";
import { quickSearchAdvance, getTransactionData } from "../../../services/dashboardService";
import Modal from 'react-bootstrap/Modal';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { verifyPolicy, setPolicyPropsData, getBaseData } from "../../../services/coreSystemService";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";

const AdvanceSearchQuick = (props) => {
    const text = props.text;
    const [advancePage, setAdvancePage] = useState(false);
    const [dataSearch, setDataSearch] = useState([]);
    const navigation = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({
        page: 1,
        page_size: 10,
    });
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [link, setLink] = useState('#');
    const handleClose = () => setShowDownloadModal(false);
    const handleShow = () => setShowDownloadModal(true);

    useEffect(() => {
        if (props.text) {
            getAgentSearchAllTasks();
        }
    }, [props.text, filter.page, filter.page_size]);

    const policyNoColumnFormatter = (cell, row) => {
        if (row) {
            return (
                <a href="#" onClick={() => verify(row)}>
                    {cell}
                </a>

            );
        } else {
            return row.policy_no;
        }
    };
    const verify = async (row) => {
        var params = {}
        setLoading(true);
        let policyStatus = false;
        if (row.policy_no.includes("TMP") || (row.policy_status == "QUOTE" || row.policy_status == "APPLICATION")) {
            await dispatch(getTransactionData(row.policy_no)).then((res) => {
                if(isEmpty(res)){
                    toast.error("Policy Not Found");
                    setLoading(false);
                    return false;
                }else{
                    if (res.n_ProductId_FK === 10) {
                        navigation(`/flood/quote/${res.n_potransaction_PK}`);
                        setLoading(false);
                        window.location.reload();
                    }
                }
            })
        } else {
            await dispatch(verifyPolicy(row.policy_no)).then((res) => {
                policyStatus = res.status;
                if (!policyStatus) {
                    toast.error(res.message);
                    setLoading(false);
                    return false;
                }
                else {
                    if (res.policyIdPK && res.status) {
                        params = {
                            n_POPolicyMasterFK: res.policyIdPK,
                            n_POTermMasterFK: res.termMasterPK,
                            n_POTransactionFK: res.poTransPK,
                        };
                    }
                    else {
                        navigation(`/core/policy`);
                    }
                }

            });
            if (policyStatus) {
                await dispatch(setPolicyPropsData(params));
                await dispatch(
                    getBaseData(params.n_POTransactionFK)
                );
                navigation(`/core/policy/${row.policy_no}`);
                setLoading(false);
                window.location.reload();
            }
        }
    };

    const formatAddress = (cell, row) => {
        return row.address_line1 + ', ' + row.city + ', ' + row.county + ', ' + row.state + ', ' + row.zip_code
    }

    const advancedPage = () => {
        setAdvancePage(true);
        navigation("/advanced-search");
    }

    const options = {
        page: filter.page,
        page_size: filter.page_size,
        sortIndicator: false,
        pageStartIndex: 1,
        totalSize: dataSearch.length,
        totalSize: total,
        showTotal: true,
    };

    const getAgentSearchAllTasks = async () => {
        let Url = "";
        Url = Url.concat("text=", text, "&")
        Url = Url.concat("paginate=", "true", "&")
        setLoading(true);
        let advanceData = await dispatch(quickSearchAdvance(Url)).catch(() => {
            setLoading(false);
        });
        setDataSearch(advanceData.data.data.data);
        setTotal(advanceData.data.data.total);
        setLoading(false);
    }

    const navigateToPolicy = (policyNo) => {
        navigation(`/core/policy/${policyNo}`)
        window.location.reload()
    }

    // const formatInsuredName = (cell, row) => {
    //     return row.first_name + ' ' + row.last_name
    // }
    const columns = [
        // {
        //     dataField: "name",
        //     text: t("Search Match"),
        // },
        {
            dataField: "policy_no",
            text: t("Policy/Quote Number"),
            formatter: (value, row) => policyNoColumnFormatter(value, row),
            sort: true,
            style: {
                textAlign: "left"
            }

        },
        {
            dataField: "policy_status",
            text: t("Status"),
            sort: true,
            style: {
                textAlign: "left"
            }
        },
        {
            dataField: "address_line1",
            text: t("Property Address"),
            formatter: (value, row) => formatAddress(value, row),
            sort: true,
            style: {
                textAlign: "left"
            }
        },
        {
            dataField: "full_name",
            text: t("Insured Name"),
            // formatter: (value, row) => formatInsuredName(value, row),
            sort: true,
            style: {
                textAlign: "left"
            }
        },
        {
            dataField: "effective_date",
            text: t("Effective Date"),
            sort: true,
            style: {
                textAlign: "left"
            }
        }
    ]

    const exportToCSV = async () => {
        var payload = "text=" + text + "&is_csv=true";
        setLoading(true);
        let csv = await dispatch(quickSearchAdvance(payload))
        if (csv.status === 200) {
            setLink(csv.data.data.url)
            handleShow();
        }
        setLoading(false);
    }

    const emptyDataMessage = () => { return 'No Data to Display'; }

    const DataSearchField = dataSearch;
    const DataSearchResult = (DataSearchField.length == 0 ? <h5>{t("Try")}</h5> : DataSearchField.length <= 10 ? <h5>{t("Don't see what are you looking for? try")}</h5> : "")

    return (
        Object.keys(props).length > 0 ? <>
            <LoadingOverlay>
                <Loader loading={loading} />
                <div className="add-mortage-table-div">

                    <Card>
                        <div >
                            {  DataSearchField.length > 10 ? <div style={{display: "flex", alignItems: "center"}}>{DataSearchResult} &nbsp;&nbsp; <h5 onClick={advancedPage} style={{ color: "blue", cursor: "pointer" }}>{t('Refine Results')}</h5></div> : <div style={{display: "flex", alignItems: "center"}}>{DataSearchResult} &nbsp;&nbsp; <h5 onClick={advancedPage} style={{ color: "blue", cursor: "pointer" }}>{t('Advanced Search')}</h5></div>}
                            {dataSearch.length > 0 ? <div classname="table-main-title-div" style={{ float: "right", marginRight: "2%" }}>
                                <Button className="btnCustom btnCustom-info pr-4 pl-4" style={{ border: "1px solid", fontSize: "15px" }} size="sm" color="primary" onClick={() => exportToCSV()}>Export To CSV</Button>
                            </div> : null}
                        </div>
                        <Table className="add-mortage-table" borderless responsive>
                            <br />
                            <Col xs="12">
                                <BootstrapTable
                                    wrapperClasses="dashboard-table-main"
                                    bootstrap4
                                    keyField="policy_no"
                                    columns={columns}
                                    data={dataSearch}
                                    hover
                                    bordered={false}
                                    pagination={paginationFactory(options)}
                                    noDataIndication={emptyDataMessage}
                                />
                            </Col>
                        </Table>
                    </Card>
                </div>
            </LoadingOverlay>

            <Modal show={showDownloadModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Search List CSV File</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to download file ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Discard
                    </Button>
                    <Button variant="primary" href={link} target="_blank" onClick={handleClose}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </> : null
    )
}

export default AdvanceSearchQuick;