import React, { useState, useRef, useMemo } from 'react';
// import BootstrapTable from "react-bootstrap-table-next";
// import { Col } from "react-bootstrap";
import { CSVLink } from 'react-csv';
import { AiOutlineEye } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";
import "../dashboard.scss";
// import { getPolicyListData } from "../../../services/dashboardService";
import { verifyPolicy, resetCoreStore, setPolicyPropsData, getBaseData } from "../../../services/coreSystemService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch } from 'react-redux';
// import paginationFactory from "react-bootstrap-table2-paginator";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import { BsArrowLeftSquare } from "react-icons/bs";
import { useNavigate,useLocation  } from 'react-router-dom';
// import ReactTooltip from "react-tooltip";
import { Box, Button, Flex, Select, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
import AdvancedGridTable from "../../../components/AdvancedGridTable";
import { FaFileDownload } from "react-icons/fa";
import { formateDate } from '../../common/FormatDate';
import NumberCell from "../NumberClick";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const devKey = process.env.REACT_APP_NODE_ENV;
const isEnabledDataToggleOptionForDashboardData = process.env.REACT_APP_EMABLED_DATA_TOGGLE_OPTION_FOR_DASHBOARD_DATA;  

const ApplicationDashboard = () => {
    const { t } = useTranslation();
    const gridRef = useRef(null);
    const [nameOption, setNameOption] = useState("self");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const  location  = useLocation();
    const [ApplicationData, setApplicationData] = useState(location.state);
    // const [filter, setFilter] = useState({
    //     page: 1,
    //     sizePerPage: 10,
    // });
    // const [total, setTotal] = useState(0);
    const navigation = useNavigate();
    // useEffect(() => {
        // if(devKey !== "PROD"){
            // ApplicationPolicyData();
        // }
    // }, [filter.page, filter.sizePerPage])

    const headers = [
        { label: "Application/Quote Number", key: "Policy_No" },
        { label: "Requested Effective Date", key: "d_TransEffectiveFrom" },
        { label: "Policyholder/Member Name", key: "d_PolicyOwnerDBAName" },
        // { label: "Property Address", key: "property_address" },
        // { label: "Status", key: "s_PolicyStatusCode" },
    ]

    // const columnFormatterAction = (cell, row) => {
    //     if (row && row.Policy_No) {
    //         return (
    //             <>
    //                 <AiOutlineEye size={26} style={{ backgroundColor: "#66D7CD", border: "1px solid #66D7CD", cursor: "pointer" }} onClick={() => urlBuilder(row)} data-tip data-for="view">
    //                     {cell}
    //                 </AiOutlineEye>&nbsp;
    //                 <ReactTooltip id="view" place="top" effect="solid">
    //                     View
    //                 </ReactTooltip>
    //                 <BsCurrencyDollar size={26} style={{ backgroundColor: "#66D7CD", border: "1px solid #66D7CD", cursor: "pointer" }} onClick={() => urlBuilder(row)} data-tip data-for="dollar">
    //                     {cell}
    //                 </BsCurrencyDollar>&nbsp;
    //                 <ReactTooltip id="dollar" place="top" effect="solid">
    //                     Submit Payment
    //                 </ReactTooltip>
    //             </>
    //         )
    //     } else {
    //         return row.Policy_No;
    //     }
    // }

    const urlBuilder = async (row) => {
        await dispatch(verifyPolicy(row.Policy_No)).then((res) => {
            if (res.status) {
                dispatch(resetCoreStore());
                const params = {
                    n_POPolicyMasterFK: res.policyIdPK,
                    n_POTermMasterFK: res.termMasterPK,
                    n_POTransactionFK: res.poTransPK,
                }
                dispatch(setPolicyPropsData(params));
                dispatch(
                    getBaseData(res.productFK)
                );
                navigation(`/core/policy/${row.Policy_No}`);
            }
        })
    }

    // const columns = [
    //     {
    //         dataField: "Policy_No",
    //         text: "Application/Quote Number",
    //         style: {
    //             width: "12%"
    //         },
    //         sort: true
    //     },
    //     {
    //         dataField: "d_TransEffectiveFrom",
    //         text: "Request Effective Date",
    //         style: {
    //             width: "12%"
    //         },
    //         sort: true
    //     },
    //     {
    //         dataField: "d_PolicyOwnerDBAName",
    //         text: "Policyholder Name",
    //         style: {
    //             width: "12%"
    //         },
    //         sort: true
    //     },
    //     // {
    //     //     dataField: "s_AddressLine1",
    //     //     text: "Property Address",
    //     //     formatter: (value, row) => {
    //     //         return value + ' ' + row.s_CountyName + ' ' + row.s_CityName + ' ' + row.s_StateCode + ' ' + row.s_PostalCode
    //     //     },
    //     //     style: {
    //     //         width: "40%"
    //     //     },
    //     // },
    //     // {
    //     //     dataField: "s_PolicyStatusCode",
    //     //     text: "Status",
    //     //     style: {
    //     //         width: "12%"
    //     //     },
    //     // },
    //     {
    //         dataField: "",
    //         text: "Action",
    //         formatter: columnFormatterAction
    //     },
    // ]

    // const handlePageChange = (page, sizePerPage) => {
    //     setFilter({ ...filter, page: page, sizePerPage: sizePerPage });
    // };

    // const sizePerPageListChange = (page, sizePerPage) => {
    //     setFilter({ ...filter, sizePerPage: sizePerPage });
    // };

    // const onTableChange = (type, { page, sizePerPage }) => {
    //     setFilter({ ...filter, page: page, sizePerPage: sizePerPage });
    // };

    // const renderShowsTotal = (from, to, size) => (
    //     <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
    // );

    // const options = {
    //     page: filter.page,
    //     sizePerPage: filter.sizePerPage,
    //     sortIndicator: false,
    //     pageStartIndex: 1,
    //     totalSize: ApplicationData.count,
    //     totalSize: total,
    //     showTotal: true,
    //     onPageChange: handlePageChange,
    //     onSizePerPageChange: sizePerPageListChange,
    //     paginationTotalRenderer: renderShowsTotal,
    // };

    // const ApplicationPolicyData = async (type = nameOption, transType = "NEWBUSINESS", page = filter.page, sizePerPage = filter.sizePerPage) => {
    //     await dispatch(getPolicyListData(type, transType, sizePerPage, page)).then((res) => {
    //         setApplicationData(res.data.data);
    //         setTotal(res.data.count);
    //     })
    //     setLoading(false);
    // }

    const namelist = [
        { value: 'all', label: 'All Data' },
        { value: 'self', label: 'My Data' }
    ]

    const namelistdata = (e) => {
        setNameOption(e.target.value);
        // ApplicationPolicyData(e.target.value);
    }

    const homePage = () => {
        navigation("/home");
    }

    const onNumberClick = (params) => {
        navigation(`/core/policy/${params.data.Policy_No}`);
    };

    const ActionCellRenderer = (params) => {
        const rowData = params.data;
        if (rowData && rowData.Policy_No) {
            return (
                <Flex align={'center'} pt={'4px'}>
                    <Tooltip hasArrow label='View' placement='top'>
                        <Button mr={2} size={'sm'} onClick={() => urlBuilder(rowData)}>
                            <AiOutlineEye size={'20px'} />
                        </Button>
                    </Tooltip>
                    <Tooltip hasArrow label='Submit Payment' placement='top'>
                        <Button size={'sm'} onClick={() => urlBuilder(rowData)}>
                            <BsCurrencyDollar size={'20px'} />
                        </Button>
                    </Tooltip>
                </Flex>
            );
        } else {
            return rowData.Policy_No;
        }
    };

    const defaultColDef = useMemo(() => ({
        resizable: true,
        sortable: true,
        floatingFilter: true,
        filter: true,
        flex: 1,
        wrapText: true,
        autoHeight: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        suppressHeaderMenuButton: true,
        suppressFloatingFilterButton: true,
    }),[]);

    const [columnDefs] = useState([
        {
            headerName: "Application/Quote Number",
            field: "Policy_No",
            filter: "agTextColumnFilter",
            minWidth: 250,
            cellStyle: {
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
            },
            cellRenderer: (params) => (
                <NumberCell
                    value={params.value}
                    onNumberClick={() => onNumberClick(params)}
                />
            ),
        },
        {
            headerName: "Request Effective Date",
            field: "d_TransEffectiveFrom",
            // filter: "agDateColumnFilter",
            floatingFilter: false,
            minWidth: 200,
            valueFormatter: (params) => formateDate(params.value),
        },
        {
            headerName: "Policyholder Name",
            field: "d_PolicyOwnerDBAName",
            filter: "agTextColumnFilter",
            minWidth: 200,
        },
        {
            headerName: "Action",
            field: "",
            filter: false,
            sortable: false,
            minWidth: 150,
            cellRenderer: ActionCellRenderer,
        },
    ]);

    return (
      <>
        <LoadingOverlay>
          <Loader loading={loading} />
          <Box mb={8}>
            <Flex mb={4} justifyContent={"space-between"}>
              <Flex>
                <Text mb={0} fontWeight={600} fontSize="2xl">
                  {t("Application")}
                </Text>
              </Flex>
              <Flex direction={"row"} align={"center"}>
                {isEnabledDataToggleOptionForDashboardData == "SHOW" ? (
                  <>
                    <Select
                      size={"sm"}
                      mr={4}
                      value={nameOption}
                      onChange={(e) => namelistdata(e)}
                    >
                      {namelist &&
                        namelist.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                    </Select>
                  </>
                ) : (
                  ""
                )}
                <Box>
                  <Button
                    mr={4}
                    leftIcon={<MdOutlineKeyboardBackspace />}
                    size={"sm"}
                    onClick={homePage}
                  >
                    {t("Back")}
                  </Button>
                </Box>
                <CSVLink
                  data={location.state.records}
                  headers={headers}
                  filename={"Application_Data.csv"}
                >
                  <Button leftIcon={<FaFileDownload />} size="sm">
                    {t("Export To CSV")}
                  </Button>
                </CSVLink>
              </Flex>
            </Flex>
            <Box
              bgColor={"white"}
              boxShadow={"lg"}
              p={5}
              borderRadius={"2xl"}
              borderColor={"white"}
            >
              <Box
                className="ag-theme-alpine"
                h={"70vh"}
                minHeight={"400px"}
                maxHeight={"600px"}
                id="myGrid"
                w={"100%"}
              >
                <AdvancedGridTable
                  gridRef={gridRef}
                  rowData={ApplicationData?.records ?? []}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  pagination={true}
                  paginationPageSize={10}
                  paginationPageSizeSelector={[10, 25, 50]}
                  enableCellTextSelection={true}
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </Box>
            </Box>
          </Box>
        </LoadingOverlay>
        {/* <Fragment>
            <LoadingOverlay>
                <Loader loading={loading} />
                <h4 className="dashboard-cards-table-title"> <BsArrowLeftSquare onClick={homePage} style={{ cursor: "pointer" }} /> &nbsp;&nbsp; Dashboard</h4>
                <Col xs="12" className="billing-tab-row2">
                    <div id="dashboard-table-section">
                        <div className="table-main-title-div">
                            <span className="table-main-title-name">  Application</span>
                            {
                                devKey !== "PROD" ? 
                                <>
                                    <span className="table-main-title-name" style={{ marginLeft: "50%" }}>
                                        <label htmlFor="">Select Data View</label>
                                    </span>
                                    <select style={{ width: "9%", padding: "8px" }} value={nameOption} onChange={(e) => namelistdata(e)}>
                                        {namelist && namelist.map((option) => (
                                            <option value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </>
                                : ""
                            }
                            <span className="table-main-title-name">
                                <CSVLink data={location.state.records} headers={headers} filename={'Application_Data.csv'}>
                                    <button className="btnCustom btnCustom-info pr-4 pl-4" style={{ border: "1px solid", fontSize: "15px" }} size="sm" color="primary">Export To CSV</button>
                                </CSVLink>
                            </span>
                        </div>
                        <div className="managment-tab-body" style={{ paddingBottom: "2rem" }}>
                            <BootstrapTable
                                wrapperClasses="dashboard-table-main"
                                bootstrap4
                                keyField="s_POAppNo"
                                remote
                                columns={columns}
                                data={ApplicationData.records}
                                hover
                                bordered={false}
                                pagination={paginationFactory(options)}
                                onTableChange={onTableChange}
                            />
                        </div>
                    </div>
                </Col>
            </LoadingOverlay>
        </Fragment> */}
      </>
    )
}

export default ApplicationDashboard