import Http from '../Http';
import * as action from '../store/actions';

export function getActivityListData(params) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/activity/list?filterCriteria=+${params.FilterCriteria}&filterCriteriaValue=+${params.FilterCriteriaValue}&readUnreadFlag=+${params.Display}&filterByFlag=+${params.FilterByFlag}&filterByFlagStatus=+${params.FilterByFlagStatus}&scheduledFrom=+${params.ScheduledFrom}&scheduledTo=+${params.ScheduledTo}&activityUserID=+${params.ActivityUserID}&activityPersonPK=+${params.ActivityPersonPK}&sourceType=+${params.sourceType}&sourceCode=+${params.sourceCode}&page=+${params.page}&pageLength=+${params.pageLength}`;
        Http.get(url)
            .then((res) => {
              dispatch(action.getActivityListData(res.data));
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function getMessageData(MessageInfo_PK) {
	const url = "/api/activity/getMessageData/"+MessageInfo_PK;
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.get(url)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}

export function getReplyData(MessageInfo_PK) {
	const url = "/api/activity/getReplyData/"+MessageInfo_PK;
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.get(url)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}

export function getActivitiesTypeData() {
	const url = "/api/activity/getActivityTypeDD";
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.get(url)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}

export function getDocumentsTypeData(module, submodule) {
	const url = "/api/activity/getDoctype/"+module+"/"+submodule;
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.get(url)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}

export function verifyActivityType(params) {
	const url = "/api/activity/verifyActivityType/"+params.ActivityType+"/"+params.MessageSourceCode;
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.get(url)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}

export function getActivitySave(params) {
	const url = "/api/activity/getActivitySave";
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.post(url, params)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}

export function getActivityFileSave(params) {
	const url = "/api/activity/getActivityFileSave";
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.post(url, params)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}

export function getusersListData(params) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/activity/agencyUsersList?AgencyIdPk=+${params.AgencyId_Pk}&authorisation=+${params.ActivityAuthorisation}&sourceType=+${params.sourceType}&searchKey=+${params.searchKey}&page=+${params.page}&pageLength=+${params.pageLength}`;
        Http.get(url)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function getChangeMultipleMessageUR(data) {
	return new Promise((resolve, reject) => {
	  const url	= `/api/activity/getChangeMultipleMessageUR`;
	  Http.post(url, data)
		.then(res => {
		  if (res.status === 200) {
			resolve(res);
		  } else {
			reject(res)
		  }
		})
	})
  }

export function getChangeMessageStatus(data) {
	return new Promise((resolve, reject) => {
		const url	= `/api/activity/changeMessageStatus`; 			
	  Http.post(url, data)
		.then(res => {
		  if (res.status === 200) {
			resolve(res);
		  } else {
			reject(res)
		  }
		}).catch((err) => {
			reject(err);
		})
	})
  }