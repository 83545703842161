import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { FaInbox, FaEye, FaPlus, FaFilter } from "react-icons/fa";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-overlay-loader/styles.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { getAgentTasksList } from  "../../../services/dashboardService";
import { useDispatch, connect } from "react-redux";
import {
  setPolicyPropsData, verifyPolicy, getBaseData, updateAgentTaskUserStatus
} from "../../../services/coreSystemService";
import { useNavigate } from "react-router-dom";

const AgentTasks = (props) => {
  const navigate = useNavigate();
  const { setLoading } = props;
  const [agentTasks, setAgentTasks] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 1,
    pageLength: 5,
  });

  const dispatch = useDispatch();
 
  useEffect(() => {
    getAgentAllTasks();
  }, [])

  useEffect(() => {
    getAgentAllTasks();
  }, [filter.page,filter.pageLength]);
  
  const getAgentAllTasks = async () => {
    const params = {
      limit: 0,
      page: filter.page,
      pageLength: filter.pageLength,
    }
    await dispatch(getAgentTasksList(params)).then((res)=>{
      setAgentTasks(res.data.data.taskdata);
      setTotal(res.data.data.total);
    })
  }

  const onTableChange = (type, { page, sizePerPage }) => {
    setFilter({ ...filter, page: page, pageLength: sizePerPage });
  }

  const columnFormatter = (cell, row) => {
    if (row && row.Policy_No) {
      return (
        <a href="#" onClick={() => verify(row.Policy_No, row.status)}>
          {cell}
        </a>
      );
    } else {
      return row.Policy_No;
    }
  };

  const verify = async (policyNumber, status) => {
    var params = {};
    let agentPortalTransPk = 0;
    setLoading(true);
    await dispatch(verifyPolicy(policyNumber)).then((res) => {
      if (res.status) {
        params = {
          n_POPolicyMasterFK: res.policyIdPK,
          n_POTermMasterFK: res.termMasterPK,
          n_POTransactionFK: res.poTransPK,
        };
        agentPortalTransPk = res.agentPortalTransPk
      } else {
        navigate(`/core/policy`);
      }
    }).catch(() => {
      setLoading(false);
    });
    await dispatch(setPolicyPropsData(params));
    await dispatch(
      getBaseData(params.n_POTransactionFK)
    );
    setLoading(false);
    /*
    * redirect all policies to policy screen (including TMP application policies)
    * @link https://taurus-llc.atlassian.net/browse/ODS-3990
    */
    navigate(`/core/policy/${policyNumber}`);
    window.location.reload();
  };

  const handleAction = async (e,row) => {
    setLoading(true);
    const status=e.target.value;
    await dispatch(updateAgentTaskUserStatus(status,row.id))
      .then((res) => {
        getAgentAllTasks();
      }).catch((err) => {
         
      });
    setLoading(false);
  }

  const taskColumns = [
    {
      dataField: "Policy_No",
      text: "Policy No",
      formatter: columnFormatter
    },
    {
      dataField: "s_PRTranTypeCode",
      text: "Transaction Type",
    },
    {
      dataField:"task_title",
      text:"Task List",
    },
    {
      dataField: "note",
      text: "Log Note",
    },
    {
      dataField: "created_at",
      text: "Date",
    },
    {
      dataField: "task",
      text: "Action",
      formatter: (cellContent, row) => {
        const defaultValue = 'SELECT';
        return (
         <div>
            <Form>
              <Form.Group controlId="Agent-action">
                <Form.Select onChange={(e) => handleAction(e, row)} style={{ margin: "auto", width: "150px" }}
                  value={defaultValue}>
                  <option value="SELECT">Select</option>
                  <option value="NOACTION">No Action</option>
                  <option value="DELETED">Deleted</option>
                  <option value="COMPLETED">Completed</option>
                </Form.Select>
              </Form.Group>
            </Form>
         </div>
        );
      }
    }
  ];
  
  const renderShowsTotal = (from, to, size) => (
    <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
  );

  const handlePageChange = (page, sizePerPage) => {
    setFilter({ ...filter, page: page, pageLength: sizePerPage });
  };

  const sizePerPageListChange = (page, sizePerPage) => {
    setFilter({ ...filter, pageLength: sizePerPage });
  };
  
  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    showTotal: true,
    totalSize: total,
    pageStartIndex: 1,
    paginationTotalRenderer: renderShowsTotal,
    onPageChange: handlePageChange,
    onSizePerPageChange: sizePerPageListChange,
  };
  return (
     <div>
      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">Task List</span>
          </div>
          <BootstrapTable
            wrapperClasses="dashboard-table-main"
            bootstrap4
            keyField="PolicyNo"
            data={agentTasks}
            columns={taskColumns}
            hover
            remote
            bordered={false}
            pagination={paginationFactory(options)}
            onTableChange={onTableChange}
          />
        </div>
      </Col>
     </div> 
  );
};

export default AgentTasks;

