import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { currencyFormat } from '../../../../../services/commonService';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { getPremiumDetails } from '../../../../../services/floodService';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

const PremiumDetails = (props) => {
    const dispatch = useDispatch();
    const { emptyQuoteData } = useSelector(state => state.flood);
    const [isLoading, setIsLoading] = useState(false);
    const [premiumDetails, setPremiumDetails] = useState({});

    useEffect(() => {
        var sourceID = '', isUuid = 'N';
        if (props.poTransPK && ![undefined, null, ''].includes(props.poTransPK)) {
            sourceID = props.poTransPK;
        } else if (Object.keys(emptyQuoteData).length > 0 && emptyQuoteData.uuid && ![null, ''].includes(emptyQuoteData.uuid)) {
            sourceID = emptyQuoteData.uuid; isUuid = 'Y';
        }
        (props.showPremium && sourceID !== '') && fetchDetails(sourceID, isUuid);
    }, [props.poTransPK, emptyQuoteData, props.showPremium])



    const fetchDetails = async (sourceID, isUuid) => {
        setIsLoading(true);
        await dispatch(getPremiumDetails(sourceID, isUuid))
            .then((res) => {
                if (res.status == 'Y') {
                    setPremiumDetails(res.premiumDetails);
                } else {
                    toast.error(res.msg);
                }
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
    }

    const makeView = () => {
        if (Object.keys(premiumDetails).length > 0) {
            return (
                <React.Fragment>
                    <Row className='p-0 m-0 text-center'>
                        <Col md="12" className='p-1' ><b className="text-center" >COMPONENTS OF THE TOTAL AMOUNT DUE</b></Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >BUILDING PREMIUM</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.buildingPremium)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >CONTENT PREMIUM</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.contentsPremium)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >INCREASED COST OF COMPLIANCE (ICC) PREMIUM</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.iccPremium)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >MITIGATION DISCOUNT</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.mitigationDiscounts)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >COMMUNITY RATING SYSTEM REDUCTION</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.crsDiscount)}</Col>
                    </Row>
                    <hr className='mt-0 mb-0' />
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >FULL RISK PREMIUM</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.fullRiskPremium)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >ANNUAL INCREASE CAP DISCOUNT</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.annualIncreaseCapDiscount)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >NEWLY MAPPED DISCOUNT</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.newlyMappedDiscount)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >OTHER STATUTORY DISCOUNT</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.otherStatutoryDiscounts)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >PRE-FIRM DISCOUNT</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.preFirmDiscount)}</Col>
                    </Row>
                    <hr className='mt-0 mb-0' />
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >DISCOUNTED PREMIUM</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.discountedPremium)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >RESERVE FUND ASSESSMENT</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.reserveFundAssessment)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >HFIAA SURCHARGE</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.hfiaaSurcharge)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >FEDERAL POLICY FEE</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.federalPolicyFee)}</Col>
                    </Row>
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} >PROBATION SURCHARGE</Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} >{premiumDetails && currencyFormat(premiumDetails.probationSurcharge)}</Col>
                    </Row>
                    <hr className='mt-0 mb-0' />
                    <Row className='p-0 pr-3 m-0'>
                        <Col md="10" className='p-0 pr-2' style={{ textAlign: 'right' }} ><b>TOTAL AMOUNT DUE</b></Col>
                        <Col md="2" className='p-0 pr-2' style={{ textAlign: 'right' }} ><b>{premiumDetails && currencyFormat(premiumDetails.totalAnnualPayment)}</b></Col>
                    </Row>
                </React.Fragment>
            );
        } else {
            return <h2 className="text-center">Records Not Found</h2>
        }

    }

    return (
        <Modal className="custom-dashboard-modal" show={props.showPremium}>
            <Modal.Header closeButton className="custom-dashboard-modal-header" onClick={() => props.setShowPremium(false)}>Premium</Modal.Header>
            <Modal.Body className="custom-dashboard-modal-body pl-2 pr-2">
                <LoadingOverlay >
                    <Loader loading={isLoading} />
                    {makeView()}
                </LoadingOverlay>
            </Modal.Body>
        </Modal>
    )
}

PremiumDetails.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(PremiumDetails);
