import React, { useEffect, useState } from "react";
import { Text, Button, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const ICON_MAP = {
  FRAME: "/starImages/miniIcons/frame.svg",
  JOISTEDMASONRY: "/starImages/miniIcons/joistedMasonry.svg",
  NONCOMBUSTIBLE: "/starImages/miniIcons/nonCombustible.svg",
  MASONRYNONCOMBUSTIBLE: "/starImages/miniIcons/masonryNonCombustile.svg",
  MODIFIEDFIRERESISTIVE: "/starImages/miniIcons/modifiedFireResistive.svg",
  FIRERESISTIVE: "/starImages/miniIcons/fireResistive.svg",
};

const DEFAULT_ICON = "/starImages/miniIcons/frame.svg";

const ConstructionTypeCard = ({ name, icon, isSelected, onClick }) => (
  <Button
    width="full"
    justifyContent={"flex-start"}
    borderRadius={"xl"}
    py={6}
    variant={isSelected ? "outline" : "outline"}
    colorScheme={isSelected ? "primary" : "gray"}
    leftIcon={<Image src={icon || DEFAULT_ICON} width="2em" height="1.5em" />}
    onClick={onClick}
  >
    {name}
  </Button>
);

export const ConstructionTypeSelection = ({ index }) => {
  const { buildingDetails, setBuildingDetails, baseDD } = useQuote();
  const { t } = useTranslation();
  const [constructionTypes, setConstructionTypes] = useState([]);

  useEffect(() => {
    const typesWithIcons = baseDD?.COMMCONSTRTYPE?.map((type) => ({
      name: type.s_AppCodeNameForDisplay,
      icon: ICON_MAP[type.s_AppCodeName] || DEFAULT_ICON,
      value: type.s_AppCodeName,
    }));

    setConstructionTypes(typesWithIcons);
  }, [baseDD]);

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  const handleSelection = (name) => {
    updateBuildingDetails(index, "constructionType", name);
  };

  return (
    <>
      <Flex gap={2} my={0}>
        <Text fontWeight="500">{t("Construction Type")}:</Text>{" "}
        {buildingDetails[index]?.constructionType && (
          <Text fontWeight="500" color={"neutral.300"}>
            ({" "}
            {baseDD?.COMMCONSTRTYPE?.find(
              (type) =>
                type.s_AppCodeName === buildingDetails[index]?.constructionType
            )?.s_AppCodeNameForDisplay ||
              buildingDetails[index]?.constructionType}{" "}
            )
          </Text>
        )}
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={4} align="stretch">
        {constructionTypes?.map(({ name, icon, value }) => (
          <GridItem key={name}>
            <ConstructionTypeCard
              name={name}
              icon={icon}
              isSelected={buildingDetails[index]?.constructionType === value}
              onClick={() => handleSelection(value)}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
