import { React, useEffect, useState, useRef } from "react";
import { Row, Col, Tabs, Tab, NavLink } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { showDataTableTotalText, EncryptOrDecrypt } from "../../services/commonService";
import { showCommissions, showCommStmtDetails,printBatchAgentStatement, downloadAgentStatementCSV } from '../../services/commissionService';
import { useDispatch } from 'react-redux';
import CommissionStatementDetail from './CommissionStatementDetails';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
const CommissionStatement = () => {
    const dispatch = useDispatch();
    const commStmt_arr = [];
    const [commStmtArray, setCommStmtArray] = useState([]);
    const [isDetailLoading, setIsDetailLoading] = useState(false);
    const [detailsData, setDetailsData] = useState([]);
    const [detailsTotal, setDetailsTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const scollToRef = useRef();
    const navigate = useNavigate();
	const agentSubModulesAccess = localStorage.getItem("agentSubModules") ? JSON.parse(
        localStorage.getItem("agentSubModules")
      ) : [];
    const devKey = process.env.REACT_APP_NODE_ENV;	
	  
    useEffect(() => {
        const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
        dispatch(showCommissions(userDatas.n_AgencyAddlInfoId_PK))
            .then((res) => {
                setCommStmtArray(res?.stmtArr || []);
            });
    }, [csvData]);
    const dateFormatter = (cell) => {
        var dt = new Date(cell);
        return (
            <span>
                {(
                    dt.getMonth() + 1).toString().padStart(2, '0')}-{
                    dt.getDate().toString().padStart(2, '0')}-{
                    dt.getFullYear().toString().padStart(4, '0')}
            </span>
        );
    }

    const getStmtLink = (cell, row, enumObject, rowIndex) => {
        return (
            <NavLink href="#" style={{ padding: "0px" }} onClick={() =>  getStmtDetails(row.n_PAAgentStatementMaster_PK, 'DATA')}>
                {row.s_StatementNo}
            </NavLink>
        );
    }

    const amtPriceFormatter = (cell) => {
        return (
            <div style={{ color: cell < 0 ? 'red' : '' }}>
                {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                }).format(Math.abs(cell))}
            </div>
        )
    }

    const getStmtDetails = (n_PAAgentStatementMaster_PK, action) => {
        const commissionElelment = document.getElementById("commissionStatementDetails");
        commissionElelment.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        setIsDetailLoading(true);
        dispatch(showCommStmtDetails(n_PAAgentStatementMaster_PK))
            .then((res) => {
                setDetailsData(res ?? []);
                setDetailsTotal(res?.length ?? 0);
                setIsDetailLoading(false);
            }).catch(() => {
                setIsDetailLoading(false);
            });
    }

    const commissionStatementPdf = (pAAgentStatementMasterPK) => {
        if (pAAgentStatementMasterPK != '') {
            setLoading(true);
            dispatch(printBatchAgentStatement(pAAgentStatementMasterPK))
            .then((res) => {
                window.open(res.url, '_blank');
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
        }
    }

    const handleDownloadStatementCSV = (pAAgentStatementMasterPK) => {
        if (pAAgentStatementMasterPK != '') {
            setLoading(true);
            dispatch(downloadAgentStatementCSV(pAAgentStatementMasterPK))
            .then((res) => {
                
                const tableData = res[0]?.queryData;
                const headerDataSummary = res[0]?.headerData;
                const comDetailData = res[0]?.comDetails;

                const formatNumber = (value) =>
                {
                    if (value === 0 || value === 0.00 || value === null) {
                        return "0.00";
                    }
                    return new Intl.NumberFormat('en-US', {
                        mFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(value);
                }
                const compName = comDetailData?.s_HoldingCompanyName;
                const statDate = headerDataSummary?.d_StatementDate;
                const agentAccNo = tableData[0]?.Agent_Account_No;
                const prevStatement = formatNumber(parseFloat(headerDataSummary?.Previous_balance));
                const commPaidStatement = formatNumber(parseFloat(headerDataSummary?.Commission_Paid));
                const refundAdj = formatNumber(parseFloat(headerDataSummary?.Refunds_Adjustments));
                const currentStatementComm =  formatNumber(parseFloat(headerDataSummary?.Current_Statement_Comm));
                const finalStatementAmt = formatNumber(parseFloat(headerDataSummary?.Final_Statement_Amount));

                const CommSummaryHeaders = ["", "", ""];
                const CommSummaryData = [
                    { label: "Company Name", value: `$${compName}`},
                    { label : "FOR THE MONTH OF", value: statDate},
                    { label: "Agent Acct No", value: agentAccNo},
                    {label: "Previous Statement Balance", value: prevStatement < 0 ? `$ (${Math.abs(prevStatement)})` :  `$ ${prevStatement}`},
                    {label: "Commission Paid", value: commPaidStatement < 0 ? `$ (${Math.abs(commPaidStatement)})` : `$ ${commPaidStatement}`},
                    {label: "Refunds & Adjustments", value:  refundAdj < 0 ? `$ (${Math.abs(refundAdj)})` : `$ ${refundAdj}`},
                    {label: "Current Commission Activity", value: currentStatementComm < 0 ? `$ (${Math.abs(currentStatementComm)})` : `$ ${currentStatementComm}`},
                    {label: "Net Due From Agent", value: finalStatementAmt < 0 ? `$ (${Math.abs(finalStatementAmt)})` : `$ ${finalStatementAmt}`},
                ];
                const table2Headers = ["Policy No", "Effective","Insured Name", "Transaction", "Agency Name", "Agency Flood Code",
                    "WYO Agency Code", "Agent Name", "WYO Agent Code", "Written Premium", "Rate", "Commission"];

                const reformattedData = tableData.map(item => ({
                    Policy_No: item.Policy_No,
                    Policy_eff_from: item.Policy_eff_from,
                    Policy_Account_Name: item.Policy_Account_Name,
                    Transaction_Type: item.Transaction_Type,
                    BusinessAgencyName: item.BusinessAgencyName,
                    BusinessAgencyFloodCode: item.BusinessAgencyFloodCode,
                    BusinessWyoAgencyCode: item.BusinessWyoAgencyCode,
                    BusinessAgentName: item.BusinessAgentName,
                    BusinessAgentWyoCode: item.BusinessAgentWyoCode,
                    paid_amount:  formatNumber(parseFloat(item.paid_amount) || 0),
                    n_CommissionRate: item.n_CommissionRate,
                    comm_amount: formatNumber(parseFloat(item.comm_amount) || 0),
                }));

                const addValues = {
                    paid_amount: tableData.reduce((sum, item) => sum + (parseFloat(item.paid_amount) || 0), 0),
                    comm_amount: tableData.reduce((sum, item) => sum + (parseFloat(item.comm_amount) || 0), 0)
                };                
                 
                const totalsRow = {
                    Policy_No: "Total",
                    Policy_eff_from: "",
                    Policy_Account_Name: "",
                    Transaction_Type: "",
                    BusinessAgencyName: "",
                    BusinessAgencyFloodCode: "",
                    BusinessAgentName: "",
                    BusinessAgentWyoCode: "",
                    paid_amount:formatNumber(addValues.paid_amount),
                    n_CommissionRate: "",
                    comm_amount: addValues.comm_amount < 0 
                    ? `(${formatNumber(Math.abs(addValues.comm_amount))})` 
                    : `${formatNumber(addValues.comm_amount)}`
                };
                reformattedData.push(totalsRow);

                const data = [
                    CommSummaryHeaders, 
                    ...CommSummaryData.map(item => [item.label, item.value]),
                    ["", "", ""],  
                    table2Headers,
                    ...reformattedData.map(item => [ 
                        item.Policy_No,
                        item.Policy_eff_from,
                        item.Policy_Account_Name,
                        item.Transaction_Type,
                        item.BusinessAgencyName,
                        item.BusinessAgencyFloodCode,
                        item.BusinessWyoAgencyCode,
                        item.BusinessAgentName,
                        item.BusinessAgentWyoCode,
                        item.paid_amount < 0 ? `$ (${Math.abs(item.paid_amount)})` : `$ ${item.paid_amount}`,
                        item.n_CommissionRate,
                        item.comm_amount < 0 ? `$ (${Math.abs(item.comm_amount)})` : `$ ${item.comm_amount}`,
                    ])
                ]
                
                setCsvData(data);
                document.getElementById('csvLinkID').click();
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
        }
    }

    const pdfStringFormatter = (cell, row, enumObject, rowIndex) => {
        return (
            <NavLink href="#" target="_blank" style={{ padding: "0px", color: "blue" }} onClick={() => commissionStatementPdf(row.n_PAAgentStatementMaster_PK)}>
                STATEMENT
            </NavLink>
        )
    }
    const downloadStatementCsvFormat = (cell, row, enumObject, rowIndex) => {
        return (
            <div>
            <button
                onClick={() => handleDownloadStatementCSV(row.n_PAAgentStatementMaster_PK)}
                style={{ color: "blue", textDecoration: "none" }}
            >
                    DOWNLOAD
            </button>
           </div>
        )
    }

    const columns = [
        {
            dataField: "d_StatementDate",
            text: "Statement Date",
            formatter: dateFormatter
        },
        {
            dataField: "s_StatementNo",
            text: "Statement No",
            formatter: (value, row) => getStmtLink(value, row),
            // formatter: getStmtLink
        },
        {
            dataField: "n_StatementCommAmt",
            text: "Total Amount",
            formatter: amtPriceFormatter
        },
        {
            dataField: "n_PAAgentStatementMaster_PK",
            text: "CSV",
            formatter: downloadStatementCsvFormat,
            
        },
        {
            dataField: "n_PAAgentStatementMaster_PK",
            text: "PDF",
            formatter: pdfStringFormatter
            
        },
    ];
    return (
		
        <div className="" id="dashboard-table-section" >
			{agentSubModulesAccess.includes('COMMISSION_STATEMENT')?(
				<>
				<Col xs="12" style={{ padding: 0 }}>
                <div className="table-main-title-div">
                    <span className="table-main-title-name">
                    Commission Statement
                    </span>
                     
                    {agentSubModulesAccess.includes('ACH_INFO_AGENT') && <button className="btnCustom btnCustom-info pr-4 pl-4" style={{ border: "1px solid", fontSize: "15px", background:"var(--buttonColor)" }} onClick={() => navigate(`/ACH-Information`)}>
                        Manage ACH Information
                    </button>}
                </div>
            </Col>
            <LoadingOverlay>
                <Loader loading={loading} />
                <Col xs="12" style={{ padding: 0 }}>
                    <BootstrapTable
                        wrapperClasses="management-functional-table"
                        bootstrap4
                        keyField="n_PAAgentStatementMaster_PK"
                        data={commStmtArray}
                        columns={columns}
                        hover
                        ref={scollToRef}
                    />
                </Col>
            </LoadingOverlay>
                <br></br>
                <br></br>
                <br></br>
                <CommissionStatementDetail
                    isDetailLoading={isDetailLoading}
                    detailsData={detailsData}
                    detailsTotal={detailsTotal}
                />
				</>) : 
				<strong>You Don't Have Access to View This Module</strong> }
            { csvData?.length > 0 &&
                <CSVLink
                    data={csvData}
                    filename={`Statement.csv`}
                    id="csvLinkID"
                >
                </CSVLink>
            }
            </div>
    );
};

export default CommissionStatement;
