import { Box, Flex, Grid, Tag } from "@chakra-ui/react";
import {
  ChakraSelect,
  CustomFormControl,
} from "../../commercial-quote/Components/CustomComponents";
import DateInput from "../../../create-star-quote/utils/DateInput";
import { useQuote } from "../../CommCreateQuote";
import { propertySchema } from "../../commercial-quote/data/propertySchema";
import { businessClassIdentifier } from "../../CreateCommercialPool";

export const BuissnessEstablisments = () => {
  const {
    setMasterQuoteData,
    masterQuoteData,
    buildingDetails,
    setBuildingDetails,
    baseDD,
    selectedCard,
    hotelResponses,
    convenienceStoreResponses,
  } = useQuote();

  const addNewLocation = (count = 1) => {
    const newLocations = Array.from({ length: count }, (_, i) => ({
      ...propertySchema,
      locationIndetifier: `Building 0${buildingDetails.length + i + 1}`,
    }));
    setBuildingDetails([...buildingDetails, ...newLocations]);
  };

  return (
    <>
      <Box>
        <Flex justifyContent={"center"} mt={2} gap={1} color={"gray"}>
          {businessClassIdentifier(
            selectedCard,
            hotelResponses,
            convenienceStoreResponses,
            buildingDetails
          ) && (
            <>
              <Tag variant={"outline"} textAlign={"center"}>
                {businessClassIdentifier(
                  selectedCard,
                  hotelResponses,
                  convenienceStoreResponses,
                  buildingDetails
                ) || ""}
              </Tag>
            </>
          )}
        </Flex>
        <Grid templateColumns={"repeat(3, 1fr)"} gap={4} mt={5}>
          <CustomFormControl label={"Business Establishment"}>
            <ChakraSelect
              value={masterQuoteData?.policy?.businessEstablishment || ""}
              onChange={(e) => {
                setMasterQuoteData({
                  ...masterQuoteData,
                  policy: {
                    ...masterQuoteData?.policy,
                    businessEstablishment: e.target.value,
                  },
                });
              }}
              className="inputSelectBoxes"
            >
              <option value=""></option>
              {baseDD?.COMBUISNESSESTABLISHMENT?.map((option, index) => (
                <option key={index} value={option?.s_AppCodeName}>
                  {option?.s_AppCodeNameForDisplay}
                </option>
              ))}
            </ChakraSelect>
          </CustomFormControl>
          <CustomFormControl label={"Effective Date"}>
            <DateInput
              value={masterQuoteData?.policy?.effectiveDate || ""}
              styleProps={{
                className: "inputSelectBoxes",
              }}
              onChange={(e) => {
                setMasterQuoteData({
                  ...masterQuoteData,
                  policy: {
                    ...masterQuoteData?.policy,
                    effectiveDate: e,
                  },
                });
              }}
            />
          </CustomFormControl>
          <CustomFormControl label={"Number of Buildings"}>
            <ChakraSelect
              value={masterQuoteData?.policy?.numberOfBuildings || ""}
              onChange={(e) => {
                setMasterQuoteData({
                  ...masterQuoteData,
                  policy: {
                    ...masterQuoteData?.policy,
                    numberOfBuildings: e.target.value,
                  },
                });
                if (e.target.value > buildingDetails.length) {
                  addNewLocation(e.target.value - buildingDetails.length);
                }
              }}
              className="inputSelectBoxes"
            >
              <option value=""></option>
              {[...Array(10).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1 === 10 ? "10+" : i + 1}
                </option>
              ))}
            </ChakraSelect>
          </CustomFormControl>
        </Grid>
      </Box>
    </>
  );
};
