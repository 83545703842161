import React, { useState, useRef, useMemo } from "react";
// import { Link, useParams } from "react-router-dom";
// import BootstrapTable from "react-bootstrap-table-next";
// import { Row, Col, Form, Alert, Toast } from "react-bootstrap";
import { CSVLink } from 'react-csv';
// import Select from 'react-select';
// import paginationFactory from "react-bootstrap-table2-paginator";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDispatch } from "react-redux";
import { BsCurrencyDollar } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { IoDocumentsOutline } from "react-icons/io5";
import { getPolicyListData } from "../../../services/dashboardService";
import { verifyPolicy, resetCoreStore, setPolicyPropsData, getBaseData } from "../../../services/coreSystemService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useNavigate, useLocation } from 'react-router-dom';
// import { BsArrowLeftSquare } from "react-icons/bs";
// import ReactTooltip from "react-tooltip";
import { Box, Button, Flex, Select, Text, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import AdvancedGridTable from "../../../components/AdvancedGridTable";
import { formateDate } from "../../common/FormatDate";
import { Currency } from "../../common/Currency";
import NumberCell from "../NumberClick";
import { FaFileDownload } from "react-icons/fa";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const devKey = process.env.REACT_APP_NODE_ENV;
const isEnabledDataToggleOptionForDashboardData = process.env.REACT_APP_EMABLED_DATA_TOGGLE_OPTION_FOR_DASHBOARD_DATA;

const UpcomingRenewals = () => {
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const [nameOption, setNameOption] = useState("self");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [UpcomingData, setupcomingData] = useState(location.state);
  const [filter, setFilter] = useState({
    page: 1,
    sizePerPage: 10,
  });
  const navigation = useNavigate();
  const [total, setTotal] = useState(0);

  // useEffect(() => {
    // if(devKey !== "PROD"){
      // UpComingPolicyData();
    // }
  // }, [filter.page, filter.sizePerPage])

  const headers = [
    { label: "Policy No", key: "Policy_No" },
    { label: "Expire Date", key: "d_TransEffectiveFrom" },
    { label: "Policyholder/Member Name", key: "d_PolicyOwnerDBAName" },
    // { label: "Property Address", key: "property_address" },
    { label: "Premium", key: "premium" },
    // { label: "Payor", key: "BillToType_Name" },
  ]

  const UpComingPolicyData = async (type = nameOption, transType = "RENEW", page = filter.page, sizePerPage = filter.sizePerPage) => {
    setLoading(true);
    await dispatch(getPolicyListData(type, transType, sizePerPage, page)).then((res) => {
      setupcomingData(res.data.data);
      setTotal(res.data.count);
    }).catch(() => {
      // Nothing specific needed on catch
    });
    setLoading(false);
  }

  const namelist = [
    { value: 'all', label: 'All Data' },
    { value: 'self', label: 'My Data' }
  ]

  const namelistdata = (e) => {
    setNameOption(e.target.value);
    UpComingPolicyData(e.target.value);
  }

  // const actionFormatter = (cell, row) => {
  //   if (row && row.Policy_No) {
  //     return (
  //       <>
  //         <BsCurrencyDollar size={26} style={{ backgroundColor: "#66D7CD", border: "1px solid #66D7CD", cursor: "pointer" }} onClick={() => urlBuilder(row)} data-tip data-for="dollar">
  //           {cell}
  //         </BsCurrencyDollar>&nbsp;
  //         <ReactTooltip id="dollar" place="top" effect="solid">
  //           Submit Payment
  //         </ReactTooltip>
  //         <FiEdit2 size={26} style={{ backgroundColor: "#66D7CD", border: "1px solid #66D7CD", cursor: "pointer" }} onClick={() => urlBuilder(row, "VIEWENDORSEPOLICY")} data-tip data-for="endorsement" />&nbsp;
  //         <ReactTooltip id="endorsement" place="top" effect="solid">
  //           Endorsement
  //         </ReactTooltip>
  //         <IoDocumentsOutline size={26} style={{ backgroundColor: "#66D7CD", border: "1px solid #66D7CD", cursor: "pointer" }} onClick={() => urlBuilder(row, "DOCUMENTS")} data-tip data-for="document">
  //           {cell}
  //         </IoDocumentsOutline>
  //         <ReactTooltip id="document" place="top" effect="solid">
  //           View Document
  //         </ReactTooltip>
  //       </>
  //     )
  //   } else {
  //     return row.Policy_No;
  //   }
  // }

  const urlBuilder = async (row, actionType) => {
    await dispatch(verifyPolicy(row.Policy_No)).then((res) => {
      if (res.status) {
        dispatch(resetCoreStore());
        const params = {
          n_POPolicyMasterFK: res.policyIdPK,
          n_POTermMasterFK: res.termMasterPK,
          n_POTransactionFK: res.poTransPK,
        }
        dispatch(setPolicyPropsData(params));
        dispatch(
          getBaseData(res.productFK)
        );
        if (actionType == 'VIEWENDORSEPOLICY') {
          navigation(`/core/policy/${actionType}/${row.Policy_No}`);
        } else if (actionType == 'DOCUMENTS') {
          navigation(`/core/policy/${actionType}/${row.Policy_No}`);

        } else {
          navigation(`/core/policy/${row.Policy_No}`);

        }
      }
    })
  }

  // const columns = [
  //   {
  //     dataField: "Policy_No",
  //     text: "Policy No",
  //     style: {
  //       width: "12%"
  //     },
  //     sort: true
  //   },
  //   {
  //     dataField: "d_TransEffectiveFrom",
  //     text: "Effective Date",
  //     style: {
  //       width: "12%"
  //     },
  //     sort: true
  //   },
  //   {
  //     dataField: "d_PolicyOwnerDBAName",
  //     text: "Policyholder",
  //     style: {
  //       width: "12%"
  //     },
  //     sort: true
  //   },
  //   // {
  //   //   dataField: "s_AddressLine1",
  //   //   text: "Property Address",
  //   //   formatter: (value, row) => {
  //   //     return value + ' ' + row.s_CountyName + ' ' + row.s_CityName + ' ' + row.s_StateCode + ' ' + row.s_PostalCode
  //   //   },
  //   //   style: {
  //   //     width: "35%"
  //   //   },
  //   // },
  //   {
  //     dataField: "payment_amount",
  //     text: "Premium",
  //     formatter: (value, row) => {
  //       return '$'+ row.payment_amount
  //     }
  //   },
  //   // {
  //   //   dataField: "BillToType_Name",
  //   //   text: "Payor",
  //   // },
  //   {
  //     dataField: "actionsw",
  //     text: "Action",
  //     formatter: actionFormatter
  //   },
  // ];

  // const handlePageChange = (page, sizePerPage) => {
  //   setFilter({ ...filter, page: page, sizePerPage: sizePerPage });
  // };

  // const sizePerPageListChange = (page, sizePerPage) => {
  //   setFilter({ ...filter, sizePerPage: sizePerPage });
  // };

  // const onTableChange = (type, { page, sizePerPage }) => {
  //   setFilter({ ...filter, page: page, sizePerPage: sizePerPage });
  // };

  // const renderShowsTotal = (from, to, size) => (
  //   <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
  // );

  // const options = {
  //   page: filter.page,
  //   sizePerPage: filter.sizePerPage,
  //   sortIndicator: false,
  //   pageStartIndex: 1,
  //   totalSize: UpcomingData.count,
  //   showTotal: true,
  //   paginationTotalRenderer: renderShowsTotal,
  //   onPageChange: handlePageChange,
  //   onSizePerPageChange: sizePerPageListChange,
  // };

  const homePage = () => {
    navigation("/home");
  }

  const onNumberClick = (params) => {
    const rowData = params.data;
    navigation(`/core/policy/${rowData.Policy_No}`);
  };

  const ActionCellRenderer = (params) => {
    const rowData = params.data;
    if(rowData && rowData.Policy_No) {
      return (
        <Flex align={'center'} pt={'4px'}>
          <Tooltip hasArrow label='Submit Payment' placement='top'>
            <Button size={'sm'} onClick={() => urlBuilder(rowData)}>
              <BsCurrencyDollar size={'20px'} />
            </Button>
          </Tooltip>
          <Tooltip hasArrow label='Endorsement' placement='top'>
            <Button ml={2} size={'sm'} onClick={() => urlBuilder(rowData, "VIEWENDORSEPOLICY")}>
              <FiEdit2 size={'20px'} />
            </Button>
          </Tooltip>
          <Tooltip hasArrow label='View Document' placement='top'>
            <Button ml={2} size={'sm'} onClick={() => urlBuilder(rowData, "DOCUMENTS")}>
              <IoDocumentsOutline size={'20px'} />
            </Button>
          </Tooltip>
        </Flex>
      );
    } else {
      return rowData.Policy_No;
    }
  };

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: true,
      flex: 1,
      wrapText: true,
      autoHeight: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressHeaderMenuButton: true,
      suppressFloatingFilterButton: true,
      minWidth: 200,
    }),
    []
  );

  const [columnDefs] = useState([
    {
      headerName: "Policy Number",
      field: "Policy_No",
      filter: "agTextColumnFilter",
      cellStyle: {
        textDecoration: "underline",
        color: "blue",
        cursor: "pointer",
      },
      cellRenderer: (params) => (
        <NumberCell
          value={params.value}
          onNumberClick={()=>onNumberClick(params)}
        />
      ),
    },
    {
      headerName: "Effective Date",
      field: "d_TransEffectiveFrom",
      // filter: "agDateColumnFilter",
      floatingFilter: false,
      valueFormatter: (params) => formateDate(params.value),
    },
    {
      headerName: "Policyholder",
      field: "d_PolicyOwnerDBAName",
      filter: "agTextColumnFilter",
      wrapText: true,
    },
    {
      headerName: "Premium",
      field: "payment_amount",
      filter: false,
      sortable: false,
      cellRenderer: (params) => <Currency amount={params?.value}/>,
    },
    {
      headerName: "Action",
      field: "",
      filter: false,
      sortable: false,
      cellRenderer: ActionCellRenderer,
    },
  ]);

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />
        <Box mb={8}>
          <Flex mb={4} justifyContent={"space-between"}>
            <Flex>
              <Text mb={0} mr={4} fontWeight={600} fontSize="2xl">
                {t("Upcoming Renewals")}
              </Text>
            </Flex>
            <Flex direction={"row"} align={"center"}>
              {isEnabledDataToggleOptionForDashboardData == "SHOW" ? (
                <>
                  <Select
                    size={"sm"}
                    mr={4}
                    value={nameOption}
                    onChange={(e) => namelistdata(e)}
                  >
                    {namelist &&
                      namelist.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                  </Select>
                </>
              ) : (
                ""
              )}
              <Box>
                <Button
                  mr={4}
                  leftIcon={<MdOutlineKeyboardBackspace />}
                  size={"sm"}
                  onClick={homePage}
                >
                  {t("Back")}
                </Button>
              </Box>
              <CSVLink
                data={UpcomingData?.records ?? []}
                headers={headers}
                filename={"Upcoming_Renewal_Data.csv"}
              >
                <Button leftIcon={<FaFileDownload />} size="sm">
                  {t("Export To CSV")}
                </Button>
              </CSVLink>
            </Flex>
          </Flex>
          <Box
            bgColor={"white"}
            p={5}
            boxShadow={"lg"}
            borderRadius={"2xl"}
            borderColor={"white"}
          >
            <Box
              className="ag-theme-alpine"
              h={"70vh"}
              minHeight={"400px"}
              maxHeight={"600px"}
              id="myGrid"
              w={"100%"}
            >
              <AdvancedGridTable
                gridRef={gridRef}
                rowData={UpcomingData?.records ?? []}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={[10, 25, 50]}
                enableCellTextSelection={true}
                onGridReady={(params) => {
                  params.api.sizeColumnsToFit();
                }}
              />
            </Box>
          </Box>
        </Box>
      </LoadingOverlay>
    {/* <Fragment>
      <LoadingOverlay>
        <Loader loading={loading} />

        <h4 className="dashboard-cards-table-title"><BsArrowLeftSquare onClick={homePage} style={{ cursor: "pointer" }} /> &nbsp;&nbsp;Dashboard</h4>
        <Col xs="12" className="billing-tab-row2">
          <div id="dashboard-table-section">
            <div className="table-main-title-div">
              <span className="table-main-title-name">Upcoming Renewals</span>
              {
                devKey !== "PROD" ? 
                <>
                <span className="table-main-title-name" style={{ marginLeft: "44%" }}>
                  <label htmlFor="">Select Data View</label>
                </span> */}
                {/* <Select options={namelist} defaultValue={nameOption} onChange={(e) => namelistdata(e)} /> */}
                {/* <select style={{ width: "9%", padding: "8px" }} value={nameOption} onChange={(e) => namelistdata(e)}>
                  {namelist && namelist.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
                </>
              : ""}
              <span className="table-main-title-name">
                <CSVLink data={UpcomingData.records} headers={headers} filename={'Upcoming_Renewal_Data.csv'}>
                  <button className="btnCustom btnCustom-info pr-4 pl-4" style={{ border: "1px solid", fontSize: "15px" }} size="sm" color="primary">Export To CSV</button>
                </CSVLink>
              </span>
            </div>
            <div className="managment-tab-body" style={{ paddingBottom: "2rem" }}>
              <BootstrapTable
                wrapperClasses="dashboard-table-main"
                bootstrap4
                keyField="PolicyNo"
                columns={columns}
                data={UpcomingData.records}
                hover
                remote
                bordered={false}
                pagination={paginationFactory(options)}
                onTableChange={onTableChange}
              />
            </div>
          </div>
        </Col>
      </LoadingOverlay>
    </Fragment> */}
    </>
  );
};

export default UpcomingRenewals;