import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { stateList, userProductsLicense } from "../../services/enrollService";
import { Box, Flex } from "@chakra-ui/react";
import AgencyDetails from "./components/AgencyDetails";
import MailingAddress from "./components/MailingAddress";
import LicenseInformation from "./components/LicenseInformation";
import AddAgencyUsers from "./components/AddAgencyUsers";
import ConfirmationPage from "./components/ConfirmationPage";
import { Step, Steps, useSteps } from "chakra-ui-steps";

const EnrolAgent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const [licManagerArray, setLicManagerArray] = useState([]);
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });
  const [userDataArray, setUserDataArray] = useState([]);
  // const [copymail, setCopymail] = useState(false)
  const [formDataArray, setFormDataArray] = useState({
    agency_name: "",
    agency_code: "",
    n_AgencyNPN: "",
    corp_status: "",
    DBA_name: "",
    tax_type: "",
    tax_number: "",
    mail_s_AddressLine1: "",
    mail_s_PostalCode: "",
    mail_n_Zipcodes_PK: "",
    mail_n_CityId_FK: "",
    mail_s_CityName: "",
    mail_n_StateId_FK: "",
    mail_s_StateName: "",
    mail_n_CountyId_FK: "",
    mail_s_CountyName: "",
    mail_n_CountryId_FK: "",
    loc_s_AddressLine1: "",
    loc_s_PostalCode: "",
    loc_n_Zipcodes_PK: "",
    loc_n_CityId_FK: "",
    loc_s_CityName: "",
    loc_n_StateId_FK: "",
    loc_s_StateName: "",
    loc_n_CountyId_FK: "",
    loc_s_CountyName: "",
    loc_n_CountryId_FK: "",
    web_s_WebsiteURL: "",
    phone_s_PhoneNumber: "",
    fax_s_FaxNumber: "",
    contact_s_Email: "",
    contact_s_ContactName: "",
    W9_Form: "",
    license_Information: licManagerArray,
    user_data: userDataArray,
  });
  const [states, setStates] = useState([]);
  const [isConfirmation, setIsConfirmation] = useState(false);
  // const [multipleCity, setMultipleCity] = useState(null);
  // const [show, setShow] = useState(false);
  // const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const userData = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    npn: "",
    agent_code: "",
    resident_state_id: "",
    user_detail: [
      {
        s_LicenseStatus: "",
        n_LicenseNo: "",
        n_StateId_FK: "",
        n_AgentNPN: "",
        d_ExpireDate: "",
      },
    ],
  };

  const licManagerData = {
    n_LicenseType: "",
    s_LicenseNo: "",
    n_StateId_FK: "",
    d_IssueDate: "",
    d_ExpireDate: "",
  };

  useEffect(() => {
    (async () => {
      const stateData = await dispatch(stateList());
      setStates(stateData.data);
      // eslint-disable-next-line no-unused-vars
      const productsLicenseList = await dispatch(userProductsLicense());
    })();

    setUserDataArray([userData]);
    setLicManagerArray([licManagerData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newData = { ...formDataArray };
    newData["license_Information"] = licManagerArray;
    setFormDataArray(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licManagerArray]);

  useEffect(() => {
    const newData = { ...formDataArray };
    newData["user_data"] = userDataArray;
    setFormDataArray(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataArray]);

  const validateEmail = (email) => {
    return email.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const newSteps = [
    {
      label: t("Agency Details"),
      component: (
        <AgencyDetails
          formDataArray={formDataArray}
          setFormDataArray={setFormDataArray}
          nextStep={nextStep}
          validateEmail={validateEmail}
        />
      ),
    },
    {
      label: t("Mailing Address"),
      component: (
        <MailingAddress
          nextStep={nextStep}
          prevStep={prevStep}
          setLoading={setLoading}
          formDataArray={formDataArray}
          setFormDataArray={setFormDataArray}
        />
      ),
    },
    {
      label: t("License Information"),
      component: (
        <LicenseInformation
          nextStep={nextStep}
          prevStep={prevStep}
          states={states}
          licManagerData={licManagerData}
          licManagerArray={licManagerArray}
          setLicManagerArray={setLicManagerArray}
        />
      ),
    },
    {
      label: t("Add Agency Users"),
      component: (
        <AddAgencyUsers
          prevStep={prevStep}
          states={states}
          userData={userData}
          userDataArray={userDataArray}
          setUserDataArray={setUserDataArray}
          formDataArray={formDataArray}
          setFormDataArray={setFormDataArray}
          setLoading={setLoading}
          setIsConfirmation={setIsConfirmation}
          setLicManagerArray={setLicManagerArray}
          validateEmail={validateEmail}
        />
      ),
    },
  ];

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />
        <Box>
          {!isConfirmation ? (
            <>
              <Flex
                minH={"70vh"}
                height={"max-content"}
                alignItems="center"
                direction={"column"}
                mt={8}
              >
                <Steps
                  pl={2}
                  w={"90rem"}
                  responsive={true}
                  alignItems="center"
                  justifyContent="center"
                  variant={"circles"}
                  colorScheme="blue"
                  activeStep={activeStep}
                  className="arrow-steps clearfix"
                  sx={{
                    fontSize: ["14px", "16px", "18px"],
                    "& .arrow-steps": {
                      display: "flex",
                    },
                  }}
                >
                  {newSteps.map(({ label, component }, index) => (
                    <Step key={index} label={label}>
                      <Box mt={8}>{component}</Box>
                    </Step>
                  ))}
                </Steps>
              </Flex>
            </>
          ) : (
            <ConfirmationPage />
          )}
        </Box>
      </LoadingOverlay>
      <style>{`.arrow-steps {
          display: flex;
          align-items: center;
          justify-content: center;
          width: max-content;
      }

      .arrow-steps .cui-steps__horizontal-step {
        font-size: 12px;
        text-align: center;
        color: var(--chakra-colors-neutral-500);
        cursor: default;
        margin: 0 3px;
        padding: 15px 16px 15px 30px;
        min-width: 13.6%;
        float: left;
        position: relative;
        background-color: var(--chakra-colors-neutral-100);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: background-color 0.2s ease;
      }

      .arrow-steps .cui-steps__horizontal-step:after,
      .arrow-steps .cui-steps__horizontal-step:before {
        content: " ";
        position: absolute;
        top: 0px;
        right: -16px;
        width: 0;
        height: 0;
        border-top: 26px solid transparent;
        border-bottom: 27px solid transparent;
        border-left: 17px solid var(--chakra-colors-neutral-100);
        z-index: 1;
        transition: border-color 0.2s ease;
      }

      .arrow-steps .cui-steps__horizontal-step:before {
        right: auto;
        left: 0;
        border-left: 17px solid var(--chakra-colors-white);
        z-index: 0;
      }

      .arrow-steps .cui-steps__horizontal-step:first-child:before {
        border: none;
      }

      .arrow-steps .cui-steps__horizontal-step:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .arrow-steps .cui-steps__horizontal-step:last-child:after {
        border: none;
      }

      .arrow-steps .cui-steps__horizontal-step:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .cui-steps__step-icon-container {
        display: none;
      }

      .cui-steps__horizontal-step-container .css-0 span {
        color: var(--chakra-colors-black);
        font-size: 16px;
        font-weight: bold;
      }

      .arrow-steps .cui-steps__horizontal-step[aria-disabled="false"] {
        color: var(--chakra-colors-white);
        background-color: var(--chakra-colors-primary-500);
      }

      .arrow-steps .cui-steps__horizontal-step[aria-disabled="false"] .cui-steps__horizontal-step-container .css-0 span {
        color: var(--chakra-colors-white);
      }

      .arrow-steps .cui-steps__horizontal-step[aria-disabled="false"]:after {
        border-left: 17px solid var(--chakra-colors-primary-500);
      }`}</style>
    </>
  );
};

export default EnrolAgent;
