import React from 'react';
import { useNavigate } from 'react-router-dom';
import './page.scss';
import { Box, Flex, Text, VStack, Button } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { FiLogIn } from 'react-icons/fi';

const Page419 = () => {
    const navigation = useNavigate();
    const { t } = useTranslation();
    return (
      <>
        <Flex
          h={"80vh"}
          justifyContent="center"
          alignItems="center"
          direction={"column"}
          maxHeight={"770px"}
        >
          <Box>
            <VStack spacing={4} w={"100%"}>
              <Box
                borderRadius={"2xl"}
                border={"1px solid"}
                borderColor={{ lg: "whiteAlpha.100", base: "white" }}
                p={{ base: 3, lg: 8, md: 5 }}
                boxShadow={"0 0 6px rgba(0, 0, 0, 0.3)"}
                w={"55vw"}
                maxWidth={900}
              >
                <Flex direction={"column"} align={"center"}>
                  <Text
                    fontSize={"3xl"}
                    fontWeight={600}
                    align={"center"}
                  >
                    {t("419")}
                  </Text>
                  <Text
                    fontSize={"xl"}
                    fontWeight={600}
                    align={"center"}
                  >
                    {t("Oops! Link expired")}.
                  </Text>
                  <Text
                    fontSize={"lg"}
                    fontWeight={600}
                    align={"center"}
                  >
                    {t("The page you are looking for was expired")}.
                  </Text>
                  <Button
                    w={{ base: "9rem", lg: "12rem" }}
                    color={"white"}
                    borderRadius={"xl"}
                    onClick={() => {
                      navigation("/login");
                    }}
                    leftIcon={<FiLogIn />}
                  >
                    {t("Go to Login")}
                  </Button>
                </Flex>
              </Box>
            </VStack>
          </Box>
        </Flex>
      </>
    )
}

export default Page419