import React, { useState } from "react";
import {
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  GridItem,
  Switch,
  Text,
  VStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import ZipDetailsView from "../ZipDetailsView";
import { getZipDetails } from "../../../services/enrollService";
import { useDispatch } from "react-redux";
import { IoArrowBackSharp } from "react-icons/io5";
import { GrFormNextLink } from "react-icons/gr";

export default function MailingAddress({
  nextStep,
  prevStep,
  formDataArray,
  setFormDataArray,
  setLoading,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [copymail, setCopymail] = useState(false);
  const [multipleCity, setMultipleCity] = useState(null);
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataArray((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSwitchChange = (checkedValue, event, name) => {
    const newData = { ...formDataArray };
    if (checkedValue.target.checked === true) {
      newData["loc_s_AddressLine1"] = newData["mail_s_AddressLine1"];
      newData["loc_s_PostalCode"] = newData["mail_s_PostalCode"];
      newData["loc_n_Zipcodes_PK"] = newData["mail_n_Zipcodes_PK"];
      newData["loc_n_CityId_FK"] = newData["mail_n_CityId_FK"];
      newData["loc_s_CityName"] = newData["mail_s_CityName"];
      newData["loc_n_StateId_FK"] = newData["mail_n_StateId_FK"];
      newData["loc_s_StateName"] = newData["mail_s_StateName"];
      newData["loc_n_CountyId_FK"] = newData["mail_n_CountyId_FK"];
      newData["loc_s_CountyName"] = newData["mail_s_CountyName"];
      newData["loc_n_CountryId_FK"] = newData["mail_n_CountryId_FK"];
    } else {
      newData["loc_s_AddressLine1"] = "";
      newData["loc_s_PostalCode"] = "";
      newData["loc_n_Zipcodes_PK"] = "";
      newData["loc_n_CityId_FK"] = "";
      newData["loc_s_CityName"] = "";
      newData["loc_n_StateId_FK"] = "";
      newData["loc_s_StateName"] = "";
      newData["loc_n_CountyId_FK"] = "";
      newData["loc_s_CountyName"] = "";
      newData["loc_n_CountryId_FK"] = "";
    }
    setFormDataArray(newData);
    setCopymail(!copymail);
  };

  const handleSubmit = () => {
    if (formDataArray.mail_s_AddressLine1.trim() === "") {
      toast.error("Mailing Address Line 1 is required");
      return false;
    }
    if (formDataArray.mail_s_PostalCode.trim() === "") {
      toast.error("Mailing Address Zip is required");
      return false;
    }
    if (formDataArray.mail_s_CityName.trim() === "") {
      toast.error("Mailing Address City Name is required");
      return false;
    }
    if (formDataArray.mail_s_StateName.trim() === "") {
      toast.error("Mailing Address State is required");
      return false;
    }
    nextStep();
  };

  const getDataFromZip = (zipCodeVal, target) => {
    /*
            Required in future
            if (streetName === '') {
                toast.error('Please Enter Street Name!');
                setZipCode('')
                return false;
            }
        */
    if (zipCodeVal !== "") {
      setLoading(true);
      dispatch(getZipDetails(zipCodeVal)).then((res) => {
        if (res.success) {
          setLoading(false);
          var result = res?.data;
          if (result.status === "Y") {
            if (result.count === 1) {
              setZipDetailsData(result.zipData[0], target);
            } else {
              showMultipleCityModal(result.zipData, target);
            }
          } else {
            toast.error(res.message);
            if (target === "mail") {
              setFormDataArray({
                ...formDataArray,
                mail_n_CityId_FK: "",
                mail_s_CityName: "",
                mail_n_StateId_FK: "",
                mail_s_StateName: "",
                mail_n_CountyId_FK: "",
                mail_s_CountyName: "",
                mail_n_CountryId_FK: "",
                mail_n_Zipcodes_PK: "",
              });
            }
            if (target === "loc") {
              setFormDataArray({
                ...formDataArray,
                loc_n_CityId_FK: "",
                loc_s_CityName: "",
                loc_n_StateId_FK: "",
                loc_s_StateName: "",
                loc_n_CountyId_FK: "",
                loc_s_CountyName: "",
                loc_n_CountryId_FK: "",
                loc_n_Zipcodes_PK: "",
              });
            }
          }
        }
      });
    } else {
      toast.error("Zip Code should not be empty!");
    }
  };

  const showMultipleCityModal = (zipData, target) => {
    setMultipleCity(
      <ZipDetailsView
        zipData={zipData}
        setZipDetailsData={setZipDetailsData}
        target={target}
      />
    );
    setShow(true);
  };

  // Get selected zip data
  const setZipDetailsData = (zipData, target) => {
    if (target === "mail") {
      setFormDataArray({
        ...formDataArray,
        mail_n_CityId_FK: zipData.n_CityId_PK,
        mail_s_CityName: zipData.s_CityName,
        mail_n_StateId_FK: zipData.n_StateId_PK,
        mail_s_StateName: zipData.s_StateCode,
        mail_n_CountyId_FK: zipData.n_CountyId_FK,
        mail_s_CountyName: zipData.s_CountyCode,
        mail_n_CountryId_FK: zipData.n_CountryId_FK,
        mail_n_Zipcodes_PK: zipData.n_Zipcodes_PK,
      });
    }

    if (target === "loc") {
      setFormDataArray({
        ...formDataArray,
        loc_n_CityId_FK: zipData.n_CityId_PK,
        loc_s_CityName: zipData.s_CityName,
        loc_n_StateId_FK: zipData.n_StateId_PK,
        loc_s_StateName: zipData.s_StateCode,
        loc_n_CountyId_FK: zipData.n_CountyId_FK,
        loc_s_CountyName: zipData.s_CountyCode,
        loc_n_CountryId_FK: zipData.n_CountryId_FK,
        loc_n_Zipcodes_PK: zipData.n_Zipcodes_PK,
      });
    }
    setMultipleCity(null);
    setShow(false);
  };

  return (
    <>
      <Box>
        <VStack spacing={4} w={"100%"}>
          <Box
            borderRadius={"2xl"}
            border={"1px solid"}
            borderColor={{ lg: "whiteAlpha.100", base: "white" }}
            p={{ base: 3, sm: 4, md: 6, lg: 8 }}
            boxShadow={"0 0 6px rgba(0, 0, 0, 0.3)"}
            width={"65rem"}
            maxWidth={"70rem"}
            bgColor={"white"}
          >
            <Text fontWeight={"600"} fontSize={"3xl"}>
              {t("Mailing Address")}
            </Text>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3,1fr)",
              }}
              gap={{ base: 3, md: 5, xl: 6 }}
            >
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="mail_s_AddressLine1">
                    {t("Address Line 1")}:
                  </FormLabel>
                  <Input
                    id="mail_s_AddressLine1"
                    name="mail_s_AddressLine1"
                    autoComplete="none"
                    type="text"
                    bgColor={"white"}
                    className="dashboard-group-input"
                    value={formDataArray.mail_s_AddressLine1}
                    onChange={handleChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="mail_s_CityName">{t("City")}:</FormLabel>
                  <Input
                    isDisabled
                    id="mail_s_CityName"
                    name="mail_s_CityName"
                    bgColor={"gray.200"}
                    _disabled={{
                      opacity: 1,
                    }}
                    type="text"
                    value={formDataArray.mail_s_CityName}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="mail_s_StateName">
                    {t("State")}:
                  </FormLabel>
                  <Input
                    isDisabled
                    id="mail_s_StateName"
                    name="mail_s_StateName"
                    bgColor={"gray.200"}
                    _disabled={{
                      opacity: 1,
                    }}
                    type="text"
                    value={formDataArray.mail_s_StateName}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel htmlFor="mail_s_PostalCode">{t("Zip")}:</FormLabel>
                  <Input
                    id="mail_s_PostalCode"
                    name="mail_s_PostalCode"
                    autoComplete="none"
                    type="number"
                    bgColor={"white"}
                    className="dashboard-group-input"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    onWheel={(e) => {
                      e.target.blur();
                    }}
                    value={formDataArray.mail_s_PostalCode}
                    onChange={(e) => {
                      if (e.target.value.length <= 5) {
                        handleChange(e);
                      }
                    }}
                    onBlur={(e) => getDataFromZip(e.target.value, "mail")}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel htmlFor="mail_s_CountyName">
                    {t("County")}:
                  </FormLabel>
                  <Input
                    isDisabled
                    id="mail_s_CountyName"
                    name="mail_s_CountyName"
                    bgColor={"gray.200"}
                    _disabled={{
                      opacity: 1,
                    }}
                    className="dashboard-group-input"
                    type="text"
                    value={formDataArray.mail_s_CountyName}
                  />
                </FormControl>
              </GridItem>
            </Grid>
            <Flex
              py={4}
              textAlign={{ base: "start", md: "left" }}
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={{ base: "center", md: "space-between" }}
            >
              <Text as={"span"} fontWeight="600" fontSize="2xl">
                {t("Location Address")}
              </Text>
              <Flex flexDir={"row"} alignItems={"center"}>
                <Text as={"span"} mr={2} fontWeight="600" fontSize="md">
                  {t("Copy as Mailing Address")}
                </Text>
                <Switch
                  color={"#7017FF"}
                  isChecked={copymail}
                  onChange={(checkedValue, event, name) => {
                    handleSwitchChange(checkedValue, event, name);
                  }}
                  className="react-switch"
                  id="commission.rollover"
                />
              </Flex>
            </Flex>
            {!copymail && (
              <>
                <Grid
                  templateColumns={{
                    base: "repeat(2,1fr)",
                    md: "repeat(3,1fr)",
                    lg: "repeat(3,1fr)",
                  }}
                  gap={{ base: "12px", md: "24px" }}
                >
                  <GridItem>
                    <FormControl>
                      <FormLabel htmlFor="loc_s_AddressLine1">
                        {t("Address Line 1")}:
                      </FormLabel>
                      <Input
                        id="loc_s_AddressLine1"
                        name="loc_s_AddressLine1"
                        autoComplete="none"
                        type="text"
                        bgColor={"white"}
                        className="dashboard-group-input"
                        value={formDataArray.loc_s_AddressLine1}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel htmlFor="loc_s_CityName">
                        {t("City")}:
                      </FormLabel>
                      <Input
                        isDisabled
                        id="loc_s_CityName"
                        name="loc_s_CityName"
                        bgColor={"gray.200"}
                        _disabled={{
                          opacity: 1,
                        }}
                        type="text"
                        value={formDataArray.loc_s_CityName}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel htmlFor="loc_s_StateName">
                        {t("State")}:
                      </FormLabel>
                      <Input
                        isDisabled
                        id="loc_s_StateName"
                        name="loc_s_StateName"
                        bgColor={"gray.200"}
                        _disabled={{
                          opacity: 1,
                        }}
                        type="text"
                        value={formDataArray.loc_s_StateName}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel htmlFor="loc_s_PostalCode">
                        {t("Zip")}:
                      </FormLabel>
                      <Input
                        id="loc_s_PostalCode"
                        name="loc_s_PostalCode"
                        autoComplete="none"
                        bgColor={"white"}
                        className="dashboard-group-input"
                        type="number"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        onWheel={(e) => {
                          e.target.blur();
                        }}
                        value={formDataArray.loc_s_PostalCode}
                        onChange={(e) => {
                          if (e.target.value.length <= 5) {
                            handleChange(e);
                          }
                        }}
                        onBlur={(e) => getDataFromZip(e.target.value, "loc")}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel htmlFor="loc_s_CountyName">
                        {t("County")}:
                      </FormLabel>
                      <Input
                        isDisabled
                        id="loc_s_CountyName"
                        name="loc_s_CountyName"
                        bgColor={"gray.200"}
                        _disabled={{
                          opacity: 1,
                        }}
                        type="text"
                        value={formDataArray.loc_s_CountyName}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </>
            )}
            {copymail && (
              <Flex
                textAlign={"center"}
                justifyContent={"center"}
                p={5}
                opacity={0.8}
              >
                <Text pt={4} fontSize={"xl"} color={"green"}>
                  {t("Copied as Mailing Address")}
                </Text>
              </Flex>
            )}
          </Box>
          <Container pt={4} pb={6} minH={"10vh"}>
            <Flex gap={6} alignItems={"center"} justifyContent={"center"}>
              <Box>
                <Button
                  variant={"outline"}
                  borderRadius={"xl"}
                  onClick={prevStep}
                  _hover={{ color: "white", bgColor: "primary.500" }}
                  leftIcon={<IoArrowBackSharp size={18} />}
                >
                  {t("Back")}
                </Button>
              </Box>
              <Box>
                <Button
                  type="submit"
                  color={"white"}
                  bgColor={"primary.500"}
                  borderRadius={"xl"}
                  onClick={handleSubmit}
                  rightIcon={<GrFormNextLink size={24} />}
                >
                  {t("Next")}
                </Button>
              </Box>
            </Flex>
          </Container>
        </VStack>
      </Box>
      {/* Zip Details Modal */}
      <Modal isOpen={show} size={"lg"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>
            {t("Select City and County")}
          </ModalHeader>
          <ModalBody py={4}>{multipleCity}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
