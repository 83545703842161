import React, { useEffect, useState } from "react";
import { Text, Button, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const ICON_MAP = {
  NONE: "/starImages/miniIcons/none.svg",
  "24HOURS": "/starImages/miniIcons/availaible247.svg",
  LESSTHEN24HOURS: "/starImages/miniIcons/availaible12.svg",
};

const DEFAULT_ICON = "/starImages/miniIcons/none.svg";

const MaintenanceServiceCard = ({ name, icon, isSelected, onClick }) => (
  <Button
    width="full"
    justifyContent={"flex-start"}
    borderRadius={"xl"}
    variant={isSelected ? "outline" : "outline"}
    colorScheme={isSelected ? "primary" : "gray"}
    py={6}
    leftIcon={<Image src={icon || DEFAULT_ICON} width="2em" height="1.5em" />}
    onClick={onClick}
  >
    {name}
  </Button>
);

export const MaintenanceServiceSelection = ({ index }) => {
  const { buildingDetails, setBuildingDetails, baseDD } = useQuote();
  const { t } = useTranslation();
  const [maintenanceServices, setMaintenanceServices] = useState([]);

  useEffect(() => {
    const servicesWithIcons = baseDD?.COMMAINTENANCE?.map((service) => ({
      name: service.s_AppCodeNameForDisplay,
      icon: ICON_MAP[service.s_AppCodeName] || DEFAULT_ICON,
      value: service.s_AppCodeName,
    }));

    setMaintenanceServices(servicesWithIcons);
  }, [baseDD]);

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  const handleSelection = (name) => {
    updateBuildingDetails(index, "maintenanceService", name);
  };

  return (
    <>
      <Flex gap={2} my={0}>
        <Text fontWeight="500">{t("Maintenance Service")}:</Text>{" "}
        {buildingDetails[index]?.maintenanceService && (
          <Text fontWeight="500" color={"neutral.300"}>
            ({" "}
            {baseDD?.COMMAINTENANCE.find(
              (service) =>
                service.s_AppCodeName ===
                buildingDetails[index]?.maintenanceService
            )?.s_AppCodeNameForDisplay ||
              buildingDetails[index]?.maintenanceService}{" "}
            )
          </Text>
        )}
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={4} align="stretch">
        {maintenanceServices?.map(({ name, icon, value }) => (
          <GridItem key={name}>
            <MaintenanceServiceCard
              name={name}
              icon={icon}
              isSelected={buildingDetails[index]?.maintenanceService === value}
              onClick={() => handleSelection(value)}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
