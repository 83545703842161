import React, { useState } from "react";
import {
  Select,
  FormControl,
  FormLabel,
  useToast,
  Flex,
  Button,
} from "@chakra-ui/react";
import { FaSave, FaWindowClose } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { buissnessClassFields } from "../data/commercialDD";

const BusinessClass = ({ commercialData, setCommercialData, onClose }) => {
  const toast = useToast();
  const [selectedClass, setSelectedClass] = useState(
    commercialData?.propertyDetail?.[0]?.buissnessClass || ""
  );
  const { t } = useTranslation();

  const handleSave = () => {
    if (!selectedClass) {
      toast({
        title: "Selection Required",
        description: "Please select a business class",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-center",
      });
      return;
    }

    setCommercialData((prev) => ({
      ...prev,
      propertyDetail: prev.propertyDetail.map((location, index) =>
        index === 0 ? { ...location, buissnessClass: selectedClass } : location
      ),
    }));

    toast({
      title: "Business Class Updated",
      description: `Business class set to ${selectedClass}`,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top-center",
    });

    onClose();
  };

  return (
    <Flex direction={"column"} gap={8} my={4}>
      <FormControl>
        <FormLabel htmlFor="business-class-select">
          {t("Select Class")}:
        </FormLabel>
        <Select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
        >
          <option value=""></option>
          {buissnessClassFields.map((businessClass) => (
            <option key={businessClass} value={businessClass}>
              {businessClass}
            </option>
          ))}
        </Select>
      </FormControl>
      <Flex justifyContent={"flex-end"} gap={3}>
        <Button
          fontWeight={400}
          borderRadius={"xl"}
          variant="outline"
          onClick={onClose}
          leftIcon={<FaWindowClose />}
        >
          {t("Cancel")}
        </Button>
        <Button
          fontWeight={400}
          borderRadius={"xl"}
          onClick={handleSave}
          leftIcon={<FaSave />}
        >
          {t("Change")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default BusinessClass;
