import "bootstrap-daterangepicker/daterangepicker.css";
import React, { useEffect, useState } from "react";
// import { Navbar, Button, Col, Form, Row, Tab, Tabs, Alert, Badge } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  FaRegFileImage,
  FaRegIdBadge,
  FaRegFilePdf,
  FaBan,
  FaCheckCircle,
  FaFileUpload
} from "react-icons/fa";
import { GoReport } from "react-icons/go";
import { GrHelpBook } from "react-icons/gr";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { connect, useDispatch } from "react-redux";
import {
  verifyPolicy, setPolicyPropsData, setTermMaster, setTransaction, applicationData, termSelection,
  transactionDD, getCvg, additionalData, mortgageeData, householdData, addiIntData, priorPolicyLossData, getBillingData,
  getPropertyInfo, getBaseData, decPage, updateAgentTaskUserStatus
} from "../../../services/coreSystemService";
import {checkClaimExist,getReportAClaim} from "../../../services/claimService";

import AdditionalInfoTab from "./AdditionalInfoTab";
import BillingTab from "./BillingTab";
import CoverageTab from "./CoverageTab";
import PropertyInfoTab from "./PropertyInfoTab";
import ApplicationTab from "./ApplicationTab";
import FloodInfoTab from "./FloodPropertyInfo";
import ClaimTab from "./ClaimTab";
import "./policy-details.scss";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AttachmentModal from "../Common/Attachment/index";
import ReactTooltip from "react-tooltip";
import NewTransaction from "./NewTransaction";
import EndorsementIndex from "../endorsement-quote";
import Swal from 'sweetalert2';
// import notIcon from "../../../assets/noti-icon.png";
import LogModalView from "../Common/LogModalView";
import CancelQuoteIndex from "../cancel-quote";
import Moment from 'moment';
import PayNow from "../Common/PayNow";
import { resetCoreStore } from "../../../store/actions";
import { checkPivotOutage } from "../../../services/commonService";
import HiscoxActions from "../hiscox-actions/HiscoxActions";
// import Dropdown from 'react-bootstrap/Dropdown';
import PropertyInfoHiscox from "./PropertyInfoHiscox";
import { Alert, AlertIcon, Badge, Box, Button, Flex, FormControl, FormLabel, Image, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import CommonHeader from "./CommonHeader";
import RequiredDocument from "../Core/RequiredDocument";

const PolicyDetails = (props) => {
  const navigation = useNavigate();
  const isPivotOutage = checkPivotOutage();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [activeTab, setActiveTab] = useState('application');
  const { policyNumber, type = '' } = useParams();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showActivityLogModal, setShowActivityLogModal] = useState(false);
  const [showNewTransactionModal, setShowNewTransactionModal] = useState(false);
  const [quoteStatus, setQuoteStatus] = useState(false);
  const [showEndorse, setShowEndorse] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [transactionType, setTransactionType] = useState('ENDORSE');
  const [tranTypeCode, setTranTypeCode] = useState("");
  const [showCancel, setShowCancel] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [showPaymentButton, setShowPaymentButton] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [showDecPageButton, setShowDecPageButton] = useState(false);
  const [showCancelPendingAlert, setShowCancelPendingAlert] = useState(false);
  const [ratingMethod, setRatingMethod] = useState(null);
  const [agentTasks, setAgentTasks] = useState([]);
  const [agentTasksNote, setAgentTasksNote] = useState('');
  const [isRollover, setIsRollover] = useState(false);
  const [paymentSource, setPaymentSource] = useState("AgentSide_UW_Renewquote");
  const [quoteSource, setQuoteSource] = useState('');
  const[mappingId, setMappingPk]=useState("");
  const [productCodeName, setProdeuctCodeName] = useState(false);
  const [requireDocumentCount, setRequireDocumentCount] = useState(0);
  const [transactionPK, setTransactionPK] = useState(0);
  const [showCoreRequiredDocumentModel, setShowCoreRequiredDocumentModel] = useState(false);

  const [dropdownAction, setDropdownAction] = useState("");
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(() => {
    if(["HiscoxFloodPlus"].includes(props?.applicationData?.s_ProductCode)){
      setProdeuctCodeName(props?.applicationData?.s_ProductCode);
    }
  } , [props?.applicationData?.s_ProductCode]);
  // Verify Policy If Policy ID, Term Master ID & Transaction ID Empty
  useEffect(() => {
    const verify = async () => {
      setLoading(true);
      await dispatch(verifyPolicy(policyNumber)).then((res) => {
        if (res.status === true) {
          const params = {
            n_POPolicyMasterFK: res.policyIdPK,
            n_POTermMasterFK: res.termMasterPK,
            n_POTransactionFK: res.poTransPK,
          };
          dispatch(setPolicyPropsData(params));
          dispatch(
            getBaseData(res.productFK)
          );
          setTransactionPK(res.poTransPK)
          getApplicant(res.poTransPK);
        } else {
          toast.error(res.message);
          navigation(`/core/policy`);
        }
      }).catch(() => {
        setLoading(false);
      });
    };

    if (policyNumber) {
      verify();
    }	

	checkReportAClaimFunc();
  }, [policyNumber]);

  // Trigger on update Policy ID
  useEffect(() => {
    const getTerm = () => {
      dispatch(termSelection(props.n_POPolicyMasterFK));
    };
    if (props.n_POPolicyMasterFK !== "") {
      getTerm();
    }
  }, [dispatch, props.n_POPolicyMasterFK]);

  // Trigger on update Policy ID & Term Master
  useEffect(() => {
    if (props.n_POPolicyMasterFK !== "" && props.n_POTermMasterFK !== "") {
      getTransactions(props.n_POPolicyMasterFK, props.n_POTermMasterFK);
    }
  }, [props.n_POPolicyMasterFK, props.n_POTermMasterFK]);

  useEffect(() => {
    if (type == 'DOWNLOADDOCUMENTS') {
      // setActiveTab('forms');
      setCurrentTabIndex(6);
    }
    if (type == 'DOCUMENTS') {
      setShowModal(true)
    }
    
    if (Object.keys(props.applicationData).length != 0) {
      if (type == 'VIEWENDORSEPOLICY' && props.applicationData.s_TransactionCycleCode === 'ISSUED') {
        transactionModalFunction();
      }
    }
  }, [type, props.applicationData]);


  useEffect(() => {
    var quoteStatus = false;
    var isEndorse = false;

    if (
      (props.transactionDD && props.transactionDD.length > 0) && 
      props.n_POTransactionFK &&
      props?.applicationData?.s_ProductCode
    ) {
      // filter transaction dropdown based on selected transaction to reflect screen
      var temp = props.transactionDD.filter((val) => val.n_potransaction_PK === parseInt(props.n_POTransactionFK))?.[0];

      // only for quote transaction
      if (
        temp &&
        temp.s_TransactionCycleCode === 'QUOTE'
      ) {
        quoteStatus = true;

        // Only for flood product endorsement transaction
        if (
          temp.s_TranTypeScreenName === 'Endorsement' &&
          props?.applicationData?.s_ProductCode === 'FLOOD'
        ) {
          isEndorse = true;
          quoteStatus = false;
        }
      }
    }

    setShowEndorse(isEndorse);
    setQuoteStatus(quoteStatus);
  }, [props.transactionDD, props.applicationData]);

  // get selected trans type code
  useEffect(() => {
    if (props.n_POTransactionFK !== "" && props.transactionDD && props.transactionDD.length > 0) {
      var temp = props.transactionDD.filter((val) => val.n_potransaction_PK == props.n_POTransactionFK)[0];
      temp && setTranTypeCode(temp.s_PRTranTypeCode || "");
    }
  }, [props.n_POTransactionFK, props.transactionDD]);

  // check pre cancel request quote 
  useEffect(() => {
    if (props.applicationData && props.applicationData.hasOwnProperty("preCancelQuoteRequest")) {
      var preCancelQuoteRequests = props.applicationData.preCancelQuoteRequest;
      if (preCancelQuoteRequests.count && preCancelQuoteRequests.count > 0) {
        setShowCancel(true);
        handleShowAlert(preCancelQuoteRequests);
      }

      // show payment button
      const applicationData = props.applicationData;
      var temp = false;
      if (
        ['New Business', 'Renewal'].includes(applicationData.s_TranTypeScreenName) &&
        applicationData.s_TransactionCycleCode === "QUOTE"
      ) {
        if (applicationData.showPaymentBtn) {
          // payment source 
          if(applicationData.s_TranTypeScreenName == 'New Business') {
            setPaymentSource("AgentSide_UW_NBquote");
          }
          temp = applicationData.showPaymentBtn;
        }
      }
      setShowPaymentButton(temp);
      // display cancel pending message
      if (
        applicationData.s_TranTypeScreenName === "Cancel Policy" &&
        applicationData.s_TransactionCycleCode === "QUOTE"
      ) {
        setShowCancelPendingAlert(true);
      }

      // setRatingMethod
      if (props.applicationData.ratingMethod) {
        setRatingMethod(props.applicationData.ratingMethod);
      }
    }

    if (props.applicationData && props.applicationData.hasOwnProperty('uwAgentTasks')) {      
      if(props.applicationData.uwAgentTasks.taskArray.length > 0){
        setAgentTasks(props.applicationData.uwAgentTasks.taskArray);
        setAgentTasksNote(props.applicationData.uwAgentTasks.taskNote);
       }
       else{
        setAgentTasks([]);
        setAgentTasksNote();
       }
    }

    // show only on rollover renewal 
    if (props.applicationData && props.applicationData.hasOwnProperty('isRollover')) {
      setIsRollover(props.applicationData.isRollover || false);
    }

    // source of quote creation
    if (props.applicationData && props.applicationData.hasOwnProperty('quoteSource')) setQuoteSource(props.applicationData.quoteSource || '');

    // to show dec button
    if (
      ['New Business', 'Renewal', 'Endorsement'].includes(props.applicationData.s_TranTypeScreenName) &&
      props.applicationData.s_TransactionCycleCode === "ISSUED" && policyNumber== props.applicationData.Policy_No
    ) {
      setShowDecPageButton(true);
    } else{
      setShowDecPageButton(false);
    }
    
    // to show required document count
    if (props?.applicationData && props?.applicationData?.requiredDocCount) {
      setRequireDocumentCount(props?.applicationData?.requiredDocCount || 0);
    }
  }, [props.applicationData])

  useEffect(() => {
    setMappingPk(agentTasks.map(item => item.mappingpk).join('|')); 
  }, [agentTasks]);

  const transactionModalFunction = async () => {
    setLoading(true);
    setShowNewTransactionModal(true);
  };

  // Fetch Transaction List and set transaction data
  const getTransactions = async (policyIdPK, termMasterPK) => {
    await dispatch(transactionDD(policyIdPK, termMasterPK)).then((res) => {
      if (res && res.length > 0) {
        dispatch(setTransaction(res[0].n_potransaction_PK));
      } else {
        toast.error(t('Something Went Wrong!'));
      }
    }).catch(() => {
      toast.error(t('Something Went Wrong!'));
    });
  };

  // Fetch Policy & Applicant Details
  const getApplicant = async (poTransPK) => {
    setLoading(true);
    await dispatch(applicationData(poTransPK)).catch(() => {
      setLoading(false);
    });
    setLoading(false);
  };

  // Fetch Transaction List On Change Term
  const onTermChange = (termMasterPK) => {
    // setActiveTab('application');
    setCurrentTabIndex(0);
    getTransactions(props.n_POPolicyMasterFK, termMasterPK);
    dispatch(setTermMaster(termMasterPK));
  };

  // Fetch Policy & Applicant Details On Change Trnasctions
  const onTransactionChanged = (poTransPK) => {
    var getScreenName = "";
    var getCycleCode = "";
    if (props.transactionDD && props.transactionDD.length > 0) {
      var temp = props.transactionDD.filter((val) => val.n_potransaction_PK == poTransPK)[0];
      getScreenName = temp.s_TranTypeScreenName;
      getCycleCode = temp.s_TransactionCycleCode;
    }
    if (
      getScreenName === 'Endorsement' &&
      getCycleCode === 'QUOTE' &&
      props?.applicationData?.s_ProductCode === 'FLOOD'
    ) {
      setShowEndorse(true);
    } else {
      setShowEndorse(false);
      // setActiveTab('application');
      setCurrentTabIndex(0);
      getApplicant(poTransPK);
    }
    dispatch(setTransaction(poTransPK));
  };

  const handleSelect = async (key) => {
    // setActiveTab(key);
    setCurrentTabIndex(key);
    switch (key) {
      case 1: //'coverage'
        setLoading(true);
        await dispatch(getCvg(props.n_POPolicyMasterFK, transactionPK)).catch(() => {
          setLoading(false);
        });
        setLoading(false);
        break;
      case 2: //"property-info"
        setLoading(true);
        await dispatch(
          getPropertyInfo(props.n_POPolicyMasterFK, transactionPK)
        ).catch(() => {
          setLoading(false);
        });
        setLoading(false);
        break;
      case 3: //'additional-info'
        setLoading(true);       
        try {
          await dispatch(mortgageeData(transactionPK));
          await dispatch(addiIntData(transactionPK));
          if (productCodeName !== "HiscoxFloodPlus") {
            await dispatch(additionalData(props.n_POPolicyMasterFK, transactionPK));
            await dispatch(householdData(transactionPK));
            await dispatch(priorPolicyLossData(transactionPK));
          }   
        }
        finally {
          setLoading(false);
          break;
        }
      case 4: //'billings'
        setLoading(true);
        try {
          await dispatch(getBillingData(policyNumber));
        }
        finally {
          setLoading(false);
          break;
        }
      default:
        break;
    }
  };

  // get declaration page as per transaction type
  const getDecPage = async () => {
    setLoading(true);
    const poTransPK = transactionPK;
    const transTypeName = props.applicationData.s_TranTypeScreenName;
    const decPageType = transTypeName === 'New Business' ? 'DeclarationNB' : 'DeclarationEndorse';
    await dispatch(decPage(poTransPK, decPageType))
      .then((res) => {
        if (res.status === 'Y') {
          window.open(res.url, '_blank');
        }
      }).catch((err) => {
        
      });
    setLoading(false);
  }

  const handleAction = async (eventkey) => {
    setLoading(true);
    await dispatch(updateAgentTaskUserStatus(eventkey, mappingId))
      .then((res) => {
        getApplicant(transactionPK);
      }).catch((err) => {
        
      });
    setLoading(false);
  }

  const transactionModal = async (type) => {
    setShowNewTransactionModal(true)
    setTransactionType(type);
  }

  const reportAClaimFunc = (event) => {
    event.preventDefault();
    dispatch(checkClaimExist(policyNumber)).then((res) => {
      if (res.data == 0) {
        navigation("/claim/report-a-claim/" + policyNumber);
      } else {

        Swal.fire({
          icon: 'warning',
          title: t('Please Wait'),
          text: t('Claim is Already Reported for this Policy'),
          confirmButtonColor: '#66e3d8',
        })
      }
    });
  }
  
  const checkReportAClaimFunc = () => {
	dispatch(getReportAClaim(policyNumber)).then((res) => { 
		if(res.data != null) {
			setShowNotif(true);
		}
	});
  }
  
  const viewAClaimFunc = (event) => {
	event.preventDefault();
	navigation("/claim/view-a-claim/"+policyNumber);
  }

  // alert for pre cancel created
  const handleShowAlert = (data) => {
    setShowAlert(true);
    var text = t("Pre cancel request by ") + data.updatedUser + t(" on ") + Moment(data.updatedDate).format("MM-DD-YYYY h:mm A");
    setAlertText(text);
  }

  // for payment
  const getPaymentAmount = () => {
    var amount = 0;

    // (Premium Changes + Fees) 
    if (props.applicationData) {
      amount = parseInt(props.applicationData.n_PremiumChange) + parseInt(props.applicationData.n_Policyfees);
    }

    return amount;
  }

  // load updated data
  const afterPayment = async () => {
    dispatch(resetCoreStore());
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  const taskList = agentTasks.map((record , index ) => {
    return(
      <span Key={record.id}>
        {(index ? ', ' : '' ) + record.task_title}
      </span>
    )
  });

  const handlechange=(eventKey)=>{
    setDropdownAction(eventKey);
    handleAction(eventKey);
  }

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading || paymentLoading} />

        {agentTasks.length > 0 && (
          <Box>
            <Flex
              cursor={"pointer"}
              className="header-section"
              justifyContent={"space-between"}
              mx={0}
            >
              <Flex align={"center"}>
                <Image src="/assets/images/notificationBell.png" />
                <Text width={"700px"} fontWeight={700} ml={4} mb={0}>
                  {t("Agent Task")} : {taskList}. {t("UW Note")} :{" "}
                  {agentTasksNote}{" "}
                </Text>
              </Flex>
              <Box>
                <Select
                  bg={"var(--chakra-colors-primary-500)"}
                  color={"white"}
                  width={"max-content"}
                  value={dropdownAction}
                  onChange={(e) => handlechange(e.target.value)}
                >
                  <option style={{ color: "black" }} value={"NOACTION"}>
                    {"No Action"}
                  </option>
                  <option style={{ color: "black" }} value={"DELETED"}>
                    {"Deleted"}
                  </option>
                  <option style={{ color: "black" }} value={"COMPLETED"}>
                    {"Completed"}
                  </option>
                </Select>
              </Box>
            </Flex>
          </Box>
        )}

        {/* Hiscox Action Buttons */}
        {productCodeName === "HiscoxFloodPlus" && (
          <HiscoxActions
            setLoading={setLoading}
            quoteStatus={quoteStatus}
            tranTypeScreenName={props.applicationData.s_TranTypeScreenName}
            transactionCycleCode={props.applicationData.s_TransactionCycleCode}
            termSelection={props.termSelection}
          />
        )}

        <Box className="policy-details-section">

          {/* Flood Action Buttons */}
          {!productCodeName && (
            <Flex mr={4} mb={4} justifyContent={"flex-end"}>
              {showPaymentButton === true && (
                <PayNow
                  showPaymentButton={showPaymentButton}
                  amount={getPaymentAmount()}
                  successUrl={null}
                  cancelUrl={null}
                  policyNumber={policyNumber}
                  poTransPK={props.n_POTransactionFK}
                  loading={paymentLoading}
                  setLoading={setPaymentLoading}
                  afterPayment={afterPayment}
                  source={paymentSource}
                  transTypeScreenName={
                    props.applicationData.s_TranTypeScreenName
                  }
                />
              )}
                <Button
                  className="mx-2"
                  size={"sm"}
                  leftIcon={requireDocumentCount > 0 ? <Badge bg='danger' className='Blink'>{requireDocumentCount}</Badge> : <FaFileUpload size={18} />}
                  onClick={() => setShowCoreRequiredDocumentModel(!showCoreRequiredDocumentModel)}
                >
                  {t("Required Document")}
                </Button>
              <Button
                size={"sm"}
                leftIcon={<FaRegIdBadge size={18} />}
                onClick={() => setShowActivityLogModal(!showActivityLogModal)}
              >
                {t("log")}
              </Button>
              <Button
                ml={2}
                size={"sm"}
                leftIcon={<FaRegFileImage size={18} />}
                onClick={() => setShowModal(true)}
              >
                {t("Documents")}
              </Button>
              {
                // Do not show dec page option on cancel transaction
                showDecPageButton && (
                  <Button
                    ml={2}
                    size={"sm"}
                    leftIcon={<FaRegFilePdf size={18} />}
                    onClick={() => getDecPage()}
                  >
                    {t("Dec Page")}
                  </Button>
                )
              }
              {!quoteStatus &&
                //display only for 2.0
                ratingMethod === "2.0" &&
                // Only If Want to view or create endorsement
                props.applicationData &&
                props.applicationData.s_TranTypeScreenName !==
                  "Cancel Policy" &&
                props.applicationData.s_TransactionCycleCode === "ISSUED" && (
                  <Button
                    ml={2}
                    size={"sm"}
                    isDisabled={isPivotOutage == "false" ? false : true}
                    leftIcon={<FaCheckCircle size={18} />}
                    onClick={() => transactionModal("ENDORSE")}
                  >
                    {t("Create Endorsement")}
                  </Button>
                )}
              {!quoteStatus &&
                //display only for 2.0
                ratingMethod === "2.0" &&
                // Only If Want to view or cancel policy
                props.applicationData &&
                props.applicationData.s_TranTypeScreenName !==
                  "Cancel Policy" &&
                props.applicationData.s_TransactionCycleCode === "ISSUED" && (
                  <Button
                    ml={2}
                    size={"sm"}
                    isDisabled={isPivotOutage == "false" ? false : true}
                    leftIcon={<FaBan size={18} />}
                    onClick={() => setShowCancel(true)}
                  >
                    {t("Cancel Policy")}
                  </Button>
                )}
              {!(
                props.applicationData.s_TranTypeScreenName === "New Business" &&
                props.applicationData.s_TransactionCycleCode === "QUOTE"
              ) && (
                <Button
                  ml={2}
                  size={"sm"}
                  leftIcon={<GoReport size={18} />}
                  onClick={(e) => reportAClaimFunc(e)}
                >
                  {t("Report A Claim")}
                </Button>
              )}
              <div data-for="soclose" data-tip="7">
                <Button
                  size={"sm"}
                  ml={2}
                  leftIcon={<GrHelpBook  size={18} />}
                >
                  {t("Document Help")}
                </Button>
              </div>
              <ReactTooltip
                id="soclose"
                getContent={() => (
                  <div>
                    <p style={{
                  width: "250px"
                }}>
                      {t("Having trouble finding the form you're looking for")}? {t("Try toggling the Terms Selection or Transaction Selection dropdowns to view various copies of the policy documents")}.
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place="bottom"
                border={true}
                type="light"
              />
            </Flex>
          )}

          {/* Warnings */}
          {showAlert && (
            <Alert status="warning" mb={2}>
              <AlertIcon />
              <Text>{alertText}</Text>
              <Box
                _hover={{ textDecoration: "underline" }}
                cursor={"pointer"}
                onClick={() => setShowCancel(true)}
                color={"var(--chakra-colors-primary-500)"}
              >
                {t("View")}
              </Box>
            </Alert>
          )}
          {showCancelPendingAlert && (
            <Alert status="warning" mb={2}>
              <AlertIcon />
              {t("Cancellation Pending")}
            </Alert>
          )}
          {isRollover && (
            <Alert status="info" mb={2}>
              <AlertIcon />
              {t("Rollover at Renewal")}
            </Alert>
          )}
          {showNotif && (
            <Flex
              className="header-section"
              cursor={"pointer"}
              align={"center"}
              mx={0}
            >
              <Image src="/assets/images/notificationBell.png" />
              <Text fontWeight={700} ml={4} mb={0} mr={4}>
                {t("Recent reported claim is under review please ")}
              </Text>
              <a
                style={{ textDecoration: "underline" }}
                href="#"
                onClick={(e) => viewAClaimFunc(e)}
              >
                {t("Click here to view")}
              </a>
            </Flex>
          )}

          {/* Policy Details */}
          <Box
            my={4}
            border={"1px solid white"}
            borderRadius={"2xl"}
            boxShadow={"lg"}
            p={5}
            bg={"white"}
          >
            <Flex justifyContent={"flex-end"}>
              <Flex>
                {
                  // source of quote creation
                  quoteSource && (
                    <Badge
                      borderRadius={"md"}
                      p={"4px"}
                      colorScheme="yellow"
                      fontSize="0.9em"
                      height={"max-content"}
                    >
                      {t("Quote Source")}: {quoteSource}
                    </Badge>
                  )
                }
                <FormControl width={"max-content"} ml={4}>
                  <FormLabel className="dashboard-group-label">
                    {t("Terms Selection") + "(" + props?.n_POTermMasterFK + ")"}
                  </FormLabel>
                  <Select
                    name="termSelection"
                    className="dashboard-group-input"
                    id="select-ym-option"
                    value={props?.n_POTermMasterFK || ""}
                    onChange={(e) => onTermChange(e.currentTarget.value)}
                  >
                    {Object.keys(props.termSelection).length > 0 &&
                      props.termSelection.map((v, i) => {
                        return (
                          <option key={i} value={v.n_TermMaster_PK}>
                            {v.term}
                          </option>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl width={"max-content"} ml={4}>
                  <FormLabel className="dashboard-group-label">
                    {t("Transaction Selection")}
                    {"(" + props?.n_POTransactionFK + ")"}
                  </FormLabel>
                  <Select
                    name="transactionSelection"
                    className="dashboard-group-input"
                    id="select-ym-option"
                    value={props?.n_POTransactionFK || ""}
                    onChange={(e) =>
                      onTransactionChanged(e.currentTarget.value)
                    }
                  >
                    {Object.keys(props.transactionDD).length > 0 &&
                      props.transactionDD.map((v, i) => {
                        return (
                          <option key={i} value={v.n_potransaction_PK}>
                            {v.code}
                          </option>
                        );
                      })}
                  </Select>
                </FormControl>
              </Flex>
            </Flex>

            {!showEndorse && (<CommonHeader />)} {/* Common policy details */}
          </Box>

          {/* Tabs */}
          <Box mb={4}>
            {showEndorse && (
              <EndorsementIndex
                showEndorse={showEndorse}
                policyMasterFK={props.n_POPolicyMasterFK}
                poTransPK={props.n_POTransactionFK}
                policyNumber={policyNumber}
              />
            )}
            {!showEndorse && (
              <Flex id="policy-details-tabs-section">
                <Tabs
                  index={currentTabIndex}
                  onChange={(index) => handleSelect(index)}
                  width={"100%"}
                >
                  <TabList>
                    <Tab
                      mb={0}
                      _selected={{
                        bgColor: "var(--chakra-colors-primary-500)",
                        color: "white",
                      }}
                    >
                      {t("Application")}
                    </Tab>
                    <Tab
                      mb={0}
                      _selected={{
                        bgColor: "var(--chakra-colors-primary-500)",
                        color: "white",
                      }}
                    >
                      {t("Coverage")}
                    </Tab>
                    <Tab
                      mb={0}
                      _selected={{
                        bgColor: "var(--chakra-colors-primary-500)",
                        color: "white",
                      }}
                    >
                      {t("Property Info")}
                    </Tab>
                    <Tab
                      mb={0}
                      _selected={{
                        bgColor: "var(--chakra-colors-primary-500)",
                        color: "white",
                      }}
                    >
                      {t("Additional Info")}
                    </Tab>
                    <Tab
                      mb={0}
                      _selected={{
                        bgColor: "var(--chakra-colors-primary-500)",
                        color: "white",
                      }}
                    >
                      {t("Billings")}
                    </Tab>
                    {props?.applicationData?.s_ProductCode !==
                      "HiscoxFloodPlus" && (
                      <Tab
                        mb={0}
                        _selected={{
                          bgColor: "var(--chakra-colors-primary-500)",
                          color: "white",
                        }}
                      >
                        {t("Claims")}
                      </Tab>
                    )}
                  </TabList>
                  <TabPanels>
                    <TabPanel p={0}>
                      <ApplicationTab />
                    </TabPanel>
                    <TabPanel p={0}>
                      <CoverageTab />
                    </TabPanel>
                    <TabPanel p={0}>
                      {Object.keys(props.applicationData).length > 0 &&
                      props?.applicationData?.n_ProductId_FK === 10 ? (
                        <FloodInfoTab />
                      ) : props?.applicationData?.s_ProductCode ===
                        "HiscoxFloodPlus" ? (
                        <PropertyInfoHiscox />
                      ) : (
                        <PropertyInfoTab />
                      )}
                    </TabPanel>
                    <TabPanel p={0}>
                      <AdditionalInfoTab
                        poTransPK={props.n_POTransactionFK}
                        policyNumber={policyNumber}
                        policyPK={props.n_POPolicyMasterFK}
                      />
                    </TabPanel>
                    <TabPanel p={0}>
                      <BillingTab />
                    </TabPanel>
                    <TabPanel p={0}>
                      <ClaimTab policyNumber={policyNumber} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            )}
          </Box>

          {/* attachment modal */}
          {showModal && (
            <AttachmentModal
              show={showModal}
              setShow={setShowModal}
              policyNumber={policyNumber}
              policyPK={props.n_POPolicyMasterFK}
              productPK={props.applicationData.n_ProductId_FK}
            />
          )}
        {/* Core system - Required document model */}
          {
            showCoreRequiredDocumentModel && (
              <RequiredDocument
                poTransPK={props.n_POTransactionFK}
                policyNumber={policyNumber}
                policyPK={props.n_POPolicyMasterFK}
                show={showCoreRequiredDocumentModel}
                setShow={setShowCoreRequiredDocumentModel}
              />
            )
          }
          {showNewTransactionModal && !quoteStatus ? (
            <NewTransaction
              transactionDD={props.transactionDD}
              transactionType={transactionType}
              setShowNewTransactionModal={(data) =>
                setShowNewTransactionModal()
              }
              loading={loading}
              setLoading={setLoading}
            />
          ) : null}
          {/* Show Activity Log Modal */}
          {showActivityLogModal && (
            <LogModalView
              show={showActivityLogModal}
              setShow={setShowActivityLogModal}
              policyNumber={policyNumber}
              from={"POLICY_HEADER_BUTTON"}
              policyPK={props.n_POPolicyMasterFK}
              poTransPK={props.n_POTransactionFK}
              tranTypeCode={tranTypeCode}
            />
          )}
          {showCancel && (
            <CancelQuoteIndex
              show={showCancel}
              setShow={setShowCancel}
              setShowAlert={setShowAlert}
              showAlert={showAlert}
              alertText={alertText}
              handleShowAlert={handleShowAlert}
              effectiveDate={props.effectiveDate || null}
              policyNumber={policyNumber}
              policyPK={props.n_POPolicyMasterFK}
              poTransPK={props.n_POTransactionFK}
              applicationData={props.applicationData}
              termMasterFK={props.n_POTermMasterFK}
            />
          )}
        </Box>
      </LoadingOverlay>
    {/* <LoadingOverlay>
      <Loader loading={loading || paymentLoading} />
      {productCodeName == "HiscoxFloodPlus" && (
        <HiscoxActions setLoading={setLoading} 
          quoteStatus={quoteStatus} 
          tranTypeScreenName={props.applicationData.s_TranTypeScreenName}
          transactionCycleCode={props.applicationData.s_TransactionCycleCode} 
          termSelection={props.termSelection}/>
      )}
      {agentTasks.length>0 &&
      <div>       
        <Navbar className="header-section text-center" style={{marginTop:'0px',marginBottom:'0px',cursor:'pointer'}} >
          <div className="header-section-main">
            <Row className="align-items-center">
              <Col xs="9" sm="9" md="9" lg="9" xl="9">
                <div className="header-col1">
                  <img src={notIcon} />
                  <span>Agent Task : {taskList}.  UW Note : {agentTasksNote} &nbsp;&nbsp;</span>
                </div>
              </Col>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <div className="d-flex justify-content-end">
                  <Dropdown onSelect={(eventKey)=>handlechange(eventKey)}>
                      <Dropdown.Toggle style={{backgroundColor:"#64d6cd",borderColor:"#64d6cd"}} id="dropdown-basic">
                      <span>{t('Action')}</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="NOACTION">No Action</Dropdown.Item>
                        <Dropdown.Item eventKey="DELETED"> Deleted</Dropdown.Item>
                        <Dropdown.Item eventKey="COMPLETED">Completed</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                </div>
              </Col>
            </Row>
          </div>
        </Navbar> 
          <br />
      </div>
      }
      <div className="policy-details-section">
      <Row className="mb-2">
        <Col md='12' >
        {!productCodeName && 
        <div style={{
                display: "flex",
                justifyContent: "right",
              }}>
              {
                showPaymentButton === true &&
                <PayNow
                  showPaymentButton={showPaymentButton}
                  amount={getPaymentAmount()}
                  successUrl={null}
                  cancelUrl={null}
                  policyNumber={policyNumber}
                  poTransPK={props.n_POTransactionFK}
                  loading={paymentLoading}
                  setLoading={setPaymentLoading}
                  afterPayment={afterPayment}
                  source={paymentSource}
                  transTypeScreenName={props.applicationData.s_TranTypeScreenName}
                />
              }
              <div className="policy-details-icons-section" onClick={() => setShowActivityLogModal(!showActivityLogModal)}>
                <Button id="common-btn" variant="primary" size="sm" style={{ padding: "4px 6px" }}>
                  <FaRegIdBadge size={18} />&nbsp;
                  {t('log')}
                </Button>
              </div>
            &nbsp;&nbsp;
              <div className="policy-details-icons-section"  onClick={() => setShowModal(true)}>
                <Button id="common-btn" variant="primary" size="sm" style={{ padding: "4px 6px" }}>
                  <FaRegFileImage size={18} />&nbsp;
                  {t('Documents')}
                </Button>
              </div>
            &nbsp;&nbsp;
            {
              // Do not show dec page option on cancel transaction
              showDecPageButton && (
                <>
                <div className="policy-details-icons-section"  onClick={() => getDecPage()} >
                  <Button id="common-btn" variant="primary" size="sm" style={{ padding: "4px 6px" }}>
                    <FaRegFilePdf size={18} />&nbsp;
                    {t('Dec Page')}
                  </Button>
                </div>
                  &nbsp;&nbsp;
                </>
              )
            }
            {!quoteStatus &&
                //display only for 2.0 
                ratingMethod === "2.0" &&
              // Only If Want to view or create endorsement
              (props.applicationData && props.applicationData.s_TranTypeScreenName !== 'Cancel Policy' && props.applicationData.s_TransactionCycleCode === 'ISSUED') && (
                <>
                <React.Fragment>
                    <Button disabled={isPivotOutage=='false' ? false : true} onClick={() => transactionModal('ENDORSE')} id="common-btn" variant="primary" size="sm" style={{ padding: "4px 6px" }}>{t("Create Endorsement")}</Button>
                </React.Fragment>
                &nbsp;&nbsp;
                </>
              )
            }
            {!quoteStatus &&
              //display only for 2.0 
              ratingMethod === "2.0" &&
              // Only If Want to view or cancel policy
              (props.applicationData && props.applicationData.s_TranTypeScreenName !== 'Cancel Policy' && props.applicationData.s_TransactionCycleCode === 'ISSUED') && (
                <>
                <React.Fragment>
                    <Button disabled={isPivotOutage=='false' ? false : true} onClick={() => setShowCancel(true)} id="common-btn" variant="primary" size="sm" style={{ padding: "4px 6px" }}>{t("Cancel Policy")}</Button>
                </React.Fragment>
                &nbsp;&nbsp;
                </>
              )
            }
            {
              <React.Fragment>
                  <Button onClick={(e) => reportAClaimFunc(e)} id="common-btn" variant="primary" size="sm" style={{ padding: "4px 6px" }} hidden={props.applicationData.s_TranTypeScreenName === 'New Business' && props.applicationData.s_TransactionCycleCode === 'QUOTE'}>{t("Report A Claim")}</Button>
                &nbsp;&nbsp;
                <a data-for="soclose" href='#' data-tip="7">
                  <Button id="common-btn" variant="primary" size="sm" style={{ padding: "4px 6px" }}>{t("Document Help")}</Button>
                </a>
                <ReactTooltip
                  id='soclose'
                  getContent={() => (
                    <div>
                      <p>Having trouble finding the form you're looking for ? Try <br></br> toggling the Terms Selection or Transaction Selection <br></br> dropdowns to view various copies of the policy documents</p>
                    </div>
                  )}
                  effect="solid"
                  delayHide={500}
                  delayShow={500}
                  delayUpdate={500}
                  place="bottom"
                  border={true}
                  type="light"
                />
              </React.Fragment>
            }
        </div>
        }
		</Col>
          
      </Row>
        {
          showAlert && (
            <Col md='12' className='p-0'>
              <Alert color={'warning'} isOpen={true} className='p-1 pl-2 m-0 mb-1' >
                <i className='fa fa-exclamation-triangle'></i>&nbsp;&nbsp;{alertText} {" "} {<Button variant="link" onClick={() => setShowCancel(true)}>{t('View')}</Button>}
              </Alert>
            </Col>
          )
        }
        {
          showCancelPendingAlert && (
            <Col md='12' className='p-0'>
              <Alert color={'warning'} isOpen={true} className='p-1 pl-2 m-0 mb-1' >
                <i className='fa fa-exclamation-triangle'></i>&nbsp;&nbsp;{t("Cancellation Pending")}
              </Alert>
            </Col>
          )
        }
        {
          // show only on rollover renewal 
          isRollover && (
            <Col md='12' className='p-0'>
              <Alert variant={'info'} show={isRollover} className='p-2 pl-2 m-0 mb-1' >
                <i className='fa fa-info-circle'></i>&nbsp;&nbsp;{t("Rollover at Renewal")}
              </Alert>
            </Col>
          )
        }
      <Row className="mb-2 text-center">
      <Col md='6' className="text-center">
				{showNotif && 
					<Navbar className="header-section text-center" style={{marginTop:'0px',marginBottom:'0px',cursor:'pointer'}} >
				      <div className="header-section-main">
				        <Row className="align-items-center">
				          <Col xs="12" sm="12" md="12" lg="12" xl="12">
				            <div className="header-col1">
				              <img src={notIcon} />
				              <span>{t('Recent reported claim is under review please ')}&nbsp;&nbsp;</span><a href="#" onClick={(e) => viewAClaimFunc(e)}> click here to view</a>
				            </div>
				          </Col>
				        </Row>
				      </div>
				      <br />
				    </Navbar>
				}
			</Col>
		
      </Row>
        <Row className="policy-details-header" style={{ padding: "5px" }}>
          {/* <Col md='5'>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="policy-details-icons-section"
                onClick={() => setShowActivityLogModal(!showActivityLogModal)}
              >
                <FaRegIdBadge size={20} color="#64D6CD" />
                <span>{t('log')}</span>
              </div>
              <div
                className="policy-details-icons-section"
                onClick={() => setShowModal(true)}
              >
                <FaRegFileImage size={20} color="#64D6CD" />
                <span>{t('Attachment')}</span>
              </div>
              {
                // Do not show dec page option on cancel transaction
                props.applicationData && props.applicationData.s_TranTypeScreenName !== 'Cancel Policy' && (
                  <div
                    className="policy-details-icons-section"
                    onClick={() => getDecPage()}
                  >
                    <FaRegFilePdf size={20} color="#64D6CD" />
                    <span>{t('Dec Page')}</span>
                  </div>
                )
              }
            </div>
          </Col> */}
          {/* <Col xs='3'>
            <Form.Label
              className="dashboard-group-label"
            >
              {t('Terms Selection') + '(' + props.n_POTermMasterFK + ')'}
            </Form.Label>
            <Form.Select
              name="termSelection"
              className="dashboard-group-input"
              id="select-ym-option"
              value={props.n_POTermMasterFK}
              onChange={(e) => onTermChange(e.currentTarget.value)}
            >
              {Object.keys(props.termSelection).length > 0 &&
                props.termSelection.map((v, i) => {
                  return (
                    <option key={i} value={v.n_TermMaster_PK}>
                      {v.term}
                    </option>
                  );
                })}
            </Form.Select>
          </Col>
          <Col md='4'>
            <Form.Label
              className="dashboard-group-label"
            >
              {t('Transaction Selection')}
              {
                "(" + (props.n_POTransactionFK ? props.n_POTransactionFK : '') + ")"
              }
            </Form.Label>
            <Form.Select
              className="dashboard-group-input"
              id="select-ym-option"
              value={props.n_POTransactionFK}
              onChange={(e) =>
                onTransactionChanged(e.currentTarget.value)
              }
            >
              {Object.keys(props.transactionDD).length > 0 &&
                props.transactionDD.map((v, i) => {
                  return (
                    <option key={i} value={v.n_potransaction_PK}>
                      {v.code}
                    </option>
                  );
                })}
            </Form.Select>
          </Col>
          {
            // source of quote creation
            (quoteSource) && (
              <Col md='5' style={{ textAlign: "right" }}>
                <h5><Badge bg="warning">Quote Source: {quoteSource}</Badge></h5>
              </Col>
            )
          }
        </Row>
        <Row>
          {
            showEndorse && (
              <EndorsementIndex
                showEndorse={showEndorse}
                policyMasterFK={props.n_POPolicyMasterFK}
                poTransPK={props.n_POTransactionFK}
                policyNumber={policyNumber}
              />
            )
          }
          {
            !showEndorse && (
              <Col xs="12" id="policy-details-tabs-section" className="custom-tabs">
                <Tabs
                  activeKey={activeTab}
                  id="uncontrolled-tab-example"
                  onSelect={handleSelect}
                >
                  <Tab eventKey="application" title={t("Application")}>
                    <ApplicationTab />
                  </Tab>
                  <Tab eventKey="coverage" title={t("Coverage")}>
                    <CoverageTab />
                  </Tab>

                  <Tab eventKey="property-info" title={t("Property Info")}>

                    {Object.keys(props.applicationData).length > 0 &&
                      (props.applicationData.n_ProductId_FK == 10)
                        ? (
                      <FloodInfoTab />
                    ) : (
                      (props.applicationData.s_ProductCode === 'HiscoxFloodPlus')
                        ? (
                          <PropertyInfoHiscox />
                        ) : (
                          <PropertyInfoTab />
                        )
                    )}
                  </Tab>
                      <Tab eventKey="additional-info" title={t("Additional Info")}>
                    <AdditionalInfoTab poTransPK={props.n_POTransactionFK} policyNumber={policyNumber} policyPK={props.n_POPolicyMasterFK}/>
                  </Tab>
                    {/* <Tab eventKey="forms" title={t("Forms")}>
                    <FormTab />
                  </Tab> */}
                  {/* <Tab eventKey="billings" title={t("Billings")}>
                    <BillingTab />
                  </Tab>
                  {
                    props.applicationData.s_ProductCode !== "HiscoxFloodPlus" && (
                      
                      <Tab eventKey="claims" title={t("Claims")}>
                    <ClaimTab policyNumber={policyNumber}/>
                  </Tab>
                    )
                  }
                </Tabs>
              </Col>
            )
          }
        </Row> */}

        {/* attachment modal */}
        {/* {
          showModal && (
            <AttachmentModal
              show={showModal}
              setShow={setShowModal}
              policyNumber={policyNumber}
              policyPK={props.n_POPolicyMasterFK}
              productPK={props.applicationData.n_ProductId_FK}
            />
          )
        }
        {showNewTransactionModal && !quoteStatus ? <NewTransaction transactionDD={props.transactionDD} transactionType={transactionType} setShowNewTransactionModal={(data) => setShowNewTransactionModal()} loading={loading} setLoading={setLoading} /> : null} */}
        {/* <Modal
          className="custom-dashboard-modal"
          size="xl"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header closeButton className="custom-dashboard-modal-header">
            <Modal.Title>Attachment</Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-dashboard-modal-body">
            <Row>
              <Col xs="12">
                <span className="dashboard-group-label">
                  Select Document Type
                </span>
                <div className="select-document-type-div">
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="6" xl="3">
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>All Documents</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Inspection Report</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Police Report</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Letter of Acknowledgement</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Signed Application</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Four Point Inspection</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Wind Mitigation Inspection</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Photos </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Proof of Prior Insurance</span>
                      </div>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="3">
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>HUD Statement</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Loss History - lexis Nexis</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Loss History - A plus</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Burglar Alarm Certificate </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Fire & Burglar Alarm Certificate</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Driver's License</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Endorsement Request</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Sinkhole Coverage Exclusion form signed </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Animal Liability Exclusion form Signed</span>
                      </div>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="3">
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>
                          Building Law & Ordination Building & Ordination{" "}
                        </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Building Permit records</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Roof permit records</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>County Appraisal records</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Return Mail PH</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Return Mail MTG</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Email from Agent</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Email from PH </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Complaint</span>
                      </div>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="3">
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>POP </span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Reissue Refund Check Request</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Reissue Claim Check Request</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Reissue Commission Check Request</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Others</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Property Appraisal</span>
                      </div>
                      <div className="document-type-subdiv">
                        <Form.Check
                          type="checkbox"
                          label=""
                          className="table-checkbox"
                          id="custom-checker"
                        />
                        <span>Claim Supporting Document</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xs="12" style={{ marginTop: "25px" }}>
                <span className="dashboard-group-label">Attachment</span>
                <div
                  id="drop-document-type-div"
                  {...getRootProps({ className: "dropzone" })}
                >
                  <input {...getInputProps()} />
                  <FaFileUpload size={34} color={"#64D6CD"} />
                  <p>Drag and Drop File Here</p>
                  <Button id="common-btn" variant="primary">
                    <FaPlus size={15} />
                    Add File
                  </Button>{" "}
                </div>

                <aside className="reply-document-name">
                  <ul>{files}</ul>
                </aside>
              </Col>
            </Row>
          </Modal.Body>
        </Modal> */}
        {/* Show Activity Log Modal */}
        {/* {
          showActivityLogModal && (
            <LogModalView
              show={showActivityLogModal}
              setShow={setShowActivityLogModal}
              policyNumber={policyNumber}
              from={"POLICY_HEADER_BUTTON"}
              policyPK={props.n_POPolicyMasterFK}
              poTransPK={props.n_POTransactionFK}
              tranTypeCode={tranTypeCode}
            />
          )
        }
        {
          showCancel && (
            <CancelQuoteIndex
              show={showCancel}
              setShow={setShowCancel}
              setShowAlert={setShowAlert}
              showAlert={showAlert}
              alertText={alertText}
              handleShowAlert={handleShowAlert}
              effectiveDate={props.effectiveDate || null}
              policyNumber={policyNumber}
              policyPK={props.n_POPolicyMasterFK}
              poTransPK={props.n_POTransactionFK}
              applicationData={props.applicationData}
              termMasterFK={props.n_POTermMasterFK}
            />
          )
        }
      </div>
      
    </LoadingOverlay> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    transactionDD: state.coreSystem.transactionDD,
    termSelection: state.coreSystem.termSelection,
    n_POPolicyMasterFK: state.coreSystem.n_POPolicyMasterFK,
    n_POTermMasterFK: state.coreSystem.n_POTermMasterFK,
    n_POTransactionFK: state.coreSystem.n_POTransactionFK,
    applicationData: state.coreSystem.applicationData,
    newTransactionBaseData: state.coreSystem.newTransactionBaseData,
    effectiveDate: state.coreSystem.applicationData ? state.coreSystem.applicationData.d_TransEffectiveFrom : null,
  };
};
export default connect(mapStateToProps)(PolicyDetails);
