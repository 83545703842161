import React, { useState, useEffect } from "react";
import "./login.scss";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { authCheck, login,getSiteData,forgotPass,addEamil,getLogo,getUserDebardStatus, logout, setUserDebardStatus} from "../../services/authService";
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";
import { EncryptOrDecrypt,checkPermission,setSiteDataInLocalStorage } from "../../services/commonService";
import Moment from "moment";
import Http from "../../Http";
import Swal from 'sweetalert2';
import { setButtonColor, setTitleColor } from "../../setButtonColor";
import SecureImage from "../common/SecureImage";
import { Card, Container, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import DebarmentModal from "./components/DebarmentModal";
import LoginPage from "./components/LoginPage";
import ResetPasswordScreenPage from "./components/ResetPasswordScreenPage";
import EmailUpdatePage from "./components/EmailUpdatePage";
import ForgotPassword from "./ForgotPassword";
import OdysseyLogin from "./OdysseyLogin";
import ResetPassword from "./ResetPassword";
import ResetUsername from "./ResetUsername";

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const token = localStorage.getItem('access_token') || '';
	const [logo, setLogo] = useState('');
    // const tenant = window.location.host.split('.')[0];
    const [userPasswordReset, setUserPasswordReset] = useState('');
	const [view, setView] = useState('login');
	const [newEmail, setNewEmail] = useState('');
	const [isPasswordExpired, setIsPasswordExpired] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDebardModal, setShowDebardModal] = useState(false);
    let debardStatus = [];
	useEffect(() => {
		callGetSiteData();
        //eslint-disable-next-line
	  }, []);
	  
	  const callGetSiteData = async () => {
		// setLoading(true);
		await dispatch(getSiteData()).then((res) => { 
			if (res.data.success === true) {
                setSiteDataInLocalStorage(res.data.data);
				setLogo(localStorage.getItem('company_logo'));
                setColors(res.data.data.mainData);
				setLoading(false);
			} else {
				localStorage.setItem('company_logo','https://www.pngkey.com/png/full/246-2467574_your-company-slogen-here-your-company-logo-here.png');
				setLogo(localStorage.getItem('company_logo'));
				setLoading(false);
			}
            dispatch(getLogo(localStorage.getItem('company_logo')));
		});
	  };

      function setColors(mainData){
        setButtonColor({ color:  mainData[0]['primary_color'] });
        setTitleColor({ color: mainData[0]['secondary_color'] });
      }
	
    useEffect(() => {
        if (token.length > 0) {
            window.location = '/';
        }
        //eslint-disable-next-line
    }, [])
	 
	// const toggelView = async (e) => {
    //     e.preventDefault();

    // }
    const handleDebard = async(e) =>{
        setLoading(true)
        const userId = EncryptOrDecrypt(localStorage.getItem('user_id'), 'D');
        await dispatch(setUserDebardStatus({'Admin_ID':userId,'is_approved':false,'is_debarred':true}))
        await dispatch(logout());
        localStorage.clear();
        setShowModal(false);
        setLoading(false)
        setShowDebardModal(true);
        //navigate('/login');
    }
    const handleApproved = async(e) =>{
        setLoading(true)
        const userId = EncryptOrDecrypt(localStorage.getItem('user_id'), 'D');
        await dispatch(setUserDebardStatus({'Admin_ID':userId,'is_approved':true,'is_debarred':false})).then((res)=>{
            if(res.is_login === true && res.status === true){
                handleSubmit(e)   
            }
        })
        setShowModal(false);
        setLoading(false)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const request = {
            'username': username,
            'password': password,
        };
        
        dispatch(getUserDebardStatus(username)).then(async (res) =>{
            debardStatus = res
          let  EncryptUserData = EncryptOrDecrypt(res.data.Admin_ID, 'E');
          localStorage.setItem('user_id', EncryptUserData);
            if(res.status === true){
                if(debardStatus.data.show_modal === true){
                    setShowModal(true);
                }
                else{                    
                    if(res.data.is_login === true && debardStatus.data.show_modal === false){
                        dispatch(login(request)).then(async (response) => {
                            var EncryptUserData = ''; 
                            var accessToken ='';
                            if (response.success === true) {
                                if (Object.keys(response.data).length > 0 && response.data.status === 200) {
                                    accessToken = response.data.access_token;
                                    localStorage.setItem('access_token', response.data.access_token);
                                    Http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
                                    var tokenExpireAt = EncryptOrDecrypt(UTCDateToLocalDate(response.data.access_token_expires_at), 'E');
                                    localStorage.setItem('access_token_expires_at', tokenExpireAt);
                                    EncryptUserData = EncryptOrDecrypt(response.data.extraDetails, 'E');
                                    localStorage.setItem('user', EncryptUserData);
                                    localStorage.setItem('is_first_login', response.data.is_first_login);
                                    const productCodeList=JSON.stringify(response?.data?.extraDetails?.productCodeList)
                                    localStorage.setItem('productCodeList',productCodeList)
                                    await dispatch(authCheck());
                                    if(response.data?.extraDetails?.companyDetails?.logo_url) {
                                        localStorage.setItem('company_logo', response.data?.extraDetails?.companyDetails?.logo_url);
                                    }
                                    const showDashboard = () => {
                                        if(response.data.extraDetails.accessRights.modules.includes('AGENTPORTAL')){
                                            dispatch(checkPermission('AGENTPORTAL')).then((ckresult)=>{
                                                navigate("/dashboard");
                                            });
                                        }else{
                                            navigate("/dashboard");
                                        }
                                    }
                                    if(response.data?.passwordExpirationNotification?.status === 'Y') {
                                    await Swal.fire({
                                        title: response?.data?.passwordExpirationNotification?.msg,
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085D6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Reset your password',
                                        cancelButtonText:'Cancel'
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                            navigate("/profile",{state:{id:"1"}});

                                        }else{
                                            showDashboard();
                                        }
                                    })
                                    }
                                    else{
                                        showDashboard();
                                    }
                                    
                                
                                } else if(response.success === true && response.data.username !== '' &&  response.data.code === 200){
                                    if(response.data.email === ''){
                                        setUserPasswordReset(response.data.username);
                                        setView('emailUpdateScreen');
                                        setLoading(false);
                                    } else {
                                        setUserPasswordReset(response.data.username);
                                        setView('resetPasswordScreen');
                                        setLoading(false);
                                        setIsPasswordExpired(response.data.passwordExpirationStatus);
                                    } 
                
                                } else {
                                    setUsername('');
                                    setPassword('');
                                    toast.error(response.data.errors);
                                }
                            }
                            setLoading(false);
                        }).catch(() => {
                            setLoading(false);
                            toast.error(t('Please Try again'));
                        });
                    }
            }
            }
            else if(res.status === false){
                setLoading(false);
                toast.error(res.message);
            }
        })
        

    }

    const UTCDateToLocalDate = (date) => {
        date = date.replace('UTC', '').trim(); 
        return Moment(new Date(date)).format();
    }

    const resetPassword = (e) => {
        e.preventDefault();
        const credentials = {
          username: userPasswordReset,
          sourceView: "AGENTPORTAL"
        };

        dispatch(forgotPass(credentials))
        .then(async (response) => {
          if (response.status === true) {
            toast.success(response.message);
            Swal.fire('Success', response.message, 'success');
            setView('login');
          } else {
            setUsername("");
            toast.error(response.data.errors);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          toast.error(t("Please Try again"));
        });
    }
    
    const addEmailOnCognito = (e) => {
        e.preventDefault();
        const credentials = {
            username: userPasswordReset,
            email: newEmail
        };
        dispatch(addEamil(credentials))
          .then((res) => {
            if(res.resetStatus === true){
                setUserPasswordReset(res.username);
                setView('resetPasswordScreen');
            } else {
                toast.error(res.msg);
            }
          }).catch((err) => {
            console.log(err);
            // const response = {
            //   error: true,
            //   message: err.error,
            // };
          })
    }

    // const displayEnrollButtonForTenant = (tenant) => {
    //     const excludedTenants = ['amfam', 'csaa', 'farmers', 'foremost', 'alliedtrust','autoclub','ngm'];
    //     return !excludedTenants.includes(tenant);
    // }

    const showSideImage = useBreakpointValue(
        {
          base: false,
          sm: false,
          md: true,
        },
        {
          fallback: true,
        },
      )

    return (
      <>
        <LoadingOverlay>
          <Loader loading={loading} />
          <Flex
            width={"100%"}
            h={"110vh"}
            minHeight={"770px"}
            maxHeight={"770px"}
            alignItems="center"
            justifyContent={"center"}
          >
            <Container maxW="1300px">
              <Card
                w="100%"
                h="100vh"
                minHeight={"700px"}
                maxHeight={"700px"}
                borderRadius={"2xl"}
              >
                <Flex height={"100%"} justifyContent={"space-between"}>
                  <Flex
                    hidden={!showSideImage}
                    borderLeftRadius={"2xl"}
                    bg={"var(--chakra-colors-primary-500)"}
                    width={"55%"}
                    height={"100%"}
                    px={3}
                  >
                    <Flex
                      height={"100%"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Flex direction={"column"} width={"50%"}>
                        <Text
                          fontWeight="var(--chakra-fontWeights-medium)"
                          fontSize="35px"
                          color="white"
                        >
                          {t("Welcome Back to Agent Portal")}
                        </Text>
                      </Flex>
                      <Flex>
                        <Image
                          width={"100%"}
                          height={"300px"}
                          src="/assets/images/Illustration.png"
                          alt="Illustration"
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    width={!showSideImage ? "100%" : "45%"}
                    height={"max-content"}
                    px={{ base: 8, sm: 12, lg: 16, xl: 20 }}
                    py={{ base: 8, sm: 12 }}
                    direction={"column"}
                  >
                    <Flex justifyContent={"center"} height={95}>
                      <SecureImage
                        style={{ maxHeight: "95px" }}
                        src={`${logo}`}
                        alt=""
                      />
                    </Flex>
                    {location.pathname === "/login" ? (
                      view === "login" ? (
                        <LoginPage
                          username={username}
                          setUsername={setUsername}
                          password={password}
                          setPassword={setPassword}
                          handleSubmit={handleSubmit}
                          loading={loading}
                        />
                      ) : view === "resetPasswordScreen" ? (
                        <ResetPasswordScreenPage
                          loading={loading}
                          isPasswordExpired={isPasswordExpired}
                          resetPassword={resetPassword}
                        />
                      ) : view === "emailUpdateScreen" ? (
                        <EmailUpdatePage
                          loading={loading}
                          newEmail={newEmail}
                          setNewEmail={setNewEmail}
                          addEmailOnCognito={addEmailOnCognito}
                        />
                      ) : null
                    ) : location.pathname === "/forgotPassword" ? (
                      <ForgotPassword />
                    ) : location.pathname === "/odysseyLogin" ? (
                      <OdysseyLogin />
                    ) : location.pathname.includes("reset-password") ? (
                      <ResetPassword />
                    ) : location.pathname.includes("reset-username") ? (
                      <ResetUsername />
                    ) : null}
                  </Flex>
                </Flex>
              </Card>
            </Container>
          </Flex>
        </LoadingOverlay>

        {/* Suspension / Debarment modal and Access Denied modal */}
        <DebarmentModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleApproved={handleApproved}
          handleDebard={handleDebard}
          showDebardModal={showDebardModal}
          setShowDebardModal={setShowDebardModal}
        />
      </>
    );
};

export default Login;