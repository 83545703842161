import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { getPropertyData, getBaseData, propertyDataAutocompleteStore, pivotApiCall, resetFloodStore, getAgentAuthorizeState, validateCeLicense, createQuote, setTempQuoteNo, getZoneDetermination, selectedAgencyWyoCode, getZipDetails } from '../../../../../services/floodService';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import "./flood-address.scss";
import { EncryptOrDecrypt,checkPivotOutage, getTenant } from "../../../../../services/commonService";
import ManualAddress from "./ManualAddress";
import Error from "../../quote/Modal/Error";
import Swal from 'sweetalert2';
import { setTempZDDetails } from "../../../../../store/actions";
import Select from 'react-select';
import { getAssociateAgencyList } from "../../../../../services/profileService";
import ZipDetailsView from '../../quote/Modal/ZipDetailsView';
import { isEmpty } from "lodash";

const FloodAddress = () => {
    const { t } = useTranslation();
	const isPivotOutage = checkPivotOutage();
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const userData = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
    const { apiLoading } = useSelector(state => state.flood);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [isManual, setIsManual] = useState(false);
    const [validateManualInput, setValidateManualInput] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const buttonRef = useRef();
    const tenant = getTenant();
    const [selectedPropertyData, setSelectedPropertyData] = useState({
        street_number: "",
        street_name: "",
        city: "",
        CountyName: "",
        PostalCode: "",
        country: "US",
        latitude: null,
        longitude: null,
        value: "",
        autocomplete: "",
        state: "",
        s_PostalCodeSuffix: "",
        apt_Number:""
    });
    const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
    const [isLoadWYOAgentCodeDD, setIsLoadWYOAgentCodeDD] = useState((userDatas?.personUPN && userDatas.personUPN != null) ? true : false);
    const [associateAgencyList, setAssociateAgencyList] = useState([]);
    const [selectedWyoAgentCodeData, setSelectedWyoAgentCodeData] = useState({});
    const [multipleCity, setMultipleCity] = useState(null);
    const [show, setShow] = useState(false);
    const [cityFromZipDetail, setCityFromZipDetail] = useState('');

    // React-Select Custome Style
    const customStyles = {
        control: (base) => ({
            ...base,
        }),
    };
    useEffect(() => {
        if(isLoadWYOAgentCodeDD && (['farmers','foremost'].includes(tenant)) ){
            getAgentWyoCode();
        }
    },[isLoadWYOAgentCodeDD])
    useEffect(() => {
        dispatch(resetFloodStore());
    }, [])
    

    useEffect(() => {
        setLoading(apiLoading)
    }, [apiLoading])

    useEffect(() => {
        if (search != null) {
            getGeoCode(search.value.description);
        }
    }, [search]);

    useEffect(() => {
        var temp = false;
        if (isManual) {
            // validate for mannual address
            ['street_name', 'city', 'state', 'PostalCode', 'street_number', 's_PostalCodeSuffix', 'country'].forEach((key) => {
                if ([null, '', undefined].includes(selectedPropertyData[key]) || selectedPropertyData[key].trim().length === 0) {
                    temp = true;
                }
            });
        } else {
            if (selectedPropertyData.street_name === '' && selectedPropertyData.PostalCode === '') {
                temp = true;
            }
        }
        setDisabled(temp);

        if (!temp && !cityFromZipDetail) {
            checkForMultipleCity(selectedPropertyData);
        }

    }, [selectedPropertyData, cityFromZipDetail]);

    const getGeoCode = (description) => {
        setCityFromZipDetail('');
        geocodeByAddress(description)
            .then(addr => {
                const propData = { ...selectedPropertyData };
                addr && addr[0].address_components.forEach(e => {
                    if (e.types.includes('postal_code')) {
                        propData.PostalCode = e.short_name
                    } else if (e.types.includes('street_number')) {
                        propData.street_number = e.short_name
                    } else if (e.types.includes('route')) {
                        propData.street_name = e.short_name
                    } else if (e.types.includes('locality', 'political')) {
                        propData.city = e.short_name
                    } else if (e.types.includes('country', 'political')) {
                        propData.country = e.short_name
                    } else if (e.types.includes('administrative_area_level_2', 'political')) {
                        propData.CountyName = e.short_name
                    } else if (e.types.includes('administrative_area_level_1', 'political')) {
                        propData.state = e.short_name
                    } else if (e.types.includes('postal_code_suffix')) { // Require For Flood Product
                        propData.zipPlus4 = e.short_name
                        propData.s_PostalCodeSuffix = e.short_name
                    }
                    else if(e.types.includes('subpremise')){
                        propData.apt_Number ='#'+ e.short_name
                    }
                });
                propData.autocomplete = addr[0].formatted_address;
                if (addr && addr[0]) {
                    getLatLng(addr[0]).then(({ lat, lng }) => {
                        propData.latitude = lat.toFixed(7);
                        propData.longitude = lng.toFixed(7);
                    }
                    );
                }
                setSelectedPropertyData(propData);
            })
    }

    const checkForMultipleCity = async (propData) => {
        if (propData?.PostalCode) {
            await dispatch(getZipDetails('10', propData?.PostalCode, 'APPLICANT'))
                .then((res) => {
                    if (res.success === true) {
                        var data = res.data;
                        if (data.status === 'Y') {
                            const zipDetail = data.zipData.filter(x => x.s_CityName.toUpperCase() === propData.city.toUpperCase());
                            if (isEmpty(zipDetail)) {
                                showMultipleCityModal(data.zipData);
                            }
                        } else {
                            toast.error(data.errorMsg);
                        }
                    }
                });
        }
    }

    // open Zip Modal
    const showMultipleCityModal = (zipData) => {
        setMultipleCity(<ZipDetailsView zipData={zipData} setZipDetailsData={setZipDetailsData} />)
        setShow(true);
    }

    // get selected zip data
    const setZipDetailsData = (zipData) => {
        setSelectedPropertyData({...selectedPropertyData, city: zipData.s_CityName});
        setCityFromZipDetail(zipData.s_CityName);
        setMultipleCity(null);
        setShow(false);
    }

    // Check Address is Severe Repetitive Loss
    const getGeoApi = async (selectedPropertyData) => {
        const reqJson = {
            'addressLine1': selectedPropertyData.autocomplete,
            //'addressLine1': selectedPropertyData.street_number + ' ' + selectedPropertyData.street_name,
            'addressLine2': selectedPropertyData.street_number + ' ' + selectedPropertyData.street_name,
            'apt_number': selectedPropertyData.apt_Number,
            'city': selectedPropertyData.city,
            'state': selectedPropertyData.state,
            'zipCode': selectedPropertyData.PostalCode,
            'zipPlus4': selectedPropertyData.zipPlus4,
            'latitude': selectedPropertyData.latitude,
            'longitude': selectedPropertyData.longitude,
            'uniqueKey': localStorage.getItem('uniqueKey')
        }
        const code = 'ADDRANALYSISGEO';
        const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
        // Call Api
        return await dispatch(pivotApiCall(code, request));
    }

    const onAutocompleteSubmit = async () => {
        setLoading(true);
        if (selectedPropertyData.street_name === '' && selectedPropertyData.PostalCode === '') {
            toast.error(t('Please enter address!'));
            return false;
        }
        let params = {
            'userPersonInfoFK' : userData.n_PersonInfoId_FK,
            'product' : 10,
            'state' : selectedPropertyData.state
        }
        if(associateAgencyList.length > 0 && selectedWyoAgentCodeData!= {} && (['farmers','foremost'].includes(tenant)) ){
            params['userPersonInfoFK']=selectedWyoAgentCodeData.person_id
        }

        let callPropertyDataApi = true;
        try {
            await dispatch(validateCeLicense()).then((res) => {
                if(res.status == false){
                    Swal.fire({
                        icon: 'warning',
                        title: t('Please Wait'),
                        html: t('celicenseErrorMsg'),
                        confirmButtonColor: '#66e3d8',
                        showCloseButton: true,
                        confirmButtonText: 'Upload CE',
                        allowOutsideClick: false,
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            navigation('/celicense'); 
                        }
                    })
                    //toast.error(t('CE License Expired - You are not eligible for write new business.'));
                    callPropertyDataApi = false;
                    return false;
                }
            }).catch(() => {
                callPropertyDataApi = false;
                setLoading(false);
            });
            await dispatch(getBaseData(10));
            const userAccess = userData.accessRights ? userData.accessRights.levelName : '';
            if(userAccess != 'EMPLOYEE'){
                await dispatch(getAgentAuthorizeState(params)).then((res) => {
                    if(res.userAuthorizedState == false){
                        toast.error(t('State Not Authorized To Agent!'));
                        callPropertyDataApi = false;
                    }
                });
            }
            if(callPropertyDataApi){
                await dispatch(getPropertyData(selectedPropertyData)).then((res) => {
                    if (res.status == 200) {
                        dispatch(propertyDataAutocompleteStore(selectedPropertyData));
                        if(isPivotOutage == 'true') {
                            navigation('/flood/instantquote/step/home-type');
                            return true;
                        }
                        getGeoApi(selectedPropertyData).then(async (result) => { 
                            if(result.data){
                                // if geo failed
                                let callZoneDeterminationApi  = 'N';
                                if (result.data.analysisStatus !== 'SUCCEEDED') {
                                    callZoneDeterminationApi  = 'Y';
                                }

                                if (callZoneDeterminationApi  == 'N' && result.data.analysisStatus == 'SUCCEEDED') {
                                    // check if geo returning required details
                                    let geoData = result.data;
                                    if (geoData.floodZoneDetermination) {
                                        let isValid = 'Y';
                                        let requiredKey = ['communityNumber', 'floodHazardZone'];

                                        for (var i = 0; i < requiredKey.length; i++) {
                                            if ([undefined, null, ''].includes(geoData.floodZoneDetermination[requiredKey[i]])) {
                                                isValid = 'N';
                                                break;
                                            }
                                        }

                                        if (isValid == 'N') {
                                            // call massive cert
                                            callZoneDeterminationApi  = 'Y';
                                        }
                                    } else {
                                        // call massive cert
                                        callZoneDeterminationApi  = 'Y';
                                    }
                                }

                                // To display proper error message on UI if pivot api down
                                if(
                                    result.data.statusCode && 
                                    [403, 500].includes(result.data.statusCode)
                                ){
                                    toast.error(('Pivot Api Response - ' + result.data.message));
                                }

                                // if geo failed
                                if (callZoneDeterminationApi  == 'Y') {
                                    let zoneDeterminationStatus  = 'completed';
                                    await callZoneDetermination().then((result) => {
                                        if (!['completed'].includes(result)) {
                                            zoneDeterminationStatus  = result;
                                        }
                                    });
                                    // if geo and detemination both failed
                                    if (zoneDeterminationStatus  !== 'completed') {
                                        return false;
                                    }
                                } 
                                if (result.data.severeRepetitiveLoss === false) {
                                    navigation('/flood/instantquote/step/home-type');
                                }
                                if (result.data.severeRepetitiveLoss === true) {
                                    navigation('/flood/error/srl');
                                }
                                if (result.data.cbrsFloodInsuranceProhibitionDate && ![null, ''].includes(result.data.cbrsFloodInsuranceProhibitionDate)) {
                                    navigation('/flood/error/cbrs');
                                }
                            }
                        }).catch(() => {
                            toast.error(t('Something Went Wrong!'));
                        })
                    } else {
                        toast.error(t('We are not writing business in this area'));
                    }
                }).catch((err) => {
                    const { errors } = err.data || {};
                    let showError = false, validationError = [];
                    if (errors) {
                        Object.values(errors).map((value) => {
                            validationError.push(value[0]);
                        });
                        showError = true;
                    } else {
                        toast.error(t('Something Went Wrong!'));
                    }
                    setShowErrorModal(showError);
                    setIsManual(showError);
                    setValidateManualInput(showError);
                    setErrorList(validationError);
                });
                setLoading(false);
            }
        }
        finally {
            setLoading(false);
        }
    }

    /**
     * Call Massive cert
     */
    const callZoneDetermination  = async () => {
        const reqJson = {
            'addressLine1': selectedPropertyData.street_number + ' ' + selectedPropertyData.street_name,
            'addressLine2': selectedPropertyData.autocomplete,
            'city': selectedPropertyData.city,
            'state': selectedPropertyData.state,
            'zipCode': selectedPropertyData.PostalCode,
            'zipPlus4': selectedPropertyData.s_PostalCodeSuffix,
            'latitude': selectedPropertyData.latitude,
            'longitude': selectedPropertyData.longitude,
            'uniqueKey': localStorage.getItem('uniqueKey') || ''
        }
        // const code = 'ZONE_DETERMINATION';
        // const requestCode = 'ZONE_DETERMINATION_MASSIVECERTS';
        // const request = { 'code': requestCode, 'requestParam': reqJson, 'sourceId': 0 };
        let deteminationStatus = 'completed';
        setLoading(true);
        try {
            await dispatch(getZoneDetermination(reqJson)).then(async (res) => {
                if (res.status == false && res?.data?.status && !['completed'].includes(res.data.status)) {

                    // if (res.data.data.Description) {
                        let msg = res.data.description ?? ' Request Id: ' + res.data.requestId, title = res.data.message ?? res.msg, poTransPK = 0;

                        let request = {
                            'address': selectedPropertyData,
                            'uniqueKey': localStorage.getItem('uniqueKey')
                        };
                        if(tenant === 'farmers' || tenant ==='foremost'){
                        request = {
                                'address': selectedPropertyData,
                                'uniqueKey': localStorage.getItem('uniqueKey'),
                                'selectedWyoAgentCodeData':selectedWyoAgentCodeData
                            };
                        }
                        
                        // create quote with empty data
                        await dispatch(createQuote(request)).then(async (result) => {
                            if (result.success === true) {
                                if (result.data.quoteNo) {
                                    poTransPK = result.data.poTransPK;
                                    msg = msg + ' Quote no is <a href=/flood/quote/' + poTransPK + '><abbr title="Redirect to quote">' + result.data.quoteNo + '</abbr></a>';
                                    // set temp quote no in reducer
                                    await dispatch(setTempQuoteNo(result.data.quoteNo));
                                }
                            }
                        }).catch(() => toast.error(t('Something Went Wrong!')));
                        msg = msg + " <br/><div style='justify-content:center'><i style='font-size:14px'>Due to manual zone review, no rate can be obtained until the zone determination is complete… but hey… you can still add your quote details. When your ZD is returned, just rate your quote and get a premium! You can also just save your quote and come back later.</i></div>";
                        // alert
                        await Swal.fire({
                            title: t(title),
                            html: t(msg),
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#66e3d8',
                            cancelButtonColor: '#66e3d8',
                            confirmButtonText: 'Save Quote',
                            cancelButtonText: "Continue Quote",
                            allowOutsideClick: false,
                        }).then((result) => {
                            // redirect to dashboard
                            if (result.isConfirmed) {
                                navigation('/');
                            }
                            // redirect to quote
                            if (result.isDismissed && poTransPK > 0) {
                                deteminationStatus = res.data.status;
                                window.location = `/flood/quote/${poTransPK}`;
                            }
                            deteminationStatus = res.data.status;
                        });
                    // }
                }
                await dispatch(setTempZDDetails(res.data));
            }).catch(() => {
                toast.error(t('Something Went Wrong!'));
            });
        }
        finally {
        setLoading(false);
        }
        return deteminationStatus;
    }

    const handleTabPress = (event) => {
      if (event.key === "Tab") {
        event.preventDefault();
        buttonRef.current.focus();
      }
    };
    const handleEnterPress = (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          if(e.target.name === "nextButton")onAutocompleteSubmit();
          if(e.target.name === "checkBox")setIsManual(e.target.checked = true);
        }
    };
    const getAgentWyoCode = async () => {
        const params= {
			producerPersonId: userDatas.n_PersonInfoId_FK,
			producerUPN:'',
            sourceScreen:"CREATEQUOTE"
		};
        await dispatch(getAssociateAgencyList(params)).then((res) => {
            setAssociateAgencyList(res.data);
            if(res.data != []){
                setSelectedWyoAgentCodeData(res.data != [] ?  res.data[0] : null)
                dispatch(selectedAgencyWyoCode(res.data[0]))
            }
        })
    }

    const handleAgentWyoCodeChange = (row) =>{
        setSelectedWyoAgentCodeData(row)
        dispatch(selectedAgencyWyoCode(row))
    }
    return (
        <LoadingOverlay>
            <Loader loading={loading} />
            <section className="property-details-section">
                <Row className="justify-content-center">
                    <Col xs="12" sm="12" md="10" lg="8" xl="8" className="p-form">
                        <div className="hero-section-headings">
                            <h6 className="h-1">{t('GET YOUR INSTANT QUOTE')}</h6>
                            <h2>{t('FAST FLOOD QUOTES')}</h2>
                        </div>
                        {!isManual && (
                            <>
                                <div className="form-search-input">
                                    <Col xs="6" className="">
                                        <GooglePlacesAutocomplete
                                            id="places-input"
                                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                            selectProps={{
                                                search,
                                                onChange: (e) => { setSearch(e); },
                                                styles: customStyles,
                                                placeholder: t('Enter Your Address'),
                                                autoFocus: true,
                                                onKeyDown: { handleTabPress }
                                            }}
                                        />
                                    </Col>
                                </div>

                                <div className="form-search-input">
                                    <Col md="6">
                                        <Form.Check
                                            type="checkbox"
                                            label={t('Manual Address Entry')}
                                            className="table-checkbox"
                                            style={{ color: '#64d6cd' }}
                                            id="custom-checker"
                                            onChange={(e) => setIsManual(e.target.checked)}
                                            onKeyDown={handleEnterPress}                                     
                                            name="checkBox"
                                        />
                                    </Col>
                                </div>
                            </>
                        )}
                        {
                            isManual && (
                                <ManualAddress
                                    selectedPropertyData={selectedPropertyData}
                                    setSelectedPropertyData={setSelectedPropertyData}
                                    validateManualInput={validateManualInput}
                                />
                            )
                        }
                        {
                            ( tenant === 'farmers' || tenant === 'foremost') && isLoadWYOAgentCodeDD && associateAgencyList.length > 0 && <>
                               <div className="form-search-input">
                                    <Col xs="6" className="mt-3">
                                        <h6 style={{ color: '#64d6cd' }} className="h-1">{t('WYO Agent Code')}</h6>
                                       
                                        <Select
                                            getOptionValue={(option) => option.person_id}
                                            getOptionLabel={(option) => option.agent_wyo_code}
                                            placeholder='select WYO Agent Code'   
                                            options={associateAgencyList}
                                            value={selectedWyoAgentCodeData}
                                            onChange={(row)=>{handleAgentWyoCodeChange(row)}}
                                        />
                                    </Col>
                                </div> 
                            </>
                            
                        }
                        <Col xs="12" className="form-btns">
                            <div className="flood-bts-div">
                                <Button
                                    id="primary-colored-btn"
                                    ref={buttonRef}
                                    variant="primary"
                                    onClick={() => onAutocompleteSubmit()}
                                    onKeyDown={handleEnterPress}
                                    disabled={disabled}
                                    name="nextButton"
                                >
                                    {t('Next')}
                                </Button>{" "}
                            </div>
                        </Col>
                        
                   </Col> 
                </Row>
                {
                    showErrorModal === true && (
                        <Error
                            show={showErrorModal}
                            error={errorList}
                            toggle={setShowErrorModal}
                        />
                    )
                }
                {/* Zip Details Modal */}
                {
                    show && (
                        <Modal size="md" show={show} className="zip-details-modal">
                            <Modal.Body className="zip-details-modal-body">
                                {multipleCity}
                            </Modal.Body>
                        </Modal>
                    )
                }
            </section>
        </LoadingOverlay>
    );
};

export default FloodAddress;
