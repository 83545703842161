import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button } from "@chakra-ui/react";
import { useDispatch } from 'react-redux';
import { useDropzone } from "react-dropzone";
import { useTranslation } from 'react-i18next';
import { FaWindowClose, FaTimes, FaUpload } from 'react-icons/fa';
import { uploadCancelDocument } from '../../../services/floodService';

const AddAttachment = (props) => {
    const { loading, setLoading, policyNumber, policyPK, cancelJSON, handleOnAttachmentClose, setDocData, docData, setCancelJSON, termMasterFK } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isFileSelected, setIsFileSelected] = useState(true);

    const acceptedFileTypes = ['application/pdf', 'application/msword', 'text/plain', 'image/*',"application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error('Only PDF, DOC, TXT, and image files are allowed.');
            return;
        }
        setSelectedFiles(acceptedFiles[0]);
    }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: acceptedFileTypes,
    });

    // on drop file
    // const onDrop = useCallback(event => {
    //     setSelectedFiles(event[0]);
    // }, [])

    // const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });

    // display file name 
    var files = acceptedFiles.map((file, i) => (
        <li key={file.path}>
            {file.name} &nbsp;&nbsp;<FaTimes size={15} onClick={() => removeFile(i)} style={{display:"inline-block", cursor:"pointer"}}/>
        </li>
    ));

    // to delete file
    const removeFile = (file) => {
        const newFiles = [...files];
        newFiles.splice(file, 1);
        acceptedFiles.splice(file, 1);
        setSelectedFiles(newFiles);
    };

    // reset state
    const resetAttachmentState = () => {
        setDocData({});
        setSelectedFiles([]);
        acceptedFiles.length = 0;
        acceptedFiles.splice(0, acceptedFiles.length);
    };

    // validation
    const validate = () => {
        var isValid = true;
        if (!selectedFiles || selectedFiles.length === 0) {
            toast.error(t('Please Select File'))
            isValid = false;
            setIsFileSelected(false);
        }
        return isValid;
    };

    // submit form
    const handleSubmit = async () => {
        const isValid = validate();
        if (isValid) {
            const formData = new FormData();
            formData.append("file", selectedFiles, selectedFiles.name);
            formData.append("docScreenName", docData.docScreenName);
            formData.append("docType", docData.docType);
            formData.append("policyNumber", policyNumber);
            formData.append("policyPK", policyPK);
            formData.append("n_TermMaster_PK", termMasterFK);
            formData.append("cancelJSON", JSON.stringify(cancelJSON));

            setLoading(true);
            await dispatch(uploadCancelDocument(formData)).then((res) => {
                setLoading(false);
                if (res.status === 'Y') {
                    if (res.data && res.data.hasOwnProperty("metadata")) {
                        var tempJSON = { ...res.data.metadata };
                        tempJSON['preCancelQuoteId'] = res.data.preCancelQuoteId;
                        setCancelJSON(tempJSON);
                    }
                    resetAttachmentState();
                    toast.success(res.msg);
                    handleOnAttachmentClose(false);
                } else {
                    toast.error(t('Something Went Wrong!'));
                }
            }).catch(() => {
                setLoading(false);
                toast.error(t('Something Went Wrong!'));
            });
        }
    };

    return (
        <div className='interview-questions-div'>
            <span className="card-header-main-flood">
                
            </span>
            <Box display={"flex"} justifyContent={"center"} m={2}
                {...getRootProps({ className: "dropzone" })}
            >
                <input {...getInputProps()} type='file' accept='.pdf,.docx,.txt'/>
                <img
                          src={"/assets/images/uploadImageFrame.svg"}
                          alt="frame"
                          height={"400px"}
                          width={600}
                          style={{ cursor: "pointer", objectFit: "cover" }}
                        />
            </Box>

            <aside className="reply-document-name">
                <ul>{files}</ul>
            </aside>
            {
                !isFileSelected && (
                    <aside className="reply-document-name" style={{ color: 'red' }}>
                        <ul>{t('Please Select File')}</ul>
                    </aside>
                )
            }
            <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
                <Button
                    size={"sm"}
                    borderRadius={"lg"}
                    variant="solid"
                    style={{ float: 'right' }}
                    onClick={() => handleOnAttachmentClose(false)}
                    isDisabled={loading}
                    leftIcon={<FaWindowClose size={15} />}
                >
                    {t('Close')}
                </Button>
                <Button
                    size={"sm"}
                    variant="solid"
                    onClick={() => handleSubmit()}
                    isDisabled={loading}
                    leftIcon={<FaUpload size={15} />}
                >
                    {t('Upload')}
                </Button>
            </Box>
        </div>
    )
}

export default AddAttachment;
