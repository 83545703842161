import React, { useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedFormControl from "react-bootstrap-maskedinput";

import {
  InputGroup,
  InputRightElement,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Popover,
  Box,
  Input,
} from "@chakra-ui/react";
import { toast } from "react-toastify";

const DateInput = ({
  value,
  onChange,
  styleProps,
  error,
  placeholder,
  minDate,
  maxDate,
  isDisabled,
  placement,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [format] = useState("MM/DD/YYYY");

  const handleDatePick = (newDate) => {
    const newDateMoment = moment(newDate);
    if (!newDateMoment.isValid()) {
      console.log("Invalid time value");
      return;
    }
    onChange(moment(newDate).format(format));
    setIsOpen(false);
  };

  const parseDate = (date) => {
    return moment(date, ["MM/DD/YYYY", "YYYY-MM-DD"]).toDate();
  };

  const handleInputChange = (e) => {
    const val = e.target.value;
    onChange(val);
  };

  const handleBlur = (e) => {
    const val = e.target.value;
    const dateMoment = moment(val, format, true);
    if (!dateMoment.isValid()) {
      console.warn("Invalid date value");
      toast.error(`Please enter a valid date in ${format} format.`);
      onChange("");
    } else {
      onChange(dateMoment.format(format));
    }
  };

  return (
    <>
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement={placement ? "start-end" : "bottom-start"}
      >
        <PopoverTrigger>
          <InputGroup>
            <Input
              as={MaskedFormControl}
              mask="11/11/1111"
              placeholder={placeholder || "MM/DD/YYYY"}
              value={value}
              isDisabled={isDisabled || false}
              {...styleProps}
              _disabled={{ opacity: 1 }}
              onChange={handleInputChange}
              onBlur={handleBlur}
              isInvalid={!!error}
            />
            {isDisabled !== true && (
              <InputRightElement
                alignItems={styleProps?.size === "sm" ? "normal" : "center"}
              >
                <Box onClick={() => setIsOpen(!isOpen)} mt={1.5}>
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                </Box>
              </InputRightElement>
            )}
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <DatePicker
              selected={
                value && moment(value, format, true).isValid()
                  ? moment(value, ["MM/DD/YYYY", "YYYY-MM-DD"]).toDate()
                  : null
              }
              onChange={handleDatePick}
              inline
              showYearDropdown
              dropdownMode="select"
              showMonthDropdown
              dateFormat="MM/DD/YYYY"
              minDate={minDate ? parseDate(minDate) : null}
              maxDate={maxDate ? parseDate(maxDate) : null}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default DateInput;
