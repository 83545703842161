import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { resetPass, verifyToken } from "../../services/authService";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { toast } from "react-toastify";
import PasswordChecklist from "react-password-checklist";
import ReactTooltip from "react-tooltip";
import { BsInfoCircle } from "react-icons/bs";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPassword, setshowPassword] =useState(false);
  const[showRetypePassword,setshowRetypePassword] =useState(false);

  useEffect(() => {
    checkToken();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkToken() {
    dispatch(verifyToken(token))
      .catch((err) => {
        const { status } = err.response;
        if (status === 419 || status === 404) {
          navigate("/" + status);
        }
      });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      toast.error(t('Password & Confirm Password are require.'));
      return false;
    }
    if (password !== confirmPassword) {
      toast.error(t('Password and Confirm Password must be same.'));
      return false;
    }

    const isContainFlood = /flood/i.test(password); 
    if(isContainFlood){
        return toast.error('The password must not include the word flood.');
    }
    const isContainPassword = /password/i.test(password);
    if(isContainPassword){
        return toast.error('The password must not include the word Password.');
    }

    // eslint-disable-next-line no-useless-escape
    const isValidPassword = /^(?!.*password)(?!.*flood)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!~#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]).{8,}$/.test(password);
    if(!isValidPassword){
      toast.error(t('Password Validation Failed.'));
      return false;
    }
    const request = {
      password: password,
      confirmPass: confirmPassword,
      token: token,
    };
    setLoading(true);
    dispatch(resetPass(request))
      .then(async (response) => {
        if (response.status === true) {
          toast.success(response.message);
          navigate("/200/reset-password");
        } else if (response.status === false) {
          toast.error(response.error);
        } else {
          setPassword("");
          setConfirmPassword("");
          toast.error(response.data.errors);
        }
        setLoading(false);
      })
      .catch((error) => {
      const { status, data } = error.response;
      if (status === 421) {
        const { error } = data;
        toast.error(error);
      } else {
        toast.error(t("Please Try again"));
      }
      setLoading(false);
      });
  };

  const handlePassword = () => {
    setshowPassword(!showPassword);
  };

  const handleRetypePassword = () => {
    setshowRetypePassword(!showRetypePassword);
  };

  return (
    <>
      <Flex direction={"column"} width={"100%"} mt={6}>
        <Text fontSize="31px" mb={4}>
          {t("Reset Password")}
        </Text>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box>
            <FormControl mb={4}>
              <FormLabel>{t("New Password")}</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  maxLength={25}
                  onChange={(e) => setPassword(e.target.value)}
                  isRequired
                  placeholder={t("New Password")}
                  borderColor="var(--chakra-colors-neutral-500)"
                  _placeholder={{
                    color: "neutral.800",
                    fontSize: "sm",
                  }}
                  autoComplete="new-password"
                  backgroundColor="var(--chakra-colors-white)"
                  borderRadius={10}
                  width="100%"
                  height={"47px"}
                />
                <InputRightElement h={"100%"}>
                  <Flex
                    align={"center"}
                    pr={3}
                    cursor={"pointer"}
                    onClick={handlePassword}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </Flex>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel display={"flex"}>
                {t("Confirm Password")}
                <BsInfoCircle
                  style={{
                    marginTop: "4px",
                    marginLeft: "4px",
                  }}
                  data-tip
                  data-for="password"
                  data-tooltip-delay-hide={5000}
                />
                <ReactTooltip id="password" effect="solid">
                  <PasswordChecklist
                    rules={[
                      "minLength",
                      "capital",
                      "lowercase",
                      "number",
                      "specialChar",
                      "match",
                    ]}
                    minLength={8}
                    maxLength={25}
                    // eslint-disable-next-line no-useless-escape
                    specialCharsRegex={/[@!~#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/}
                    value={password}
                    valueAgain={confirmPassword}
                    messages={{
                      minLength: t(
                        "Must be 8-25 characters in length and cannot contain any spaces"
                      ),
                      capital: t(
                        "Must contain at least one uppercase alphabetic character: A-Z"
                      ),
                      lowercase: t(
                        "Must contain at least one lowercase alphabetic character: a-z"
                      ),
                      number: t("Must contain at least one digit"),
                      specialChar: t(
                        "Must contain at least one non-alphabetic 'special' character: i.e.: -#&$*!~@%+"
                      ),
                      match: t("Password and confirm password must match"),
                    }}
                  />
                </ReactTooltip>
              </FormLabel>
              <InputGroup>
                <Input
                  type={showRetypePassword ? "text" : "password"}
                  value={confirmPassword}
                  maxLength={25}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  isRequired
                  placeholder={t("Confirm Password")}
                  borderColor="var(--chakra-colors-neutral-500)"
                  _placeholder={{
                    color: "neutral.800",
                    fontSize: "sm",
                  }}
                  autoComplete="new-password"
                  backgroundColor="var(--chakra-colors-white)"
                  borderRadius={10}
                  width="100%"
                  height={"47px"}
                />
                <InputRightElement h={"100%"}>
                  <Flex
                    align={"center"}
                    pr={3}
                    cursor={"pointer"}
                    onClick={handleRetypePassword}
                  >
                    {showRetypePassword ? <FaEyeSlash /> : <FaEye />}
                  </Flex>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Box>
              <Button
                w={"100%"}
                my={4}
                borderRadius={"10px"}
                fontWeight="var(--chakra-fontWeights-medium)"
                fontSize={16}
                type="submit"
                border={"1px solid var(--chakra-colors-primary-500)"}
                width="100%"
                height={"47px"}
                isDisabled={loading}
              >
                {t("Reset")}
              </Button>
            </Box>
            <Box width={"100%"}>
              <Button
                background="var(--chakra-colors-white)"
                color="var(--chakra-colors-black)"
                borderRadius="10px"
                fontWeight="var(--chakra-fontWeights-medium)"
                fontSize={16}
                border="1px solid var(--chakra-colors-neutral-200)"
                width="100%"
                _hover={{
                  background: "var(--chakra-colors-primary-500)",
                  color: "var(--chakra-colors-white)",
                }}
                onClick={() => navigate("/login")}
                height={"47px"}
                isDisabled={loading}
              >
                {t("Go to Login")}
              </Button>
            </Box>
          </Box>
        </form>
      </Flex>
    </>
  );
};

export default ResetPassword;
