import { Box, Button, Spinner } from "@chakra-ui/react";
import AdvancedGridTable from "../../../../../components/AdvancedGridTable";
import { useEffect, useRef, useState } from "react";
import { getRateHistory } from "../api/rateHistoryApi";
import { BsEye } from "react-icons/bs";
import { rateViewer } from "../api/viewHistoryRate";
import { toast } from "react-toastify";

export const RateHistoryModal = ({ poTransPk }) => {
  const gridRef = useRef(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const columnDefs = [
    {
      headerName: "Created Date",
      field: "d_VersionDateTime",
      headerClass: "header-center",
      sort: "desc",
      filter: "agDateColumnFilter",
      valueFormatter: (params) => {
        const dateString = params.value;
        const [year, month, day] = dateString.split("-");
        return `${month}/${day.slice(0, 2)}/${year}`;
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          const dateParts = dateAsString.split("-");
          const cellDate = new Date(
            Number(dateParts[0]),
            Number(dateParts[1]) - 1,
            Number(dateParts[2].substring(0, 2))
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
      },
    },
    {
      headerName: "Amount",
      headerClass: "header-center",
      field: "n_ItemProrat_Amount",
      valueFormatter: (params) =>
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params?.value),
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Tax Fee Amount",
      headerClass: "header-center",
      field: "n_Taxnfees",
      valueFormatter: (params) =>
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params?.value),
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Action",
      headerClass: "header-center",
      field: "",
      cellRenderer: (e) => {
        return (
          <Button
            variant={"outline"}
            onClick={() => {
              if (e?.data?.n_PremiumCalcsheetHistory_PK) {
                viewHistoryRateSheet(e?.data?.n_PremiumCalcsheetHistory_PK);
              } else {
                toast.warn("No Data Found");
              }
            }}
            leftIcon={<BsEye size={20} />}
            mr={2}
            size="sm"
          >
            View
          </Button>
        );
      },
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    floatingFilter: true,
    flex: 1,
    cellStyle: { textAlign: "center", justifyContent: "center" },
  };

  const fetchRateHistory = async () => {
    try {
      const res = await getRateHistory(poTransPk);
      setData(res.data);
      setError(null);
    } catch (_error) {
       setError("Failed to fetch quote history. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const viewHistoryRateSheet = async (sheetpk) => {
    try {
      setLoading(true);
      const res = await rateViewer(sheetpk);
      const isSuccess = res && (res.status === "Y" || res.status === true);
      if (isSuccess) {
        toast.success(res.msg || "Document Generated", { autoClose: 2000 });

        if (res.url) {
          await new Promise((resolve) => setTimeout(resolve, 1250));
          window.open(res.url, "_blank");
        } else {
          toast.info("Document is being generated, please try again later.");
        }
      } else {
        toast.error(res.msg || "Document Generation Failed", {
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error fetching rate view history:", error);
      toast.error("An error occurred while fetching the rate view history.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!poTransPk) {
      return;
    }
    fetchRateHistory();
  }, [poTransPk]);

  return (
    <div className="ag-theme-alpine" style={{ width: "100%", height: "400px" }}>
      {error && <div className="error-message">{error}</div>}
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="400px"
        >
          <Spinner size="xl" color="primary.500" />
          <Box ml="3" fontWeight="medium" fontSize={"lg"}>
            Generating Document...
          </Box>
        </Box>
      ) : (
        <Box className="ag-theme-alpine" width="100%" height="400px">
          <AdvancedGridTable
            gridRef={gridRef}
            rowData={data}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={10}
            onGridReady={(params) => {
              gridRef.current = params.api;
              params.api.sizeColumnsToFit();
            }}
          />
        </Box>
      )}
      <style>
        {`
          .ag-header-cell.header-center .ag-header-cell-label {
            justify-content: center;
          }
          .ag-header-cell.header-center .ag-header-cell-menu-button {
            margin-left: auto;
          }
        `}
      </style>
    </div>
  );
};
