import React from "react";
import { Box, Flex, Text, VStack, Switch, Select } from "@chakra-ui/react";
import { ChakraSelect } from "../../commercial-quote/Components/CustomComponents";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";
import { spoilageCoverageType } from "../../commercial-quote/data/commercialDD";
import { CustomCurrencyInput } from "../../common/CustomCurrencyInput";

const FormField = ({ label, children }) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Text pt={0} mb={0}>
      {label}
    </Text>
    {children}
  </Flex>
);

export const BlanketAdditional = () => {
  const { t } = useTranslation();
  const {
    blanketDisplayCoverages,
    setBlanketDisplayCoverages,
    selectedCard,
    baseDD,
  } = useQuote();

  const updateCoverageDetails = (name, value) => {
    setBlanketDisplayCoverages({
      ...blanketDisplayCoverages,
      additionalCoverages: {
        ...blanketDisplayCoverages.additionalCoverages,
        [name]: value,
      },
    });
  };

  return (
    <Box
      maxWidth="600px"
      height={selectedCard === "ConvenienceStore" ? 650 : 600}
      maxHeight={"700px"}
      margin="auto"
      padding="4"
      overflow={"auto"}
    >
      <VStack spacing="6" align="stretch">
        {Array.isArray(baseDD?.all_basic_Coverage) &&
          baseDD?.all_basic_Coverage.map(
            (element, i) =>
              element?.s_LimitCodeType === "DROPDOWN" &&
              ["Water Sublimit"].includes(element?.s_ScreenName) && (
                <FormField key={i} label={element?.s_ScreenName}>
                  <Select
                    w="30%"
                    className="inputSelectBoxes"
                    value={
                      (element?.s_ScreenName === "Water Sublimit" &&
                        blanketDisplayCoverages?.additionalCoverages
                          ?.waterSublimit) ||
                      blanketDisplayCoverages?.additionalCoverages?.[
                        element?.s_CoverageCode
                      ] ||
                      ""
                    }
                    name={
                      element?.s_ScreenName === "Water Sublimit"
                        ? "waterSublimit"
                        : element?.s_CoverageCode
                    }
                    onChange={(e) => {
                      updateCoverageDetails(e.target.name, e.target.value);
                    }}
                  >
                    <option value=""></option>
                    {element?.dropDown?.map((option, idx) => (
                      <option key={idx} value={option?.s_LimitCode}>
                        {option?.s_LimitScreenName}
                      </option>
                    ))}
                  </Select>
                </FormField>
              )
          )}
        {/* <FormField label={t("Water Sublimit")}>
          <SelectField
            value={
              blanketDisplayCoverages?.additionalCoverages?.waterSublimit || ""
            }
            onChange={(e) => {
              updateCoverageDetails("waterSublimit", e.target.value);
            }}
            options={waterSublimitFields}
          />
        </FormField> */}

        <FormField label={t("Exclude Sinkhole")}>
          <Switch
            isChecked={
              blanketDisplayCoverages?.additionalCoverages?.excludSinkhole ===
              "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                "excludSinkhole",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>

        <FormField label={t("Cosmetic Roof Damage")}>
          <Switch
            isChecked={
              blanketDisplayCoverages?.additionalCoverages
                ?.cosmeticRoofDamage === "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                "cosmeticRoofDamage",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>

        <FormField label={t("ACV Roof Surface")}>
          <Switch
            isChecked={
              blanketDisplayCoverages?.additionalCoverages?.acvRoofSurface ===
              "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                "acvRoofSurface",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>
        <FormField label={t("Theft Exclusion")}>
          <Switch
            isChecked={
              blanketDisplayCoverages?.basicCoverages?.theftExclusions === "YES"
            }
            onChange={(e) => {
              setBlanketDisplayCoverages({
                ...blanketDisplayCoverages,
                basicCoverages: {
                  ...blanketDisplayCoverages.basicCoverages,
                  theftExclusions: e.target.checked ? "YES" : "NO",
                },
              });
            }}
            size="lg"
            defaultIsChecked
          />
        </FormField>

        <FormField label={t("Wind Exclusion")}>
          <Switch
            isChecked={
              blanketDisplayCoverages?.basicCoverages?.windExclusions === "YES"
            }
            onChange={(e) => {
              setBlanketDisplayCoverages({
                ...blanketDisplayCoverages,
                basicCoverages: {
                  ...blanketDisplayCoverages.basicCoverages,
                  windExclusions: e.target.checked ? "YES" : "NO",
                },
              });
            }}
            size="lg"
            defaultIsChecked
          />
        </FormField>
        <FormField label={t("BOP Coverage Extension")}>
          <Switch
            isChecked={
              blanketDisplayCoverages.additionalCoverages
                ?.bopCoverageExtenstion === "YES"
            }
            onChange={(e) => {
              setBlanketDisplayCoverages({
                ...blanketDisplayCoverages,
                additionalCoverages: {
                  ...blanketDisplayCoverages.additionalCoverages,
                  bopCoverageExtenstion: e.target.checked ? "YES" : "NO",
                },
              });
            }}
            size="lg"
            defaultIsChecked
          />
        </FormField>
        {selectedCard === "ConvenienceStore" && (
          <>
            <FormField label={t("Canopy")}>
              <CustomCurrencyInput
                value={blanketDisplayCoverages?.additionalCoverages?.canopy}
                onValueChange={(value) => {
                  updateCoverageDetails("canopy", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
            <FormField label={t("Pumps")}>
              <CustomCurrencyInput
                value={blanketDisplayCoverages?.additionalCoverages?.pumps}
                onValueChange={(value) => {
                  updateCoverageDetails("pumps", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
            <FormField label={t("Sign")}>
              <CustomCurrencyInput
                value={blanketDisplayCoverages?.additionalCoverages?.sign}
                onValueChange={(value) => {
                  updateCoverageDetails("sign", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
          </>
        )}
        <FormField label={t("Spoilage Coverage")}>
          <Switch
            isChecked={
              blanketDisplayCoverages.additionalCoverages?.spoilageCoverage ===
              "YES"
            }
            onChange={(e) => {
              setBlanketDisplayCoverages({
                ...blanketDisplayCoverages,
                additionalCoverages: {
                  ...blanketDisplayCoverages?.additionalCoverages,
                  spoilageCoverage: e.target.checked ? "YES" : "NO",
                },
              });
            }}
            size="lg"
          />
        </FormField>
        {blanketDisplayCoverages?.additionalCoverages?.spoilageCoverage ===
          "YES" && (
          <>
            {Array.isArray(baseDD?.all_basic_Coverage) &&
              baseDD?.all_basic_Coverage.map(
                (element, i) =>
                  element?.s_LimitCodeType === "DROPDOWN" &&
                  ["Spoilage"].includes(element?.s_ScreenName) && (
                    <FormField
                      key={i}
                      label={
                        element?.s_ScreenName === "Spoilage"
                          ? t("Spoilage Limit")
                          : element?.s_ScreenName
                      }
                    >
                      <Select
                        w="30%"
                        className="inputSelectBoxes"
                        value={
                          (element?.s_ScreenName === "Spoilage" &&
                            blanketDisplayCoverages?.additionalCoverages
                              ?.spoilageLimit) ||
                          blanketDisplayCoverages?.additionalCoverages?.[
                            element?.s_CoverageCode
                          ] ||
                          ""
                        }
                        name={
                          element?.s_ScreenName === "Spoilage"
                            ? "spoilageLimit"
                            : element?.s_CoverageCode
                        }
                        onChange={(e) => {
                          updateCoverageDetails(e.target.name, e.target.value);
                        }}
                      >
                        <option value=""></option>
                        {element?.dropDown?.map((option, idx) => (
                          <option key={idx} value={option?.s_LimitCode}>
                            {option?.s_LimitScreenName}
                          </option>
                        ))}
                      </Select>
                    </FormField>
                  )
              )}
            {/* <FormField label={t("Spoilage Limit")}>
              <ChakraSelect
                value={
                  blanketDisplayCoverages?.additionalCoverages?.spoilageLimit
                }
                onChange={(e) => {
                  setBlanketDisplayCoverages({
                    ...blanketDisplayCoverages,
                    additionalCoverages: {
                      ...blanketDisplayCoverages?.additionalCoverages,
                      spoilageLimit: e.target.value,
                    },
                  });
                }}
                w="30%"
              >
                <option value=""></option>
                {spoilageLimit?.map((e, i) => (
                  <option value={e.value} key={i}>
                    {" "}
                    {e.label}
                  </option>
                ))}
              </ChakraSelect>
            </FormField> */}
            <FormField label={t("Spoilage Coverage Type")}>
              <ChakraSelect
                value={
                  blanketDisplayCoverages?.additionalCoverages
                    ?.spoilageCoverageType
                }
                onChange={(e) => {
                  setBlanketDisplayCoverages({
                    ...blanketDisplayCoverages,
                    additionalCoverages: {
                      ...blanketDisplayCoverages?.additionalCoverages,
                      spoilageCoverageType: e.target.value,
                    },
                  });
                }}
                w="30%"
              >
                <option value=""></option>
                {spoilageCoverageType?.map((e, i) => (
                  <option value={e} key={i}>
                    {" "}
                    {e}
                  </option>
                ))}
              </ChakraSelect>
            </FormField>
          </>
        )}
        <FormField label={t("Carport Limit")}>
          <CustomCurrencyInput
            value={blanketDisplayCoverages?.additionalCoverages?.carpolLimit}
            onValueChange={(value) => {
              setBlanketDisplayCoverages({
                ...blanketDisplayCoverages,
                additionalCoverages: {
                  ...blanketDisplayCoverages.additionalCoverages,
                  carpolLimit: value,
                },
              });
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>
        <FormField label={t("Swimming Pool Limit")}>
          <CustomCurrencyInput
            value={
              blanketDisplayCoverages?.additionalCoverages?.swimmingPoolLimit
            }
            onValueChange={(value) => {
              setBlanketDisplayCoverages({
                ...blanketDisplayCoverages,
                additionalCoverages: {
                  ...blanketDisplayCoverages.additionalCoverages,
                  swimmingPoolLimit: value,
                },
              });
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>
        <FormField label={t("Outdoor Property Coverage")}>
          <CustomCurrencyInput
            value={
              blanketDisplayCoverages?.additionalCoverages?.outdoorPropertyCVG
            }
            onValueChange={(value) => {
              setBlanketDisplayCoverages({
                ...blanketDisplayCoverages,
                additionalCoverages: {
                  ...blanketDisplayCoverages.additionalCoverages,
                  outdoorPropertyCVG: value,
                },
              });
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>
      </VStack>
    </Box>
  );
};
