import { Box, Button, Checkbox, Flex } from "@chakra-ui/react";
import { Center, HStack, Text } from "@chakra-ui/react";

import Insure from "./Insure";
import Interest from "./Interest";
import Mortgagee from "./Mortgagee";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useStarDataContext } from "../../StarDataContext";
import PropertyDetail from "../under-writing/PropertyDetail";
import { useEffect } from "react";
import RoofProperties from "../under-writing/RoofProperties";
import LossHistoryForDisplay from "./LossHistoryForDisplay";

export default function Confirmation() {
  const { lang } = useParams();
  const { t } = useTranslation(lang, "quote");
  const {
    confirmation,
    setConfirmation,
    validationError,
    setValidationError,
    lossHistoryData,
  } = useStarDataContext();
  return (
    useEffect(() => {
      setConfirmation({
        ...confirmation,
        checkboxes: {
          readSubscriberAgreement: false,
          readTermsAndConditions: false,
          readFraudStatement: false,
        },
      });
      setValidationError({
        ...validationError,
        confirmation: false,
      });
    }, []),
    (
      <>
        <Box>
          <PropertyDetail />
        </Box>
        <RoofProperties />
        {Array.isArray(lossHistoryData) && lossHistoryData?.length > 0 && (
          <LossHistoryForDisplay />
        )}
        <Mortgagee />
        <Interest />
        <Insure />
        <>
          <Center pt="35px">
            <Flex direction={"column"}>
              <HStack userSelect={"none"} cursor={"pointer"}>
                <Checkbox
                  pb={"1rem"}
                  isChecked={confirmation?.checkboxes?.readSubscriberAgreement}
                  onChange={(e) => {
                    setConfirmation({
                      ...confirmation,
                      checkboxes: {
                        ...confirmation?.checkboxes,
                        readSubscriberAgreement: e.target.checked,
                      },
                    });
                  }}
                />
                <Text
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmation({
                      ...confirmation,
                      checkboxes: {
                        ...confirmation?.checkboxes,
                        readSubscriberAgreement:
                          !confirmation?.checkboxes?.readSubscriberAgreement,
                      },
                    });
                  }}
                  color={"neutral.500"}
                >
                  {t("i_have_read_and_agree_to_the")}
                </Text>
                <Text color="primary.500">
                  {" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://uat-odyssey-images.s3.us-east-1.amazonaws.com/subscriber-agreement.pdf"
                  >
                    {t("subscriber_agreement")}
                  </a>{" "}
                </Text>
              </HStack>
              {validationError?.confirmation &&
                !confirmation?.checkboxes?.readSubscriberAgreement && (
                  <Text color={"red"}>
                    {t("Please read and agree to the Subscriber's Agreement")}
                  </Text>
                )}
            </Flex>
          </Center>
          <Center py={1}>
            <Flex direction={"column"}>
              <HStack userSelect={"none"} cursor={"pointer"}>
                <Checkbox
                  pb={"1rem"}
                  isChecked={confirmation?.checkboxes?.readTermsAndConditions}
                  onChange={(e) => {
                    setConfirmation({
                      ...confirmation,
                      checkboxes: {
                        ...confirmation?.checkboxes,
                        readTermsAndConditions: e.target.checked,
                      },
                    });
                  }}
                />
                <Text
                  color={"neutral.500"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmation({
                      ...confirmation,
                      checkboxes: {
                        ...confirmation?.checkboxes,
                        readTermsAndConditions:
                          !confirmation?.checkboxes?.readTermsAndConditions,
                      },
                    });
                  }}
                >
                  {t("i_have_read_and_agree_to_the")}
                </Text>
                <Text
                  color="primary.500"
                  _hover={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://uat-odyssey-images.s3.amazonaws.com/Terms-and-Conditions.pdf"
                  >
                    {t("terms_and_conditions")}
                  </a>
                </Text>
                <Text color={"neutral.500"} mx={"-1px"}>
                  {t("and")} {t("the")}
                </Text>
                <Text
                  color="primary.500"
                  _hover={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://uat-odyssey-images.s3.amazonaws.com/Terms-Of-Use.pdf"
                  >
                    {t("Terms of Use")}.
                  </a>
                </Text>
              </HStack>
              {validationError?.confirmation &&
                !confirmation?.checkboxes?.readTermsAndConditions && (
                  <Text color={"red"}>
                    {t(
                      "Please read and agree to the Terms and Conditions and the Terms of Use"
                    )}
                  </Text>
                )}
            </Flex>
          </Center>
          <Center>
            <Flex direction={"column"}>
              <HStack userSelect={"none"} cursor={"pointer"}>
                <Checkbox
                  pb={"1rem"}
                  isChecked={confirmation?.checkboxes?.readFraudStatement}
                  onChange={(e) => {
                    setConfirmation({
                      ...confirmation,
                      checkboxes: {
                        ...confirmation?.checkboxes,
                        readFraudStatement: e.target.checked,
                      },
                    });
                  }}
                />
                <Text
                  color={"neutral.500"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmation({
                      ...confirmation,
                      checkboxes: {
                        ...confirmation?.checkboxes,
                        readFraudStatement:
                          !confirmation?.checkboxes?.readFraudStatement,
                      },
                    });
                  }}
                >
                  {t("i_have_read_and_agree_to_the")}
                </Text>
                <Text
                  color="primary.500"
                  _hover={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://uat-odyssey-images.s3.amazonaws.com/Fraud-Statement.pdf"
                  >
                    {t("fraud_statement")}
                  </a>
                </Text>
                <Text>{t("and")}</Text>
                <Text
                  color="primary.500"
                  _hover={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://uat-odyssey-images.s3.amazonaws.com/Inspection-Statement.pdf"
                  >
                    {t("inspection_statement")}
                  </a>
                </Text>
              </HStack>
              {validationError?.confirmation &&
                !confirmation?.checkboxes?.readFraudStatement && (
                  <Text color={"red"}>
                    {t(
                      "Please read and agree to the Fraud Statement And Inspection Statement"
                    )}
                  </Text>
                )}
            </Flex>
          </Center>
        </>
      </>
    )
  );
}
