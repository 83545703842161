import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Select,
  Input,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa";
import { IoArrowBackSharp } from "react-icons/io5";
import { GrFormNextLink } from "react-icons/gr";
import { IoMdAdd } from "react-icons/io";
import DateInput from "../../quote/create-star-quote/utils/DateInput";
import { formateDate } from "../../common/FormatDate";
import { sortMutliDimensionArray } from "../../common/ArrayFunctions";

export default function LicenseInformation({
  nextStep,
  prevStep,
  states,
  licManagerData,
  licManagerArray,
  setLicManagerArray,
}) {
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (licManagerArray.length > 0) {
      let hasError = false;
      new Promise(() => {
        for (var row = 0; row < licManagerArray.length; row++) {
          if (licManagerArray[row].s_LicenseNo.trim() === "") {
            toast.error(
              "License Number at row " + (parseInt(row) + 1) + " is required"
            );
            hasError = true;
            break;
          }
          if (!licManagerArray[row].n_StateId_FK) {
            toast.error(
              "License State at row " + (parseInt(row) + 1) + " is required"
            );
            hasError = true;
            break;
          }
        }
      });
      if (hasError === true) {
        return;
      }
    }
    nextStep();
  };

  const addLicenseInformationRow = () => {
    const oldLicManagerArray = [...licManagerArray];
    oldLicManagerArray.push(licManagerData);
    setLicManagerArray(oldLicManagerArray);
  };

  const removeLicenseInformationRow = (i) => {
    const licManagerNewArr = [...licManagerArray];
    if (licManagerNewArr.length > 1) {
      licManagerNewArr.splice(i, 1);
      setLicManagerArray(licManagerNewArr);
    } else {
      toast.error("At least one License Information row is required!");
      return false;
    }
  };

  const handleChangeAgency = (index, dataType, value) => {
    const licManager_arr = licManagerArray.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setLicManagerArray(licManager_arr);
  };

  return (
    <>
      <Box>
        <VStack spacing={4} w={"100%"}>
          <Box
            borderRadius={"2xl"}
            border={"1px solid"}
            borderColor={{ lg: "whiteAlpha.100", base: "white" }}
            p={{ base: 3, sm: 4, md: 6, lg: 8 }}
            boxShadow={"0 0 6px rgba(0, 0, 0, 0.3)"}
            width={"65rem"}
            maxWidth={"70rem"}
            bgColor={"white"}
          >
            <Flex flexDir={"column"}>
              <HStack mb={1} w={"100%"} justifyContent={"space-between"}>
                <Text fontSize={"3xl"} fontWeight={600}>
                  {t("License Information")}
                </Text>
                <Box>
                  <Button
                    size={"md"}
                    onClick={addLicenseInformationRow}
                    leftIcon={<IoMdAdd />}
                  >
                    {t("Add Row")}
                  </Button>
                </Box>
              </HStack>
              <Box>
                <TableContainer
                  borderRadius={"2xl"}
                  border={"1px solid"}
                  borderColor={"gray.200"}
                  overflowY={"auto"}
                  maxHeight={"25rem"}
                >
                  <Table variant="unstyled">
                    <Thead
                      bg={"var(--chakra-colors-agGridHeaderBg)"}
                      position={"sticky"}
                      top={0}
                      zIndex={"2"}
                    >
                      <Tr>
                        <Th>{t("License Status")}</Th>
                        <Th>
                          {t("License Number")}{" "}
                          <Text display={"inline"} color="red">
                            *
                          </Text>
                        </Th>
                        <Th>
                          {t("State")}{" "}
                          <Text display={"inline"} color="red">
                            *
                          </Text>
                        </Th>
                        <Th>{t("Issue Date")}</Th>
                        <Th>{t("Expiration Date")}</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {licManagerArray.map((licManager, index) => {
                        return (
                          <>
                            <Tr
                              key={index}
                              _hover={{
                                bg: `rgba(0, 0, 0, 0.075)`,
                              }}
                            >
                              <Td
                                p={"8px"}
                                width={"15%"}
                                border={"1px solid"}
                                borderColor={"var(--chakra-colors-primary-50)"}
                              >
                                <Select
                                  size="sm"
                                  borderRadius={"lg"}
                                  bg={"white"}
                                  id="n_LicenseType"
                                  name="n_LicenseType"
                                  value={licManager.n_LicenseType}
                                  onChange={(e) =>
                                    handleChangeAgency(
                                      index,
                                      "n_LicenseType",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value=""></option>
                                  <option value="111">{t("Active")}</option>
                                  <option value="112">{t("Closed")}</option>
                                </Select>
                              </Td>
                              <Td
                                p={"8px"}
                                width={"20%"}
                                border={"1px solid"}
                                borderColor={"var(--chakra-colors-primary-50)"}
                              >
                                <Input
                                  size="sm"
                                  borderRadius={"lg"}
                                  bg={"white"}
                                  type="text"
                                  id="s_LicenseNo"
                                  name="s_LicenseNo"
                                  autoComplete="off"
                                  value={licManager.s_LicenseNo}
                                  onChange={(e) =>
                                    handleChangeAgency(
                                      index,
                                      "s_LicenseNo",
                                      e.target.value
                                    )
                                  }
                                />
                              </Td>
                              <Td
                                p={"8px"}
                                width={"10%"}
                                border={"1px solid"}
                                borderColor={"var(--chakra-colors-primary-50)"}
                              >
                                <Select
                                  size="sm"
                                  borderRadius={"lg"}
                                  bg={"white"}
                                  id="n_StateId_FK"
                                  name="n_StateId_FK"
                                  value={licManager.n_StateId_FK}
                                  onChange={(e) =>
                                    handleChangeAgency(
                                      index,
                                      "n_StateId_FK",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value=""></option>
                                  {sortMutliDimensionArray(
                                    "s_StateName",
                                    states
                                  ).map((state) => (
                                    <option value={state.n_StateId_PK}>
                                      {state.s_StateName}
                                    </option>
                                  ))}
                                </Select>
                              </Td>
                              <Td
                                p={"8px"}
                                width={"20%"}
                                border={"1px solid"}
                                borderColor={"var(--chakra-colors-primary-50)"}
                              >
                                <DateInput
                                  id="d_IssueDate"
                                  name="d_IssueDate"
                                  placeholder="MM/DD/YYYY"
                                  value={formateDate(licManager.d_IssueDate)}
                                  styleProps={{
                                    bgColor: "white",
                                    size: "sm",
                                    borderRadius: "lg",
                                  }}
                                  onChange={(value) =>
                                    handleChangeAgency(
                                      index,
                                      "d_IssueDate",
                                      formateDate(value, "YYYY-MM-DD")
                                    )
                                  }
                                />
                              </Td>
                              <Td
                                p={"8px"}
                                width={"20%"}
                                border={"1px solid"}
                                borderColor={"var(--chakra-colors-primary-50)"}
                              >
                                <DateInput
                                  id="d_ExpireDate"
                                  name="d_ExpireDate"
                                  placeholder="MM/DD/YYYY"
                                  value={formateDate(licManager.d_ExpireDate)}
                                  styleProps={{
                                    bgColor: "white",
                                    size: "sm",
                                    borderRadius: "lg",
                                  }}
                                  onChange={(value) =>
                                    handleChangeAgency(
                                      index,
                                      "d_ExpireDate",
                                      formateDate(value, "YYYY-MM-DD")
                                    )
                                  }
                                />
                              </Td>
                              <Td
                                p={"8px"}
                                width={"10%"}
                                border={"1px solid"}
                                borderColor={"var(--chakra-colors-primary-50)"}
                              >
                                <Flex justifyContent={"center"}>
                                  <Button
                                    size={"sm"}
                                    colorScheme="red"
                                    onClick={(e) =>
                                      removeLicenseInformationRow(index)
                                    }
                                    leftIcon={<FaTrash size={16} />}
                                  >
                                    Delete
                                  </Button>
                                </Flex>
                              </Td>
                            </Tr>
                          </>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Flex>
          </Box>
          <Container pt={4} pb={6} minH={"10vh"}>
            <Flex gap={6} alignItems={"center"} justifyContent={"center"}>
              <Box>
                <Button
                  variant={"outline"}
                  borderRadius={"xl"}
                  onClick={prevStep}
                  _hover={{ color: "white", bgColor: "primary.500" }}
                  leftIcon={<IoArrowBackSharp size={18} />}
                >
                  {t("Back")}
                </Button>
              </Box>
              <Box>
                <Button
                  type="submit"
                  color={"white"}
                  bgColor={"primary.500"}
                  borderRadius={"xl"}
                  onClick={handleSubmit}
                  rightIcon={<GrFormNextLink size={24} />}
                >
                  {t("Next")}
                </Button>
              </Box>
            </Flex>
          </Container>
        </VStack>
      </Box>
      <style>
        {`
          .react-datepicker__day--keyboard-selected,
          .react-datepicker__month-text--keyboard-selected,
          .react-datepicker__quarter-text--keyboard-selected,
          .react-datepicker__year-text--keyboard-selected {
            background-color: transparent !important;
          }
        `}
      </style>
    </>
  );
}
