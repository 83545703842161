import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { ConstructionTypeSelection } from "./ConstructionType";
import TitleHeader from "../TitleHeader";
import { useTranslation } from "react-i18next";
import LossHistory from "./LossHistory";
import { SecurityServiceSelection } from "./SecurityServices";
import { LatestClosingHoursSelection } from "./ClosingHours";
import { MaintenanceServiceSelection } from "./MaintainenceSection";
import { SmokingSelection } from "./SmokingSelection";
import { ElevatorSafetySelection } from "./ElevatorSafetySelection";
import { UnderwritingForm } from "./UnderwritingForm";
import { ApplicantDetails } from "./ApplicantDetails";
import { useQuote } from "../../CommCreateQuote";
import { LocationAddress } from "./LocationAddress";
import { FcDeleteDatabase } from "react-icons/fc";
import { useRef, useState } from "react";
import EnhancedAlertDialog from "./AlertDialoug";
import { toast } from "react-toastify";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { IoDuplicateOutline } from "react-icons/io5";
import { propertySchema } from "../../commercial-quote/data/propertySchema";

export const Underwriting = () => {
  const { t } = useTranslation();
  const [deletingIndex, setDeletingIndex] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    buildingDetails,
    setBuildingDetails,
    coverages,
    setCoverages,
    lossHistoryData,
    setLossHistoryData,
    underWriting,
    setUnderWriting,
  } = useQuote();
  const [address, setAddress] = useState("");
  const cancelRef = useRef();

  const handleDelete = () => {
    const updatedBuildingDetails = buildingDetails.filter(
      (_element, i) => i !== deletingIndex
    );
    const updateCoverages = coverages.filter(
      (_element, i) => i !== deletingIndex
    );
    setBuildingDetails(updatedBuildingDetails);
    setCoverages(updateCoverages);
    onClose();
  };

  const openDeleteModal = (index) => {
    setDeletingIndex(index);
    onOpen();
  };

  const addNewLocation = () => {
    const newLocation = {
      ...propertySchema,
      locationIndetifier: `Building 0${buildingDetails?.length + 1 || 0 + 1}`,
    };

    setBuildingDetails([...buildingDetails, newLocation]);
  };

  const duplicateCurrentLocation = (currentIndex) => {
    if (currentIndex != null && buildingDetails[currentIndex]) {
      const duplicatedLocation = { ...buildingDetails[currentIndex] };
      setBuildingDetails([...buildingDetails, duplicatedLocation]);
    }
  };

  return (
    <>
      <Flex flexDir={"column"} gap={6}>
        <TitleHeader title={t("Applicant Details")}>
          <ApplicantDetails />
        </TitleHeader>
        <LossHistory
          lossHistoryData={lossHistoryData}
          setLossHistoryData={setLossHistoryData}
          setUnderWriting={setUnderWriting}
          underWriting={underWriting}
          preHeader={true}
          key={1}
        />
        <TitleHeader title={t("Building Details")}>
          <Accordion w="100%" allowToggle defaultIndex={[0]} pb={4}>
            {buildingDetails &&
              Array.isArray(buildingDetails) &&
              buildingDetails.map((_, index) => (
                <AccordionItem
                  key={index}
                  borderRadius="xl"
                  border="1px solid"
                  borderColor={"neutral.100"}
                  overflow={"hidden"}
                  width="100%"
                  my={2}
                >
                  <AccordionButton bg={"neutral.50"} borderRadius={"xl"}>
                    <Box as="span" flex="1" textAlign="left">
                      <Text my={2} fontWeight={600}>
                        {_?.locationIndetifier || ""} -{" "}
                        {_?.locationAddress || ""}
                      </Text>
                    </Box>
                    <Flex alignItems={"center"} gap={2}>
                      <Tooltip hasArrow label={"Delete"} placement="top">
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (index !== 0) {
                              openDeleteModal(index);
                              setAddress(
                                `${
                                  buildingDetails[index]?.locationIndetifier ||
                                  ""
                                } - ${
                                  buildingDetails[index]?.locationAddress || ""
                                }`
                              );
                            } else {
                              toast.info(
                                t(
                                  "You must have at least one location to continue."
                                ),
                                {
                                  toastId: "minimumlocationerror",
                                }
                              );
                            }
                          }}
                          variant={"ghost"}
                          borderRadius={"xl"}
                          size={"sm"}
                        >
                          <FcDeleteDatabase size={30} />
                        </Button>
                      </Tooltip>
                      <AccordionIcon />
                    </Flex>
                  </AccordionButton>

                  <AccordionPanel>
                    <Box my={2} />
                    <LocationAddress index={index} />
                    <Box
                      borderTop={"1px dotted"}
                      borderColor={"neutral.300"}
                      my={4}
                    />
                    <UnderwritingForm index={index} />
                    <Box
                      borderTop={"1px dotted"}
                      borderColor={"neutral.300"}
                      my={4}
                    />
                    <ConstructionTypeSelection index={index} />
                    <Box
                      borderTop={"1px dotted"}
                      borderColor={"neutral.300"}
                      my={4}
                    />
                    <SecurityServiceSelection index={index} />
                    <Box
                      borderTop={"1px dotted"}
                      borderColor={"neutral.300"}
                      my={4}
                    />
                    <MaintenanceServiceSelection index={index} />
                    <Box
                      borderTop={"1px dotted"}
                      borderColor={"neutral.300"}
                      my={4}
                    />
                    <ElevatorSafetySelection index={index} />
                    <Box
                      borderTop={"1px dotted"}
                      borderColor={"neutral.300"}
                      my={4}
                    />
                    <LatestClosingHoursSelection index={index} />
                    <Box
                      borderTop={"1px dotted"}
                      borderColor={"neutral.300"}
                      my={4}
                    />
                    <SmokingSelection index={index} />
                    <Box
                      borderTop={"1px dotted"}
                      borderColor={"neutral.300"}
                      my={4}
                    />
                    <Flex justifyContent={"flex-end"} py={4} gap={4}>
                      <Button
                        leftIcon={<HiOutlineDocumentDuplicate size={20} />}
                        borderRadius={"xl"}
                        variant={"outline"}
                        onClick={() => {
                          if (
                            Array.isArray(buildingDetails) &&
                            buildingDetails.length <= 20
                          ) {
                            duplicateCurrentLocation(index);
                          } else {
                            toast.info(
                              t("Currently limited to 20 locations."),
                              { toastId: "lastlocationerror" }
                            );
                          }
                        }}
                      >
                        {t("Duplicate Current Location")}
                      </Button>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </Accordion>
          <Button
            leftIcon={<IoDuplicateOutline size={20} />}
            borderRadius={"xl"}
            variant={"outline"}
            onClick={() => {
              if (
                Array.isArray(buildingDetails) &&
                buildingDetails.length <= 20
              ) {
                addNewLocation();
              } else {
                toast.info(t("Currently limited to 20 locations."), {
                  toastId: "lastlocationerror",
                });
              }
            }}
          >
            {t("Add New Location")}
          </Button>
        </TitleHeader>
      </Flex>
      {/* AlertDialog for Delete Confirmation */}
      <EnhancedAlertDialog
        cancelRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        onDelete={handleDelete}
        address={address}
      />
    </>
  );
};
