import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { FaXingSquare } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./report.scss";
import { CSVLink } from 'react-csv';
import Moment from "moment";
import { isEmpty, set } from "lodash";
import { reportTypeDD,getReportDetails } from "../../services/reportService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { toast } from 'react-toastify';
import { useDispatch, useSelector , connect } from 'react-redux';

const Report = () => {
  const [startDate, setStartDate] = useState(new Date());
  
  const [ScheduledFrom, setScheduledFrom] = useState(new Date());
    const [ScheduledTo, setScheduledTo] = useState(new Date());
    const [involvedData, setInvolvedData] = useState([]);
    const [reportDD, setReportDD] = useState([]);
	const [loader, setLoader] = useState(true);
	const [loader2, setLoader2] = useState(false);
	const [docType, setDocType] = useState('false');
	const [headers, setHeaders] = useState();
	const [docFormat, setDocFormat] = useState('csv');
	const [spinner, setSpinner] = useState(false);
	const [fileName, setFileName] = useState();
	const dispatch = useDispatch();
	
	useEffect(() => {
		getReportTypeDD();
    }, []);
	
	const getReportTypeDD = async () => {
    try {
      await dispatch(reportTypeDD()).then((res) => {
        setReportDD(res.REPORTTYPE);
      });
    } finally {
      setLoader(false);
    }
  };
	
	
	const headers1 = [
        // Involvement Report
        { label: "Claim NO", key: "Claim_No"},   
        { label: "Policy Number", key: "Risk_Id"},   
        { label: "Claim Type", key: "Description"}, 
        { label: "Claim Status", key: "Claim_Status_Code"},   
        { label: "Report By", key: "s_AppCodeNameForDisplay"},
        { label: "Reported Date", key: "reportedDate"},
        { label: "PA-Yes/No", key: "PA_Involved_YN"},
        { label: "Public Adjuster Name", key: "paInvolvedName"},
        { label: "PA Involved Date", key: "paInvolvedDate"},
        { label: "AT-Yes/No", key: "Attorney_Involved_YN"},
        { label: "Attorney Name", key: "attInvolvedName"},
        { label: "AT Involved Date", key: "attInvolvedDate"},
        { label: "AOB-Yes/No", key: "s_IsAOBInvolved_YN"},
        { label: "AOB Name", key: "aobInvolvedName"},
        { label: "AOB Involved Date", key: "aobInvolvedDate"},

    ]
	
	const handleSubmit = () => {
        var fromDate = null;
        var toDate = null;
        var docPk = null;
        var format = null;
        fromDate = Moment(ScheduledFrom).format("YYYY-MM-DD");
        toDate = Moment(ScheduledTo).format("YYYY-MM-DD");
        docPk = docType;
        format = docFormat;
        const params = {
            fromDate,
            toDate,
			docPk,
			format,
        }
        getInvolvementReport(params);
    }

    // API Calling
    const getInvolvementReport = async (params) => {
      setSpinner(true);
      try {
        await dispatch(getReportDetails(params)).then((res) => {
          if (res.status == "Y") {
            setInvolvedData(res.reportData);
            setHeaders(res.CsvColumn);
            setFileName(res.fileName);
            document.getElementById("csvLinkID").click();
          } else {
            toast.error(res.msg);
          }
        });
      } finally {
        setSpinner(false);
      }
    };

  return (
	  <>
	{!loader ? 
		<LoadingOverlay >
			<Loader loading={loader2} />
		  { 
		    <Row>
		      <Col xs="12" className="report-page-row1">
		        <div id="report-section-card">
		          <div className="report-section-card-title-div">
		            <FaXingSquare size={20} color={"#fff"} />

		            <span className="report-section-card-title-name">Report</span>
		          </div>

		          <Row className="report-section-row">
		            <Col xs="12" sm="12" md="12" lg="12" xl="12">
		              <div className="report-select-div">
		                <p className="dashboard-group-label" id="report-select-label">
		                  Select Report:
		                </p>
		                <div className="report-page-selections">
		                  <Form.Select className="dashboard-group-select" onChange={(e) => setDocType(e.target.value)} disabled={spinner}>
		                    <option value={false}>Select</option>
		                    {reportDD.map((x) => (
			                    <option value={x.value}>
			                      {x.label}
			                    </option>
			                  ))}
		                  </Form.Select>
		                </div>
		              </div>
		            </Col>
		          </Row>
		        </div>
		      </Col>
			  {docType != 'false' ? 
		      <Col xs="12" className="report-page-row1">
		        <div id="report-section-card">
		          <div className="report-section-card-title-div">
		            <FaXingSquare size={20} color={"#fff"} />

		            <span
		              className="report-section-card-title-name"
		              id="report-select-label"
		            >
		              Parameters
		            </span>
		          </div>

		          <Row className="report-page-row1-subdiv">
		            <Col xs="12" sm="12" md="12" lg="4" xl="4">
		              <div className="report-select-div">
		                <p className="dashboard-group-label" id="report-select-label">
		                  From Date:
		                </p>
		                <div className="report-page-selections">
		                  <DatePicker
						  	readOnly={spinner}
		                    id="dashboard-datepicker"
		                    className="dashboard-group-input"
		                    onChange={(date) => setScheduledFrom(date)}
                            selected={ScheduledFrom}
							onKeyDown={(e) => {
								e.preventDefault();
							  }}
							  maxDate={new Date()}
		                  />
		                </div>
		              </div>
		            </Col>

		            <Col xs="12" sm="12" md="12" lg="4" xl="4">
		              <div className="report-select-div">
		                <p className="dashboard-group-label" id="report-select-label">
		                  To Date:
		                </p>
		                <div className="report-page-selections">
		                  <DatePicker
						  	readOnly={spinner}
		                    id="dashboard-datepicker"
		                    className="dashboard-group-input"
		                    onChange={(date) => setScheduledTo(date)}
                            selected={ScheduledTo}
							onKeyDown={(e) => {
								e.preventDefault();
							  }}
							maxDate={new Date()}
		                  />
		                </div>
		              </div>
		            </Col>

		            <Col xs="12" sm="12" md="12" lg="4" xl="4">
		              <div className="report-select-div">
		                <p className="dashboard-group-label" id="report-select-label">
		                  File Format:
		                </p>
		                <div className="report-page-selections">
		                  <Form.Select
						  	disabled={spinner}
		                    className="dashboard-group-select"
		                    style={{ minWidth: "200px" }}
							onChange={(e) => setDocFormat(e.target.value)}
		                  >
		                    <option value="csv">CSV</option>
		                  </Form.Select>
		                </div>
		              </div>
		            </Col>

		            <Col xs="12" className="report-generate-btn">
		              <Button id="common-btn" variant="primary" onClick={() => handleSubmit()} disabled={spinner}>
					  {spinner ? (<><Spinner
				          as="span"
				          variant="light"
				          size="sm"
				          role="status"
				          aria-hidden="true"
				          animation="border" /> &nbsp;</>) : null}
		                    Generate
		              </Button>{" "}
					  <CSVLink data={involvedData} headers={headers} filename={fileName} id="csvLinkID" ></CSVLink>
		            </Col>
		          </Row>
		        </div>
		      </Col>
			  : null }
		    </Row>
	}
</LoadingOverlay>

: 
<LoadingOverlay >
	<Loader loading={loader} />
  { 
	  
  }
</LoadingOverlay>

}
</>
  );
};

export default Report;
