import React, { useEffect, useState } from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getPayGovCollectionDetails, getPayGovToken } from "../../../../services/paymentService";
import {isAchConfigAvailable, submitAchPayment} from "../../../../services/producerService";
import { getHostUrl } from "../../../common/HostUrl";
import { toast } from "react-toastify";
import { FaCreditCard, FaMoneyCheck, FaMoneyCheckAlt, FaReceipt } from "react-icons/fa";
import Error from "../../../quote/flood/quote/Modal/Error";
import CvgOptionsModal from "./RenewPayment/CvgOptionsModal";
import CheckModal from "./CheckModal";
import { Modal, Row, Button } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import Swal from 'sweetalert2';
import { isTenantActive } from "../../../../services/commonService";

const Index = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { showPaymentButton, successUrl, cancelUrl, amount, policyNumber, poTransPK, loading, setLoading, afterPayment, source, transTypeScreenName, showRolloverAlert } = props;

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [showCvgOptionsModal, setShowCvgOptionsModal] = useState(false);
    const [paymentType, setPaymentType] = useState('');
    const [showCheckModal, setShowCheckModal] = useState(false);
    const [showAchModal, setShowAchModal] = useState(false);
    const [showAchSuccessModal, setShowAchSuccessModal] = useState(false);
    const [achConfigStatus, setAchConfigStatus] = useState(false);
    
    const location = useLocation()
    const search = location.search;
    const payGovToken = new URLSearchParams(search).get('token');
    const isCancelled = new URLSearchParams(search).get('cancelled');
   
    useEffect(() => {
        if (showPaymentButton === true) {
            // get collection details
            async function getCollectionDetails() {
                if (![null, "", undefined].includes(payGovToken) && isCancelled === 'false') {
                    await payGovCollectionDetails();
                }
            }

            getCollectionDetails();
            getAchConfigStatus();
            
        }

        // remove token from url 
        if (isCancelled === 'true') {
            navigation(window.location.pathname);
        }
    }, [showPaymentButton]);

    /**
     * Open Coverage option for renewal transaction
     * @param {String} type 
     * @param {Integer|String} amount 
     */
    const handleButtonClick = async (type, amount) => {
        setPaymentType(type);
        if (transTypeScreenName === 'Renewal') {
            setShowCvgOptionsModal(true);
        } else {
            await navigatePayment(type, amount);
        }
    }

    // pay.gov 
    const navigatePayment = async (type, paymentAmt) => {
        if (paymentAmt <= 0) {
            this.error(t('Amount is required'));
            return false;
        }
        var hostUrl = getHostUrl();

        var paymentSuccessUrl = hostUrl + location.pathname;
        var paymentCancelUrl = paymentSuccessUrl;

        // if successUrl/cancelUrl is not empty
        if (successUrl) {
            paymentSuccessUrl = successUrl;
        }

        if (cancelUrl) {
            paymentCancelUrl = cancelUrl;
        }

        // to identify if payment is cancelled or success
        paymentSuccessUrl = paymentSuccessUrl + "?cancelled=false";
        paymentCancelUrl = paymentCancelUrl + "?cancelled=true";


        const params = {
            'transaction_amount': paymentAmt,
            'url_success': paymentSuccessUrl,
            'url_cancel': paymentCancelUrl,
            'payment_type': type,
            'policyNo': policyNumber,
            'source': source
        };

        setLoading(true);
        await dispatch(getPayGovToken(params)).then((res) => {
            if (res.success === true) {
                if (res.data.payment_page_url) {
                    // in case modal is open
                    setShowCvgOptionsModal(false);
                    window.location = res.data.payment_page_url;
                }
            } else {
                toast.error(t('Something Went Wrong!'));
            }
        }).catch((error) => {
            const { data } = error || {};
            // validation error
            if (data.hasOwnProperty('status')) {
                const error = data.errors || [];
                var showError = false, validationError = [];
                if (error) {
                    validationError = [];
                    Object.values(error).map((value) => {
                        validationError.push(value[0]);
                    });
                    showError = true;
                }
                setShowErrorModal(showError);
                setErrorList(validationError);
            } else if (data.errors && data.errors.hasOwnProperty('text')) {
                toast.error(data.errors.text);
            } else {
                toast.error(t('Something Went Wrong!'));
            }
        });
        

        setLoading(false);
    }

    // after payment
    const payGovCollectionDetails = async () => {
        const params = {
            'token': payGovToken,
            'poTransPK': poTransPK,
        };
        setLoading(true);
        await dispatch(getPayGovCollectionDetails(params)).then((res) => {
            if (res.success === true) {
                if (res.data.status === "Y") {
                    if (res.data.transaction_status === "Success") {
                        toast.success(t('Payment successful'));
                    } else {
                        toast.error(t('Payment Failed'));
                    }
                }
                // remove token from url 
                navigation(location.pathname);
                // trigger after payment collection response
                if (afterPayment) {
                    afterPayment();
                }
            } else {
                toast.error(t('Something Went Wrong'));
            }
        }).catch((error) => {
            const { errors } = error.data || {};
            toast.error(errors.text || t('Something Went Wrong'));
        });
        setLoading(false);
    }

    const getAchConfigStatus = async () =>{
         setLoading(true); 
         let result = await isAchConfigAvailable()
            .then((result) => {
                setAchConfigStatus(result);
            })
            .catch(() => {
                setAchConfigStatus(false);
            })
            .finally(() => {
                setLoading(false);
            });
        }

    const addPaymentLog = async () => {
        const params = {
            'source': source,
            'poTransPK': poTransPK,
        };
        setLoading(true); 
          let result = await submitAchPayment(params)   
          if(result == true){
             setShowAchSuccessModal(true)
          }
        setLoading(false);
        setShowAchModal(false)
    }
    const handleClose = () => {
        setShowAchSuccessModal(false)
        window.location.reload();
    }

    /**
     * renewal bill alert
     */
    const rolloverBillAlert = () => {
        Swal.fire({
            title: t('Rollover Bill'),
            html: t("A renewal invoice will be generated & mailed once the policy is approved by underwriting. You will be contacted if additional information is required before an invoce can be sent.") + '<br><br>' + t('Premium can be submitted via the other methods of payment at any time.'),
            icon: 'info',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                return false;
            }
        });
    }

    return (
        <>
            {
                (isTenantActive() || (!isTenantActive() && transTypeScreenName === 'Endorsement'))  && (
                    <ButtonGroup className="mr-2">
                        <DropdownButton
                            as={ButtonGroup}
                            title={t('Pay Now')}
                            id="common-btn-sm"
                            size="sm"
                            variant="primary"
                            disabled={loading}
                        >
                            <Dropdown.Item
                                onClick={() => handleButtonClick("PLASTIC_CARD", amount)}
                                disabled={loading}
                            >
                                <FaCreditCard size={20} />&nbsp;
                                <span>{t('Credit Card')}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleButtonClick("ACH", amount)}
                                disabled={loading}
                            >
                                <FaMoneyCheck size={20} />&nbsp;
                                <span>{t('eCheck')}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => setShowCheckModal(true)}
                                disabled={loading}
                            >
                                <FaMoneyCheckAlt size={20} />&nbsp;
                                <span>{t('Check')}</span>
                            </Dropdown.Item>
                            {
                                // show only on rollover renewal 
                                showRolloverAlert && (
                                    <Dropdown.Item
                                        onClick={() => rolloverBillAlert()}
                                        disabled={loading}
                                    >
                                        <FaReceipt size={20} />&nbsp;
                                        <span>{t('Rollover Bill')}</span>
                                    </Dropdown.Item>
                                )
                            }
                            {achConfigStatus !== false && achConfigStatus?.premium === true ?
                                <>
                                    <Dropdown.Item
                                        onClick={() => setShowAchModal(true)}
                                        disabled={loading}
                                    >
                                        <FaMoneyCheckAlt size={20} />&nbsp;
                                        <span>{t('ACH')}</span>
                                    </Dropdown.Item>
                                </> : null
                            }
                        </DropdownButton>
                    </ButtonGroup>
                )
            }
            {
                showErrorModal === true && (
                    <Error
                        show={showErrorModal}
                        error={errorList}
                        toggle={setShowErrorModal}
                    />
                )
            }
            {
                showCvgOptionsModal === true && (
                    <CvgOptionsModal
                        isLoading={loading}
                        poTransPK={poTransPK}
                        paymentType={paymentType}
                        navigatePayment={navigatePayment}
                        showCvgOptionsModal={showCvgOptionsModal}
                        setShowCvgOptionsModal={setShowCvgOptionsModal}
                    />
                )
            }
            {
                showCheckModal === true && (
                    <CheckModal
                        showCheckModal={showCheckModal}
                        setShowCheckModal={setShowCheckModal}
                    />
                )
            }
            {
                showAchModal === true ? 
                <>
                    <Modal show={showAchModal} size="md" backdrop='static'>
                        <LoadingOverlay>
                        <Loader loading={loading} />   
                        <Modal.Header>
                            Payment Via ACH
                        </Modal.Header>
                        <Modal.Body>
                            <section className="pay-now-section">
                                <Row className="justify-content-center">
                                     
                                        <p className="pmd-heading">
                                            <b>
                                                A payment in the amount of ${amount} will be withdrawn from the bank account listed in your profile
                                            </b>
                                        </p>         
                                    
                                </Row>
                            </section>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                size="sm"
                                id="common-close-btn-sm"
                                variant="primary"
                                onClick={() => addPaymentLog(poTransPK)}
                            >
                                {t('Confirm')}
                            </Button>
                            <Button
                                size="sm"
                                id="common-close-btn-sm"
                                variant="secondary"
                                onClick={() => setShowAchModal(false)}
                            >
                                {t('Close')}
                            </Button>

                        </Modal.Footer>

                        </LoadingOverlay>
                    </Modal>
                </> 
                : null
            }
            {
                showAchSuccessModal === true ? 
                <>
                    <Modal show={showAchSuccessModal} size="md" backdrop='static'>
                        <LoadingOverlay>
                        <Loader loading={loading} />   
                        <Modal.Header>
                            Payment Via ACH
                        </Modal.Header>
                        <Modal.Body>
                            <section className="pay-now-section">
                                <Row className="justify-content-center">
                                    <> 
                                        <p className="pmd-heading">
                                            <b>
                                               Thank You!
                                            </b>
                                           
                                        </p>   
                                        <p>
                                            <b>
                                                Your payment has been submitted successfully. 
                                            </b>
                                        </p>
                                        <p>
                                            <b>
                                                Please allow upto 24 hours for processing 
                                            </b>
                                        </p>
                                    </>      
                                    
                                </Row>
                            </section>
                        </Modal.Body>
                        <Modal.Footer>
                           
                            <Button
                                size="sm"
                                id="common-close-btn-sm"
                                variant="secondary"
                                onClick={() => {
                                   handleClose();
                                }}
                            >
                                {t('Close')}
                            </Button>

                        </Modal.Footer>

                        </LoadingOverlay>
                    </Modal>
                </> 
                : null
            }
        </>
    )
}

export default Index;