import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import SearchQuoteTab from "./search-quote/SearchQuoteTab";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./quote.scss";
import { useDispatch, connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getQuoteData,
  getEmptyQuoteData,
  getEditEmptyQuoteData,
  getBaseData,
  saveAndRate,
  dataTransferToCore,
  getBindApplicationHO3,
  //   getErrorMsg,
  callGeneratePdfApi,
} from "../../services/quoteService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import Moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CreateStarApplicationPool } from "./search-quote/components/utils/CreateStarApplicationPool";
import { updateStarQuote } from "./search-quote/components/utils/UpdateStarData";
import ErrorModal from "./search-quote/components/utils/ErrorModal";
import { IoIosCloseCircleOutline } from "react-icons/io";
import moment from "moment";

import {
  Modal as ChakraModal,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  Button as ChakraButton,
} from "@chakra-ui/react";
import { t } from "i18next";
import states from "./common/States";
import { getFees } from "./create-quote/api/getFees";

export const getStateCode = (input) => {
  if (typeof input !== "string") {
    return "";
  }
  const normalizedInput = input.toLowerCase();

  const matchedStateByName = states.find(
    (state) => state.name.toLowerCase() === normalizedInput
  );

  if (matchedStateByName) {
    return matchedStateByName.abbr;
  }
  const matchedStateByAbbr = states.find(
    (state) => state.abbr.toLowerCase() === normalizedInput
  );

  if (matchedStateByAbbr) {
    return matchedStateByAbbr.abbr;
  } else {
    return "";
  }
};
const EditApplication = (props) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [rateLoader, setRateLoader] = useState(false);
  const [applicationDetails, setApplicationDetails] = useState({});
  let params = useParams();
  const [isBind, setIsBind] = useState(false);
  const [Show, setShow] = useState(false);
  const [errors, setErrors] = useState(false);
  const [ValidationError, setValidationError] = useState(false);
  const [underWritingQuestions, setUnderWritingQuestions] = useState([]);
  const [underWritingAnswers, setUnderWritingAnswers] = useState([]);
  const [baseData, setBaseData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorDetails, setErrorDetails] = useState([]);
  const [showErrModal, setShowErrModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [feesData, setFeesData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true);
        const product = params.productID;
        const baseData = await dispatch(getBaseData(product));
        if (baseData?.data?.GENERAL) {
          setUnderWritingQuestions(baseData?.data?.GENERAL);
          setBaseData(baseData?.data);
        }
        if (params.transID) {
          await getQuoteforEdit(params.transID);
        } else {
          await getEmptyQuote();
        }
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false);
        toast.error("Failed to fetch");
      }
    };
    fetchData();
  }, [params]);

  useEffect(() => {
    if (props.quoteData && Object.keys(props.quoteData).length > 0) {
      setApplicationDetails(props.quoteData);
    }
  }, [props.quoteData]);

  const fetchFeesData = async (premium, quoteId, state) => {
    setLoader(true);
    try {
      const res = await getFees(
        {
          prTransTypeCode: "NEWBUSINESS", // New Quote Query
          premium: Number(premium),
          productId: params.productID || 1,
          state,
          quoteId: quoteId,
        },
        "star"
      );
      if (res?.data?.success) {
        setFeesData(res?.data?.data);
      } else {
        toast.error(res?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "An error occurred while fetching the fees. Please try again later.",
        {
          position: "top-center",
        }
      );
    } finally {
      setLoader(false);
    }
  };

  const setDefaultData = (objectName, objectValue) => {
    var quoteDataVar = props.quoteData;
    quoteDataVar[objectName] = objectValue;

    dispatch(getEditEmptyQuoteData(quoteDataVar));
  };

  const getEmptyQuote = async () => {
    setLoader(true);
    try {
      dispatch(getEmptyQuoteData()).then((res) => {
        dispatch(getEditEmptyQuoteData(res));
        //setAllData(res.data);
        setLoader(false);
      });
    }
    finally {
      setLoader(false);
    }
  };

  const getQuoteforEdit = async (transID) => {
    setLoader(true);
    await dispatch(getQuoteData(transID)).then((res) => {
      if (res) {
        setUnderWritingAnswers(res?.allAnswerData);
        fetchFeesData(
          res?.premiumData?.FinalPremium,
          res?.policyInfo?.Policy_No,
          res?.personAddr?.s_StateName
        );
      }
      if (
        res.policyInfo.d_BinderDate !== "" &&
        res.policyInfo.d_BinderDate !== null
      ) {
        setIsBind(true);
      }
      dispatch(getEditEmptyQuoteData(res));
      setLoader(false);
    }).catch(()=> {
      setLoader(false);
    });
  };

  const handleChange = (e) => {
    const { name, value, title } = e.target;
    let quoteDataVar = props.quoteData;
    if (name === "d_TransEffectiveFrom") {
      const value2 = Moment(value).add(1, "year").toISOString().split("T")[0];
      quoteDataVar[title].d_TransEffectiveTo = value2;
    }
    quoteDataVar[title][name] = value;

    dispatch(getEditEmptyQuoteData(quoteDataVar));
  };

  const isEmptyOrUndefinedOrNull = (value) =>
    value === null || value === undefined || value === "";

  function isValidPhoneNumber(phoneNumber) {
    return phoneNumber && phoneNumber !== "" && phoneNumber !== "+1 ";
  }

  function isValidEmail(email) {
    if (typeof email !== "string") {
      return "";
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  function isValidNumericValue(value) {
    return value !== null && value !== "" && !isNaN(value) && value !== 0;
  }

  function isValidYear(value) {
    return (
      value !== null &&
      value !== "" &&
      !isNaN(value) &&
      value !== 0 &&
      value <= new Date().getFullYear() &&
      value.toString().length === 4
    );
  }

  const isMailingAddressPObox = (address) => {
    if (
      address?.toLowerCase()?.includes("po box") ||
      (address?.toLowerCase()?.includes("po") &&
        address?.toLowerCase()?.includes("box")) ||
      (address?.toLowerCase()?.includes("post") &&
        address?.toLowerCase()?.includes("office")) ||
      (!isNaN(address) && !isNaN(parseFloat(address)))
    ) {
      return true && !isEmptyOrUndefinedOrNull(address);
    } else {
      return false;
    }
  };

  const validateApplicantInformation = () => {
    let quoteData = props.quoteData;
    return (
      !isEmptyOrUndefinedOrNull(quoteData?.personInfo?.s_FirstName) &&
      !isEmptyOrUndefinedOrNull(
        quoteData?.personInfo?.s_LastOrganizationName
      ) &&
      !isEmptyOrUndefinedOrNull(quoteData?.personInfo?.s_FullLegalName) &&
      !isEmptyOrUndefinedOrNull(quoteData?.personInfo?.d_BirthDate) &&
      isValidEmail(quoteData?.personEmailContact?.s_CommValue) &&
      isValidPhoneNumber(quoteData?.personPhoneContact?.s_CommValue) &&
      // isValidNumericValue(Number(quoteData?.personAddr?.s_HouseNo)) &&
      !isEmptyOrUndefinedOrNull(quoteData?.personAddr?.s_StreetName) &&
      isValidNumericValue(Number(quoteData?.personAddr?.s_PostalCode)) &&
      quoteData?.personAddr?.s_PostalCode?.toString()?.length === 5 &&
      !isEmptyOrUndefinedOrNull(quoteData?.personAddr?.s_CountyName) &&
      !isEmptyOrUndefinedOrNull(quoteData?.personAddr?.s_StateName) &&
      !isEmptyOrUndefinedOrNull(quoteData?.personAddr?.s_CityName) &&
      isValidNumericValue(Number(quoteData?.personInfo?.metadata?.creditScore))
    );
  };

  const validateMailingAddress = () => {
    let quoteData = props.quoteData;
    return quoteData?.personInfo?.s_IsMailingDiff === "NO" ||
      quoteData?.personInfo?.s_IsMailingDiff === false
      ? true
      : (quoteData?.personMailingAddr?.isPostalAddress === true
          ? isMailingAddressPObox(quoteData?.personMailingAddr?.s_StreetName)
          : true &&
            !isEmptyOrUndefinedOrNull(
              quoteData?.personMailingAddr?.s_StreetName
            ) &&
            !isMailingAddressPObox(
              quoteData?.personMailingAddr?.s_StreetName
            )) &&
          isValidNumericValue(
            Number(quoteData?.personMailingAddr?.s_PostalCode)
          ) &&
          quoteData?.personMailingAddr?.s_PostalCode?.toString()?.length ===
            5 &&
          !isEmptyOrUndefinedOrNull(
            quoteData?.personMailingAddr?.s_CountyName
          ) &&
          !isEmptyOrUndefinedOrNull(
            quoteData?.personMailingAddr?.s_StateName
          ) &&
          !isEmptyOrUndefinedOrNull(quoteData?.personMailingAddr?.s_CityName);
  };

  const validatePropertyDetails = () => {
    let quoteData = props.quoteData;
    return (
      isValidNumericValue(Number(quoteData?.propertyInfo?.n_AreaSqtft)) &&
      !isEmptyOrUndefinedOrNull(quoteData?.propertyInfo?.n_NoOfStories) &&
      !isEmptyOrUndefinedOrNull(quoteData?.propertyInfo?.n_PPC) &&
      isValidNumericValue(Number(quoteData?.propertyInfo?.n_YearBuilt)) &&
      isValidYear(Number(quoteData?.propertyInfo?.n_YearBuilt)) &&
      !isEmptyOrUndefinedOrNull(quoteData?.propertyInfo?.s_UsageTypeCode) &&
      !isEmptyOrUndefinedOrNull(quoteData?.propertyInfo?.s_ConstructionType) &&
      !isEmptyOrUndefinedOrNull(quoteData?.propertyInfo?.s_StructureTypeCode) &&
      !isEmptyOrUndefinedOrNull(
        quoteData?.propertyInfo?.metadata?.claddingType
      ) &&
      !isEmptyOrUndefinedOrNull(quoteData?.propertyInfo?.s_RoofType) &&
      // !isEmptyOrUndefinedOrNull(quoteData?.propertyInfo?.s_RoofSubType) &&
      isValidNumericValue(Number(quoteData?.propertyInfo?.s_RoofAge)) &&
      !isEmptyOrUndefinedOrNull(quoteData?.propertyInfo?.s_Occupancy) &&
      // isValidNumericValue(Number(quoteData?.riskRenovationTypeData?.roofInfo?.s_RenovationYear)) &&
      !isEmptyOrUndefinedOrNull(
        quoteData?.propertyInfo?.metadata?.isBuildingVacant
      ) &&
      !isEmptyOrUndefinedOrNull(
        quoteData?.propertyInfo?.metadata?.isUnderConstruction
      )
    );
  };

  const validatePriorAddress = () => {
    let quoteData = props.quoteData;
    return quoteData?.personPriorAddr?.isNewPurchase === undefined
      ? quoteData?.personPriorAddr?.n_PersonAddressesId_PK === null
        ? true
        : validatePriorAddress()
      : quoteData?.personPriorAddr?.isNewPurchase === "No"
      ? true
      : // : isValidNumericValue(Number(quoteData?.personPriorAddr?.s_HouseNo)) &&
        true &&
        !isEmptyOrUndefinedOrNull(quoteData?.personPriorAddr?.s_StreetName) &&
        isValidNumericValue(Number(quoteData?.personPriorAddr?.s_PostalCode)) &&
        quoteData?.personPriorAddr?.s_PostalCode?.toString()?.length === 5 &&
        !isEmptyOrUndefinedOrNull(quoteData?.personPriorAddr?.s_CountyName) &&
        !isEmptyOrUndefinedOrNull(quoteData?.personPriorAddr?.s_StateName) &&
        !isEmptyOrUndefinedOrNull(quoteData?.personPriorAddr?.s_CityName);
  };

  const validateCoverages = () => {
    let quoteData = props.quoteData;
    return (
      isValidNumericValue(
        Number(quoteData?.propertyCoverageInfo?.n_coverageA)
      ) &&
      Number(quoteData?.propertyCoverageInfo?.n_coverageA) > 0 &&
      !isEmptyOrUndefinedOrNull(quoteData?.propertyCoverageInfo?.n_coverageE) &&
      !isEmptyOrUndefinedOrNull(quoteData?.propertyCoverageInfo?.n_CoverageF)
    );
  };

  const validateDeductibles = () => {
    let quoteData = props.quoteData;
    return (
      !isEmptyOrUndefinedOrNull(
        quoteData?.propertyCoverageInfo?.s_HurricaneDedutible
      ) &&
      !isEmptyOrUndefinedOrNull(quoteData?.propertyCoverageInfo?.s_AOPDedutible)
    );
  };

  const validateBillingInfo = () => {
    let quoteData = props.quoteData;
    return (
      !isEmptyOrUndefinedOrNull(quoteData?.policyInfo?.s_BillTo) &&
      !isEmptyOrUndefinedOrNull(quoteData?.policyInfo?.s_PayPlan_Code)
    );
  };

  const validateApplicationPage = () => {
    const applicantInfoValid =
      validateApplicantInformation() &&
      validateMailingAddress() && //conditional validation in function
      validatePropertyDetails() &&
      validatePriorAddress() && //conditional validation in function
      validateCoverages() &&
      validateDeductibles() &&
      validateBillingInfo();
    return applicantInfoValid;
  };

  const Submit = () => {
    const quoteData = props.quoteData;

    if (!validateApplicationPage()) {
      toast.error("Please make sure that required fields are filled.", {
        toastId: "applicationPage",
      });
      return;
    }

    const handleSubmit = async () => {
      const hasErrors = () => {
        if (!errorMessages || Object.keys(errorMessages).length === 0) {
          return false;
        }
        return Object.keys(errorMessages).some((key) =>
          errorToDisplay.includes(key)
        );
      };
      if (hasErrors()) {
        setShowErrModal(true);
        return;
      }

      try {
        setLoader2(true);
        const response = await updateStarQuote({
          requestBody: {
            ...quoteData,
            action: "SaveAndRate",
            fp_payload: CreateStarApplicationPool({
              quoteData,
              baseData,
              vendors:
                moment(quoteData?.policyInfo?.d_BoundDate).isValid() ||
                JSON.parse(quoteData?.policyInfo?.metadata)?.isFinalized
                  ? true
                  : false,
            }),
          },
          quoteId: quoteData?.policyInfo?.Policy_No_Quote,
          product: "star",
        });
        if (response?.status === true) {
          toast.success("Quote Updated Successfully", {
            autoClose: 1000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else if (response?.status === false && response?.error) {
          setErrorDetails(response);
          setIsModalOpen(true);
        } else if (response?.status === false && response?.errors) {
          setErrorDetails(response);
          setIsModalOpen(true);
        }
      } catch (error) {
        // Handle error
      } finally {
        setLoader2(false);
      }
    };

    handleSubmit();
  };

  // const Submit = async () => {

  //   const quoteData = props.quoteData;
  //   try {
  //     setLoader2(true);
  //     // Dispatch saveAndRate action and wait for its completion
  //     const { transactionPK } = await dispatch(
  //       saveAndRate({
  //         ...quoteData,
  //         fp_payload: CreateStarApplicationPool({
  //           quoteData: quoteData,
  //           baseData: baseData,
  //         }),
  //       })
  //     );
  //     toast.success("Application Rate Successfully!");
  //     if (typeof params.transID !== "undefined") {
  //       getQuoteforEdit(transactionPK);
  //     } else {
  //       setTimeout(() => {
  //         window.location.href = `/quote/editApplication/13/${transactionPK}`;
  //       }, 3000);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting application:", error);
  //     toast.error("An error occurred during submission.");
  //   } finally {
  //     setLoader2(false);
  //   }
  // };

  const submitBound = async () => {
    Swal.fire({
      title: "Please Wait",
      html: "<b>Saving Data...</b>",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    }).then(function () {
      window.location.reload();
    });

    setRateLoader(true);
    await dispatch(saveAndRate(props.quoteData)).then((res) => {
      Swal.getHtmlContainer().querySelector("b").textContent =
        "Binding Application...";
      var transferData = {};
      transferData.n_potransaction_PK =
        props.quoteData.transactionInfo.n_potransaction_PK;
      transferData.product = props.quoteData.policyInfo.n_ProductId_FK;

      let actions3 = dispatch(dataTransferToCore(transferData)).then((res2) => {
        Swal.getHtmlContainer().querySelector("b").textContent =
          "Binding Application...";
        let PotransactionPK = res2.data.transferData.PotransactionPK;
        const paramData = {
          n_potransaction_PK: PotransactionPK,
          product: props.quoteData.policyInfo.n_ProductId_FK,
          quoteTransactionPK:
            props.quoteData.transactionInfo.n_potransaction_PK,
        };
        let action = dispatch(getBindApplicationHO3(paramData)).then((res3) => {
          if (res3.data.Status == "Y") {
            Swal.update({
              icon: "success",
              html: "<b>Binding Application Successfully</b>",
              title: "",
              confirmButtonColor: "#66e3d8",
            });
            Swal.hideLoading();
          } else {
            Swal.update({
              icon: "warning",
              html: "<b>Something Went Wrong...</b>",
              title: "",
              confirmButtonColor: "#66e3d8",
            });
            Swal.hideLoading();
          }
        });
      });
    });
    setRateLoader(true);
  };

  const callGeneratePdf = async (source) => {
    setRateLoader(true);
    try {
      await dispatch(callGeneratePdfApi(source, params.transID)).then((res) => {
        if (res.status == "Y") {
          setRateLoader(false);
          window.open(res.url);
        } else {
          toast.error("Something went wrong!");
        }
      });
    } finally {
      setRateLoader(false);
    }
  };

  const renderUWErrModal = () => {
    return (
      <ChakraModal
        isCentered
        size="2xl"
        isOpen={showErrModal}
        onClose={() => setShowErrModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Underwriting Errors")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              <OrderedList py={2}>
                {Object.keys(errorMessages)
                  .filter(
                    (key) => errorToDisplay.includes(key) && errorMessages[key]
                  )
                  .map((filteredKey) => (
                    <ListItem
                      py={2}
                      fontSize={"lg"}
                      fontWeight={500}
                      key={filteredKey}
                      color={"red.500"}
                    >
                      {errorMessages[filteredKey]}
                    </ListItem>
                  ))}
              </OrderedList>
            </Text>
          </ModalBody>

          <ModalFooter>
            <ChakraButton
              leftIcon={<IoIosCloseCircleOutline />}
              variant="outline"
              onClick={() => setShowErrModal(false)}
            >
              {t("Close")}
            </ChakraButton>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    );
  };

  return (
    <div className="quote-page-section">
      {isModalOpen && (
        <ErrorModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          alertType="error"
          errorData={errorDetails}
        />
      )}
      {showErrModal && renderUWErrModal()}
      <Row>
        <Col xs="12" className="custom-tabs">
          {!loader ? (
            <LoadingOverlay>
              <Loader loading={loader2} />
              {
                <SearchQuoteTab
                  feesData={feesData}
                  baseData={baseData}
                  underWritingQuestions={underWritingQuestions}
                  underWritingAnswers={underWritingAnswers}
                  isLoading={loader}
                  handleChange={handleChange}
                  setDefaultData={setDefaultData}
                  rateSubmit={Submit}
                  isBind={isBind}
                  isInUWReview={moment(
                    props?.quoteData?.policyInfo?.d_BoundDate
                  ).isValid()}
                  handleSubmitBound={submitBound}
                  ValidationError={ValidationError}
                  callGeneratePdf={callGeneratePdf}
                  rateLoader={rateLoader}
                  errorMessages={errorMessages}
                  setErrorMessages={setErrorMessages}
                />
              }
            </LoadingOverlay>
          ) : (
            <LoadingOverlay>
              <Loader loading={loader} />
              {}
            </LoadingOverlay>
          )}
        </Col>
      </Row>
      {Show && (
        <Modal size="md" show={Show} className="custom-dashboard-modal">
          <Modal.Header className="custom-dashboard-modal-header">
            {"Errors"}
          </Modal.Header>
          <Modal.Body>
            {errors.map((data, i) => (
              <span>
                <label style={{ color: "red" }}>
                  {i + 1} . {data.s_ErrorDescription}
                </label>
                <br></br>
              </span>
            ))}
          </Modal.Body>
          <Modal.Footer className="custom-dashboard-modal-footer">
            <Button
              id="common-btn"
              variant="primary"
              className="btn btn-primary"
              onClick={() => setShow(false)}
            >
              close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getQuoteDataHO3: state.common.getQuoteDataHO3
      ? state.common.getQuoteDataHO3
      : {},
  };
}

export default connect(mapStateToProps)(EditApplication);

export const errorToDisplay = [
  //pre declared
  "anyOtherBusiness",
  "inPoorCondition",
  "inForeClosure",
  "coverageDeclined",
  "hasTrampolineAndPoolSecurity",
  "activeFloodPolicy",
  "anyRecreationalVehicle",
  "anythingSignificant",
  "hasSolarPanels",
  "additionalRisks",
  "hasAnimals",
];
