import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { FaUpload, FaEye } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import AddAttachment from "./AddAttachment";
import { generateTempUrl } from "../../../services/floodService";
// import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Box, Button, IconButton, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";

const ListRequiredDoc = (props) => {
    const { loading, setLoading, cancelJSON } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [docList, setDocList] = useState([]);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [docData, setDocData] = useState({});

    useEffect(() => {
        setList();
    }, [cancelJSON])

    const setList = async () => {
        setLoading(true);
        var tempDocList = [];
        if (cancelJSON.document.length > 0) {
            cancelJSON.document.map((v, i) => {
                tempDocList.push({
                    "id": i,
                    ...v
                });
            });

            setDocList(tempDocList);
        }
        setLoading(false);
    }

    // handle on click
    const handleOnClick = (row) => {
        setDocData(row);
        setShowAttachmentModal(true);
    }

    // view documet
    const viewDocument = async (selectedDocData) => {
        const params = {
            'path': selectedDocData.path
        };

        setLoading(true);
        await dispatch(generateTempUrl(params)).then((res) => {
            if (res.status === 'Y') {
                window.open(res.data);
            } else {
                toast.error(res.data);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            toast.error(t("Something Went Wrong"));
        });
    }

    

    const handleOnAttachmentClose = (value) => {
        setShowAttachmentModal(value);
        setDocData({});
    }

    return (
        <>
            {
                !showAttachmentModal && (
                    <>
                    <Box w={"100%"} mt={4}>
                        <TableContainer border={"1px solid "} borderColor={"gray.200"} borderRadius={"2xl"}>
                            <Table variant="unstyled" border={"1px solid "} borderColor={"gray.200"} borderRadius={"2xl"} overflow={"hidden"}>
                                <Thead bg={"var(--chakra-colors-agGridHeaderBg)"}>
                                    <Tr>
                                        <Th border={"1px solid var(--chakra-colors-agGridHeaderBg)"}>{t("Name")}</Th>
                                        <Th border={"1px solid var(--chakra-colors-agGridHeaderBg)"}>{t("Is Attached")}</Th>
                                        <Th border={"1px solid var(--chakra-colors-agGridHeaderBg)"}></Th>
                                    </Tr>
                                </Thead>
                                <Tbody >
                                    {docList.map((row, index) => (
                                        <Tr key={index} >
                                            <Td border={"1px solid var(--chakra-colors-agGridHeaderBg)"}>{row.docTypeForDisplay}</Td>
                                            <Td border={"1px solid var(--chakra-colors-agGridHeaderBg)"}>
                                                {row.is_attached === "N" ? (
                                                    <Text>{t("No")}</Text>
                                                ) : (
                                                    <Text>{t("Yes")}</Text>
                                                )}
                                            </Td>
                                            <Td border={"1px solid var(--chakra-colors-agGridHeaderBg)"}>
                                                {row.is_attached === "N" ? (
                                                    <IconButton
                                                        id="search-quote-btn"
                                                        onClick={() => handleOnClick(row)}
                                                        icon={<FaUpload size={20} />}
                                                    />
                                                ) : (
                                                    <IconButton
                                                        id="search-quote-btn"
                                                        onClick={() => {
                                                            if (![null, "", undefined].includes(row.path)) {
                                                                viewDocument(row);
                                                            }
                                                        }}
                                                        icon={<FaEye size={20}/>}
                                                    />
                                                    
                                                )}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        </Box>
                        {/* <Box w={"100%"} className="ag-theme-alpine" mt={4}>
                            <AdvancedGridTable
                                gridRef={gridRef}
                                rowData={docList}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                // pagination={true}
                                // paginationPageSize={5}
                                domLayout="autoHeight"
                            />
                        </Box> */}
                    </>
                )
            }
            {
                showAttachmentModal && (
                    <AddAttachment
                        {...props}
                        loading={loading}
                        setLoading={setLoading}
                        handleOnAttachmentClose={handleOnAttachmentClose}
                        docData={docData}
                        setDocData={setDocData}
                    />
                )
            }
        </>
    )
}

export default ListRequiredDoc;
