import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, Form, Row } from "react-bootstrap";
import { postEndorsePolicy } from "./api/postHiscoxPolicyEndorsement";
import getResidentialEndorseBody from "./requestBodies/endoResidentialReqBody";
import getTenantedReqBody from "./requestBodies/endoTenatedReqBody";
import getOwnedReqBody from "./requestBodies/endoOwnedReqBody";
import { ResidentialDataDD } from "./data/ResidentialData";
import { CommercialDataDD } from "./data/CommercialData";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  getCvg,
  saveEndorsmentData,
  saveNewTransaction,
  verifyPolicy,
} from "../../../services/coreSystemService";
import { useDispatch, useSelector } from "react-redux";
import EndorsementModal from "./components/EndorsementModal";
import PriorLossesTable from "./components/PriorLosses";
import EndorsementForm from "./components/RenewComponents/EndorsementForm";
import LimitsTable from "./components/LimitsTable";
import getEndorsementApplicationBody from "./requestBodies/endorsementApplicationBody";
import { FaCheckCircle } from "react-icons/fa";
import { EndorseConfirmation } from "./components/EndorseConfirmation";
let minDate = "2000-01-01"; //minimum date for calender ui.
export default function Endorsement({
  quoteDetailData,
  quoteId,
  commercialProductType,
  productType,
  productCode,
  productId,
}) {
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleCloseMain = () => setShowModal(false);
  const [myEndorsementData, setMyEndorsementData] = useState({
    effectiveDate: minDate,
  });
  const [dataForEndorsement, setDataForEndorsement] = useState(null);
  const [premiumResponseData, setPremiumResponseData] = useState({});
  const [requestBody, setRequestBody] = useState({});
  const [coverageData, setCoverageData] = useState({});
  const [readyToSave, setReadyToSave] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [noPremiumChangeEndorse, setNoPremiumChangeEndorse] = useState(false);

  useEffect(() => {
    if (myEndorsementData?.quoteDetailData?.data?.quoteId) {
      const { quoteDetailData, effectiveDate } = myEndorsementData;
      const getRequestBody = (type) => {
        switch (type) {
          case "Tenanted":
            return getTenantedReqBody({ quoteDetailData, effectiveDate });
          case "Owned":
            return getOwnedReqBody({ quoteDetailData, effectiveDate });
          default:
            return getResidentialEndorseBody({
              quoteDetailData,
              effectiveDate,
            });
        }
      };
      setRequestBody(getRequestBody(commercialProductType || ""));
    }
  }, [myEndorsementData]);

  function getSevenDaysLaterDate() {
    const today = new Date();
    const sevenDaysLater = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    return sevenDaysLater.toISOString().split("T")[0];
  }

  useEffect(() => {
    if (quoteDetailData) {
      setMyEndorsementData({
        ...myEndorsementData,
        quoteDetailData,
        effectiveDate: getSevenDaysLaterDate(),
      });
    }
  }, [quoteDetailData]);

  async function submitEndorsement() {
    setLoading(true);
    const policyId = quoteId || 0;
    try {
      const res = await postEndorsePolicy({
        requestBody: requestBody,
        productId: policyId,
      });
      if (res?.status === false && Array.isArray(res?.errors)) {
        res.errors.forEach((error) => toast.error(error, { autoClose: 5000 }));
      } else if (res?.status === true) {
        toast.success("Endorsement Data Fetched Successfully!");
        setPremiumResponseData(res);
        setShow(true);
      }
    } catch (error) {
      console.error("Endorsement error:", error);
    }
    setLoading(false);
  }

  const dispatch = useDispatch();
  const [dynamicId, setDynamicId] = useState({
    PolicyPK: 0,
    n_TermMaster_PK: 0,
    TransPK: 0,
  });
  const { policyNumber } = useParams();

  const reduxStore = useSelector((state) => state);
  //order
  const verify = async () => {
    await dispatch(verifyPolicy(policyNumber)).then((res) => {
      if (res.status) {
        getCoverageInfo({
          PolicyPK: res.policyIdPK,
          TransPK: res.poTransPK,
        });
        setDynamicId({
          ...dynamicId,
          PolicyPK: res.policyIdPK,
          TransPK: res.poTransPK,
        });
      }
    }).catch(() => {
      setLoading(false);
    });
  };

  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1; // Months are zero-based
  let day = today.getDate();

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  const formattedDate = `${year}-${month}-${day}`;

  const handleTransaction = async () => {
    try {
      // Save Transaction
      setLoading(true);
      const param = {
        s_PRTranTypeCode: "ENDORSE",
        s_PRTranSubTypeCode: "RATEINFO",
        s_PRTranReasonCode: "NONE",
        s_RequestedBy: "INSURED",
        n_StandardTerm: "12",
        s_StandardTermUnit: "MONTH",
        s_TermTypeCode: "NONCOMMON",
        d_TransEffectiveFrom: requestBody?.effectiveDate,
        d_TransEffectiveTo:
          reduxStore?.coreSystem?.applicationData?.d_TermEndDate,
        d_AccountingDate: formattedDate,
        s_Note: "",
        Policy_No: policyNumber,
        PolicyPK: reduxStore?.coreSystem?.n_POPolicyMasterFK || 0,
        n_TermMaster_PK: reduxStore?.coreSystem?.n_POTermMasterFK || 0,
        TransPK: reduxStore?.coreSystem?.n_POTransactionFK || 0,
        isCorrection: false,
        reasonCode: [],
        isPoAssignmant: false,
        source: "AGENT_PORTAL",
        s_PolicyWaitingPeriod: "STANDARD30",
        effectiveDate: requestBody?.effectiveDate,
        currentWaitingPeriod: "STANDARD30",
        noPremiumChangeEndorse: noPremiumChangeEndorse,
      };
      const res = await dispatch(saveNewTransaction(param));
      if (res?.Status === 1) {
        // 1  == error
        toast.error(res?.msg, {
          autoClose: 5000,
          toastId: "transactionSaved1",
        });
      } else if (res?.Status === 0) {
        //0 == success
        toast.success(res?.msg, {
          autoClose: 5000,
          toastId: "transactionSaved",
        });
        verify();
      } else {
        toast.error("Failed to save transaction");
      }
    } catch (error) {
      console.log("saveTransactionErr", error);
      toast.error("Something Went Wrong!");
    } finally {
      setLoading(false);
    }
  };

  function findCoverageInfo(data, coverageCode, infoType) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].s_CoverageCode === coverageCode) {
        switch (infoType) {
          case "POCoverageTran_PK":
            return data[i].n_POCoverageTran_PK;
          case "InsuredValue":
            return data[i].n_InsuredValue;
          case "PrDiscountCode":
            return data[i].s_PrDiscountCode;
          default:
            return null;
        }
      }
    }
    return null;
  }

  const hiscoxBuildingType = [
    "",
    "BrickVeneer",
    "EIFS",
    "Frame",
    "Log",
    "Masonry",
    "Stucco",
    "Asbestos",
  ];

  const getCoverageInfo = async ({ PolicyPK, TransPK }) => {
    try {
      const res = await dispatch(getCvg(PolicyPK, TransPK));
      if (res?.data) {
        const mergedCoverageData = [
          ...(res?.data?.basicCvgData || {}),
          ...(res?.data?.optionalCvgData || {}),
        ];
        setCoverageData(mergedCoverageData);
        setReadyToSave(true);
      }
    } catch (error) {
      toast.error("Something Went Wrong while fetching coverage data!");
    }
  };

  useEffect(() => {
    let PolicyPK = dynamicId?.PolicyPK;
    let TransPK = dynamicId?.TransPK;
    if (readyToSave === true) {
      saveEndorsementToDB({
        PolicyPK,
        TransPK,
      });
    }
  }, [readyToSave]);

  const buildCoverageObject = (
    coverageCode,
    limit,
    premium,
    annualizedPremium,
    TransPK
  ) => ({
    poTransPK: TransPK,
    n_POCoverageTran_PK: findCoverageInfo(
      coverageData,
      coverageCode,
      "POCoverageTran_PK"
    ),
    value: limit,
    premium: noPremiumChangeEndorse ? 0 : Number(premium),
    annualizedPremium: Number(annualizedPremium),
  });

  const buildDeductibleObject = (
    coverageCode,
    deductible,
    PolicyPK,
    TransPK
  ) => {
    const {
      coreSystem: {
        applicationData: { n_ProductId_FK },
      },
    } = reduxStore;

    const selectedPremium = quoteDetailData?.data?.premium?.selectedPremium;
    const selectedCoverage = quoteDetailData?.data?.premium?.selectedCoverage;
    const getDeductibleValue = () => {
      if (noPremiumChangeEndorse) {
        if (selectedPremium?.type === "Excess") {
          return "";
        }
        if (selectedPremium?.type === "Primary") {
          return `FLDDED${selectedCoverage?.deductible}`;
        }
      }
      return deductible === 0 ? "" : `FLDDED${deductible}`;
    };

    return {
      poTransPK: TransPK,
      policyNoPK: PolicyPK,
      product: n_ProductId_FK,
      whichDeductible: coverageCode,
      value: getDeductibleValue(),
    };
  };

  const saveEndorsementToDB = async ({ PolicyPK, TransPK }) => {
    const endorsementApplicationBody = getEndorsementApplicationBody({
      requestBody: requestBody,
    });

    try {
      const {
        coreSystem: {
          applicationData: { n_NetPremium },
        },
      } = reduxStore;

      const { selectedCardData, limits, options, totalPremium } =
        dataForEndorsement || {};
      const quoteValues = quoteDetailData?.data?.premium;

      const {
        buildingAnnualizedPremium,
        buildingAdditionalPremium,
        improvementsAndBettermentsAnnualizedPremium,
        improvementsAndBettermentsAdditionalPremium,
        includeContentsAnnualizedPremium,
        includeContentsAdditionalPremium,
        excludeContentsAdditionalPremium,
        excludeContentsAnnualizedPremium,
        includeLossOfUseAnnualizedPremium,
        includeLossOfUseAdditionalPremium,
        excludeLossOfUseAdditionalPremium,
        excludeLossOfUseAnnualizedPremium,
        otherStructuresAnnualizedPremium,
        otherStructuresAdditionalPremium,
        excludeBusinessIncomeAdditionalPremium,
        excludeBusinessIncomeAnnualizedPremium,
        includeBusinessIncomeAdditionalPremium,
        includeBusinessIncomeAnnualizedPremium,
        deductible,
      } = selectedCardData || {};

      const buildingCoverage = buildCoverageObject(
        "FLDBLDCVGAMT",
        !noPremiumChangeEndorse
          ? limits?.buildingLimit ?? limits?.improvementsAndBettermentsLimit
          : quoteValues?.selectedPremium?.buildingLimit,
        buildingAdditionalPremium ??
          improvementsAndBettermentsAdditionalPremium,
        !noPremiumChangeEndorse
          ? buildingAnnualizedPremium ??
              improvementsAndBettermentsAnnualizedPremium
          : quoteValues?.selectedPremium?.buildingPremium,
        TransPK
      );
      const contentCoverage = buildCoverageObject(
        "FLDCONTCVGAMT",
        !noPremiumChangeEndorse
          ? options?.includeContents
            ? limits?.contentsLimit
            : 0
          : quoteValues?.selectedPremium?.contentsLimit,
        options?.includeContents
          ? includeContentsAdditionalPremium
          : excludeContentsAdditionalPremium,
        !noPremiumChangeEndorse
          ? options?.includeContents
            ? includeContentsAnnualizedPremium
            : excludeContentsAnnualizedPremium
          : quoteValues?.selectedPremium?.contentsPremium,
        TransPK
      );

      const buildingDeductible = buildDeductibleObject(
        "FLDBLDCVGAMT",
        deductible,
        PolicyPK,
        TransPK
      );

      const contentDeductible = buildDeductibleObject(
        "FLDCONTCVGAMT",
        deductible,
        PolicyPK,
        TransPK
      );
      const includeLossOfUseCoverage =
        productType === "Residential"
          ? buildCoverageObject(
              "HOMCVGD",
              !noPremiumChangeEndorse
                ? options?.includeLossOfUse
                  ? limits?.lossOfUseLimit
                  : 0
                : quoteValues?.selectedPremium?.coverageB,
              options?.includeLossOfUse
                ? includeLossOfUseAdditionalPremium
                : excludeLossOfUseAdditionalPremium,
              !noPremiumChangeEndorse
                ? options?.includeLossOfUse
                  ? includeLossOfUseAnnualizedPremium
                  : excludeLossOfUseAnnualizedPremium
                : quoteValues?.selectedPremium?.premiumB,
              TransPK
            )
          : null;

      const otherStructureCoverage =
        productType === "Residential"
          ? buildCoverageObject(
              "HOMCVGB",
              !noPremiumChangeEndorse
                ? limits?.otherStructureLimit
                : quoteValues?.selectedPremium?.coverageA,
              otherStructuresAdditionalPremium,
              !noPremiumChangeEndorse
                ? otherStructuresAnnualizedPremium
                : quoteValues?.selectedPremium?.premiumA,
              TransPK
            )
          : null;

      const buissnessIncomeCoverage =
        productType === "Commercial"
          ? buildCoverageObject(
              "BUSIINCOMEEXP",
              noPremiumChangeEndorse
                ? quoteValues?.selectedPremium?.coverageA
                : options?.businessIncomePremium
                ? limits?.businessIncomeAndExtraExpenseAnnualValue
                : 0,
              options?.businessIncomePremium
                ? includeBusinessIncomeAdditionalPremium
                : excludeBusinessIncomeAdditionalPremium,
                noPremiumChangeEndorse
                ? quoteValues?.selectedCoverage?.businessIncomePremium
                : options?.businessIncomePremium
                ? includeBusinessIncomeAnnualizedPremium
                : excludeBusinessIncomeAnnualizedPremium,
              TransPK
            )
          : null;

      const requestBody = {
        poTransPK: TransPK,
        transType: "ENDORSE",
        isPrimaryRes: "NO",
        nPolicyPK: PolicyPK,
        metadata: {
          current: {
            Coverage: {
              buildingCoverage: findCoverageInfo(
                coverageData,
                "FLDBLDCVGAMT",
                "InsuredValue"
              ),
              contentCoverage: findCoverageInfo(
                coverageData,
                "FLDCONTCVGAMT",
                "InsuredValue"
              ),
              buildingDeductible: findCoverageInfo(
                coverageData,
                "FLDBLDCVGAMT",
                "PrDiscountCode"
              ),

              contentDeductible: findCoverageInfo(
                coverageData,
                "FLDCONTCVGAMT",
                "PrDiscountCode"
              ),
            },
            InsuredInformation: [],
            Lender: [],
            AdditionalInformation: [],
            EndorsmentEffectiveDate: [],
            RequestUnderwriterReview: [],
            Email: [],
            Phone: [],
          },
          new: {
            Coverage:
              productType === "Residential"
                ? {
                    buildingCoverage,
                    buildingDeductible,
                    contentCoverage,
                    contentDeductible,
                    includeLossOfUseCoverage,
                    otherStructureCoverage,
                  }
                : {
                    //this is for Commercial (Hiscox)
                    buildingCoverage,
                    buildingDeductible,
                    contentCoverage,
                    contentDeductible,
                    buissnessIncomeCoverage,
                  },
            InsuredInformation: [],
            Lender: [],
            AdditionalInformation: {
              ...endorsementApplicationBody,
              policyType: quoteDetailData?.data?.premium?.selectedPremium?.type,
              endorsementContentsOriginalLimit:
                limits?.contentsLimit ||
                quoteValues?.selectedPremium?.contentsLimitOrignal,
            },
            EndorsmentEffectiveDate: [],
            RequestUnderwriterReview: [],
            Email: [],
            Phone: [],
          },
          preEndorsePK: 0,
          isPrimaryRes: "NO",
          isPaymentDone: false,
          isDocRequired: false,
          newPremium: Number(
            ((n_NetPremium || 0) + (totalPremium || 0) || 0).toFixed(2)
          ),
          premiumChange: noPremiumChangeEndorse ? 0 : totalPremium || 0,
          currentPremium: Number(n_NetPremium) || 0,
        },
        policyNumber: quoteId,
        Action: "Update",
      };
      setLoading(true);
      const res = await dispatch(saveEndorsmentData(requestBody));

      if (res?.data?.status === "N" && res?.data?.msg) {
        toast.error(res?.data?.msg, {
          autoClose: 5000,
          toastId: "error",
        });
      } else if (res?.data?.status === "Y" && res?.data?.msg) {
        toast.success(res?.data?.msg, {
          autoClose: 5000,
          toastId: "success",
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 1600);
      } else if (res?.success === true) {
        toast.success("Endorsement Created Successfully!", {
          autoClose: 1500,
          toastId: "success1",
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 1600);
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
      console.error(error);
    } finally {
      setReadyToSave(false);
      setLoading(false);
      setOpenConfirmationModal(false);
    }
  };

  return (
    <div>
      <Button
        id="common-btn"
        variant="primary"
        size="sm"
        style={{ padding: "4px 6px" }}
        onClick={() => setOpenConfirmationModal(true)}
      >
        <FaCheckCircle size={18} /> &nbsp; Create Endorsement
      </Button>
      <EndorseConfirmation
        isOpen={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        setShowModal={setShowModal}
        handleTransaction={handleTransaction}
        isLoading={loading || readyToSave}
        setNoPremiumChangeEndorse={setNoPremiumChangeEndorse}
        noPremiumChangeEndorse={noPremiumChangeEndorse}
      />
      <EndorsementModal
        premiumResponseData={premiumResponseData}
        productType={productType}
        show={show}
        setShow={setShow}
        handleTransaction={handleTransaction}
        setDataForEndorsement={setDataForEndorsement}
        commercialProductType={commercialProductType}
        handleCloseMain={handleCloseMain}
        productCode={productCode}
        productId={productId}
        saveLoading={loading}
      />
      <div>
        <Modal
          className={show ? "d-none" : ""}
          size="xl"
          centered
          show={showModal}
          onHide={handleCloseMain}
        >
          <Modal.Header id="common-modal-header" className="px-4" closeButton>
            <Modal.Title className="d-flex align-items-center gap-3 text-white">
              <span>Policy Endorsement</span>
              <span className="pt-1">
                {loading && (
                  <Spinner animation="border" variant="light" size="lg" />
                )}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EndorsementForm
              CommercialDataDD={CommercialDataDD}
              ResidentialDataDD={ResidentialDataDD}
              hiscoxBuildingType={hiscoxBuildingType}
              requestBody={requestBody}
              setRequestBody={setRequestBody}
              quoteId={quoteId}
              key={"hiscoxEndorsementForm"}
            />
            <div
              style={{
                marginTop: "2rem",
                padding: "0.5rem",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
                gap: "2rem",
              }}
            >
              <LimitsTable
                commercialProductType={commercialProductType}
                requestBody={requestBody}
                setRequestBody={setRequestBody}
              />

              <PriorLossesTable
                requestBody={requestBody}
                setRequestBody={setRequestBody}
                key={"0"}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="gap-1">
            <Button
              variant="primary"
              onClick={handleCloseMain}
              id="common-btn"
              style={{ padding: "8px  10px" }}
            >
              Close
            </Button>
            <Button
              id="common-btn"
              style={{ padding: "8px  10px" }}
              variant="primary"
              onClick={submitEndorsement}
              disabled={loading}
            >
              {loading ? (
                <>
                  <div className="pr-2">
                    <Spinner animation="border" size="sm" /> Creating...
                  </div>
                </>
              ) : (
                "Create Endorsement"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
