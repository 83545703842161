// temporary only
const locationDistanceToCoastFields = [
  "0-4.9",
  "5-9.9",
  "10-14.9",
  "15-19.9",
  "20-24.9",
  "25-29.9",
  "30-34.9",
  "35-39.9",
  "40-44.9",
  "45-49.9",
  "50+",
];

const coveredFerilsFields = [
  "Named Perils",
  "Named Perils + Burglary/Robbery",
  "Special Coverage",
];

const buissnessClassFields = [
  "HOA",
  "Hotels 1-4 Stories - No/Limited Cooking",
  "Hotels (Luxury) 1-4 Stories - No/Limited Cooking",
  "Hotels 1-4 Stories + Cooking",
  "Hotels (Luxury) 1-4 Stories + Cooking",
  "Hotels 5+ Stories - No/Limited Cooking",
  "Hotels (Luxury) 5+ Stories - No/Limited Cooking",
  "Hotels 5+ Stories + Cooking",
  "Hotels (Luxury) 5+ Stories + Cooking",
  "Convenience Store + Restaurant NO GAS",
  "Convenience Store NO Restaurant NO GAS",
  "Convenience Store + Restaurant + GAS",
  "Convenience Store NO Restaurant + GAS",
  "Gas Station NO Convenience Store No Restaurant",
];


const waterSublimitFields = [
  "Excluded",
  "$50,000",
  "$100,000",
  "$200,000",
  "Full Coverage",
];

const threeYearLostCountFields = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "10+"];

const lossesLastFiveYearsFields = [0, 1, "2+"];

const petsAndAnimalsFields = [
  "None",
  "Cats only",
  "Small dogs only",
  "Medium dogs only",
  "Small dogs and cats",
  "Medium dogs and cats",
  "Small dogs, cats and other benign animals/pets",
  "Medium dogs, cats and other benign animals/pets",
  "All other pets/animals",
];

const bildingCoverageSection = ["100%", "90%", "80%"];

const buildingInterpetionsFields = [
  "0 Hours",
  "24 Hours",
  "48 Hours",
  "72 Hours",
];

const restorationFactorFields = [
  "6 Months",
  "12 Months",
  "18 Months",
  "24 Months",
];

const spoilageLimit = [
  {
    value: 0,
    label: "$0",
  },
  { value: 5000, label: "$5,000" },
  { value: 10000, label: "$10,000" },
  { value: 15000, label: "$15,000" },
  { value: 20000, label: "$20,000" },
  { value: 25000, label: "$25,000" },
  { value: 30000, label: "$30,000" },
  { value: 35000, label: "$35,000" },
  { value: 40000, label: "$40,000" },
  { value: 45000, label: "$45,000" },
  { value: 50000, label: "$50,000" },
];

const spoilageCoverageType = [
  "Breakdown or Contamination w/ Refrigeration Maintenance Agreement",
  "Breakdown or Contamination NO Refrigeration Maintenance Agreement",
  "Power Outage",
  "Both Breakdown or Contamination AND Power Outage w/ Refrigeration Maintenance Agreement",
  "Both Breakdown or Contamination AND Power Outage NO Refrigeration Maintenance Agreement",
];

const causesOfLoss = [
  {
    s_AppCodeName: "Bodily Injury",
    s_AppCodeNameForDisplay: "Bodily Injury",
  },
  {
    s_AppCodeName: "Collapse",
    s_AppCodeNameForDisplay: "Collapse",
  },
  {
    s_AppCodeName: "Earthquake",
    s_AppCodeNameForDisplay: "Earthquake",
  },
  {
    s_AppCodeName: "Equipment Breakdown",
    s_AppCodeNameForDisplay: "Equipment Breakdown",
  },
  {
    s_AppCodeName: "Explosion",
    s_AppCodeNameForDisplay: "Explosion",
  },
  {
    s_AppCodeName: "Fire",
    s_AppCodeNameForDisplay: "Fire",
  },
  {
    s_AppCodeName: "Flood",
    s_AppCodeNameForDisplay: "Flood",
  },
  {
    s_AppCodeName: "Hail",
    s_AppCodeNameForDisplay: "Hail",
  },
  {
    s_AppCodeName: "Liability",
    s_AppCodeNameForDisplay: "Liability",
  },
  {
    s_AppCodeName: "Lightning",
    s_AppCodeNameForDisplay: "Lightning",
  },
  {
    s_AppCodeName: "Mold",
    s_AppCodeNameForDisplay: "Mold",
  },
  {
    s_AppCodeName: "Property Damage",
    s_AppCodeNameForDisplay: "Property Damage",
  },
  {
    s_AppCodeName: "Theft",
    s_AppCodeNameForDisplay: "Theft",
  },
  {
    s_AppCodeName: "Vandalism / Malicious Mischief",
    s_AppCodeNameForDisplay: "Vandalism / Malicious Mischief",
  },
  {
    s_AppCodeName: "Volcano",
    s_AppCodeNameForDisplay: "Volcano",
  },
  {
    s_AppCodeName: "Water",
    s_AppCodeNameForDisplay: "Water",
  },
  {
    s_AppCodeName: "WildFire",
    s_AppCodeNameForDisplay: "WildFire",
  },
  {
    s_AppCodeName: "Wind",
    s_AppCodeNameForDisplay: "Wind",
  },
];

const lossCounts = [
  { value: "0", label: "0 Claims" },
  { value: "1", label: "1 Claim" },
  { value: "2", label: "2 Claims" },
  { value: "3", label: "3 Claims" },
  { value: "4+", label: "4 or More Claims" },
  { value: "na", label: "N/A (Not Applicable)" },
];

export {
  locationDistanceToCoastFields,
  buissnessClassFields,
  waterSublimitFields,
  threeYearLostCountFields,
  lossesLastFiveYearsFields,
  petsAndAnimalsFields,
  bildingCoverageSection,
  buildingInterpetionsFields,
  restorationFactorFields,
  causesOfLoss,
  lossCounts,
  coveredFerilsFields,
  spoilageLimit,
  spoilageCoverageType,
};
