import { EncryptOrDecrypt } from "../../../services/commonService";

const selectExcessRequestParams = (state) => {
  const commonData = state.common;
  // const naicNumber = flood?.quoteResponseDetails?.data?.policyQuoteDetails?.naicNumber;
  
  if(Object.keys(commonData.emptyQuoteData).length > 0){
    //commonData.emptyQuoteData 
    const {effectiveDate, personInfo, policyInfo, personEmailContact, propertyInfo, personAddr, personPhoneContact, selectedAgency, selectedAgent, viewType,propertyCoverageInfo} = commonData.emptyQuoteData;
    const insuredFirst = personInfo?.s_FirstName;
    const insuredLast = personInfo?.s_LastOrganizationName;
    const insuredFullName = personInfo?.s_FullLegalName;
    const insuredType = personInfo?.s_EntityType === 'INDIVIDUAL' ? 'Individual': 'Business';
    const userEmail = personEmailContact?.s_CommValue || null;
    const buildingDescriptions = {
      MAINHOUSEBLDG20: 'Main',
      DETACHEDGUESTHOUSE20: 'Guest',
      OTHER20: 'Other',
    };
    const hiscoxConstructionTypeKeys = {
      FRAME20: 'Frame',
      MASONRY20: 'Masonry',
      OTHER20: 'Other',
    };
    const hiscoxFoundationTypeKeys = {
      ELEWATEDONCRAWLSPACE20: 'Crawlspace',
      SLABONGRADE20: 'SlabOnGrade',
      BASEMENT20: 'Basement',
      ELEVATEDWITHOUTENCLOSURE20: 'ElevatedWithOutEnclosure',
      ELEVATEDWITHENCLOSURE20: 'PiersPostsPiles',
      ELEVATEDWITHENCLOSURENOTPPP20: 'ElevatedWithEnclosure',
    };
    const hiscoxUsageKeys = {
      PRIMARY: 'Primary',
      SEASONAL: 'Seasonal',
      TENANT: 'Tenanted',
      RENT: 'Rent',
      UNDERCONST: 'Coc',
    }
    const hiscoxOccupancyTypeKeys = {
      SINGLEFAMILY20: 'SingleFamily',
      TWOFOURFAMILY20: '2-4Family',
      OTHERRESIDENTIAL20: 'OtherResidential',
      NONRESIDENTIALMOBILEHOME20: 'MobileHome',
      RESIDENTIALMOBILEHOME20: 'MobileHome',
      RESIDENTIALCONDOBUILD20: 'Condominium',
      RESIDENTIALUNIT20: 'Other',
      NONRESIDENTIALUNIT20: 'Other',
      NONRESIDENTIALBUILD20: 'Other',
    }
  
    const propertyTypes = {
      HOME: 'Home',
      MOBILEHOME: 'MobileHome',
      UNIT: 'Unit',
      RESIDENTIALBLDG: 'Residential',
      NONRESIDENTIALBLDG: 'NonResidential',
    }
    const buildingPurpose = ['NONRESIDENTIALMOBILEHOME20', 'NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20']
      .indexOf(propertyInfo?.s_Occupancy) > -1 ? 'NonResidential' : 'Residential';
    let tenantOccupied = null;
    if (propertyInfo?.s_Usage === 'TENANT') {
      tenantOccupied = 'Yes';
    }
    if (propertyInfo?.s_Usage === 'RENT') {
      tenantOccupied = 'No';
    }
    const condoUnit = propertyInfo?.s_CondoOwnership === 'YES' && propertyInfo?.s_Occupancy === 'RESIDENTIALUNIT20' ? 'Yes' : 'No'
    let elevation = 1.1000000352;
  
    const anchoringMethods = {
      NOTANCHORED: 'NotAnchored',
      MANUFACTURESPECIFICATION: 'ManufactureSpecification',
      MANAGMENTSPECIFICATION: 'ManagementSpecification',
      TIESTOGROUNDANCHORED: 'GroundAnchored',
    };
    let primaryResidenceForBulding = "No";
    if(propertyInfo?.s_Usage == "PRIMARY" || propertyInfo?.s_Usage == "TENANT"){
      primaryResidenceForBulding = "Yes";
    }
    let yearBuilt = '';
    if (propertyInfo) {
      yearBuilt = propertyInfo?.d_DateOfConstruction ?
        new Date(propertyInfo?.d_DateOfConstruction).getFullYear() :
        parseInt(propertyInfo?.s_YearBuilt);
    }
    const building = {
      basementType: propertyInfo?.s_Foundation === 'BASEMENT20' ? 'Finished' : 'Unfinished',
      condoUnit: condoUnit,
      constructionType: hiscoxConstructionTypeKeys[propertyInfo?.s_BldgConstructionType],
      floodZone: propertyInfo?.s_FloodZone ?? 'X',
      foundationType: hiscoxFoundationTypeKeys[propertyInfo?.s_Foundation],
      hasBasement: propertyInfo?.s_Foundation === 'BASEMENT20' ? 'Yes' : 'No',
      isElevated: (
        propertyInfo?.s_Foundation === 'SLABONGRADE20' ||
        propertyInfo?.s_Foundation === 'BASEMENT20'
      ) ? 'No' : 'Yes',
      isOverWater: propertyInfo?.s_BuildingOverWater === 'NOTOVERWATER' ? 'No' : 'Yes',
      noOfStories: propertyInfo?.n_NoOfFloors,
      numberOfFamilies: propertyInfo?.s_NoOfUnits,
      numberOfUnits: propertyInfo?.s_NoOfUnits,
      occupancyType: hiscoxOccupancyTypeKeys[propertyInfo?.s_Occupancy],
      purpose: buildingPurpose,
      squareFootage: propertyInfo?.s_TotalSqFootage,
      tenantOccupied: tenantOccupied,
      usage: hiscoxUsageKeys[propertyInfo?.s_Usage],
      yearBuilt:yearBuilt,
      isUnderConstruction: propertyInfo?.s_BuildingCourseConstruction === 'YES' ? 'Yes' : 'No',
      primaryResidence: primaryResidenceForBulding,
      hasEC: !!propertyInfo?.s_isValidElevationCert ? 'Yes' : 'No',
      elevation: elevation,
      replacementCost: propertyInfo?.n_ReplacementCost,
      propertyType: propertyTypes[viewType],
      enclosureSize: propertyInfo?.s_AreaInSqFt,
      description: buildingDescriptions[propertyInfo?.s_BuildingUse] ?? null,
      anchoringMethod: anchoringMethods[propertyInfo?.s_homeAnchored] ?? 'NotAnchored',
      buildingUse : propertyInfo?.s_BuildingUse ?? null,
    }
  
    const locationAddress = {
      city: personAddr?.s_CityName,
      county: personAddr?.s_CountyName,
      line1: personAddr?.s_AddressLine1,
      line2: "",
      state: personAddr?.s_StateName,
      street: "",
      zip: personAddr?.s_PostalCode,
      googlePlaceId: null,
      latitude: personAddr?.s_Latitude,
      longitude: personAddr?.s_Longitude
    }
    const agentFirst = selectedAgent?.[0]?.label.split(/(\s+)/)[0];
    const agentLast = selectedAgent?.[0]?.label.split(/(\s+)/)[2];
    const agentLicenseNumber = selectedAgent?.[0]?.NPN;
    const agencyName = selectedAgency?.[0]?.label;
    let agentNo = '';
    let agencyNo = '';
    try {
      agentNo = agencyName?.split(' - ')[0];
      agencyNo = agencyName?.split(' - ')[0];
    }catch (e) {
      agentNo  = '';
      agencyNo  = '';
    }
    //const quoteNumber = policyInfo.Policy_No;
    
    const coverages = {
          dwelling: propertyCoverageInfo?.n_BuildingCvg,
          personalProperty: propertyCoverageInfo?.n_ContentCvg,
          otherStructures: propertyCoverageInfo?.n_BuildingCvg * 0.1,
          lossOfUse: propertyCoverageInfo?.n_BuildingCvg * 0.2,
        };
    const customBldgDed = propertyCoverageInfo?.s_BuildingDeductible;
    const customContentDed = propertyCoverageInfo?.s_ContentDeductible;
    const primaryPolicy = {
      PolicyNumber: policyInfo?.Policy_No,
      InsuranceType: 'NFIP',
      Carrier: '',
      FloodZone: building.floodZone,
      BuildingCoverage: propertyCoverageInfo?.n_BuildingCvg,
      ContentCoverage: propertyCoverageInfo?.n_ContentCvg,
      EffectiveDate: effectiveDate,
    };
    const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
    return {
      //quoteNumber,
      building,
      effectiveDate,
      isLoanTransaction: propertyInfo?.s_PolicyWaitingPeriod === 'LOANNOWAIT' ? 'Yes' : 'No',
      addresses: {
        location: locationAddress
      },
      firstInsured: {
        firstName: insuredFirst,
        lastName: insuredLast,
        fullName: insuredFullName,
        email: userEmail,
        phone: personPhoneContact?.s_CommValue,
        type: insuredType,
        mailingAddress: null,
        businessName : insuredType === 'Business' ? insuredFullName : null
      },
      secondInsured: null,
      agent: {
        firstName: agentFirst,
        lastName: agentLast,
        number: agentNo,
        tenantCode: userDatas?.companyDetails?.naic_number ?? '', //need to change
        licenseNumber: agentLicenseNumber,
      },
      agency: {
        name: agencyName,
        agencyNo: agencyNo,
      },
      coverages,
      customBldgDed,
      customContentDed,
      primaryPolicy
    }
  }
}
export {
  selectExcessRequestParams
}
