import Http from '../Http';
import * as action from '../store/actions';

export function getProfileInfo() {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/profile/getprofile`;
        Http.get(url)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function randomNo(length) {
    var i = 0
    var result = '';
    var characters = '0123456789bcdfghjnmkpqrtsvxwyza';
    var charactersLength = characters.length;
    for (i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result
  }

export function getuserNameSavedprofile(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/profile/changeUserName`;
        Http.post(url, data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
  }

  export function getOtherInfoSavedProfile(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/profile/saveOtherInfo`;
        Http.post(url, data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
  }

  export function getPasswordInfoSavedProfile(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/profile/savePasswordInfo`;
        Http.post(url, data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
  }

  export function GetSelectedFileUploaded(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/profile/uploadProfile`;
        Http.post(url, data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
  }
	
	const keys = {
		upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
		lowerCase: "abcdefghijklmnopqrstuvwxyz",
		number: "0123456789",
		symbol: "@#$%^&*()_+~|}{[]></-="
	}
	const getKey = [
		function symbol2() {
			return keys.symbol[Math.floor(Math.random() * keys.symbol.length)];
		},
		function upperCase() {
			return keys.upperCase[Math.floor(Math.random() * keys.upperCase.length)];
		},
		function lowerCase() {
			return keys.lowerCase[Math.floor(Math.random() * keys.lowerCase.length)];
		},
		function number() {
			return keys.number[Math.floor(Math.random() * keys.number.length)];
		},
		function symbol() {
			return keys.symbol[Math.floor(Math.random() * keys.symbol.length)];
		}
	];

export function createPassword(len = 8) {
	const options = {
		numbers: true,
		special: true,
		lowerCase: true,
		upperCase: true
	};
  const chars = {
    num: "1234567890",
    specialChar: "!@#$%&'()*+,^-./:;<=>?[]_`{~}|",
    lowerCase: "abcdefghijklmnopqrstuvwxyz",
    upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    custom: options.custom || undefined
  };

  const shuffleStr = str => str.split('').sort(() => 0.5 - Math.random()).join('')

  const factor = Math.ceil(len / Object.values(options).reduce((a, b) => b ? a + 1 : a, 0))

  let str = ''
  if (options.numbers) str += shuffleStr(chars.num.repeat(factor)).substr(0, factor)
  if (options.special) str += shuffleStr(chars.specialChar.repeat(factor)).substr(0, factor)
  if (options.lowerCase) str += shuffleStr(chars.lowerCase.repeat(factor)).substr(0, factor)
  if (options.upperCase) str += shuffleStr(chars.upperCase.repeat(factor)).substr(0, factor)
  if (options.custom) str += shuffleStr(chars.custom.repeat(factor)).substr(0, factor)

  return shuffleStr(str).substr(0, len)
}

export function getAgentExcessInfo() {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/getAgentExcessInfo`;
        Http.get(url)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}
  
export function saveAgentExcessInfo(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/saveAgentExcessInfo`;
        Http.post(url, data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
  }
  
  export function getExcessProducts() {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/getPrivateProductExcess`;
        Http.get(url)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}
  
export function getAssociateAgencyList(params) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/profile/listAssociateAgencyCode?producerPersonId=${params.producerPersonId}&producerUPN=${params.producerUPN}&sourceScreen=${params.sourceScreen}`;
        Http.get(url)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return reject(err.response);
              }
            });
        })
    );
}