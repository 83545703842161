import { useEffect, useState } from "react";
import { Box, Flex, Spacer } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FaHome,
  FaListAlt,
  FaRegQuestionCircle,
} from "react-icons/fa";
import { BsCurrencyDollar, BsSearch, BsCardList, BsFileEarmark, BsBookmark} from "react-icons/bs";
import { IoPeopleSharp } from "react-icons/io5";
import { checkProducerVisibility } from "../../../services/moduleVisibilityService"
import { EncryptOrDecrypt } from "../../../services/commonService";
import { tenant } from "../../../pages/common/Helper";
import { starTenancySynonyms } from "../../../pages/quote/common/tenantSynonyms";

export default function NavbarItems() {
  const [endpoint, setEndpoint] = useState("");
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [agentSubModulesAccess] = useState(localStorage.getItem("agentSubModules") ? JSON.parse(
    localStorage.getItem("agentSubModules")) : []);
  const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
  const userLevel = userDatas?.accessRights?.levelName;
  const userSubLevel = userDatas?.accessRights?.subLevelName;

  const links = [
    {
      id: 'dashboard',
      title: t("Dashboard"),
      path: `/dashboard`,
      subPath: ['/applications','/upcoming-renewals','expire-renewals','open-claim','paid-application','paid-renewal','cancellation','closed-claim'],
      icon: <FaHome size={20} className="sidebar-link-icon" />,
      className: '',
    },
    {
      id: 'quote_application',
      title: t("Quotes & Applications"),
      path: `/quote`,
      subPath: ['/instantquote','/instantQuote'],
      icon: <BsCurrencyDollar size={20} className="sidebar-link-icon" />,
      className: '',
    },
    {
      id: 'policy_search',
      title: t("Policy Search"),
      path: `/core/policy`,
      subPath: ['/claim'],
      icon: <BsSearch size={20} className="sidebar-link-icon" />,
      className: '',
    },
    {
      id: 'pending_transaction',
      title: t("Pending Transactions"),
      path: `/pending-transaction`,
      icon: <BsCardList size={20} className="sidebar-link-icon" />,
      className: '',
    },
    {
      id: 'private_flood_servicing',
      title: t("Private Flood Servicing"),
      path: `/private/floodService`,
      icon: <BsBookmark size={20} className="sidebar-link-icon" />,
      className: '',
    },
    {
      id: 'activity_log_and_tasks',
      title: t("Activity Log & Tasks"),
      path: `/active-log`,
      icon: <BsCardList size={20} className="sidebar-link-icon" />,
      className: '',
    },
    {
      id: 'comission_statement',
      title: t("Commission Statement"),
      path: `/commission-statement`,
      subPath: ['/ACH-Information'],
      icon: <BsFileEarmark size={20} className="sidebar-link-icon" />,
      className: '',
    },
    {
      id: 'document_center',
      title: t("Document Center"),
      path: `/documentcenter`,
      icon: <FaListAlt size={20} className="sidebar-link-icon" />,
      className: '',
    },
    {
      id: 'producers',
      title: t("Producers"),
      path: `/producer`,
      icon: <IoPeopleSharp size={20} className="sidebar-link-icon" />,
      className: '',
    },
    {
      id: 'agent_training_and_resources',
      title: t("Agent Training & Resources"),
      path: `/help`,
      icon: <FaRegQuestionCircle size={20} className="sidebar-link-icon" />,
      className: 'Fifth-step',
    }
  ];
  useEffect(() => {
    setEndpoint(pathname);
  }, [pathname])

  return (
    <Box bg="white" mt="-20px" mb={"20px"} pt="16px" px="0" shadow={"lg"} style={{fontSize:"14px" }}>
      <Flex
        justifyContent="flex-start"
        pl="18px"
        pt="12px"
        pb="4px"
        margin="auto"
        gap="0.5rem"
        flexWrap="wrap"
        className="Second-step"
        backgroundColor={"primary.500"}
      >
        {links.map(function ({ id, title, path, icon, className, subPath }) {
          // Removing the agent training resources if the tenant is "starvantage"
          if(starTenancySynonyms.includes(tenant)) {
            if (id === "agent_training_and_resources") {
              return null;
            }
          }
          if (
            (id === 'private_flood_servicing' && agentSubModulesAccess && !agentSubModulesAccess.includes('PRIVATE_PRODUCT_SERVICING')) ||
            (id === 'comission_statement' && agentSubModulesAccess && !agentSubModulesAccess.includes('COMMISSION_STATEMENT')) ||
            (id === 'producers' && !checkProducerVisibility(agentSubModulesAccess, userLevel, userSubLevel)) ||
            (id === 'pending_transaction' && !checkProducerVisibility(agentSubModulesAccess, userLevel, userSubLevel))
          ) {
            return null;
          } else {
            return <Link to={path} key={title}>
              <Box
                display="flex"
                alignItems="center"
                mb={2}
                p={2}
                cursor="pointer"
                borderRadius="md"
                bg={
                  endpoint === path || path.endsWith(endpoint) || endpoint.includes(path) || (subPath && subPath.some(sub => endpoint.includes(sub)))
                    ? "primary.700"
                    : "transparent"
                }
                _hover={{
                  bg: "primary.700"
                }}
                color={"white"}
                className= {className}
              >
                {icon}
                <span style={{ marginLeft: "4px" }}>{title}</span>
              </Box>
            </Link>
          }
        })}
        <Spacer />
      </Flex>
    </Box>
  );
}
