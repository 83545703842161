import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Avatar,
  MenuGroup,
  MenuDivider,
  InputLeftElement,
} from "@chakra-ui/react";
import { GrNotification } from "react-icons/gr";
import { LangContext } from "../../../contexts/LangContext";
import { useTranslation } from "react-i18next";
import { logout } from "../../../services/authService";
import { useDispatch, useSelector } from 'react-redux';
import "./header.scss";
import { toast } from 'react-toastify';
import { BsChat} from "react-icons/bs";

import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { EncryptOrDecrypt, getCompanyDetails } from "../../../services/commonService";
import { getAgentTasks } from "../../../services/dashboardService";
import AWS from 'aws-sdk';
import {
  FaSearch,
  FaTimes
} from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import logoicon from "../../../assets/logoicon.png";
import RefreshToken from "../../../pages/login/RefreshToken";
import {
  verifyPolicy, setPolicyPropsData, getBaseData, resetCoreStore
} from "../../../services/coreSystemService";
import "./header.scss";
import HeaderSearchQuick from "./HeaderSearchQuick";
import AdvanceSearchQuick from "./AdvanceSearchQuick"
import { quickSearch } from "../../../services/dashboardService"
import AdvancedSearchPage from "./AdvancedSearchPage"
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { resetFloodStore } from "../../../services/floodService";
import NavbarItems from "./NavbarItems";
import { tenant } from "../../../pages/common/Helper";
import LiveChatCustom from "../../../pages/dashboard/help/LiveChatCustom";
import { LiveChatWidget } from "@livechat/widget-react";
import { isTenancyEnabledForAmazonChat } from "../../../pages/common/Helper";
import { FiPhoneCall } from "react-icons/fi";
import SecureImage from "../../../pages/common/SecureImage";
import { starTenancySynonyms } from "../../../pages/quote/common/tenantSynonyms";
import { getProductListData } from "../../../services/quoteService";
import { FaCaretDown } from "react-icons/fa6";

const licenseKey = process.env.REACT_APP_LIVECHAT_LICENSE_KEY;

const AdvancedHeader = () => {
  const navigate = useNavigate();
  const { selectedLng, handleDropdownChange } = useContext(LangContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = localStorage.getItem('access_token') || ''
  const enableChatBot = localStorage.getItem('enable_chat_bot');
  const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
  const companyPhone = getCompanyDetails("phone");
  const store = useSelector(state => state); 
  const [question, setQuestion] = useState("");
  const [sessionAttributes, setSessionAttributes] = useState({});
  const [lexUserId, setlexUserId] = useState('chatbot-demo' + Date.now());
  const [showModel, setShowModel] = useState(false);
  const [logo] = useState(localStorage.getItem('company_logo'));
  const [agentTasks, setAgentTasks] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatButton, setChatButton] = useState(false);
  const [checkProducts, setCheckProducts] = useState(true);
  const [debouncedInputValue, setDebouncedInputValue] = useState("");
  AWS.config.region = process.env.REACT_APP_REGION; // Region
  AWS.config.credentials = {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };

  AWS.config.CognitoIdentityCredentials = {
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  }

  var lexruntime = new AWS.LexRuntime();
  const agentSubModulesAccess = localStorage.getItem("agentSubModules") ? JSON.parse(
    localStorage.getItem("agentSubModules")
  ) : [];
  //var lexUserId = 'chatbot-demo' + Date.now();
  //let sessionAttributes = {};

  useEffect(() => {
    if (token.length === 0) {
      navigate('/login');
    }
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(userInput);
    }, 700);
    return () => clearTimeout(timeoutId);
  }, [userInput]);

  useEffect(() => {
    if (userInput.length > 2) {
      getQuickSearchData(userInput)
    }
    setAdvanceSearch(false);
  }, [debouncedInputValue]);

  useEffect(() => {
    // 60000 = 1 Min
    const interval = setInterval(() => {
      checkTimeDifference();
    }, 60000);
    getAgentAllTasks();
    return () => clearInterval(interval);
  }, []);

  const getAgentAllTasks = async () => {
    await dispatch(getAgentTasks()).then((res) => {
      setAgentTasks(res.data.data);
    })
  }

  const checkTimeDifference = () => {
    var now = new Date();
    var tokenExpiresAt = EncryptOrDecrypt(localStorage.getItem('access_token_expires_at'), 'D');
    tokenExpiresAt = new Date(tokenExpiresAt);
    var milliSec = (tokenExpiresAt - now); // milliseconds between now & tokenExpiresAt
    var minutes = Math.round((milliSec / 1000) / 60);

    // 300000 = 5 Min
    if (minutes <= 5) {
      setShowModel(true);
    }
  }

  const handleSelect = async () => {
    // if (eventKey === 'logout') {
      await dispatch(logout());
      localStorage.clear();
      navigate('/login');
    // }
  }

  const showchatDiv = () => {

    var element1 = document.getElementById('chatbot');
    element1.style.visibility = 'visible';
    var element2 = document.getElementById('chatbotIcon');
    element2.style.visibility = 'hidden';
    var conversationDiv = document.getElementById('conversation');
    var responseDiv = document.createElement("div");
    responseDiv.className = 'lexResponse';
    var responseIcon = document.createElement("img");
    responseIcon.src = logoicon;
    responseIcon.className = 'lexResponseIcon';
    responseIcon.alt = 'chatbot';
    var responsePara = document.createElement("div");
    responsePara.className = 'lexResponseText';
    responsePara.innerHTML = "Hi, it's great to see you!";
    var responseIconDiv = document.createElement("div");
    responseIconDiv.className = 'lexResponseIconDiv';
    responseIconDiv.appendChild(responseIcon);
    responseDiv.appendChild(responsePara);
    responseDiv.appendChild(responseIconDiv);
    conversationDiv.appendChild(responseDiv);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const hidechatDiv = () => {
    var element1 = document.getElementById('chatbot');
    element1.style.visibility = 'hidden';
    var element2 = document.getElementById('chatbotIcon');
    element2.style.visibility = 'visible';
    var conversationDiv = document.getElementById('conversation');
    conversationDiv.innerHTML = '';
  }


  const showRequest = (daText) => {

    var conversationDiv = document.getElementById('conversation');
    var requestPara = document.createElement("P");
    requestPara.className = 'userRequest';
    requestPara.appendChild(document.createTextNode(daText));
    conversationDiv.appendChild(requestPara);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const showError = (daText) => {

    var conversationDiv = document.getElementById('conversation');
    var errorPara = document.createElement("P");
    errorPara.className = 'lexError';
    errorPara.appendChild(document.createTextNode(daText));
    conversationDiv.appendChild(errorPara);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const showResponse = (lexResponse) => {

    var conversationDiv = document.getElementById('conversation');
    var responseDiv = document.createElement("div");
    responseDiv.className = 'lexResponse';
    var responseIcon = document.createElement("img");
    responseIcon.src = logoicon;
    responseIcon.className = 'lexResponseIcon';
    responseIcon.alt = 'chatbot';
    var responsePara = document.createElement("div");
    responsePara.className = 'lexResponseText';
    responsePara.innerHTML = lexResponse.message;
    var responseIconDiv = document.createElement("div");
    responseIconDiv.className = 'lexResponseIconDiv';
    responseIconDiv.appendChild(responseIcon);
    responseDiv.appendChild(responsePara);
    responseDiv.appendChild(responseIconDiv);
    conversationDiv.appendChild(responseDiv);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // if there is text to be sent...
    var wisdomText = document.getElementById('wisdom');
    if (wisdomText && wisdomText.value && wisdomText.value.trim().length > 0) {

      // disable input to show we're sending it
      var wisdom = wisdomText.value.trim();
      wisdomText.value = '...';
      wisdomText.locked = true;
      // send it to the Lex runtime
      var params = {
        botAlias: '$LATEST',
        botName: 'AgentPortal',
        inputText: question,
        userId: lexUserId,
        sessionAttributes: sessionAttributes
      };
      showRequest(question);
      lexruntime.postText(params, function (err, data) {
        if (err) {
          console.log(err, err.stack);
          showError('Error:  ' + err.message + ' (see console for details)')
        }
        if (data) {
          // capture the sessionAttributes for the next cycle
          setSessionAttributes(data.sessionAttributes);
          //sessionAttributes = data.sessionAttributes;
          // show response and/or error/dialog status
          showResponse(data);
        }
        // re-enable input
        wisdomText.value = '';
        wisdomText.locked = false;
      });
    }
    // we always cancel form submission
    return false;
  }
  
  useEffect(()=>{
    if(checkProducts) {
    const fetchProductList = async () => {
       try {
        setLoading(true);
        await dispatch(getProductListData());
      } catch (_error) {
          
      } finally {
        setCheckProducts(false);
        setLoading(false);
      }
    }
    fetchProductList();
  }
  },[checkProducts])

  const floodpage = async () => {
    // Reset stores
    await Promise.all([dispatch(resetFloodStore()), dispatch(resetCoreStore())]);
  
    const hasMultipleProducts = Array.isArray(store?.common?.getProductList?.data) 
      && store.common.getProductList.data.length > 1;
  
    if (starTenancySynonyms.includes(tenant) || hasMultipleProducts) {
      navigate('/quote?activeTab=createQuote');
    } else {
      navigate('/flood/instantquote/step/address');
    }
  };
  

  const handleRedirectToPolicy = (policyNo, status) => {
    verify(policyNo, status)
  }

  const verify = async (policyNumber, status) => {
    var params = {}, agentPortalTransPk = 0;
    await dispatch(verifyPolicy(policyNumber)).then((res) => {
      if (res.status) {
        params = {
          n_POPolicyMasterFK: res.policyIdPK,
          n_POTermMasterFK: res.termMasterPK,
          n_POTransactionFK: res.poTransPK,
        };
        agentPortalTransPk = res.agentPortalTransPk
      } else {
        navigate(`/core/policy`);
      }
    });
    await dispatch(setPolicyPropsData(params));
    await dispatch(
      getBaseData(params.n_POTransactionFK)
    );
    // redirect based on status
    if (['APPLICATION', 'QUOTE'].includes(status) && agentPortalTransPk > 0) {
      navigate(`/flood/quote/${agentPortalTransPk}`);
    } else {
      navigate(`/core/policy/${policyNumber}`);
    }
    window.location.reload();
  };
  const notificationOnClick = () => {
    if (agentTasks?.length === 0) {
      navigate(`/active-log`);
    }
  }

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      const userInput = e.currentTarget.value;
      setUserInput(userInput);
      setAdvanceSearch(true);
      navigate('/quick-search');
    }
  }

  const inputFieldClear = () => {
    setUserInput("");
  }

  const quickSearchData = (e) => {
    e.preventDefault()
    const userInput = e.currentTarget.value;
    setUserInput(userInput);
    // if (userInput.length > 2) {
    //   getQuickSearchData(userInput)
    // }
    // setAdvanceSearch(false);
    // setUserInput(e.target.value);
  }

  const getQuickSearchData = async (input) => {
    setSearchData([]);
    setLoading(true);
    await quickSearch(input).then((res) => {
      if (res.data.totalCount !== 0) {
        res.data.data.length > 0 && setSearchData(res.data.data);
      } else {
        setSearchData([]);
      }
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      toast.error(t("An error occurred, please try again later."));
    })
  }

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />
        <Flex
          px={"20px"}
          minHeight={"52px"}
          py={"10px"}
          bgColor={"white"}
          justifyContent={"space-between"}
        >
          {/* Logo */}
          <Box minHeight={"52px"}>
            <SecureImage src={`${logo}`} maxHeight={"52px"} alt="" />
          </Box>
          <Flex gap={3} justifyContent={"flex-end"}>
            {/* Search Box */}
            <Flex direction={"column"}>
              <InputGroup>
                <InputLeftElement alignItems={"normal"} mt={"10px"}>
                  <FaSearch />
                </InputLeftElement>
                <Input
                  type="text"
                  className="form-control"
                  width={"30vw"}
                  height={"35px"}
                  placeholder={t("Names, Address, Policy Number")}
                  onChange={(e) => quickSearchData(e)}
                  value={userInput}
                  onKeyDown={onKeyDown}
                />
                <InputRightElement
                  alignItems={"normal"}
                  mt={"10px"}
                  cursor={"pointer"}
                  onClick={inputFieldClear}
                >
                  <FaTimes />
                </InputRightElement>
              </InputGroup>
              <Box>
                {/* Search Output below Search box */}
                {userInput.length > 2 ? (
                  <>
                    {advanceSearch ? (
                      ""
                    ) : (
                      <HeaderSearchQuick data={searchData} loading={loading} />
                    )}
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Flex>
            {/* Create Quote Button */}
            {EncryptOrDecrypt(localStorage.getItem("is_active"), "D") ===
              "Y" && (
              <Box>
                <Button
                  size={"sm"}
                  leftIcon={<AiOutlinePlus size={16} />}
                  onClick={floodpage}
                >
                  {t("Create Quote")}
                </Button>
              </Box>
            )}
            {/* Chat Now Button */}
            {enableChatBot === "Y" && (
              <Box>
                <Button
                  className="launch-widget-btn Forth-step"
                  size={"sm"}
                  leftIcon={<BsChat size={20} />}
                  onClick={() => {
                    setChatButton(!chatButton);
                  }}
                >
                  {t("Chat Now")}
                </Button>
              </Box>
            )}
            {chatButton && !isTenancyEnabledForAmazonChat && (
              <>
                <LiveChatCustom />
                <LiveChatWidget license={licenseKey} visibility="maximized" />
              </>
            )}
            {/* Company Phone Number */}
            {tenant !== "farmers" && (
              <Box display="flex" pt={2}>
                <FiPhoneCall
                  size={18}
                  className="sidebar-link-icon"
                  style={{ marginRight: "4px" }}
                />
                {companyPhone}
              </Box>
            )}
            {/* Translation - Language */}
            <Box>
              <Menu>
                <MenuButton
                  bgColor={"transparent"}
                  _hover={{
                    bgColor: "transparent",
                  }}
                  _active={{
                    bgColor: "transparent",
                  }}
                  border={"1px solid"}
                  borderColor={"black"}
                  color={"black"}
                  as={Button}
                  width={"150px"}
                  rightIcon={<FaCaretDown size={12} />}
                  leftIcon={
                    <Image
                      src={`/assets/icons/${selectedLng}.png`}
                      h={"20px"}
                      w={"20px"}
                      alt=""
                    />
                  }
                >
                  {selectedLng}
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => handleDropdownChange("English", "en")}
                  >
                    {t("English")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDropdownChange("Spanish", "spa")}
                  >
                    {t("Spanish")}
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            {/* Notification bell */}
            <Box>
              <Menu>
                <MenuButton
                  as={Button}
                  p={0}
                  bgColor={"transparent"}
                  _hover={{
                    bgColor: "transparent",
                  }}
                  _active={{
                    bgColor: "transparent",
                  }}
                  color={"black"}
                  onClick={() => notificationOnClick()}
                >
                  <Box
                    style={
                      agentTasks?.length > 0
                        ? {
                            right: "17px",
                            position: "absolute",
                            width: "10.37px",
                            height: "10.37px",
                            backgroundColor: "#ff1e1e",
                            borderRadius: "50%",
                          }
                        : {}
                    }
                  />
                  <GrNotification size={22} />
                </MenuButton>
                <MenuList>
                  {agentTasks?.map((item, i) => (
                    <MenuItem
                      key={i}
                      onClick={() =>
                        handleRedirectToPolicy(item.Policy_No, item.status)
                      }
                    >
                      {item.task_title}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>
            {/* Profile Logo and Menu bar */}
            <Box>
              <Menu>
                <MenuButton
                  as={Button}
                  p={0}
                  bgColor={"transparent"}
                  _hover={{
                    bgColor: "transparent",
                  }}
                  _active={{
                    bgColor: "transparent",
                  }}
                >
                  <Avatar
                    name={userDatas.s_ScreenName}
                    id="img-profile"
                    src={userDatas?.profileImage}
                  />
                </MenuButton>
                <MenuList>
                  <MenuGroup title={userDatas.s_ScreenName}>
                    <MenuItem
                      color={"var(--chakra-colors-primary-500)"}
                      fontSize={"14px"}
                      onClick={() => navigate("/profile")}
                    >
                      {t("Edit Profile")}
                    </MenuItem>
                    {(!starTenancySynonyms.includes(tenant)) ? (
                      <>
                        <MenuItem
                          color={"var(--chakra-colors-primary-500)"}
                          fontSize={"14px"}
                          onClick={() => navigate("/celicense")}
                        >
                          {t("CE-License")}
                        </MenuItem>
                      </>
                    ) : (
                      ""
                    )}
                    <MenuItem
                      color={"var(--chakra-colors-primary-500)"}
                      fontSize={"14px"}
                      onClick={() => navigate("/al3")}
                    >
                      {t("AL3")}
                    </MenuItem>
                    {agentSubModulesAccess.includes("ACH_INFO_AGENT") && (
                      <MenuItem
                        color={"var(--chakra-colors-primary-500)"}
                        fontSize={"14px"}
                        onClick={() => navigate("/ACH-Information")}
                      >
                        {t("ACH Information")}
                      </MenuItem>
                    )}
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup>
                    <MenuItem
                      color={"var(--chakra-colors-primary-500)"}
                      fontSize={"14px"}
                      onClick={handleSelect}
                    >
                      {t("Log out")}
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        </Flex>
        <Box>
          <NavbarItems />
        </Box>
        {showModel && (
          <RefreshToken showModel={showModel} setShowModel={setShowModel} />
        )}
        {userInput.length > 2 ? (
          <>
            {advanceSearch && window.location.pathname === "/quick-search" ? (
              <AdvanceSearchQuick data={searchData} text={userInput} />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </LoadingOverlay>
    </>
  );
};

export default AdvancedHeader;
