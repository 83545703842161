import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Card,
  Text,
  Center,
  Flex,
  Spacer,
  Input,
  InputGroup,
  TableContainer,
  Table,
  Tr,
  Th,
  Tbody,
  Td,
  Thead,
  Switch,
  Divider,
} from "@chakra-ui/react";
import {
  getClaimForMasters,
  getPolicyClaimDetails,
  checkClaimExist,
  getReportAClaim,
} from "../../services/claimService";
import { saveReportAClaim } from "../../services/claimService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import { getZipDetails } from "../../services/floodService";
import { useTranslation } from "react-i18next";
import Select from "react-select";
// import Switch from "react-switch";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
// import { customStyles } from "../../components/reactSelectCustomStyle";
import { toast } from "react-toastify";
import ZipDetailsView from "../common/ZipDetailsView";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";
import { FaArrowLeft } from "react-icons/fa";
import { TextMask, InputAdapter } from "react-text-mask-hoc";

const ReportACLaim = (props) => {
  //   const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [claims, setClaims] = React.useState([]);

  const [streetName, setStreetName] = useState("");
  const [googlePlace, setGooglePlace] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [zipCodeSuffix, setZipCodeSuffix] = useState("");
  const [isGPUpdate, setIsGPUpdate] = useState(false);
  const [countryName, setCountyName] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [multipleCity, setMultipleCity] = useState(null);
  const [show, setShow] = useState(false);
  const [allDropDown, setAllDropDown] = useState();
  const [date1, setDate1] = useState();
  const [policyNo, setPolicyNo] = useState();
  const [policyClaimData, setPolicyClaimData] = useState();
  const [personAdrArr, setPersonAdrArr] = useState();
  const [claimExist, setClaimExist] = useState(false);
  const [claimExistView, setClaimExistView] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [jsonData, setJsonData] = useState();
  const [reasonExist, setReasonExist] = useState(false);
  const [ReportedClaimDetails, setReportedClaimDetails] = useState({});
  const [tenancyNaicPrefix, setTenancyNaicPrefix] = useState("");

  const initialState = [
    {
      reference_id: "",
      reportedFrom: 'AGENT',
      propertyLocation: "",
      isTemporaryAddr: "",
      contactNo: "",
      contactNo2: "",
      email: "",
      acceptSms: "",
      claimType: "",
      typeOfLoss: "",
      dateofLoss: "",
      serviceRep: "",
      catastropheLoss: "",
      eventName: "",
      claimReportedById: 5,
      personTempAddr: {},
      firstNoticeOfLoss: {
        lossDate: "",
        lossReason: "",
        TypeofLoss: "",
        insuredResides: "",
        waterLevelFeet: "",
        waterLevelInch: "",
        waterLevelNoIdea: "",
        waterRemainedDays: "",
        waterRemainedHours: "",
        waterRemainedNotIdea: "",
        allowedOther: "",
        allowedBack: "",
        isCleanUpStart: "",
        repairStart: "",
        repairs: "",
        contractorName: "",
        isResidence: "",
        isAdjuster: "",
        isMortgagee: "",
      },
    },
  ];

  useEffect(() => {
    toast.configure();
    setLoading(true);
    let policyNo = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    setPolicyNo(policyNo);
    if (window.location.href.includes("view-a-claim")) {
      viewAClaimFunc(policyNo);
    } else {
      setJsonData(initialState);
      reportAClaimFunc(policyNo);
    }
  }, []);

  useEffect(() => {
    if (claimExistView == true) {
      dispatch(getClaimForMasters(policyNo)).then((res) => {
        setAllDropDown(res.data);
      });

      dispatch(getPolicyClaimDetails(policyNo)).then((res) => {
        setTenancyNaicPrefix(
          res.data.tenancy.toUpperCase().charAt(0) + res.data.naic
        );
        setPolicyClaimData(res.data);

        let poRiskmasterId_Pk = "";
        if (!isEmpty(res.data.policy_risk_master_info)) {
          poRiskmasterId_Pk =
            res.data.policy_risk_master_info.n_PORiskMaster_PK;
        }

        let poRiskAddresses = "";
        var newData = [...jsonData];

        if (!isEmpty(jsonData[0].propertyLocation)) {
          poRiskAddresses = jsonData[0].propertyLocation;
        } else {
          if (!isEmpty(res.data.policy_risk_master_info.addresses)) {
            poRiskAddresses = res.data.policy_risk_master_info.addresses;
          }
        }

        if (!isEmpty(jsonData[0].personTempAddr)) {
          setZipCode(
            jsonData[0].personTempAddr.s_PostalCode
              ? jsonData[0].personTempAddr.s_PostalCode
              : null
          );
          setZipCodeSuffix(
            jsonData[0].personTempAddr.s_PostalCode
              ? jsonData[0].personTempAddr.s_PostalCodeSuffix
              : null
          );
          setCityName(
            jsonData[0].personTempAddr.s_CityName
              ? jsonData[0].personTempAddr.s_CityName
              : null
          );
          setCountyName(
            jsonData[0].personTempAddr.s_CountryName
              ? jsonData[0].personTempAddr.s_CountryName
              : null
          );
          setStateName(
            jsonData[0].personTempAddr.s_StateName
              ? jsonData[0].personTempAddr.s_StateName
              : null
          );
        }

        setJsonData(newData);

        let addressSelectData = [];
        if (poRiskmasterId_Pk != "") {
          if (jsonData.length) {
            jsonData[0].propertyLocation.map((ele) => {
              let tempObject = {};
              tempObject.label =
                (ele.s_AddressLine1 ? ele.s_AddressLine1 : "") +
                (ele.cityName ? ", " + ele.cityName.s_CityName : "") +
                (ele.countyName ? ", " + ele.countyName.s_CountyName : "") +
                (ele.stateName ? ", " + ele.stateName.s_StateName : "") +
                (ele.s_PostalCode ? ", " + ele.s_PostalCode : "");

              tempObject.value = poRiskmasterId_Pk;
              addressSelectData.push(tempObject);
            });
          }
        }

        setPersonAdrArr(addressSelectData);
        setLoading(false);
      });
    }
  }, [claimExistView]);

  useEffect(() => {
    if (claimExist == true) {
      dispatch(getClaimForMasters(policyNo)).then((res) => {
        setAllDropDown(res.data);
      });

      dispatch(getPolicyClaimDetails(policyNo)).then((res) => {
        setTenancyNaicPrefix(
          res.data.tenancy.toUpperCase().charAt(0) + res.data.naic
        );
        setPolicyClaimData(res.data);

        let poRiskmasterId_Pk = "";
        if (!isEmpty(res.data.policy_risk_master_info)) {
          poRiskmasterId_Pk =
            res.data.policy_risk_master_info.n_PORiskMaster_PK;
        }

        let poRiskAddresses = "";
        var newData = [...jsonData];

        if (!isEmpty(jsonData[0].propertyLocation)) {
          poRiskAddresses = jsonData[0].propertyLocation;
        } else {
          if (!isEmpty(res.data.policy_risk_master_info.addresses)) {
            newData[0].propertyLocation =
              res.data.policy_risk_master_info.addresses;
            poRiskAddresses = res.data.policy_risk_master_info.addresses;
          }
        }

        if (
          res.data.insuredPerson_info &&
          !isEmpty(res.data.insuredPerson_info.phone_info)
        ) {
          newData[0].contactNo =
            res.data.insuredPerson_info.phone_info[0].s_PhoneNumber;
        }

        if (!isEmpty(res.data.insuredPerson_info.email_info)) {
          newData[0].email =
            res.data.insuredPerson_info.email_info[0].s_EmailAddress;
        }

        setJsonData(newData);

        let addressSelectData = [];
        if (poRiskmasterId_Pk != "") {
          //res.data.PORiskMaster_PK = poRiskmasterId_Pk;
          if (poRiskAddresses.length) {
            poRiskAddresses.map((ele) => {
              let tempObject = {};
              tempObject.label =
                (ele.s_AddressLine1 ? ele.s_AddressLine1 : "") +
                (ele.cityName ? ", " + ele.cityName.s_CityName : "") +
                (ele.countyName ? ", " + ele.countyName.s_CountyName : "") +
                (ele.stateName ? ", " + ele.stateName.s_StateName : "") +
                (ele.s_PostalCode ? ", " + ele.s_PostalCode : "");

              tempObject.value = poRiskmasterId_Pk;
              addressSelectData.push(tempObject);
            });
          }
        }

        setPersonAdrArr(addressSelectData);
        setLoading(false);
      });
    }
  }, [claimExist]);

  const reportAClaimFunc = (policyNo) => {
    dispatch(checkClaimExist(policyNo)).then((res) => {
      if (res.data == 0) {
        setClaimExist(true);
      } else {
        setClaimExist(false);
        Swal.fire({
          icon: "warning",
          title: t("Please Wait"),
          text: t(
            "One of the reported claims is already pending for this Policy"
          ),
          confirmButtonColor: "#66e3d8",
        });

        navigate("/core/policy/" + policyNo);
      }
    });
  };

  const viewAClaimFunc = (policyNo) => {
    dispatch(getReportAClaim(policyNo)).then((res) => {
      if (res.data != null) {
        var newData = [];
        setReportedClaimDetails(res.data);
        newData.push(res.data.metadata);
        if (res.data.status == "REJECTED") {
          setReasonExist(true);
        }
        setJsonData(newData);
        setClaimExistView(true);
        setIsDisabled(true);
      } else {
        setClaimExist(false);
        Swal.fire({
          icon: "warning",
          title: t("Please Wait"),
          text: t("Something Went Wrong"),
          confirmButtonColor: "#66e3d8",
        });

        navigate("/core/policy/" + policyNo);
      }
    });
  };
  // Inserting style only for address field
  useEffect(() => {
    document.head.insertAdjacentHTML(
      "beforeend",
      `<style>#react-select-3-input { opacity: 2 !important; width: 250px !important;}</style>`
    );
  }, []);

  const handleSubmit = () => {
    if (jsonData[0].dateofLoss == "") {
      toast.error("Please select Date of loss");
      return;
    } else if (jsonData[0].contactNo == "") {
      toast.error("Please select Contact Number");
      return;
    } else if (personAdrArr == "") {
      toast.error("Please select Property Location");
      return;
    } else if (jsonData[0].personTempAddr == "") {
      toast.error("Please select Temporary Address");
      return;
    } else if (zipCode == "" && jsonData[0].isTemporaryAddr !== "") {
      toast.error("Please select Zip Code");
      return;
    } else if (countryName == "" && jsonData[0].isTemporaryAddr !== "") {
      toast.error("Please select Country Name");
      return;
    } else if (stateName == "" && jsonData[0].isTemporaryAddr !== "") {
      toast.error("Please select State Name");
      return;
    } else if (cityName == "" && jsonData[0].isTemporaryAddr !== "") {
      toast.error("Please select City Name");
      return;
    }

    const newjsondata = jsonData[0];

    let params = {
      policyNo: policyNo,
      metadata: newjsondata,
    };
    var apiError = false;

    Swal.fire({
      title: "Please Wait",
      html: "<b>Saving Data...</b>",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    }).then(function () {
      if (apiError == false) {
        navigate("/core/policy/" + policyNo);
      }
    });

    dispatch(saveReportAClaim(params)).then((res) => {
      if (res.status == 200) {
        Swal.hideLoading();
        Swal.update({
          icon: "success",
          html: "<b>Saved Successfully</b>",
          title: "",
          confirmButtonColor: "#66e3d8",
        });
      } else {
        apiError = true;
        Swal.hideLoading();
        Swal.update({
          icon: "error",
          html:
            "<b>" + (res?.data?.errors?.text || "An error occurred.") + "</b>",
          title: "",
          confirmButtonColor: "#66e3d8",
        });
      }
    });
  };

  const handleDropDownChange = (selectedOption, parent, name, ddCode) => {
    var parentObjectName = parent;
    var newData = [...jsonData];

    if (parentObjectName == "mainNode") {
      newData[0][name] = selectedOption[ddCode];
    } else {
      const parentObjectArr = parentObjectName.split(".");
      if (parentObjectArr.length > 0) {
        if (parentObjectArr.length == 1) {
          newData[0][parentObjectArr[0]][name] = selectedOption[ddCode];
        }
      }
    }

    if (name == "typeOfLoss") {
      newData[0].firstNoticeOfLoss.TypeofLoss = newData[0].typeOfLoss;
    } else if (name == "claimType") {
      newData[0].firstNoticeOfLoss.lossReason = newData[0].claimType;
    }

    setJsonData(newData);
  };

  const handleChange = (e) => {
    const { name, attributes, value } = e.target;

    var parentObjectName = attributes.parentobject.value;
    var newData = [...jsonData];

    if (parentObjectName == "mainNode") {
      newData[0][name] = value;
    } else {
      const parentObjectArr = parentObjectName.split(".");
      if (parentObjectArr.length > 0) {
        if (parentObjectArr.length == 1) {
          newData[0][parentObjectArr[0]][name] = value;
        } else if (parentObjectArr.length == 2) {
          newData[0][parentObjectArr[0]][parentObjectArr[1]][name] = value;
        }
      }
    }

    if (name == "waterRemainedDays" || name == "waterRemainedHours") {
      if (value != "") {
        newData[0].firstNoticeOfLoss.waterRemainedNotIdea = "";
      }
    } else if (name == "waterLevelFeet" || name == "waterLevelInch") {
      if (value != "") {
        newData[0].firstNoticeOfLoss.waterLevelNoIdea = "";
      }
    }
    setJsonData(newData);
  };

  const handleSwitchChange = (checkedValue, event, name) => {
    if (checkedValue == true) {
      checkedValue = "YES";
    } else {
      checkedValue = "NO";
    }

    var parentObjectName = name;
    var newData = [...jsonData];

    const parentObjectArr = parentObjectName.split(".");
    if (parentObjectArr.length > 0) {
      if (parentObjectArr.length == 1) {
        newData[0][parentObjectArr[0]] = checkedValue;
      } else if (parentObjectArr.length == 2) {
        newData[0][parentObjectArr[0]][parentObjectArr[1]] = checkedValue;
      }
    } else {
      newData[0][parentObjectName] = checkedValue;
    }

    setJsonData(newData);
  };

  const handleDateChange = (date, parent, name) => {
    var parentObjectName = parent;
    var newData = [...jsonData];

    if (parentObjectName == "mainNode") {
      newData[0][name] = Moment(date).format("YYYY/MM/DD");
    } else {
      const parentObjectArr = parentObjectName.split(".");
      if (parentObjectArr.length > 0) {
        if (parentObjectArr.length == 1) {
          newData[0][parentObjectArr[0]][name] =
            Moment(date).format("YYYY/MM/DD");
        } else if (parentObjectArr.length == 2) {
          newData[0][parentObjectArr[0]][parentObjectArr[1]][name] =
            Moment(date).format("YYYY/MM/DD");
        }
      }
    }

    if (name == "dateofLoss") {
      newData[0].firstNoticeOfLoss.lossDate = newData[0].dateofLoss;
    }
    setJsonData(newData);
  };

  const handleQRadioChange = (e) => {
    const { name, value, attributes } = e.target;

    var parentObjectName = attributes.parentobject.value;
    var newData = [...jsonData];

    if (parentObjectName == "mainNode") {
      newData[0][name] = value;
    } else {
      const parentObjectArr = parentObjectName.split(".");
      if (parentObjectArr.length > 0) {
        if (parentObjectArr.length == 1) {
          newData[0][parentObjectArr[0]][name] = value;
        } else if (parentObjectArr.length == 2) {
          newData[0][parentObjectArr[0]][parentObjectArr[1]][name] = value;
        }
      }
    }
    if (name === "allowedBack") {
      if (value === "NO") {
        newData[0].firstNoticeOfLoss.repairStart = "NO";
      }
    }
    if (name == "waterRemainedNotIdea") {
      if (value == "YES") {
        newData[0].firstNoticeOfLoss.waterRemainedDays = "";
        newData[0].firstNoticeOfLoss.waterRemainedHours = "";
      }
    } else if (name == "waterLevelNoIdea") {
      if (value == "YES") {
        newData[0].firstNoticeOfLoss.waterLevelFeet = "";
        newData[0].firstNoticeOfLoss.waterLevelInch = "";
      }
    }
    setJsonData(newData);
  };

  const setDate = (date) => {
    return new Date(date);
  };

  // trigger to set selected address street name
  const handleGooglAutoComplete = (val, status) => {
    if (val) {
      if (status.action == "input-change") {
        setStreetName(val);
      } else if (
        ["set-value", "menu-close", "input-blur", "menu-close"].includes(
          status.action
        )
      ) {
        setStreetName(streetName.toUpperCase());
      }
    }
  };

  // get selected zip data
  const setZipDetailsData = (zipData) => {
    var addressPK = {
      n_CityId_FK: zipData.n_CityId_PK,
      s_ZipCounty: zipData.s_CountyName.toUpperCase(),
      n_CountyId_FK: zipData.n_CountyId_FK,
      s_StateCode: zipData.s_StateCode.toUpperCase(),
      n_StateId_PK: zipData.n_StateId_PK,
      s_CityName: zipData.s_CityName.toUpperCase(),
      n_CityId_PK: zipData.n_CityId_PK,
      n_CountryId_FK: zipData.n_CountryId_FK,
      n_Zipcodes_PK: zipData.n_Zipcodes_PK,
      s_CountyName: zipData.s_CountyName.toUpperCase(),
      s_StreetName: streetName,
    };
    setCountyName(zipData.s_CountyName.toUpperCase());
    setStateName(zipData.s_StateCode.toUpperCase());
    setCityName(zipData.s_CityName.toUpperCase());
    handlePropertyAddressChange("", addressPK);
    setMultipleCity(null);
    setShow(false);
  };

  /**
   * Handle Google Place Autocomplete
   * @param {element values} e
   */
  const handleGooglePlace = (e) => {
    setGooglePlace(e); // setting element value to google place autocomplete
    setStreetName(e.value.structured_formatting.main_text); // setting only address line 1 as streen name
    setIsGPUpdate(true); // to trigger dependent function
    let addr = {
      s_StreetName: e.value.structured_formatting.main_text,
    };
    handlePropertyAddressChange("", addr);
    getGeoCode(e.value.description); // to get geo-details based on address
    //isDataChanged(true); // to visible "Rate" Button
  };

  // get geocode from address
  const getGeoCode = (description) => {
    geocodeByAddress(description).then((addr) => {
      var propertyAddress = {};
      addr &&
        addr[0].address_components.forEach((e) => {
          if (e.types.includes("postal_code")) {
            setZipCode(e.short_name);
            propertyAddress = {
              ...propertyAddress,
              s_PostalCode: e.short_name,
            };
          } else if (e.types.includes("street_number")) {
            propertyAddress = {
              ...propertyAddress,
              s_HouseNo: e.short_name,
            };
          } else if (e.types.includes("locality", "political")) {
            setCityName(e.short_name);
            propertyAddress = {
              ...propertyAddress,
              s_CityName: e.short_name,
            };
          } else if (e.types.includes("country", "political")) {
            propertyAddress = {
              ...propertyAddress,
              s_CountryName: e.short_name,
            };
          } else if (
            e.types.includes("administrative_area_level_2", "political")
          ) {
            setCountyName(e.short_name);
            propertyAddress = {
              ...propertyAddress,
              s_CountyName: e.short_name,
            };
          } else if (
            e.types.includes("administrative_area_level_1", "political")
          ) {
            setStateName(e.short_name);
            propertyAddress = {
              ...propertyAddress,
              s_StateName: e.short_name,
            };
          } else if (e.types.includes("postal_code_suffix")) {
            setZipCodeSuffix(e.short_name);
            propertyAddress = {
              ...propertyAddress,
              s_PostalCodeSuffix: e.short_name,
            };
          }

          if (Object.keys(propertyAddress).length > 0) {
            handlePropertyAddressChange("", propertyAddress);
          }
        });
      if (addr && addr[0]) {
        getLatLng(addr[0]).then(({ lat, lng }) => {
          var propertyAddress = {
            s_Latitude: lat,
            s_Longitude: lng,
          };
          handlePropertyAddressChange("", propertyAddress);
        });
      }
    });
  };

  // open Zip Modal
  const showMultipleCityModal = (zipData) => {
    setMultipleCity(
      <ZipDetailsView zipData={zipData} setZipDetailsData={setZipDetailsData} />
    );
    setShow(true);
  };

  // to update value in reducer
  const handlePropertyAddressChange = (key, value) => {
    var quoteDataVar = jsonData;
    if (key.length === 0) {
      for (const [key, val] of Object.entries(value)) {
        quoteDataVar[0]["personTempAddr"][key] = val;
      }
    } else {
      if (key === "s_StreetName") {
        quoteDataVar[0]["personTempAddr"]["s_AddressLine1"] = value;
      }
      quoteDataVar[0]["personTempAddr"][key] = value;
    }

    setJsonData(quoteDataVar);
  };

  // get zip details from address
  const getDataFromZip = (zipCodeVal) => {
    if (streetName === "") {
      toast.error("Please Enter Street Name!");
      setZipCode("");
      return false;
    }
    if (zipCodeVal !== "") {
      handlePropertyAddressChange("s_StreetName", streetName);
      dispatch(getZipDetails("10", zipCodeVal, "MAILING")).then((res) => {
        if (res.success === true) {
          var data = res.data;
          if (data.status === "Y") {
            if (data.count === 1) {
              setZipDetailsData(data.zipData[0]);
            } else {
              showMultipleCityModal(data.zipData);
            }
          } else {
            toast.error(res.errorMsg);
          }
        }
      });
    } else {
      toast.error("Zip Code should not be empty!");
    }
  };
  const backTopPolicy = () => {
    navigate("/core/policy/" + policyNo);
  };

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />
        <Box></Box>
        {reasonExist && (
          <Box mt={6}>
            <FormControl>
              <FormLabel fontWeight={600}>{t("Reason")}</FormLabel>
              {jsonData && jsonData[0].reason && jsonData[0].reason}
            </FormControl>
          </Box>
        )}
        <Box>
          <Flex>
            <Text mb={0} fontWeight={600} fontSize="2xl" lineHeight="32px">
              {policyNo}/Flood Program
              {ReportedClaimDetails && ReportedClaimDetails.claim_no != null ? (
                <>
                  {"/"}
                  {tenancyNaicPrefix + ReportedClaimDetails.claim_no}
                </>
              ) : null}
            </Text>
            <Spacer />
            <Button
              leftIcon={<FaArrowLeft size={18} />}
              onClick={backTopPolicy}
              size={"sm"}
            >
              {t("Back to Policy")}
            </Button>
          </Flex>
        </Box>
        <Box mt={6}>
          <Card p={4} borderRadius={"2xl"} boxShadow={"lg"}>
            <Box p={2}>
              <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>{t("Property Location")}:</FormLabel>
                    <Select
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => option.label}
                      value={
                        policyClaimData &&
                        personAdrArr &&
                        personAdrArr.filter(
                          (option) =>
                            option.value ==
                            policyClaimData.policy_risk_master_info
                              .n_PORiskMaster_PK
                        )
                      }
                      options={personAdrArr && personAdrArr}
                      isDisabled={isDisabled}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>{t("Contact Number")}:</FormLabel>
                    <TextMask
                      mask={[
                        "(",
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      Component={InputAdapter}
                      className="form-control dashboard-group-input"
                      style={{ flex: 1 }}
                      type="text"
                      id="contactNo"
                      name="contactNo"
                      autoComplete="off"
                      onChange={handleChange}
                      parentobject="mainNode"
                      value={jsonData && jsonData[0].contactNo}
                      disabled={isDisabled}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Second Contact Number")}:</FormLabel>
                    <TextMask
                      mask={[
                        "(",
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      Component={InputAdapter}
                      className="form-control dashboard-group-input"
                      style={{ flex: 1 }}
                      type="text"
                      id="contactNo2"
                      name="contactNo2"
                      autoComplete="off"
                      onChange={handleChange}
                      parentobject="mainNode"
                      value={jsonData && jsonData[0].contactNo2}
                      disabled={isDisabled}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>{t("Email")}:</FormLabel>
                    <Input
                      type="text"
                      id="email"
                      name="email"
                      className="form-control dashboard-group-input"
                      parentobject="mainNode"
                      onChange={handleChange}
                      value={jsonData && jsonData[0].email}
                      isDisabled={isDisabled}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>{t("Date Of Loss")}:</FormLabel>
                    <DatePicker
                      as={Input}
                      className="datepicker-full-width-policy-date-loss"
                      id="dateofLoss"
                      name="adjusterFeePaidDate"
                      parentobject="mainNode"
                      selected={
                        jsonData && jsonData[0].dateofLoss
                          ? setDate(jsonData[0].dateofLoss)
                          : ""
                      }
                      onChange={(date) => {
                        setDate1(date);
                        handleDateChange(date, "mainNode", "dateofLoss");
                      }}
                      disabled={isDisabled}
                      showDisabledMonthNavigation
                      // ODS-4426
                      /*minDate={
                        new Date(
                          policyClaimData?.effectiveDate?.d_TransEffectiveFrom
                        )
                      }
                      maxDate={
                        new Date() <
                        new Date(
                          policyClaimData?.effectiveDate?.d_TransEffectiveTo
                        )
                          ? new Date()
                          : new Date(
                              policyClaimData?.effectiveDate?.d_TransEffectiveTo
                            )
                      }*/
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl>
                    <FormLabel>{t("Accept Text")}:</FormLabel>
                    <Box display={"flex"} justifyContent={"start"}>
                      <label style={{ marginRight: "0.5rem" }}>
                        {t("Yes")}
                      </label>
                      <Switch
                        mt={1}
                        isChecked={
                          jsonData && jsonData[0].acceptSms === "YES"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          handleSwitchChange(e.target.checked, e, "acceptSms");
                        }}
                        colorScheme="cyan"
                        size="md"
                        isDisabled={isDisabled}
                      />
                      <label style={{ marginLeft: "0.5rem" }}>{t("No")}</label>
                    </Box>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel whiteSpace={"nowrap"}>
                      {t("Do you want to add temporary location")}?
                    </FormLabel>
                    <Box display={"flex"} justifyContent={"start"}>
                      <label style={{ marginRight: "0.5rem" }}>
                        {t("Yes")}
                      </label>
                      <Switch
                        mt={1}
                        isChecked={
                          jsonData && jsonData[0].isTemporaryAddr === "YES"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          handleSwitchChange(
                            e.target.checked,
                            e,
                            "isTemporaryAddr"
                          );
                        }}
                        colorScheme="cyan"
                        size="md"
                        isDisabled={isDisabled}
                      />
                      <label style={{ marginLeft: "0.5rem" }}>{t("No")}</label>
                    </Box>
                  </FormControl>
                </GridItem>
                {jsonData && jsonData[0].isTemporaryAddr == "YES" && (
                  <>
                    <GridItem>
                      <FormControl>
                        <FormLabel>{t("Temporary Address")}:</FormLabel>
                        {isDisabled && jsonData[0].isTemporaryAddr == "YES" ? (
                          <Input
                            className=""
                            type="text"
                            placeholder={t("Property Address")}
                            title={t("Property Address")}
                            value={
                              jsonData &&
                              jsonData[0].personTempAddr &&
                              jsonData[0].personTempAddr.s_StreetName
                            }
                            isDisabled={isDisabled}
                          />
                        ) : (
                          <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            selectProps={{
                              // styles: customStyles,
                              inputValue: streetName,
                              value: googlePlace,
                              onInputChange: handleGooglAutoComplete,
                              onChange: handleGooglePlace,
                              name: "googlePlacesStreetName",
                              id: "googlePlacesStreetName",
                              placeholder: "Enter a location",
                            }}
                          />
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel>{t("Zip")}:</FormLabel>
                        <Input
                          type="text"
                          className="form-control dashboard-group-input"
                          name="s_PostalCode"
                          title={t("Zip")}
                          parentobject="personTempAddr"
                          border={"1px solid lightgray"}
                          value={zipCode}
                          onChange={(e) => {
                            handleChange(e);
                            setZipCode(e.currentTarget.value);
                          }}
                          onBlur={(e) => getDataFromZip(e.currentTarget.value)}
                          autoComplete="off"
                          isDisabled={isDisabled}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>{t("Zip Suffix")}:</FormControl>
                      <Input
                        type="text"
                        className="form-control dashboard-group-input"
                        name="s_PostalCodeSuffix"
                        title={t("Zip Suffix")}
                        parentobject="personTempAddr"
                        value={zipCodeSuffix}
                        isDisabled
                        onChange={(e) => {
                          handleChange(e);
                          setZipCodeSuffix(e.currentTarget.value);
                        }}
                        autoComplete="off"
                      />
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel>{t("County")}:</FormLabel>
                        <Input
                          className="form-control dashboard-group-input"
                          isDisabled
                          type="text"
                          name="s_CountyName"
                          title={t("County")}
                          parentobject="personTempAddr"
                          value={countryName}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel>{t("State")}:</FormLabel>
                        <Input
                          isDisabled
                          className="form-control dashboard-group-input"
                          type="text"
                          name="s_StateName"
                          title={t("State")}
                          parentobject="personTempAddr"
                          value={stateName}
                          readOnly
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel>{t("City")}:</FormLabel>
                        <Input
                          className="form-control dashboard-group-input"
                          isDisabled
                          type="text"
                          name="s_CityName"
                          title={t("City")}
                          parentobject="personTempAddr"
                          value={cityName}
                          readOnly
                        />
                      </FormControl>
                    </GridItem>
                  </>
                )}
              </Grid>
            </Box>
          </Card>
        </Box>
        <Box mt={4}>
          <Box>
            <Text fontSize={"xl"}>{t("Additional Details (Optional)")}</Text>
          </Box>
          <Card p={4} mt={4} borderRadius={"2xl"} boxShadow={"lg"}>
            <Box p={2}>
              <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Claim Type")}:</FormLabel>
                    <Select
                      //   className="custom-select-autocomplete"
                      getOptionValue={(option) => option.ClaimTypeId_PK}
                      getOptionLabel={(option) => option.Description}
                      value={
                        jsonData &&
                        allDropDown &&
                        allDropDown.claim_type_master.filter(
                          (option) =>
                            option.ClaimTypeId_PK == jsonData[0].claimType
                        )
                      }
                      options={allDropDown && allDropDown.claim_type_master}
                      onChange={(valueDropDown) =>
                        handleDropDownChange(
                          valueDropDown,
                          "mainNode",
                          "claimType",
                          "ClaimTypeId_PK"
                        )
                      }
                      isDisabled={isDisabled}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Type of Loss")}:</FormLabel>
                    <Select
                      //   className="custom-select-autocomplete"
                      getOptionValue={(option) => option.s_AppCodeName}
                      getOptionLabel={(option) =>
                        option.s_AppCodeNameForDisplay
                      }
                      value={
                        jsonData &&
                        allDropDown &&
                        allDropDown.type_of_loss_master.filter(
                          (option) =>
                            option.s_AppCodeName == jsonData[0].typeOfLoss
                        )
                      }
                      options={allDropDown && allDropDown.type_of_loss_master}
                      onChange={(valueDropDown) =>
                        handleDropDownChange(
                          valueDropDown,
                          "mainNode",
                          "typeOfLoss",
                          "s_AppCodeName"
                        )
                      }
                      isDisabled={isDisabled}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Service Rep")}:</FormLabel>
                    <Select
                      //   className="custom-select-autocomplete"
                      getOptionValue={(option) => option.Admin_ID}
                      getOptionLabel={(option) => option.s_ScreenName}
                      value={
                        jsonData &&
                        allDropDown &&
                        allDropDown.service_rep_master.filter(
                          (option) => option.Admin_ID == jsonData[0].serviceRep
                        )
                      }
                      options={allDropDown && allDropDown.service_rep_master}
                      onChange={(valueDropDown) =>
                        handleDropDownChange(
                          valueDropDown,
                          "mainNode",
                          "serviceRep",
                          "Admin_ID"
                        )
                      }
                      isDisabled={isDisabled}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Event Name")}:</FormLabel>
                    <Select
                      //   className="custom-select-autocomplete"
                      getOptionValue={(option) => option.s_ClaimEventCode}
                      getOptionLabel={(option) => option.s_ClaimEventName}
                      value={
                        jsonData &&
                        allDropDown &&
                        allDropDown.events_master.filter(
                          (option) =>
                            option.s_ClaimEventCode == jsonData[0].eventName
                        )
                      }
                      options={allDropDown && allDropDown.events_master}
                      onChange={(valueDropDown) =>
                        handleDropDownChange(
                          valueDropDown,
                          "mainNode",
                          "eventName",
                          "s_ClaimEventCode"
                        )
                      }
                      isDisabled={isDisabled}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Catastrophic Loss")}:</FormLabel>
                    <Box display={"flex"} justifyContent={"start"}>
                      <label style={{ marginRight: "0.5rem" }}>
                        {t("Yes")}
                      </label>
                      <Switch
                        mt={1}
                        isChecked={
                          jsonData && jsonData[0].catastropheLoss === "YES"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          handleSwitchChange(
                            e.target.checked,
                            e,
                            "catastropheLoss"
                          );
                        }}
                        colorScheme="cyan"
                        size="md"
                        isDisabled={isDisabled}
                      />
                      <label style={{ marginLeft: "0.5rem" }}>{t("No")}</label>
                    </Box>
                  </FormControl>
                </GridItem>
              </Grid>
              <Divider />
              <Grid
                templateColumns={{ md: "repeat(3, 1fr)", base: "1fr" }}
                gap={4}
                p={2}
              >
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("When did the loss occur")}?</FormLabel>
                    <DatePicker
                      as={Input}
                      className="datepicker-full-width-policy"
                      width="125%"
                      id="lossDate"
                      name="lossDate"
                      parentobject="firstNoticeOfLoss"
                      selected={
                        jsonData && jsonData[0].firstNoticeOfLoss.lossDate
                          ? setDate(jsonData[0].firstNoticeOfLoss.lossDate)
                          : ""
                      }
                      onChange={(date) => {
                        setDate1(date);
                        handleDateChange(date, "firstNoticeOfLoss", "lossDate");
                      }}
                      disabled
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("How did the loss occur")}?</FormLabel>
                    <Select
                      isDisabled
                      className="custom-select-autocomplete"
                      getOptionValue={(option) => option.ClaimTypeId_PK}
                      getOptionLabel={(option) => option.Description}
                      value={
                        jsonData &&
                        allDropDown &&
                        allDropDown.claim_type_master.filter(
                          (option) =>
                            option.ClaimTypeId_PK ==
                            jsonData[0].firstNoticeOfLoss.lossReason
                        )
                      }
                      options={allDropDown && allDropDown.claim_type_master}
                      onChange={(valueDropDown) =>
                        handleDropDownChange(
                          valueDropDown,
                          "firstNoticeOfLoss",
                          "lossReason",
                          "ClaimTypeId_PK"
                        )
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Which areas are damaged")}?</FormLabel>
                    <Select
                      isDisabled
                      className="custom-select-autocomplete"
                      getOptionValue={(option) => option.s_AppCodeName}
                      getOptionLabel={(option) =>
                        option.s_AppCodeNameForDisplay
                      }
                      value={
                        jsonData &&
                        allDropDown &&
                        allDropDown.type_of_loss_master.filter(
                          (option) =>
                            option.s_AppCodeName ==
                            jsonData[0].firstNoticeOfLoss.TypeofLoss
                        )
                      }
                      options={allDropDown && allDropDown.type_of_loss_master}
                      onChange={(valueDropDown) =>
                        handleDropDownChange(
                          valueDropDown,
                          "mainNode",
                          "TypeofLoss",
                          "s_AppCodeName"
                        )
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>
                      {t("How much water is in the building")}?
                    </FormLabel>
                    <InputGroup>
                      <Flex alignItems={"center"} gap={2} flexDir={"row"}>
                        <Text as={"span"} opacity={"60%"}>
                          ft
                        </Text>
                        <Input
                          type="text"
                          name="waterLevelFeet"
                          id="waterLevelFeet"
                          className="form-control"
                          style={{ textAlign: "left", width: "150px" }}
                          parentobject="firstNoticeOfLoss"
                          onChange={handleChange}
                          value={
                            jsonData &&
                            jsonData[0].firstNoticeOfLoss.waterLevelFeet
                          }
                          disabled={isDisabled}
                        />
                        <Text as={"span"} opacity={"60%"}>
                          in
                        </Text>
                        <Input
                          type="text"
                          name="waterLevelInch"
                          id="waterLevelInch"
                          className="form-control"
                          style={{ textAlign: "left", width: "150px" }}
                          parentobject="firstNoticeOfLoss"
                          onChange={handleChange}
                          value={
                            jsonData &&
                            jsonData[0].firstNoticeOfLoss.waterLevelInch
                          }
                          disabled={isDisabled}
                        />
                      </Flex>
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>
                      {t("How long has the water remained inside the building")}
                      ?
                    </FormLabel>
                    <InputGroup>
                      <Flex alignItems={"center"} gap={2} flexDir={"row"}>
                        <Text as={"span"} opacity={"60%"}>
                          Days
                        </Text>
                        <Input
                          type="text"
                          name="waterRemainedDays"
                          id="waterRemainedDays"
                          className="form-control"
                          style={{ textAlign: "left", width: "150px" }}
                          parentobject="firstNoticeOfLoss"
                          onChange={handleChange}
                          value={
                            jsonData &&
                            jsonData[0].firstNoticeOfLoss.waterRemainedDays
                          }
                          disabled={isDisabled}
                        />
                        <Text as={"span"} opacity={"60%"}>
                          Hours
                        </Text>
                        <Input
                          type="text"
                          name="waterRemainedHours"
                          id="waterRemainedHours"
                          className="form-control"
                          style={{ textAlign: "left", width: "150px" }}
                          parentobject="firstNoticeOfLoss"
                          onChange={handleChange}
                          value={
                            jsonData &&
                            jsonData[0].firstNoticeOfLoss.waterRemainedHours
                          }
                          disabled={isDisabled}
                        />
                      </Flex>
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>
                      {t("Have you been allowed back into the property")}?
                    </FormLabel>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                      className="custom-radio-check0"
                    >
                      <Form.Check
                        type="radio"
                        label={t("Yes")}
                        className="check-question-radio"
                        name="allowedBack"
                        value={"YES"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.allowedBack === "YES"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                      <Form.Check
                        type="radio"
                        label={t("No")}
                        name="allowedBack"
                        value={"NO"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.allowedBack === "NO"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl>
                    <FormLabel>
                      {t(
                        "Does the applicant or applicant's spouse live in this building more than 80% of the year"
                      )}
                      ?
                    </FormLabel>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                      className="custom-radio-check0"
                    >
                      <Form.Check
                        type="radio"
                        label={t("Yes")}
                        className="check-question-radio"
                        name="insuredResides"
                        value={"YES"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.insuredResides === "YES"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                      <Form.Check
                        type="radio"
                        label={t("No")}
                        name="insuredResides"
                        value={"NO"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.insuredResides === "NO"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                    </div>
                  </FormControl>
                </GridItem>
              </Grid>
              <Divider />
              <Box p={2}>
                <Text fontWeight={600}>
                  {t(
                    "Please keep samples of damaged items that are being removed (i.e. Flooring, Carpeting, Base boards, etc.). Take photos before throwing anything away and take photos of appliance serial number before removing. Start clean up as soon as possible"
                  )}
                  .
                </Text>
              </Box>
              <Grid
                templateColumns={{ md: "repeat(2, 1fr)", base: "1fr" }}
                gap={4}
                px={2}
              >
                {jsonData &&
                  jsonData[0].firstNoticeOfLoss.allowedBack == "YES" && (
                    <GridItem>
                      <FormControl>
                        <FormLabel>{t("Has clean up begun")}?</FormLabel>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="custom-radio-check0"
                        >
                          <Form.Check
                            type="radio"
                            label={t("Yes")}
                            className="check-question-radio"
                            name="isCleanUpStart"
                            value={"YES"}
                            checked={
                              jsonData &&
                              jsonData[0].firstNoticeOfLoss.isCleanUpStart ===
                                "YES"
                                ? true
                                : false
                            }
                            parentobject="firstNoticeOfLoss"
                            onChange={handleQRadioChange}
                            style={{ marginRight: "10%" }}
                            disabled={isDisabled}
                          />
                          <Form.Check
                            type="radio"
                            label={t("No")}
                            name="isCleanUpStart"
                            value={"NO"}
                            checked={
                              jsonData &&
                              jsonData[0].firstNoticeOfLoss.isCleanUpStart ===
                                "NO"
                                ? true
                                : false
                            }
                            parentobject="firstNoticeOfLoss"
                            onChange={handleQRadioChange}
                            style={{ marginRight: "10%" }}
                            disabled={isDisabled}
                          />
                        </div>
                      </FormControl>
                    </GridItem>
                  )}
                {jsonData &&
                  jsonData[0].firstNoticeOfLoss.allowedBack == "YES" && (
                    <GridItem>
                      <FormControl>
                        <FormLabel>{t("Have repairs begun")}?</FormLabel>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="custom-radio-check0"
                        >
                          <Form.Check
                            type="radio"
                            label={t("Yes")}
                            className="check-question-radio"
                            name="repairStart"
                            value={"YES"}
                            checked={
                              jsonData &&
                              jsonData[0].firstNoticeOfLoss.repairStart ===
                                "YES"
                                ? true
                                : false
                            }
                            parentobject="firstNoticeOfLoss"
                            onChange={handleQRadioChange}
                            style={{ marginRight: "10%" }}
                            disabled={isDisabled}
                          />
                          <Form.Check
                            type="radio"
                            label={t("No")}
                            name="repairStart"
                            value={"NO"}
                            checked={
                              jsonData &&
                              jsonData[0].firstNoticeOfLoss.repairStart === "NO"
                                ? true
                                : false
                            }
                            parentobject="firstNoticeOfLoss"
                            onChange={handleQRadioChange}
                            style={{ marginRight: "10%" }}
                            disabled={isDisabled}
                          />
                        </div>
                      </FormControl>
                    </GridItem>
                  )}
                {jsonData &&
                  jsonData[0].firstNoticeOfLoss.repairStart == "YES" && (
                    <GridItem>
                      <FormControl>
                        <FormLabel>
                          {t("What repairs are being made")}?
                        </FormLabel>
                        <br />
                        <Input
                          type="text"
                          name="repairs"
                          id="repairs"
                          className="form-control"
                          style={{ textAlign: "left" }}
                          parentobject="firstNoticeOfLoss"
                          onChange={handleChange}
                          value={
                            jsonData && jsonData[0].firstNoticeOfLoss.repairs
                          }
                          disabled={isDisabled}
                        />
                      </FormControl>
                    </GridItem>
                  )}
                {jsonData &&
                  jsonData[0].firstNoticeOfLoss.repairStart == "YES" && (
                    <GridItem>
                      <FormControl>
                        <FormLabel>
                          {t(
                            "What is the name of the contractor doing the repairs"
                          )}
                          ?
                        </FormLabel>
                        <Input
                          type="text"
                          name="contractorName"
                          id="contractorName"
                          className="form-control"
                          style={{ textAlign: "left" }}
                          parentobject="firstNoticeOfLoss"
                          onChange={handleChange}
                          value={
                            jsonData &&
                            jsonData[0].firstNoticeOfLoss.contractorName
                          }
                          disabled={isDisabled}
                        />
                      </FormControl>
                    </GridItem>
                  )}
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Is this a residence")}?</FormLabel>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                      className="custom-radio-check0"
                    >
                      <Form.Check
                        type="radio"
                        label={t("Yes")}
                        className="check-question-radio"
                        name="isResidence"
                        value={"YES"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.isResidence === "YES"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                      <Form.Check
                        type="radio"
                        label={t("No")}
                        name="isResidence"
                        value={"NO"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.isResidence === "NO"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>
                      {t("Is there a public adjuster involved")}?
                    </FormLabel>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                      className="custom-radio-check0"
                    >
                      <Form.Check
                        type="radio"
                        label={t("Yes")}
                        className="check-question-radio"
                        name="isAdjuster"
                        value={"YES"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.isAdjuster === "YES"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                      <Form.Check
                        type="radio"
                        label={t("No")}
                        name="isAdjuster"
                        value={"NO"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.isAdjuster === "NO"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>
                      {t(
                        "Is the civil authority allowing anyone other than property owners into the property"
                      )}
                      ?
                    </FormLabel>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                      className="custom-radio-check0"
                    >
                      <Form.Check
                        type="radio"
                        label={t("Yes")}
                        className="check-question-radio"
                        name="allowedOther"
                        value={"YES"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.allowedOther === "YES"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                      <Form.Check
                        type="radio"
                        label={t("No")}
                        name="allowedOther"
                        value={"NO"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.allowedOther === "NO"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>
                      {t(
                        "Is the following mortgagee information correct and up-to-date"
                      )}
                      ?
                    </FormLabel>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                      className="custom-radio-check0"
                    >
                      <Form.Check
                        type="radio"
                        label={t("Yes")}
                        className="check-question-radio"
                        name="isMortgagee"
                        value={"YES"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.isMortgagee === "YES"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                      <Form.Check
                        type="radio"
                        label={t("No")}
                        name="isMortgagee"
                        value={"NO"}
                        checked={
                          jsonData &&
                          jsonData[0].firstNoticeOfLoss.isMortgagee === "NO"
                            ? true
                            : false
                        }
                        parentobject="firstNoticeOfLoss"
                        onChange={handleQRadioChange}
                        style={{ marginRight: "10%" }}
                        disabled={isDisabled}
                      />
                    </div>
                  </FormControl>
                </GridItem>
              </Grid>
            </Box>
          </Card>
          <Card p={4} mt={4} borderRadius={"2xl"} boxShadow={"lg"}>
            <Box p={2}>
              <TableContainer borderRadius={"2xl"}>
                <Table
                  variant="unstyled"
                  border={"1px solid gray"}
                  borderColor={"gray.200"}
                  overflow={"auto"}
                >
                  <Thead bg={"var(--chakra-colors-agGridHeaderBg)"}>
                    <Tr>
                      <Th>TYPE</Th>
                      <Th>MORTGAGEES NAME</Th>
                      <Th>MORTGAGEES ADDRESS</Th>
                      <Th>LOAN#</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {claims.length > 0 ? (
                      claims?.map((key, val) => (
                        <Tr key={key}>
                          <Td>{val.s_PoMortgageeTypeCode}</Td>
                          <Td>{val.s_FullLegalName}</Td>
                          <Td>{val.product_type}</Td>
                          <Td>{val.s_LoanNumber}</Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td
                          textAlign={"center"}
                          fontWeight={"light"}
                          colSpan={4}
                        >
                          {t("No Records Found")}
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
        </Box>
        <Box mt={4} mb={4}>
          <Center>
            {!isDisabled ? (
              <Button size={"md"} onClick={handleSubmit}>
                {t("Report A Claim")}
              </Button>
            ) : (
              <p>
                {t("Status")} :{" "}
                <b>{ReportedClaimDetails && ReportedClaimDetails.status}</b>
              </p>
            )}
          </Center>
        </Box>
      </LoadingOverlay>
    </>
  );
};

function mapStateToProps(state) {
  return {
    getUserPolicyList: state.common.getUserPolicyList
      ? state.common.getUserPolicyList
      : {},
  };
}
export default connect(mapStateToProps)(ReportACLaim);
